<div class="col-6 offset-3 d-flex justify-content-center">
    <div class="card mt-5">
        <div class="card-header"><i class="fas fa-file-import mr-2"></i>FileShare</div>
        <div class="card-body">
            <div class="bg-warning text-dark p-3" *ngIf="problem">{{ problem }}</div>

            <ng-container *ngIf="!problem && fileLink">
                <dl class="row">
                    <dt class="col-sm-4">Datei</dt>
                    <dd class="col-sm-8">{{ fileLink.display_name }}</dd>

                    <dt class="col-sm-4">Größe</dt>
                    <dd class="col-sm-8">{{ fileLink.size | fileSize }}</dd>

                    <dt class="col-sm-4">Erstellt</dt>
                    <dd class="col-sm-8">{{ fileLink.created | date: "dd.MM.yyyy HH:mm" }}</dd>

                    <dt class="col-sm-4">Von</dt>
                    <dd class="col-sm-8">{{ fileLink.firstname }} {{ fileLink.lastname }}</dd>

                    <dt class="col-sm-4">Ablaufdatum</dt>
                    <dd class="col-sm-8">
                        <span *ngIf="fileLink.expiry">{{ fileLink.expiry | date: "dd.MM.yyyy" }}</span>
                        <span *ngIf="!fileLink.expiry" class="text-muted">Keiner</span>
                    </dd>

                    <dt class="col-sm-4">Einmalige Verwendung</dt>
                    <dd class="col-sm-8">{{ fileLink.single_use | yesNo }}<ng-container *ngIf="fileLink.used == '1'">, verwendet</ng-container></dd>

                    <dt class="col-sm-4">Downloads</dt>
                    <dd class="col-sm-8">{{ fileLink.downloads }}</dd>

                    <ng-container *ngIf="fileLink.password">
                        <dt class="col-sm-4">Passwort</dt>
                        <dd class="col-sm-8"><input type="password" class="form-control" name="password" [(ngModel)]="password" required></dd>
                    </ng-container>
                </dl>

                <button type="button" class="btn btn-primary btn-block btn-lg" (click)="download()" [disabled]="fileLink.used == '1' || expired || (fileLink.password && !password)">Download</button>

                <div class="bg-warning text-dark p-3 mt-3" *ngIf="fileLink.used == '1'">Dieser Link ist nur einmalig gültig und wurde bereits verwendet.</div>
                <div class="bg-warning text-dark p-3 mt-3" *ngIf="expired">Dieser Link ist abgelaufen und nicht mehr gültig.</div>
                <div class="bg-warning text-dark p-3 mt-3" *ngIf="invalidPassword">Falsches Passwort, bitte erneut eingeben!</div>

            </ng-container>
        </div>
    </div>
</div>



