<div class="btn-group btn-group-toggle mb-3" data-toggle="buttons" *ngIf="showViewLayoutSwitcher && files.length > 0">
    <label class="btn btn-secondary" [class.active]="viewLayout == fileViewLayout.TABLE" (click)="setLayout(fileViewLayout.TABLE)">
        <input type="radio" name="options" autocomplete="off" checked> Tabelle
    </label>
    <label class="btn btn-secondary" [class.active]="viewLayout == fileViewLayout.TILES" (click)="setLayout(fileViewLayout.TILES)">
        <input type="radio" name="options" autocomplete="off"> Kacheln
    </label>
</div>

<div class="pb-2 d-flex flex-wrap">
    <ng-container *ngIf="files.length == 0 && viewNoFilesText">
        <span class="text-muted">{{viewNoFilesText}}</span>
    </ng-container>

    <ng-container *ngIf="viewLayout == fileViewLayout.TILES">
        <div *ngFor="let file of files">
            <ng-container *ngIf="viewFullscreen">
                <img [src]="file.imgData" class="img-fluid">
            </ng-container>
            <ng-container *ngIf="!viewFullscreen">
                <div class="d-flex uploaded-image rounded" style="cursor: pointer">
                    <div class="rounded">
                        <div class="d-flex flex-row">
                            <div class="d-flex flex-column">
                                <div>
                                    <div class="d-flex position-relative">
                                        <img class="img-fluid rounded"
                                             (click)="openFileModal(file.FID)"
                                             [src]="file.imgData | safeHtml"
                                             [ngStyle]="{'width': viewThumbnailsTilesSize !== null ? viewThumbnailsTilesSize : 'auto', 'height': viewThumbnailsTilesSize !== null ? viewThumbnailsTilesSize : 'auto'}">
                                        <div *ngIf="canBeDeleted(file)" class="position-absolute" style="right: 0; top: 0">
                                            <button type="button" class="btn btn-danger btn-sm" (click)="openDeleteFileModal($event, file)"><i class="fa fa-times"></i></button>
                                        </div>
                                        <div *ngIf="viewCheckbox" class="position-absolute" style="left: 0; top: 0">
                                            <input type="checkbox" [(ngModel)]="file.selected" (ngModelChange)="handleSelectedChange()">
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="viewThumbnailsTitle === true" class="text-center">
                                    <small>{{file.display_name}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="viewLayout == fileViewLayout.TABLE && files.length > 0">
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                <tr>
                    <th *ngIf="viewCheckbox"></th>
                    <th></th>
                    <th>Vorschau</th>
                    <ng-container *ngFor="let field of myAppConfig.fileFields">
                        <th>{{field.name}}</th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let file of files">
                    <td *ngIf="viewCheckbox">
                        <input type="checkbox" [(ngModel)]="file.selected" (ngModelChange)="handleSelectedChange()">
                    </td>
                    <td>
                        <button type="button" class="btn btn-danger btn-sm" (click)="openDeleteFileModal($event, file)" *ngIf="canBeDeleted(file)"><i class="fa fa-times"></i></button>
                    </td>
                    <td>
                        <img
                            style="cursor: pointer"
                            class="img-fluid rounded"
                            [src]="file.imgData | safeHtml"
                            [ngStyle]="{'width ':viewThumbnailsSize !== null ? viewThumbnailsSize : 'auto', 'height':viewThumbnailsSize !== null ? viewThumbnailsSize : 'auto' }"
                            (click)="openFileModal(file.FID)">
                    </td>
                    <ng-container *ngFor="let field of myAppConfig.fileFields">
                        <td>
                            <ng-container [ngSwitch]="field.key">
                                <ng-container *ngSwitchCase="'size'">
                                    {{file[field.key] | fileSize}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'create_date'">
                                    {{file[field.key] | date: "dd.MM.yyyy, HH:mm"}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'creator_name'">
                                    {{file.firstname}} {{file.lastname}}
                                    <span class="text-muted" *ngIf="!file.firstname">Nicht gesetzt</span>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{file[field.key]}}
                                    <span class="text-muted" *ngIf="!file[field.key]">Nicht gesetzt</span>
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>

<ng-template #deleteTemplate>
    <div class="modal-header bg-danger text-white">
        <h4 class="modal-title pull-left"><i class="fa fa-warning"></i> File löschen</h4>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="result.selectedFile">
            <div class="col-md-8 pt-3">
                <table class="deleteInfo">
                    <tbody>
                    <tr *ngFor="let field of myAppConfig.fileFields">
                        <td>{{field.name}}</td>
                        <td>
                            <strong>{{field.key == 'size' ? (result.selectedFile[field.key] | fileSize) : result.selectedFile[field.key]}}</strong>
                            <span class="text-muted" *ngIf="!result.selectedFile[field.key]">Nicht gesetzt</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-4">
                <img [src]="result.selectedFile.imgData" class="img-fluid">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteFile(result.selectedFile)">Löschen</button>
        <button type="button" class="btn btn-secondary" (click)="modalDeleteRef?.hide()">Abbrechen</button>
    </div>
</ng-template>

<app-file-modal #fileModalComponent
        [viewFlipImage]="viewEditModeFlipImage"
        [viewFileInfo]="viewShowFileInfo"
        [files]="files"
        [enableLinks]="enableLinks"
/>
