import {Component, OnDestroy, OnInit, ViewChild, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {Router, RouterLink} from '@angular/router';
import {CryptoService} from '../../../global/services/crypto.service';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import {FwFileViewLayout, FileComponent} from '../../../global/components/file/file.component';
import {Subscription} from 'rxjs';
import {FormService} from '../../../global/services/form.service';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {UserService} from '../../../global/services/user.service';
import {validateDate} from '../../../global/validators/validateDate';
import {TaskDateReminderComponent} from '../task-date-reminder/task-date-reminder.component';
import {ViewMode} from '../../../interfaces';
import {TranslateService, TranslateModule} from "@ngx-translate/core";
import {BreadcrumbService} from "../../../global/components/breadcrumb/breadcrumb.service";
import {NgIf, NgClass, DatePipe} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CommentComponent} from '../../../global/components/comment/comment.component';
import {TaskStatusPipe} from '../../../global/pipes/task-status.pipe';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {ToastrService} from "ngx-toastr";

enum TabsEnum {
    REMINDERS = 'reminders',
    COMMENTS = 'comments',
    ATTACHMENTS = 'attachments',
}

@Component({
    selector: 'app-task-date-detail',
    templateUrl: './task-date-detail.component.html',
    styleUrl: './task-date-detail.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, TooltipModule, RouterLink, TaskDateReminderComponent, CommentComponent, FileComponent, DatePipe, TranslateModule, TaskStatusPipe, RightPipe]
})
export class TaskDateDetailComponent implements OnInit, OnDestroy {

    formService = inject(FormService);
    routerHelperService = inject(RouterHelperService);
    userService = inject(UserService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);
    private translateService = inject(TranslateService);
    private breadcrumbService = inject(BreadcrumbService);
    private toastrService = inject(ToastrService);

    @ViewChild('taskDateReminderComponent') taskDateReminderComponent!: TaskDateReminderComponent;

    readonly ViewMode = ViewMode;
    readonly TABS = TabsEnum;
    readonly FileViewLayout = FwFileViewLayout;

    form!: UntypedFormGroup;
    taskdate: any = {};
    task!: any;
    TDID!: string;
    TID!: string;
    viewMode!: ViewMode;
    private changesList$: Subscription[] = [];
    tab!: TabsEnum;
    tabCount: any = {};
    startInFuture = false;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.TDID = urlData.ID;
        if (urlData.data?.TID) {
            this.TID = urlData.data.TID; // only required if a new taskdate is created
        }
        this.viewMode = urlData.viewMode;

        this.form = this.fb.group({
            TDID: [null],
            TID: [this.TID],
            description: [null],
            start_date: [null, validateDate],
            end_date: [null, [Validators.required, validateDate]],
            status: ['open', Validators.required],
            oldStatus: [null],
            status_info: [''],
        });

        if (this.TDID !== '0') {
            this.getTaskDate();
        } else {
            // set start and end date if they are passed in urlData (only for generated taskdates)
            if (urlData.data.end_date) {
                this.form.patchValue({
                    start_date: urlData.data.start_date,
                    end_date: urlData.data.end_date,
                    status: 'open',
                });
            }
        }

        if (this.TID) {
            this.dataService.request('Task/get', {TID: this.TID}).subscribe(response => {
                this.task = response.task;
            });
        }

        this.setTab(TabsEnum.REMINDERS);
    }

    ngOnDestroy(): void {
        this.changesList$.forEach(element => {
            element.unsubscribe();
        });
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;
        window.location.hash = tab;
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    getTaskDate(): void {
        this.dataService.request('TaskDate/get', {
            TDID: this.TDID
        }).subscribe(response => {
            this.taskdate = response.taskdate;
            this.tabCount = response.tabCount;

            if (!this.task) {
                this.dataService.request('Task/get', {
                    TID: this.taskdate.TID
                }).subscribe(response => {
                    this.task = response.task;

                    this.breadcrumbService.setManually([
                        {
                            label: {title: 'Unternehmen ' + this.task.companyName},
                            url: '/app/company/detail/' + this.cryptoService.encrypt({ID: this.task.CID})
                        },
                        {
                            label: {title: this.translateService.instant('LOCATION.LOCATION') + ' ' + this.task.locationName},
                            url: '/app/location/detail/' + this.cryptoService.encrypt({ID: this.task.LID})
                        },
                        {
                            label: {title: 'Dokument ' + this.task.documentName},
                            url: '/app/document/detail/' + this.cryptoService.encrypt({ID: this.task.DID})
                        },
                        {
                            label: {title: 'Aufgabe ' + this.task.title},
                            url: '/app/task/detail/' + this.cryptoService.encrypt({ID: this.taskdate.TID})
                        },
                        {label: {title: 'Termin Detail'}},
                    ]);
                });
            }
            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            TDID: this.taskdate.TDID,
            TID: this.taskdate.TID,
            description: this.taskdate.description,
            start_date: this.taskdate.start_date,
            end_date: this.taskdate.end_date,
            status: this.taskdate.status,
            oldStatus: this.taskdate.status,
            status_info: this.taskdate.status_info
        });

        // toggle status options based on whether start_date is set and if it is in the future or not
        this.changesList$.push(this.form.get('start_date').valueChanges.subscribe(val => {
            if (val === '') {
                this.startInFuture = false;
                this.form.patchValue({status: 'open'});
            } else {
                const date = dayjs(val);
                if (date.isValid()) {
                    this.startInFuture = date.isAfter(dayjs());
                    if (this.startInFuture) {
                        this.form.patchValue({status: 'wait'});
                    } else {
                        this.form.patchValue({status: 'open'});
                    }
                }
            }
        }));

        if (this.taskdate.start_date !== null) {
            const date = dayjs(this.taskdate.start_date);
            if (date.isValid()) {
                this.startInFuture = date.isAfter(dayjs());
            }
        }
    }

    save(): void {
        this.dataService.request('TaskDate/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Termin wurde gespeichert');
            this.cancel();
        });
    }

    update(): void {
        this.dataService.request('TaskDate/update', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Termin wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
            this.getTaskDate();
            this.taskDateReminderComponent.listAll();
        });
    }

    cancel(): void {
        this.routerHelperService.openTask(this.TID);
    }

    edit(): void {
        this.viewMode = ViewMode.EDIT;
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Der Termin wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('TaskDate/delete', {TDID: this.TDID}).subscribe(() => {
                    this.toastrService.success('Termin wurde gelöscht');
                    this.cancel();
                });
            }
        });
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    getTabCount(): void {
        this.dataService.request('TaskDate/getTabCount', {TDID: this.TDID}).subscribe(response => {
            this.tabCount = response.tabCount;
        });
    }

    inPast(): boolean {
        return dayjs(this.form.value.end_date).isBefore(dayjs());
    }
}
