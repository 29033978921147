<div class="container">
    <div class="row">
        <div class="col">
            <h1 class="display-4 text-center my-3">Datenschutz</h1>

            <p>Vereinbarung über eine Auftragsverarbeitung nach Art. 28 DSGVO.</p>

            <p class="font-weight-bold">1. Allgemeines</p>

            <p>NoteRadar verarbeitet unter anderem personenbezogene Daten (z.B. Name, E-Mail-Adresse, etc.), die in der Verantwortung des Auftraggebers erhoben, auf Systemen von NoteRadar verarbeitet und für den jeweiligen Zweck und die
                benötigte Dauer gespeichert werden. Insbesondere sind folgende Tätigkeiten umfasst:</p>

            <ul>
                <li>Zurverfügungstellung einer Software-as-a-Service Lösung für Bescheid-, Vertrags und FileShare Management.</li>
                <li>Abwicklung von Bestellungen, Verrechnungen und Kundenkorrespondenz.</li>
                <li>Abwicklung von Supportleistungen bei Anfragen.</li>
            </ul>

            <p class="font-weight-bold">2. Datenarten</p>

            <p>Folgende Datenarten sind regelmäßig Gegenstand der Verarbeitung:</p>

            <ul>
                <li>Stammdaten und Kontaktdaten</li>
                <li>Angebotsdaten, Vertragsdaten und Verrechnungsdaten</li>
                <li>Benutzer-/Teilnehmerdaten</li>
                <li>Sicherungen und Auswertungen</li>
                <li>Daten für den Newsletter-Versand</li>
            </ul>

            <p>NoteRadar verarbeitet die Daten des Auftraggebers und seiner Nutzer zu den angeführten Zwecken und der Auftraggeber stimmt dieser Verarbeitung ausdrücklich zu. Seine Zustimmung kann der Auftraggeber jederzeit widerrufen.</p>

            <p>Persönliche Daten: NoteRadar speichert bei Registrierung die E-Mail Adresse und persönliches Kennwort für den Log-In in den gesicherten Bereich von NoteRadar. NoteRadar nutzt die E-Mail Adresse der Nutzer weiters, um den Nutzern
            Systembenachrichtigungen bei Nutzung der Dienstleistungen (z.B. Benachrichtigungen über neue Features) und Informationen zu System und Produkten von NoteRadar zu übermitteln. NoteRadar speichert weiters Name, Vorname,
            Telefonnummer, Rechnungsadresse (Straße, Hausnummer, PLZ, Ort, E- Mail Adresse), UID-Nummer und Firmennamen des Auftraggebers zur Erbringung der Leistungen und deren Abrechnung auf Basis der AGB.</p>

            <p>Dateien: Im Rahmen der Nutzung der Leistungen von NoteRadar kann der Auftraggeber Bescheide, Verträge, Unterlagen, Pläne sowie Fotos, Bilder, Texte, Audioinformationen, etc. auf den Webservern von NoteRadar speichern. Die
            gespeicherten Dateien werden jedem Nutzer zugänglich gemacht, den der Auftraggeber für diesen Mandanten freigeschaltet hat. Der Auftraggeber kann die derart von ihm gespeicherten Dateien und Daten jederzeit wieder löschen.</p>

            <p>Login Daten: Loggt sich der Nutzer mit seiner E-Mail Adresse und seinem persönlichen Kennwort bei NoteRadar ein, so speichert NoteRadar Login- Zeitpunkt und -Datum. NoteRadar nutzt diese Daten zur Erkennung und Behebung von
            Fehlern, Verbesserung des Services und zur Bearbeitung von Kundenanfragen oder -beschwerden.</p>

            <p class="font-weight-bold">3. Kategorien</p>

            <p>Folgende Kategorien betroffener Personen unterliegen der Verarbeitung:</p>

            <ul>
                <li>Kunden</li>
                <li>Interessenten</li>
                <li>Vertragspartner des Auftraggebers</li>
                <li>Beschäftigte des Auftraggebers</li>
                <li>Dritte (Subunternehmer)</li>
            </ul>

            <p class="font-weight-bold">4. Dauer der Vereinbarung</p>

            <p>Die Vereinbarung endet mit Beendigung der durchgeführten Datenverarbeitung und der verpflichtenden Datenlöschung durch NoteRadar.</p>

            <p class="font-weight-bold">5. Pflichten von NoteRadar</p>

            <p>NoteRadar verpflichtet sich, Daten ausschließlich im Rahmen der schriftlichen Aufträge des Auftraggebers zu verarbeiten. Erhält NoteRadar einen behördlichen Auftrag, Daten des Auftraggebers herauszugeben, so hat er – sofern
                gesetzlich zulässig – den Auftraggeber unverzüglich darüber zu informieren und die Behörde an diesen zu verweisen.</p>

            <p>NoteRadar erklärt rechtsverbindlich, dass alle mit der Datenverarbeitung beauftragten Personen vor Aufnahme der Tätigkeit zur Vertraulichkeit verpflichtet wurden oder diese einer angemessenen gesetzlichen
                Verschwiegenheitsverpflichtung unterliegen.</p>

            <p>NoteRadar erklärt rechtsverbindlich, dass alle erforderlichen Maßnahmen zur Gewährleistung der Sicherheit der Verarbeitung nach Art. 32 DSGVO ergriffen wurden.</p>

            <p>NoteRadar ergreift die technischen und organisatorischen Maßnahmen, damit der Auftraggeber die Rechte der betroffenen Person nach Kapitel III der DSGVO (Information, Auskunft, Berichtigung und Löschung, Datenübertragbarkeit,
                Widerspruch, sowie automatisierte Entscheidungsfindung im Einzelfall) innerhalb der gesetzlichen Fristen jederzeit erfüllen kann und überlässt dem Auftraggeber alle dafür notwendigen Informationen. Wird ein entsprechender
                Antrag an den NoteRadar gerichtet und lässt dieser erkennen, dass der Antragsteller ihn irrtümlich für den Auftraggeber der von ihm betriebenen Datenanwendung hält, hat NoteRadar den Antrag unverzüglich an den Auftraggeber
                weiterzuleiten und dies dem Antragsteller mitzuteilen.</p>

            <p>Dem Auftraggeber wird hinsichtlich der Verarbeitung der von ihm überlassenen Daten das Recht jederzeitiger Einsichtnahme und Kontrolle, sei es auch durch ihn beauftragte Dritte, eingeräumt. NoteRadar verpflichtet sich, dem
                Auftraggeber jene Informationen zur Verfügung zu stellen, die zur Kontrolle der Einhaltung der in dieser Vereinbarung genannten Verpflichtungen notwendig sind.</p>

            <p>NoteRadar ist nach Beendigung dieser Vereinbarung verpflichtet, alle Verarbeitungsergebnisse und Unterlagen, die Daten enthalten, im Auftrag des Auftraggebers zu vernichten.</p>

            <p>NoteRadar hat den Auftraggeber unverzüglich zu informieren, falls NoteRadar der Ansicht ist, eine Weisung des Auftraggebers verstößt gegen Datenschutzbestimmungen der Europäischen Union oder der Mitgliedstaaten.</p>

            <p class="font-weight-bold">6. Ort der Durchführung</p>

            <p>Alle Datenverarbeitungstätigkeiten werden ausschließlich innerhalb der EU bzw. des EWR durchgeführt.</p>

            <p class="font-weight-bold">7. Sub-Auftragsverarbeiter</p>

            <p>NoteRadar zieht die folgenden Sub-Auftragsverarbeiter im Bereich Hosting hinzu: World4You Internet Services GmbH.</p>

            <p class="font-weight-bold">8. Pflichten des Auftraggebers</p>

            <p>Der Auftraggeber wird beim Umgang mit personenbezogenen Daten die Vorschriften des Datenschutzgesetzes und des Telekommunikationsgesetzes beachten und die für den Datenschutz im Verantwortungsbereich vom Auftraggeber
                erforderlichen technischen und organisatorischen Maßnahmen treffen.</p>

            <p>Der Auftraggeber verpflichtet sich und insbesondere seine Mitarbeiter, die Bestimmungen des Datenschutzgesetzes einzuhalten.</p>

            <p>Der Auftraggeber ergreift alle zumutbaren Maßnahmen in seinem Verantwortungsbereich, um die gespeicherten Daten und Informationen gegen den unberechtigten Zugriff Dritter zu schützen. NoteRadar ist nicht dafür verantwortlich,
                wenn es Dritten gelingt, sich auf rechtswidrige Weise Zugang zu den Daten und Informationen zu verschaffen.</p>

            <p>Der Auftraggeber kann weitere Nutzer (z.B. seine Subunternehmen) mittels Eingabe ihrer E-Mail Adresse(n) zur Benützung der Software für ein konkretes Betriebsstätte und Aufgabe einladen. Diesfalls wird der Auftraggeber im
                Voraus die nachweisliche Zustimmung des jeweiligen Nutzers zu dieser Nutzung seiner personenbezogenen Daten einholen.</p>

            <p class="font-weight-bold">9. Ihre Rechte/Kontakt</p>

            <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu.</p>
            <p>Sie erreichen uns unter folgenden Kontaktdaten:</p>

            <p>
                NoteRadar GmbH<br/>
                Badener Straße 6/1b<br/>
                A-2512 Tribuswinkel<br/>
                Austria
            </p>

            <p>Sie erreichen unseren Datenschutzbeauftragten unter <a href="mailto: datenschutz@noteradar.at">datenschutz&#64;noteradar.at</a></p>

        </div>
    </div>
</div>
