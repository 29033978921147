import {Component, EventEmitter, Input, Output, ViewEncapsulation, inject} from '@angular/core';
import {
    TableConfig,
    TableFieldAlignment,
    TableColumnFilterType,
    TableRowColorConditionOperator
} from '../table.interfaces';
import dayjs from 'dayjs';
import {UserService} from '../../../services/user.service';
import {ViewMode} from '../../../../interfaces';
import {NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, DecimalPipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TableTableFieldImageComponent} from './table-table-field-image/table-table-field-image.component';
import {FileViewerSingleComponent} from '../../file/file-viewer-single/file-viewer-single.component';
import {FileModalComponent} from '../../file/file-modal/file-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {DebounceKeyupDirective} from "../../../directives/debounce-keyup.directive";

@Component({
    selector: 'app-table-table',
    templateUrl: './table-table.component.html',
    styleUrl: './table-table.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, NgFor, FormsModule, NgClass, NgSwitch, NgSwitchCase, TableTableFieldImageComponent, NgSwitchDefault, FileViewerSingleComponent, FileModalComponent, DecimalPipe, TranslateModule, DebounceKeyupDirective]
})
export class TableTableComponent {

    userService = inject(UserService);

    @Input() config!: TableConfig;
    @Input() result: any;
    @Output() changeOpenDataset = new EventEmitter<any>();
    @Output() changeSort = new EventEmitter();
    @Output() changeColumnFilterValue = new EventEmitter();

    readonly TableFieldAlignment = TableFieldAlignment;
    readonly TableColumnFilterType = TableColumnFilterType;

    rowFocus = null;

    changeColumnFilter(): void {
        this.changeColumnFilterValue.emit();
    }

    openDataset(row: any): void {
        if (this.config.enableFocusRow === true) {
            this.rowFocus = row[this.config.dataConfig.tableIndexName];
        }
        this.changeOpenDataset.emit({row: row, viewMode: ViewMode.VIEW});
    }

    checkFooterActive(): boolean {
        return this.config.dataConfig.tableFields.some(field => field.footerSum);
    }

    getColor(row: any): string {
        let result = 'transparent';

        this.config.dataConfig.rowColor.forEach(color => {
            const valid = color.conditions.every(condition => {
                if (condition.operator === TableRowColorConditionOperator.SMALLER_DATE) {
                    if (dayjs(condition.value) < dayjs(row[condition.key])) {
                        return true;
                    }
                }
                if (condition.operator === TableRowColorConditionOperator.BIGGER_DATE) {
                    if (dayjs(condition.value) > dayjs(row[condition.key])) {
                        return true;
                    }
                }
                if (condition.operator === TableRowColorConditionOperator.BIGGER_INT) {
                    if (+condition.value < +row[condition.key]) {
                        return true;
                    }
                }
                if (condition.operator === TableRowColorConditionOperator.SMALLER_INT) {
                    if (+condition.value > +row[condition.key]) {
                        return true;
                    }
                }
                if (condition.operator === TableRowColorConditionOperator.EQUAL) {
                    if (condition.value === row[condition.key]) {
                        return true;
                    }
                }
                return false;
            });

            if (valid) {
                result = color.color;
            }
        });

        return result;
    }
}
