import {Injectable, inject} from '@angular/core';
import {UrlData} from '../../interfaces';
import {CryptoService} from './crypto.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterHelperService {

    private router = inject(Router);
    private cryptoService = inject(CryptoService);

    openCompany(CID: any): void {
        const urlData: UrlData = {
            ID: CID,
        };
        this.router.navigate(['/app/company/detail/', this.cryptoService.encrypt(urlData)]);
    }

    openLocation(LID: any): void {
        const urlData: UrlData = {
            ID: LID,
        };
        this.router.navigate(['/app/location/detail/', this.cryptoService.encrypt(urlData)]);
    }

    openDocument(DID: any): void {
        const urlData: UrlData = {
            ID: DID,
        };
        this.router.navigate(['/app/document/detail/', this.cryptoService.encrypt(urlData)]);
    }

    openTask(TID: any): void {
        const urlData: UrlData = {
            ID: TID
        };
        this.router.navigate(['/app/task/detail/', this.cryptoService.encrypt(urlData)]);
    }

    // this can only be used to open existing task dates
    openTaskDate(TDID: any): void {
        const urlData: UrlData = {
            ID: TDID
        };

        this.router.navigate(['/app/taskdate/detail/', this.cryptoService.encrypt(urlData)]);
    }

    openDepartment(DPID: any): void {
        const urlData: UrlData = {
            ID: DPID
        };
        this.router.navigate(['/app/department/detail/', this.cryptoService.encrypt(urlData)]);
    }
}
