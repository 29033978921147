<ol class="breadcrumb">
    <ng-container *ngFor="let breadcrumb of breadcrumbs | async, let last = last">
        <li class="breadcrumb-item"
            *ngIf="breadcrumb.label.title"
            [ngClass]="{active: last}">
            <a *ngIf="!last" [routerLink]="breadcrumb.url">{{breadcrumb.label.translate ? (breadcrumb.label.title | translate) : breadcrumb.label.title}}</a>
            <span *ngIf="last">{{breadcrumb.label.translate ? (breadcrumb.label.title | translate) : breadcrumb.label.title}}</span>
        </li>
    </ng-container>
</ol>
