<app-table #tableComponent [config]="tableConfig"/>

<div class="input-group" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">
    <select class="custom-select" [(ngModel)]="setDocumentType">
        <option value="" disabled selected style="display: none;">Bitte auswählen</option>
        <option *ngFor="let set of standardSets" [ngValue]="set">{{ set.name }}</option>
    </select>
    <div class="input-group-append">
        <button type="button" class="btn btn-warning" (click)="loadSet(setDocumentType)" [disabled]="setDocumentType == ''">Standardset laden</button>
    </div>
</div>

<div class="card mt-1" *ngIf="setDocumentType">
    <div class="card-body">
        <h5>Attribute</h5>

        <table class="table table-sm table-striped mb-0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Typ</th>
                    <th>Pflichtfeld</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let element of setDocumentType.elements">
                    <td>{{ element.name }}</td>
                    <td>{{ element.type | fileType }}</td>
                    <td>{{ element.required | yesNo }}</td>
                </tr>
        </table>
    </div>
</div>
