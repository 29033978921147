import {Component, OnInit, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {Router} from '@angular/router';
import {FormService} from '../../../global/services/form.service';
import {UserService} from '../../../global/services/user.service';
import {ViewMode} from '../../../interfaces';
import {NgIf, NgFor} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FileTypePipe} from '../../../global/pipes/file-type.pipe';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {YesNoPipe} from '../../../global/pipes/yes-no.pipe';

@Component({
    selector: 'app-location-attributes',
    templateUrl: './location-attributes.component.html',
    styleUrl: './location-attributes.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, TranslateModule, FileTypePipe, RightPipe, YesNoPipe]
})
export class LocationAttributesComponent implements OnInit {

    formService = inject(FormService);
    userService = inject(UserService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);
    private router = inject(Router);

    readonly ViewMode = ViewMode;

    form!: UntypedFormGroup;
    attributes: any = {};
    viewMode!: ViewMode;

    ngOnInit(): void {
        this.setMode(ViewMode.VIEW);

        this.form = this.fb.group({
            attributes: this.fb.array([])
        });

        this.getAttributes();
    }

    getAttributes(): void {
        this.dataService.request('AttributeTemplate/listByCategory', {category: 'location'}).subscribe(response => {
            this.attributes = response.attributes;
            this.attributes.forEach((attribute: any) => attribute.required = attribute.required === '1');

            this.patchForm();
        });
    }

    patchForm(): void {
        this.formService.getFormArray(this.form, 'attributes').clear();
        this.attributes.forEach((attribute: any) => {
            this.addAttribute(attribute);
        });
    }

    update(): void {
        this.dataService.request('AttributeTemplate/update', this.form.getRawValue()).subscribe(() => {
            this.setMode(ViewMode.VIEW);
            this.getAttributes();
        });
    }

    cancel(): void {
        this.router.navigate(['/app/location/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    addAttribute(data: any = {}): void {
        if (!data.required) {
            data.required = true;
        }
        this.formService.getFormArray(this.form, 'attributes').push(
            this.fb.group({
                ATTID: [data.ATTID || null],
                category: [data.category || null],
                name: [data.name || '', Validators.required],
                type: [data.type || '', Validators.required],
                required: [data.required],
            })
        );
    }

    deleteAttribute(index: number, ATTID: any): void {
        this.dataService.request('AttributeTemplate/deleteTypeLocation', {ATTID}).subscribe(() => {
            this.formService.removeFormArrayItem(this.form, 'attributes', index);
        });
    }
}
