import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'digitization',
    standalone: true
})
export class DigitizationPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === 'none') {
            return 'Ohne';
        } else if (value === 'text') {
            return 'Umwandlung auf Textdatei (OCR)';
        } else if (value === 'full') {
            return 'Vollständig';
        } else {
            return '';
        }
    }

}
