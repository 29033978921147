<app-header
    [navbarBrandRouterLink]="['/app/dashboard']"
    [fixed]="true"
    [navbarBrandFull]="{src: 'assets/img/brand/logo_new.png', height: 22, alt: 'NoteRadar Logo'}"
    [navbarBrandMinimized]="{src: 'assets/img/brand/logo_only.png', width: 30, height: 30, alt: 'NoteRadar Logo'}"
    [sidebarToggler]="false"
    [asideMenuToggler]="'lg'">
    <ul class="nav navbar-nav d-md-down-none">
        <li class="nav-item px-3" routerLinkActive="active">
            <a class="nav-link" routerLink="/app/dashboard">Dashboard</a>
        </li>
        <li class="nav-item px-3" routerLinkActive="active" [attr.data-right]="'DOCUMENT_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_VIEW')">
            <a class="nav-link" routerLink="/app/document">Dokumente</a>
        </li>
        <li class="nav-item px-3" routerLinkActive="active">
            <a class="nav-link" routerLink="/app/task">Aufgaben</a>
        </li>
        <li class="nav-item px-3" routerLinkActive="active">
            <a class="nav-link" routerLink="/app/taskdate">Termine</a>
        </li>
    </ul>
    <ul class="nav navbar-nav ml-auto">
        <!--<li class="nav-item d-md-down-none">
          <a class="nav-link" href="#"><i class="far fa-bell"></i><span class="badge badge-pill badge-danger">5</span></a>
        </li>
        <li class="nav-item d-md-down-none">
          <a class="nav-link" href="#"><i class="fas fa-list"></i></a>
        </li>-->
        <li class="nav-item d-md-down-none" routerLink="/app/user/account" style="cursor: pointer">
            <span class="navbar-text mr-1">
                {{ userService.currentUser.client_name }} <span *ngIf="userService.currentUser.client_demo == '1'">(Demozugang)</span> -
            </span>
        </li>
        <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link mr-2" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
                {{userService.currentUser?.firstname}} {{userService.currentUser?.lastname}}
            </a>

            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center"><strong>Einstellungen</strong></div>
                <a class="dropdown-item" routerLink="" (click)="openProfile()"><i class="fa fa-user"></i> Profil</a>
                <a class="dropdown-item" routerLink="/app/user/account" [attr.data-right]="'ADMIN_ACCESS' | right" *ngIf="userService.hasRight('ADMIN_ACCESS')"><i class="fa fa-wrench"></i> Account</a>
                <a class="dropdown-item" href="" (click)="logout($event)"><i class="fa fa-lock"></i> Abmelden</a>
            </div>
        </li>
        <span class="navbar-text role-info">
            ({{userService.currentUser?.role}})
        </span>
    </ul>
</app-header>
<div class="app-body">
    <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
        <ng-scrollbar class="my-scrollbar" visibility="hover">
            <app-sidebar-nav [navItems]="navItems"/>
        </ng-scrollbar>
        <app-sidebar-minimizer/>
    </app-sidebar>

    <main class="main">
        <app-custom-breadcrumb/>

        <div class="container-fluid">
            <router-outlet/>
        </div>
    </main>
    <app-aside [fixed]="true" [display]="false" [ngClass]="'test'">
        <tabset>
            <!--<tab>
                <ng-template tabHeading><i class="fas fa-list"></i></ng-template>
                <div class="list-group list-group-accent">
                    <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Heute</div>
                    <div class="list-group-item list-group-item-accent-success list-group-item-divider" *ngIf="tasksToday?.length == 0">
                        <div class="text-muted">Heute keine Aufgaben</div>
                    </div>
                    <div class="list-group-item list-group-item-accent-info list-group-item-divider" *ngFor="let task of tasksToday">
                        <div>{{ task.number }} <strong><a href="#" (click)="openTask($event, task)">{{ task.title }}</a></strong>
                        </div>
                        <small class="text-muted mr-3">
                            <i class="far fa-calendar-alt"></i> {{ task.due_date | date: "dd.MM.yyyy"}}</small>
                        <small class="text-muted">
                            <i class="fas fa-map-marker-alt"></i> {{ task.type | taskType }}</small>
                    </div>

                    <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Diese Woche</div>
                    <div class="list-group-item list-group-item-accent-success list-group-item-divider" *ngIf="tasksThisWeek?.length == 0">
                        <div class="text-muted">Diese Woche keine Aufgaben</div>
                    </div>
                    <div class="list-group-item list-group-item-accent-info list-group-item-divider" *ngFor="let task of tasksThisWeek">
                        <div>{{ task.number }} <strong><a href="#" (click)="openTask($event, task)">{{ task.title }}</a></strong>
                        </div>
                        <small class="text-muted mr-3">
                            <i class="far fa-calendar-alt"></i> {{ task.due_date | date: "dd.MM.yyyy"}}</small>
                        <small class="text-muted">
                            <i class="fas fa-map-marker-alt"></i> {{ task.type | taskType }}</small>
                    </div>

                    <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Nächste Woche</div>
                    <div class="list-group-item list-group-item-accent-success list-group-item-divider" *ngIf="tasksNextWeek?.length == 0">
                        <div class="text-muted">Nächste Woche keine Aufgaben</div>
                    </div>
                    <div class="list-group-item list-group-item-accent-info list-group-item-divider" *ngFor="let task of tasksNextWeek">
                        <div>{{ task.number }} <strong><a href="#" (click)="openTask($event, task)">{{ task.title }}</a></strong>
                        </div>
                        <small class="text-muted mr-3">
                            <i class="far fa-calendar-alt"></i> {{ task.due_date | date: "dd.MM.yyyy"}}</small>
                        <small class="text-muted">
                            <i class="fas fa-map-marker-alt"></i> {{ task.type | taskType }}</small>
                    </div>
                </div>
            </tab>-->
            <!--<tab>
                <ng-template tabHeading><i class="far fa-comment-dots"></i></ng-template>
                <div class="p-3">
                    <div class="message">
                        <div class="py-3 pb-5 mr-3 float-left">
                            <div class="avatar">
                                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                                <span class="avatar-status badge-success"></span>
                            </div>
                        </div>
                        <div>
                            <small class="text-muted">Lukasz Holeczek</small>
                            <small class="text-muted float-right mt-1">1:52 PM</small>
                        </div>
                        <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
                        <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
                    </div>
                    <hr>
                </div>
            </tab>-->
            <tab>
                <ng-template tabHeading><i class="fas fa-cog"></i></ng-template>
                <div class="pt-3 pl-3 pr-3">
                    NoteRadar v{{currentApplicationVersion}}
                </div>
                <div class="p-3">
                    <h6>Einstellungen</h6>

                    <div class="aside-options" *ngIf="roles">
                        <div class="clearfix mt-4">
                            <small><b>Rolle</b></small>
                            <select class="form-control form-control-sm ml-2" style="display: inline-block; width: auto"
                                    [(ngModel)]="selectedRole"
                                    (ngModelChange)="setRole()">
                                <option *ngFor="let role of roles" [ngValue]="role">{{ role.name }}</option>
                            </select>
                        </div>
                        <div>
                            <small class="text-muted">Rollenwechsel zu Testzwecken.</small>
                        </div>
                    </div>

                    <div class="aside-options">
                        <div class="clearfix mt-4">
                            <small><b>Erinnerungsmails</b></small>
                            <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                                <input type="checkbox" class="switch-input" [checked]="settings?.reminders" [(ngModel)]="settings.reminders" (change)="updateSettings()">
                                <span class="switch-slider" data-checked="Ein" data-unchecked="Aus"></span>
                            </label>
                        </div>
                        <div>
                            <small class="text-muted">Versenden von Erinnerungsmails zu Terminen.</small>
                        </div>
                    </div>
                    <div class="aside-options" [attr.data-right]="'SUBSCRIBE_MONTHLY_REPORT' | right" *ngIf="userService.hasRight('SUBSCRIBE_MONTHLY_REPORT')">
                        <div class="clearfix mt-4">
                            <small><b>Monatsbericht</b></small>
                            <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                                <input type="checkbox" class="switch-input" [checked]="settings?.monthly_report" [(ngModel)]="settings.monthly_report" (change)="updateSettings()">
                                <span class="switch-slider" data-checked="Ein" data-unchecked="Aus"></span>
                            </label>
                        </div>
                        <div>
                            <small class="text-muted">Versenden vom Monatsrückblick am 1. jedes Monats mit Terminvorschau für das aktuelle Monat.</small>
                        </div>
                    </div>
                    <div class="aside-options" [attr.data-right]="'GENERAL_PDF_EXPORT' | right" *ngIf="userService.hasRight('GENERAL_PDF_EXPORT')">
                        <div class="clearfix mt-3">
                            <small><b>Export</b></small>
                        </div>
                        <div>
                            <p><small class="text-muted">Export Ihrer Daten als PDF mit Karte und Auflistung aller Termine.</small></p>
                            <div>
                                <button type="button" class="btn btn-sm btn-primary" (click)="exportClient()" [disabled]="exporting">Export</button>
                            </div>
                        </div>
                    </div>
                    <div class="aside-options" *ngIf="!environment.production">
                        <div class="clearfix mt-3">
                            <small><b>Administration</b></small>
                        </div>
                        <div>
                            <p><small class="text-muted">Zeige Elemente, die mit einer Berechtigung hinterlegt sind.</small></p>
                            <div>
                                <button type="button" class="btn btn-sm btn-danger" (click)="toggleRightsDebug()">{{showRights ? 'Verstecke' : 'Zeige'}} Rechte</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!--<h6>System Utilization</h6>

                    <div class="text-uppercase mb-1 mt-2"><small><b>SSD 1 Usage</b></small></div>
                    <div class="progress progress-xs">
                        <div class="progress-bar bg-danger" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <small class="text-muted">243GB/256GB</small>

                    -->
                </div>
            </tab>
        </tabset>
    </app-aside>
</div>
