import {Component, OnInit, inject} from '@angular/core';
import {TableColumnFilterType, TableConfig} from '../../../global/components/table/table.interfaces';
import {UserService} from '../../../global/services/user.service';
import {TableComponent} from '../../../global/components/table/table.component';

@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html',
    styleUrl: './company-list.component.scss',
    imports: [TableComponent]
})
export class CompanyListComponent implements OnInit {

    private userService = inject(UserService);

    tableConfig!: TableConfig;

    ngOnInit(): void {
        this.tableConfig = {
            titleIcon: 'fas fa-briefcase',
            title: 'Unternehmen',
            openDatasetRouterLink: '/app/company/detail',
            enableCreate: this.userService.hasRight('MASTER_DATA_EDIT'),
            enableExportExcel: true,
            description: 'Ihre Unternehmen - jedes Dokument wird einem Unternehmen zugeordnet.',
            dataConfig: {
                tableName: 'company',
                tableIndexName: 'CID',
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {key: 'country', name: 'country', title: 'Land', sortable: true, searchable: true},
                    {key: 'zip', name: 'zip', title: 'PLZ', sortable: true, searchable: true},
                    {key: 'city', name: 'city', title: 'Stadt', sortable: true, searchable: true},
                    {key: 'street', name: 'street', title: 'Straße', sortable: true, searchable: true},
                    {key: 'ceo_name', name: 'CEO_name', title: 'Geschäftsführer', sortable: true, searchable: true},
                    {
                        key: 'locations',
                        subquery: '(SELECT COUNT(*) AS count FROM location WHERE CID = company.CID)',
                        title: 'LOCATIONS',
                        translateTitle: true,
                        translateTitlePrefix: 'LOCATION',
                        sortable: true,
                        searchable: true
                    },
                ],
                reverse: false,
                order: 'name',
                columnFilter: {
                    enabled: true,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                        country: {value: '', type: TableColumnFilterType.GROUP},
                        city: {value: '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 2,
                name: 'company-list',
                enabled: true
            },
            paginConfig: {
                id: 'companyList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }
}
