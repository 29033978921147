import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'taskType',
    standalone: true
})
export class TaskTypePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === 'singular') {
            return 'Einmalig';
        } else if (value === 'recurring') {
            return 'Wiederkehrend';
        } else {
            return '';
        }
    }

}
