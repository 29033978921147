import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrl: './document.component.scss',
    imports: [RouterOutlet]
})
export class DocumentComponent {

}
