<div class="container">
    <div class="row">
        <div class="col">
            <h1 class="display-4 text-center my-3">Funktionen</h1>

            <p>
                Verpflichtungsmanagement richtig gemacht -
                NoteRadar ist übersichtlich und auf das Wesentliche reduziert, daher ist die WebApp einfach zu bedienen und für jeden Mitarbeiter und externe Partner sofort einsetzbar.
                Ein modernes Layout, eine strukturierte Navigation und die Kompatibilität auf mobilen Geräten ermöglicht einen flexiblen Zugang.
            </p>

            <h1 id="Vertrag">Vertragsmanagement</h1>

            <div class="row my-5">
                <div class="col-md-5">
                    <img src="assets/img/features/1.png" class="mr-3 mb-2 img-fluid">
                </div>
                <div class="col-md-7">
                    <h3 class="mt-0">Verträge digitalisieren und indizieren</h3>
                    <p>
                        Sobald Sie Ihre Verträge in NoteRadar hochgeladen haben, werden diese indiziert und stehen Ihnen zur weiteren Verwaltung zur Verfügung.
                        Neben konfigurierbaren Attributen, können Sie zusätzliche Anhänge hochladen und bei Bedarf Aufgaben definieren.
                    </p>

                    <h3 class="mt-0">Verträge sofort finden</h3>
                    <p>
                        Durch eine klare, hierarchische Struktur und eine globale Suchfunktion finden Sie schnell jene Informationen, die Sie suchen.
                        Die Suche beinhaltet auch indizierte Vertragsinhalte und kann dadurch auch Detailinformationen finden.
                    </p>

                    <h3 class="mt-0">Jederzeit den Überblick behalten</h3>
                    <p>
                        Alle Daten stehen in übersichtlichen tabellarischen Auflistungen zur Verfügung. Diese beinhalten eine Suchfunktion und umfangreiche Filter- und Sortiermöglichkeiten,
                        um eine schnelle Eingrenzung vornehmen zu können.
                    </p>

                    <h3 class="mt-0">Automatische Erinnerungen</h3>
                    <ul>
                        <li>an Verträge die auslaufen oder nachverhandelt werden sollten</li>
                    </ul>

                    <h3 class="mt-0">Ihr Mehrwert</h3>
                    <p>
                        Durch die Aufteilung der vertraglichen Verpflichtungen auf Ihre Organisation, werden die Verträge nicht mehr verwaltet, sondern in Ihrer Organisation gestaltet.
                    </p>
                </div>
            </div>

            <div class="row my-5">
                <div class="col-md-7">
                    <h3 class="mt-0">Aktive E-Mail-Erinnerung</h3>
                    <p>
                        Mit der Möglichkeit einmalige oder wiederkehrende Aufgaben zu erstellen, werden für Sie automatisch Termine generiert an deren Fälligkeit Sie aktiv
                        erinnert werden. Zusätzlich können Sie eigens definierte Erinnerungen erstellen, um so den Prozess an Ihr Zeitmanagement anzupassen.
                        Beispiele wären eine Erinnerung an Verträge die auslaufen oder Termine zur Nachverhandlung.
                    </p>
                    <p>
                        Tabellenkalkulationen oder Ordner in Ihren Regalen erinnern Sie nicht daran – kommen Sie mit NoteRadar vom Verwalten zum Gestalten.
                    </p>
                    <h3 class="mt-0">Tags</h3>
                    <p>
                        Sie können in allen Bereichen/Funktionen (Dokumente, Aufgaben, Termine, Erinnerungen, etc.) eigene Tags vergeben um Ihre Daten individuell zu strukturieren – z.B. für spezielle Projekte oder Themengebiete.
                        Eine eigene Übersicht zeigt alle vorhandenen Tags und lässt Sie alle damit verbundenen Elemente schnell und einfach finden.
                    </p>
                </div>
                <div class="col-md-5">
                    <img src="assets/img/features/2.png" class="ml-3 mb-2 img-fluid">
                </div>
            </div>

            <div class="row my-5">
                <div class="col-md-5">
                    <img src="assets/img/features/3.png" class="mr-3 mb-2 img-fluid">
                </div>
                <div class="col-md-7">
                    <h3 class="mt-0">Multi Location / Multi Company - Filialorganisation</h3>
                    <p>
                        Aufgrund der Möglichkeit der hierarchischen Abbildung von mehreren Firmen und Betriebsstätten, können die Anforderungen von Filialorganisationen sehr einfach umgesetzt werden.
                        Die Zugehörigkeit von Dokumenten, Aufgaben oder Terminen und verantwortlicher Abteilung bzw. Person ist jederzeit klar ersichtlich.
                    </p>
                    <h3 class="mt-0">Attribute / Metadaten zu Betriebsstätten</h3>
                    <p>
                        Sie können für Betriebsstätten zentral beliebige Attribute definieren um für Ihre Anforderungen angepasste Metadaten zu ermöglichen.
                        Über die Attributsuche können diese über alle Betriebsstätten übersichtlich angezeigt, gefiltert und auch nach Excel exportiert werden.
                    </p>
                </div>
            </div>

            <h1 id="Bescheid">Bescheidmanagement</h1>

            <div class="row my-5">
                <div class="col-md-7">
                    <h3 class="mt-0">Bescheide digitalisieren und indizieren</h3>
                    <p>
                        Sobald Sie Bescheide in NoteRadar hochgeladen haben, werden diese indiziert und stehen Ihnen zur weiteren Verwaltung zur Verfügung.
                        Neben konfigurierbaren Attributen, können Sie zusätzliche Anhänge hochladen und bei Bedarf Aufgaben definieren.
                    </p>

                    <h3 class="mt-0">Bescheide sofort finden</h3>
                    <p>
                        Durch eine klare, hierarchische Struktur und eine globale Suchfunktion finden Sie schnell jene Informationen, die Sie suchen.
                        Die Suche beinhaltet auch indizierte Vertragsinhalte und kann dadurch auch Detailinformationen finden.
                    </p>

                    <h3 class="mt-0">Jederzeit den Überblick behalten</h3>
                    <p>
                        Alle Daten stehen in übersichtlichen tabellarischen Auflistungen zur Verfügung. Diese beinhalten eine Suchfunktion und umfangreiche Filter und Sortiermöglichkeiten,
                        um eine schnelle Eingrenzung vornehmen zu können.
                    </p>

                    <h3 class="mt-0">Automatische Erinnerungen</h3>
                    <ul>
                        <li>an Bescheidauflagen die erfüllt werden sollten</li>
                        <li>an Wartungen die durchgeführt werden sollten</li>
                    </ul>

                    <h3 class="mt-0">Ihr Mehrwert</h3>
                    <p>
                        Durch die Aufteilung der vertraglichen Verpflichtungen auf Ihre Organisation, werden die Verträge nicht mehr verwaltet, sondern in Ihrer Organisation gestaltet.
                    </p>
                </div>
                <div class="col-md-5">
                    <img src="assets/img/features/1.png" class="ml-3 mb-2 img-fluid">
                </div>
            </div>

            <div class="row my-5">
                <div class="col-md-5">
                    <img src="assets/img/features/5.png" class="mr-3 mb-2 img-fluid">
                </div>
                <div class="col-md-7">
                    <h3 class="mt-0">Wiederkehrende Bescheidauflagen (Jahresübersicht)</h3>
                    <p>
                        Vor allem bei filialisierten Organisationen ist eine mehrjährige Übersicht der wiederkehrenden
                        Bescheidauflagen / Aufgaben für die bessere Übersicht am POS (Point of Sale) notwendig und unterstützt Sie bei der Einhaltung und zeitlichen Planung der notwendigen Maßnahmen.
                    </p>
                </div>
            </div>

            <h1 id="Share">FileShare</h1>

            <p>
                Im Bereich FileShare können Sie beliebige Dokumente ablegen und optional über einen Freigabelink mit Ihren externen Partnern austauschen.
                Die Daten liegen verschlüsselt auf Servern in Österreich und können in Ihrem Zugang mit verschiedenen Methoden eingegrenzt werden.
            </p>

            <div class="row my-5">
                <div class="col-md-7">
                    <h3 class="mt-0">Umfangreiche Ablagemöglichkeit</h3>
                    <p>
                        Sie können eine große Anzahl von Dokumenttypen ablegen, darunter Bilder, Word, Excel, PDF, Textdateien, usw.
                        Die Ansicht kann zwischen Tabelle und Kacheln umgeschaltet werden.
                    </p>

                    <h3 class="mt-0">Kein NoteRadar-Zugang notwendig</h3>
                    <p>
                        Über einen Download-Link ist es ohne einen eigenen Zugang möglich, Daten für externe Partner bereitzustellen.
                    </p>

                    <h3 class="mt-0">Download Konfiguration</h3>
                    <ul>
                        <li>Zeitliche Befristung</li>
                        <li>Einmaliger oder mehrfacher Abruf</li>
                        <li>Optionaler Passwortschutz</li>
                        <li>Einen oder mehrere Empfänger definieren</li>
                    </ul>
                </div>
                <div class="col-md-5">
                    <img src="assets/img/features/4.png" class="ml-3 mb-2 img-fluid">
                </div>
            </div>

            <h1 id="Tagging">Tags</h1>

            <p>
                Diverse Elemente wie z.B. Unternehmen, Dokumente und Aufgaben können mit frei definierbaren Tags gekennzeichnet und
                nach Ihren Anforderungen gruppiert werden. Über eine zentrale Tagsuche können Sie getaggte Daten schnell und einfach finden.
            </p>

            <h1 id="Rollen">Flexibles Berechtigungssystem</h1>

            <p>
                Über ein rollenbasiertes Berechtigungssystem mit Rechtezuweisung können Sie Anzeige-, Lese- und Bearbeitungsrechte komplett nach Ihren
                Anforderungen konfigurieren. Neben 5 Standardrollen können Sie weitere Rollen anlegen und die zugewiesenen Rechte frei anpassen.
            </p>

            <h1 id="Digitalisierung">Unterstützung Digitalisierung</h1>

            <p>
                Ihre Dokumente werden nach dem Upload automatisch mittels <a href="https://de.wikipedia.org/wiki/Texterkennung" target="_blank">OCR</a> (Optical Character Recognition) in Textform umgewandelt.
                Dadurch stehen Ihnen Ihre Daten in einheitlicher Form zur Verfügung und Sie können die Textinhalte einfach weiterverwenden, z.B. für Beschreibung von Aufgaben.
                Im Bedarfsfall beraten und unterstützen wir Sie bei der Aufbereitung der Textinhalte aber auch bei Aufgabenerstellung und Terminplanung.
            </p>

            <h1 id="Sichtbarkeitsgruppen">Sichtbarkeitsgruppen</h1>

            <p>
                Haben Sie vertrauliche Dokumente bzw. Dokumente die nur durch bestimmte Benutzer einsehbar sein sollen, können Sie
                die Sichtbarkeit dieser Dokumente durch Sichtbarkeitsgruppen konfigurieren. Sie können Dokumente einer oder mehreren
                Sichtbarkeitsgruppen zuweisen. Diese Dokumente sind dann nur für Benutzer einsehbar, die ebenfalls einer dieser
                Sichtbarkeitsgruppen zugeordnet sind.
            </p>

            <p>
                Es können beliebig viele Sichtbarkeitsgruppen angelegt werden und jeweils beliebige Dokumente und Benutzer miteinander
                verknüpft werden. Selbstverständlich wird auch die Sichtbarkeit der den Dokumenten zugehörigen Aufgaben und Terminen
                eingeschränkt.
            </p>

        </div>
    </div>
</div>
