import {Component, OnInit, inject} from '@angular/core';
import {CryptoService} from '../../../global/services/crypto.service';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {UserService} from '../../../global/services/user.service';
import Swal from 'sweetalert2';
import {ViewMode} from '../../../interfaces';
import {NgIf} from '@angular/common';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {TooltipDirective} from "ngx-bootstrap/tooltip";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-task-type-detail',
    templateUrl: './task-type-detail.component.html',
    styleUrl: './task-type-detail.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, RightPipe, TooltipDirective]
})
export class TaskTypeDetailComponent implements OnInit {

    userService = inject(UserService);
    private cryptoService = inject(CryptoService);
    private router = inject(Router);
    private fb = inject(UntypedFormBuilder);
    private dataService = inject(DataService);
    private toastrService = inject(ToastrService);

    readonly ViewMode = ViewMode;

    form!: UntypedFormGroup;
    TTID!: string;
    viewMode!: ViewMode;
    tasktype: any;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.TTID = urlData.ID;
        this.viewMode = urlData.viewMode;

        this.form = this.fb.group({
            TTID: [null],
            name: ['', Validators.required],
        });

        if (this.TTID !== '0') {
            this.getTaskType();
        }
    }

    getTaskType(): void {
        this.dataService.request('TaskType/get', {TTID: this.TTID}).subscribe(response => {
            this.tasktype = response.tasktype;
            this.tasktype.used = parseInt(this.tasktype.used, 10);

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            TTID: this.tasktype.TTID,
            name: this.tasktype.name,
        });
    }

    save(): void {
        this.dataService.request('TaskType/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Aufgabentyp wurde gespeichert');
            this.router.navigate(['/app/tasktype/list']);
        });
    }

    update(): void {
        this.dataService.request('TaskType/update', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Aufgabentyp wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
        });
    }

    cancel(): void {
        this.router.navigate(['/app/tasktype/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Der Aufgabentyp wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('TaskType/delete', {
                    TTID: this.TTID
                }).subscribe(() => {
                    this.toastrService.success('Aufgabentyp wurde gelöscht');
                    this.cancel();
                });
            }
        });
    }
}
