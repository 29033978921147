<div class="card mb-3">
    <div class="card-header">
        <h3 class="mb-0">{{ form.value.name }}</h3>

        <ul class="nav nav-tabs card-header-tabs mt-2">
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.DETAILS}" data-toggle="tab" (click)="setTab(TABS.DETAILS)">
                <i class="fas fa-building mr-2"></i>Details</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.USERS}" data-toggle="tab" (click)="setTab(TABS.USERS)" *ngIf="viewMode !== ViewMode.INSERT">
                <i class="fas fa-users mr-2"></i>Benutzer ({{ users.length }})</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.ACTIVITY}" data-toggle="tab" (click)="setTab(TABS.ACTIVITY)" *ngIf="viewMode !== ViewMode.INSERT">
                <i class="fas fa-clock-rotate-left mr-2"></i>Aktivitätsverlauf</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.ONLINE}" data-toggle="tab" (click)="setTab(TABS.ONLINE)" *ngIf="viewMode !== ViewMode.INSERT">
                <i class="fas fa-server mr-2"></i>Onlineverlauf</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.COMPANIES}" data-toggle="tab" (click)="setTab(TABS.COMPANIES)" *ngIf="viewMode !== ViewMode.INSERT">
                <i class="fas fa-hotel mr-2"></i>Unternehmen ({{ companies.length }})</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.MAIL_LOG}" data-toggle="tab" (click)="setTab(TABS.MAIL_LOG)" *ngIf="viewMode !== ViewMode.INSERT">
                <i class="fas fa-table mr-2"></i>Mail Log ({{ logs.length }})</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.MAP}" data-toggle="tab" (click)="setTab(TABS.MAP)" *ngIf="viewMode !== ViewMode.INSERT">
                <i class="fas fa-map mr-2"></i>Karte ({{ mapLocations.length }})</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.DOCUMENTS}" data-toggle="tab" (click)="setTab(TABS.DOCUMENTS)" *ngIf="viewMode !== ViewMode.INSERT">
                <i class="fas fa-file mr-2"></i>Dokumente <ng-container *ngIf="documents">({{ documents }})</ng-container></a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.OTHER}" data-toggle="tab" (click)="setTab(TABS.OTHER)" *ngIf="viewMode !== ViewMode.INSERT">
                <i class="fas fa-cubes mr-2"></i>Diverses</a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <div *ngIf="tab === TABS.DETAILS" role="tabpanel" class="tab-pane active">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h5><i class="fas fa-building fa-fw"></i> Name</h5>

                        <div class="mb-1">
                            <label>Firmenname</label>
                            <input type="text" class="form-control" formControlName="name" *ngIf="viewMode !== ViewMode.VIEW">
                            <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.name }}</p>
                        </div>

                        <div class="row">
                            <div class="col-6 mb-1">
                                <label>Firmenbuchnummer</label>
                                <input type="text" class="form-control" formControlName="register_number" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.register_number">{{ form.value.register_number }}</ng-container>
                                    <span *ngIf="!form.value.register_number" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                            <div class="col-6 mb-1" *ngIf="viewMode !== ViewMode.INSERT">
                                <label>Erstellt am</label>
                                <p>{{ form.value.created | date: "dd.MM.yyyy" }}</p>
                            </div>
                        </div>

                        <h5><i class="fa-solid fa-location-dot fa-fw"></i> Adresse</h5>
                        <div class="row">
                            <div class="col-4 mb-1">
                                <label>PLZ</label>
                                <input type="number" class="form-control" formControlName="zip" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.zip }}</p>
                            </div>
                            <div class="col-4 mb-1">
                                <label>Ort</label>
                                <input type="text" class="form-control" formControlName="city" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.city }}</p>
                            </div>
                            <div class="col-4 mb-1">
                                <label>Straße</label>
                                <input type="text" class="form-control" formControlName="street" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.street }}</p>
                            </div>
                        </div>

                        <h5><i class="fa-solid fa-address-card fa-fw"></i> Kontaktdaten</h5>

                        <div class="row">
                            <div class="col-4 mb-1">
                                <label>Email</label>
                                <input type="text" class="form-control" formControlName="email" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.email">{{ form.value.email }}</ng-container>
                                    <span *ngIf="!form.value.email" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                            <div class="col-4 mb-1">
                                <label>Telefon</label>
                                <input type="text" class="form-control" formControlName="phone" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.phone">{{ form.value.phone }}</ng-container>
                                    <span *ngIf="!form.value.phone" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                            <div class="col-4 mb-1">
                                <label>Kostenstelle</label>
                                <input type="text" class="form-control" formControlName="cost_unit" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.cost_unit">{{ form.value.cost_unit }}</ng-container>
                                    <span *ngIf="!form.value.cost_unit" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                        </div>

                        <h5><i class="fa-solid fa-database fa-fw"></i> Datenbank</h5>

                        <small class="form-text text-muted" *ngIf="viewMode === ViewMode.INSERT">Datenbank, die für den Mandanten erstellt wird.</small>
                        <div class="row">
                            <!--<div class="col mb-1">
                                <label>DB Host</label>
                                <input type="text" class="form-control" formControlName="db_host" *ngIf="viewMode != ViewMode.VIEW">
                                <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.db_host }}</p>
                            </div>-->
                            <div class="col mb-1">
                                <label>DB User / DB Name</label>
                                <input type="text" class="form-control" formControlName="db_user" *ngIf="viewMode === ViewMode.INSERT">
                                <p *ngIf="viewMode !== ViewMode.INSERT">{{ form.value.db_user }}</p>
                            </div>
                            <div class="col mb-1">
                                <label>DB Pass</label>
                                <input type="text" class="form-control" formControlName="db_pass" *ngIf="viewMode === ViewMode.INSERT">
                                <p *ngIf="viewMode !== ViewMode.INSERT">{{ form.value.db_pass }}</p>
                            </div>
                        </div>

                        <ng-container *ngIf="viewMode === ViewMode.INSERT">
                            <h5>Benutzer</h5>

                            <small class="form-text text-muted">Admin Benutzer für den Mandanten, mit diesem kann dann angemeldet und alles weitere innerhalb des neuen Mandanten angelegt werden.</small>
                            <div class="row">
                                <div class="col mb-1">
                                    <label>Admin Email</label>
                                    <input type="email" class="form-control" formControlName="user_email">
                                </div>
                                <div class="col mb-1">
                                    <label>Admin Passwort</label>
                                    <input type="text" class="form-control" formControlName="user_pass">
                                </div>
                            </div>
                        </ng-container>

                        <div class="mb-1">
                            <label>Kommentar</label>
                            <textarea class="form-control" rows="6" formControlName="comment" *ngIf="viewMode !== ViewMode.VIEW"></textarea>
                            <div *ngIf="viewMode === ViewMode.VIEW">
                                <p *ngIf="form.value.comment">{{ form.value.comment }}</p>
                                <span *ngIf="!form.value.comment" class="text-muted">Nicht gesetzt</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <h5><i class="fa-solid fa-file-invoice fa-fw"></i> Rechnungsdaten</h5>

                        <div class="row">
                            <div class="col-4 mb-1">
                                <label>PLZ</label>
                                <input type="number" class="form-control" formControlName="invoice_zip" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.invoice_zip">{{ form.value.invoice_zip }}</ng-container>
                                    <span *ngIf="!form.value.invoice_zip" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                            <div class="col-4 mb-1">
                                <label>Ort</label>
                                <input type="text" class="form-control" formControlName="invoice_city" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.invoice_city">{{ form.value.invoice_city }}</ng-container>
                                    <span *ngIf="!form.value.invoice_city" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                            <div class="col-4 mb-1">
                                <label>Straße</label>
                                <input type="text" class="form-control" formControlName="invoice_street" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.invoice_street">{{ form.value.invoice_street }}</ng-container>
                                    <span *ngIf="!form.value.invoice_street" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 mb-1">
                                <label>E-Mail</label>
                                <input type="email" class="form-control" formControlName="invoice_email" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.invoice_email">{{ form.value.invoice_email }}</ng-container>
                                    <span *ngIf="!form.value.invoice_email" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                            <div class="col-6 mb-1">
                                <label>UID</label>
                                <input type="text" class="form-control" formControlName="invoice_UID" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.invoice_UID">{{ form.value.invoice_UID }}</ng-container>
                                    <span *ngIf="!form.value.invoice_UID" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                        </div>

                        <div class="mb-1">
                            <label>Text</label>
                            <input type="text" class="form-control" formControlName="invoice_text" *ngIf="viewMode !== ViewMode.VIEW">
                            <p *ngIf="viewMode === ViewMode.VIEW">
                                <ng-container *ngIf="form.value.invoice_text">{{ form.value.invoice_text }}</ng-container>
                                <span *ngIf="!form.value.invoice_text" class="text-muted">Keine Angabe</span>
                            </p>
                        </div>

                        <h5><i class="fa-solid fa-gears fa-fw"></i> Einstellungen</h5>

                        <div class="row">
                            <div class="col-6 mb-1">
                                <label>Demozugang</label>
                                <div class="form-check" *ngIf="viewMode !== ViewMode.VIEW">
                                    <input type="checkbox" class="form-check-input ms-0" formControlName="demo">
                                    <label class="form-check-label"></label>
                                </div>
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.demo | yesNo }}</p>
                            </div>
                            <div class="col-6 mb-1">
                                <label>Aktiv</label>
                                <div class="form-check" *ngIf="viewMode !== ViewMode.VIEW">
                                    <input type="checkbox" class="form-check-input ms-0" formControlName="active">
                                    <label class="form-check-label"></label>
                                </div>
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.active | yesNo }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 mb-1">
                                <label>Vertragsbeginn</label>
                                <input type="date" class="form-control" formControlName="contract_start" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.contract_start | date: "dd.MM.yyyy" }}</p>
                            </div>
                            <div class="col-6 mb-1">
                                <label>Vertragsende</label>
                                <input type="date" class="form-control" formControlName="contract_end" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.contract_end">{{ form.value.contract_end | date: "dd.MM.yyyy" }}</ng-container>
                                    <span *ngIf="!form.value.contract_end" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 mb-1">
                                <label>Freigegebene Objekte</label>
                                <input type="number" class="form-control" formControlName="number_locations" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.number_locations }}</p>
                            </div>
                            <div class="col-6 mb-1">
                                <label>Bezahlte Objekte</label>
                                <input type="number" class="form-control" formControlName="paid_locations" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.paid_locations }}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 mb-1">
                                <label>€ / Objekt</label>
                                <input type="number" class="form-control" formControlName="price" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.price">{{ form.value.price }}</ng-container>
                                    <span *ngIf="!form.value.price" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                            <div class="col-6 mb-1">
                                <label>Zahlungsart</label>
                                <select class="form-control" formControlName="payment_type" *ngIf="viewMode !== ViewMode.VIEW">
                                    <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                    <option value="invoice">Überweisung</option>
                                    <option value="sepa">SEPA</option>
                                </select>
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.payment_type">{{ form.value.payment_type | paymentType }}</ng-container>
                                    <span *ngIf="!form.value.payment_type" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 mb-1">
                                <label>Consulting aktiv</label>
                                <div *ngIf="viewMode !== ViewMode.VIEW">
                                    <input type="checkbox" class="form-check-input ms-0" formControlName="consulting">
                                </div>
                                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.consulting | yesNo }}</p>
                            </div>
                            <div class="col-6 mb-1">
                                <label>Consulting Limit (€ / Zahlungsintervall)</label>
                                <input type="number" class="form-control" formControlName="consulting_limit" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.consulting_limit">{{ form.value.consulting_limit }}</ng-container>
                                    <span *ngIf="!form.value.consulting_limit" class="text-muted">Keine Angabe</span>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 mb-1">
                                <label>Import E-Mail</label>
                                <input type="text" class="form-control" formControlName="email_import" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.email_import">{{ form.value.email_import }}</ng-container>
                                    <span *ngIf="!form.value.email_import" class="text-muted">Nicht gesetzt</span>
                                </p>
                            </div>
                            <div class="col-6 mb-1">
                                <label>Import E-Mail Passwort</label>
                                <input type="text" class="form-control" formControlName="email_import_pass" *ngIf="viewMode !== ViewMode.VIEW">
                                <p *ngIf="viewMode === ViewMode.VIEW">
                                    <ng-container *ngIf="form.value.email_import_pass">{{ form.value.email_import_pass }}</ng-container>
                                    <span *ngIf="!form.value.email_import_pass" class="text-muted">Nicht gesetzt</span>
                                </p>
                            </div>
                        </div>

                        <label>Labels</label>
                        <ng-container *ngIf="viewMode !== ViewMode.VIEW">
                            <textarea class="form-control" rows="6" formControlName="labels"></textarea>
                            <p>Vorlage</p>
                            <pre class="mb-0">{{ labelTemplate | json }}</pre>
                        </ng-container>
                        <ng-container *ngIf="viewMode === ViewMode.VIEW">
                            <pre class="mb-0" *ngIf="form.value.labels">{{ form.value.labels }}</pre>
                            <p *ngIf="!form.value.labels">
                                <span class="text-muted">Nicht gesetzt</span>
                            </p>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="tab === TABS.USERS" role="tabpanel" class="tab-pane active">
            <div class="table-responsive">
                <table class="table table-sm table-hover mb-0">
                    <thead>
                    <tr>
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>E-Mail</th>
                        <th>Rolle</th>
                        <th>Letzter Login</th>
                        <th>Aktiv</th>
                        <th>Erinnerungen aktiv</th>
                        <th>Monatsbericht aktiv</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let user of users">
                        <td>{{ user.firstname }}</td>
                        <td>{{ user.lastname }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.role }}</td>
                        <td>
                            {{ user.last_login | date: 'dd.MM.yyyy HH:mm' }}
                            <span class="text-muted" *ngIf="!user.last_login">Keiner</span>
                        </td>
                        <td>{{ user.active | yesNo }}</td>
                        <td>{{ user.s_reminders | yesNo }}</td>
                        <td>{{ user.s_monthly_report | yesNo }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="tab === TABS.ACTIVITY" role="tabpanel" class="tab-pane active">
            <app-admin-client-activity [CLID]="CLID"/>
        </div>

        <div *ngIf="tab === TABS.ONLINE" role="tabpanel" class="tab-pane active">
            <button type="button" class="btn btn-primary btn-sm" (click)="toggleAutoReloadActivities()">
                <ng-container *ngIf="!autoReloadActivities">Autoreload aktivieren</ng-container>
                <ng-container *ngIf="autoReloadActivities">Autoreload deaktivieren</ng-container>
            </button>
            <span *ngIf="autoReloadActivities" class="ml-2 text-muted">Reload in {{ autoReloadValue }}s</span>

            <div class="table-responsive">
                <table class="table table-sm table-hover mt-3 mb-0 table-activity">
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Aktive Benutzer</th>
                        <th>Aktionen</th>
                        <th>Zeit erste Aktion</th>
                        <th>Zeit letzte Aktion</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let log of userlogs">
                        <td>{{ log.date | date: 'dd.MM.yyyy' }}</td>
                        <td>{{ log.users  }}</td>
                        <td>{{ log.count }}</td>
                        <td>{{ log.first_date | date: 'HH:mm' }}</td>
                        <td>{{ log.last_date | date: 'HH:mm' }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="tab === TABS.COMPANIES" role="tabpanel" class="tab-pane active">
            <div class="table-responsive">
                <table class="table table-sm table-hover mb-0">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Objekte ({{ locationsCount }})</th>
                        <th>Land</th>
                        <th>PLZ</th>
                        <th>Stadt</th>
                        <th>Straße</th>
                        <th>Geschäftsführer</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let company of companies">
                        <td>{{ company.name }}</td>
                        <td>{{ company.locations }}</td>
                        <td>{{ company.country }}</td>
                        <td>{{ company.zip }}</td>
                        <td>{{ company.city }}</td>
                        <td>{{ company.street }}</td>
                        <td>{{ company.ceo_name }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="tab === TABS.MAIL_LOG" role="tabpanel" class="tab-pane active">
            <p class="text-muted">(eingeschränkt auf die letzten 30 Einträge)</p>
            <div class="table-responsive">
                <table class="table table-sm table-hover mb-0">
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Empfänger</th>
                        <th>Betreff</th>
                        <th>Erfolgreich</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let log of logs" (click)="openMail(log)">
                        <td>{{ log.created | date: "dd.MM.yyyy HH:mm" }}</td>
                        <td>{{ log.recipients }}</td>
                        <td>{{ log.subject }}</td>
                        <td>{{ log.result | yesNo }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div bsModal #mailModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title pull-left"><i class="fa fa-mail"></i> Mailinhalt</h4>
                        </div>
                        <div class="modal-body">
                            <div [innerHTML]="message"></div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" appAutofocus (click)="mailModal?.hide()">OK</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="tab === TABS.MAP" role="tabpanel" class="tab-pane active">
            <app-map #mapComponent [ID]="'client'"/>
        </div>

        <div *ngIf="tab === TABS.DOCUMENTS" role="tabpanel" class="tab-pane active">
            <app-admin-client-documents [CLID]="client.CLID" (documentsLoaded)="documents = $event"/>
        </div>

        <div *ngIf="tab === TABS.OTHER" role="tabpanel" class="tab-pane active">
            <div class="row">
                <div class="col-4">
                    <h6>{{ stats.tasks.total }} Aufgaben</h6>
                    <ul class="mb-0">
                        <li>{{ stats.tasks.singular }} Einmalig</li>
                        <li>{{ stats.tasks.recurring }} Wiederkehrend</li>
                    </ul>

                    <h6>{{ stats.taskdates.total }} Termine</h6>
                    <ul class="mb-0">
                        <li>{{ stats.taskdates.wait }} Warten</li>
                        <li>{{ stats.taskdates.open }} Offen</li>
                        <li>{{ stats.taskdates.in_progress }} In Bearbeitung</li>
                        <li>{{ stats.taskdates.done }} Erledigt</li>
                    </ul>
                </div>
                <div class="col-8">
                    <h6>{{ documentTypes.length }} Dokumenttypen</h6>
                    <ul class="mb-0">
                        <li *ngFor="let documentType of documentTypes">
                            {{ documentType.name }}
                            <div><small class="text-muted">{{documentType.attributes }}</small></div>
                        </li>
                    </ul>
                    <span *ngIf="documentTypes.length === 0" class="text-muted">Keine zugewiesen</span>

                    <h6>{{ taskTypes.length }} Aufgabentypen</h6>
                    <ul class="mb-0">
                        <li *ngFor="let taskType of taskTypes">{{ taskType.name }}</li>
                    </ul>
                    <span *ngIf="taskTypes.length === 0" class="text-muted">Keine zugewiesen</span>

                    <h6>{{ taskTopics.length }} Aufgabenthemen</h6>
                    <ul class="mb-0">
                        <li *ngFor="let taskTopic of taskTopics">{{ taskTopic.name }}</li>
                    </ul>
                    <span *ngIf="taskTopics.length === 0" class="text-muted">Keine zugewiesen</span>
                </div>
            </div>

        </div>
    </div>
    <div class="card-footer">
        <ng-container *ngIf="tab === TABS.DETAILS">
            <ng-container *ngIf="viewMode === ViewMode.VIEW">
                <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)">Bearbeiten</button>
                <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">Zurück</button>
                <button type="button" class="btn btn-danger float-right" (click)="delete()">Löschen</button>
            </ng-container>

            <ng-container *ngIf="viewMode === ViewMode.EDIT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
            </ng-container>

            <ng-container *ngIf="viewMode === ViewMode.INSERT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()" [disabled]="saving">Speichern</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
            </ng-container>
        </ng-container>
    </div>
</div>
