import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import {DataService} from '../../../global/services/data.service';
import {Router, RouterLink} from '@angular/router';
import {CryptoService} from '../../../global/services/crypto.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FwFileViewLayout, FileComponent} from '../../../global/components/file/file.component';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import {TagConfig} from '../../../global/components/tag/tag.interfaces';
import {FormService} from '../../../global/services/form.service';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {UserService} from '../../../global/services/user.service';
import {ViewMode, UrlData} from '../../../interfaces';
import {Subscription} from "rxjs";
import {TranslateService, TranslateModule} from "@ngx-translate/core";
import {BreadcrumbService} from "../../../global/components/breadcrumb/breadcrumb.service";
import {NgIf, NgClass, NgFor, DatePipe} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TagComponent} from '../../../global/components/tag/tag.component';
import {TaskDateOwnComponent} from '../task-date-own/task-date-own.component';
import {CommentComponent} from '../../../global/components/comment/comment.component';
import {TaskTypePipe} from '../../../global/pipes/task-type.pipe';
import {WeekdayPipe} from '../../../global/pipes/weekday.pipe';
import {MonthPipe} from '../../../global/pipes/month.pipe';
import {TextareaPipe} from '../../../global/pipes/textarea.pipe';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {ToastrService} from "ngx-toastr";

enum TabsEnum {
    DETAILS = 'details',
    DATES = 'dates',
    COMMENTS = 'comments',
    ATTACHMENTS = 'attachments',
}

@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
    styleUrl: './task-detail.component.scss',
    imports: [NgIf, NgClass, FormsModule, ReactiveFormsModule, NgFor, RouterLink, TooltipModule, TagComponent, TaskDateOwnComponent, CommentComponent, FileComponent, DatePipe, TranslateModule, TaskTypePipe, WeekdayPipe, MonthPipe, TextareaPipe, RightPipe]
})
export class TaskDetailComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    routerHelperService = inject(RouterHelperService);
    private dataService = inject(DataService);
    private router = inject(Router);
    private fb = inject(UntypedFormBuilder);
    private cryptoService = inject(CryptoService);
    private formService = inject(FormService);
    private translateService = inject(TranslateService);
    private breadcrumbService = inject(BreadcrumbService);
    private toastrService = inject(ToastrService);

    readonly ViewMode = ViewMode;
    readonly TABS = TabsEnum;
    readonly FileViewLayout = FwFileViewLayout;

    DID!: string;
    form!: UntypedFormGroup;
    TID!: any;
    task!: any;
    viewMode!: ViewMode;
    tab!: TabsEnum;

    document: any;
    attributes!: any[];
    departments!: any[];
    topics!: any[];
    taskTypes!: any[];
    externals!: any[];
    tabCount: any = {};
    tagConfig!: TagConfig;

    private dueDateChanges$!: Subscription;

    ngOnInit(): void {
        let tab = TabsEnum.DETAILS;

        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.TID = urlData.ID;
        this.viewMode = urlData.viewMode;
        if (urlData.data?.DID) {
            this.DID = urlData.data.DID;
        }

        this.tagConfig = {
            FK_ID: this.TID,
            FK_name: 'task',
            FK_table: 'task',
            editable: false,
        };

        this.form = this.fb.group({
            TID: [null],
            DID: [this.DID, Validators.required],
            DPID: [null],
            TOID: [null, Validators.required],
            TTID: [null, Validators.required],
            UID_external: [null],
            created: [null],
            title: [null],
            text: [null, Validators.required],
            number: [null, Validators.required],
            type: ['', Validators.required],
            due_date: [null],
            interval: [null, this.formService.conditionalValidator(() => this.form.get('type').value === 'recurring', Validators.required)],
            weekly_day: [null, this.formService.conditionalValidator(() => this.form.get('type').value === 'recurring' && this.form.get('interval').value === 'weekly', Validators.required)],
            monthly_yearly_date: [null, this.formService.conditionalValidator(() => this.form.get('type').value === 'recurring' && ['monthly', 'yearly'].includes(this.form.get('interval').value), Validators.required)],
            yearly_month: [null, this.formService.conditionalValidator(() => this.form.get('type').value === 'recurring' && this.form.get('interval').value === 'yearly', Validators.required)],
            recurring_start: [null, this.formService.conditionalValidator(() => this.form.get('type').value === 'recurring', Validators.required)],
            recurring_step: [null, this.formService.conditionalValidator(() => this.form.get('type').value === 'recurring' && this.form.get('interval').value !== 'daily', Validators.required)],
            sendMail: [false],
        });

        // Note: we don't use formService.conditionalValidator here because the date control
        // doesn't fire a change event if the date is invalid or empty
        this.dueDateChanges$ = this.form.get('type').valueChanges.subscribe(val => {
            if (val == 'singular') {
                this.formService.setValidators(this.form, ['due_date'], [Validators.required]);
            } else {
                this.formService.removeValidators(this.form, ['due_date'], [Validators.required]);
            }
        });

        if (this.TID !== '0') {
            this.getTask();
        } else {
            this.dataService.request('Document/getAttributes', {DID: this.DID}).subscribe(response2 => {
                this.attributes = response2.attributes.filter((attribute: any) => attribute.type === 'date');
                this.attributes.forEach((attribute: any) => {
                    attribute.value = attribute.value_date ? dayjs(attribute.value_date).toDate() : null;
                });
            });
        }

        this.dataService.request('Department/listAll').subscribe(response => {
            this.departments = response.departments;
        });

        this.dataService.request('TaskTopic/listAll').subscribe(response => {
            this.topics = response.topics;
        });

        this.dataService.request('TaskType/listAll').subscribe(response => {
            this.taskTypes = response.tasktypes;
        });

        this.dataService.request('User/listForAssignment').subscribe(response => {
            this.externals = response.users;
        });

        if (urlData.hash) {
            tab = urlData.hash as TabsEnum;
        }
        this.setTab(tab);
    }

    ngOnDestroy() {
        this.dueDateChanges$.unsubscribe();
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;
        window.location.hash = tab;
    }

    getTask(): void {
        this.dataService.request('Task/get', {
            TID: this.TID
        }).subscribe(response => {
            this.task = response.task;
            this.tabCount = response.tabCount;

            this.breadcrumbService.setManually([
                {
                    label: {title: 'Unternehmen ' + this.task.companyName},
                    url: '/app/company/detail/' + this.cryptoService.encrypt({ID: this.task.CID})
                },
                {
                    label: {title: this.translateService.instant('LOCATION.LOCATION') + ' ' + this.task.locationName},
                    url: '/app/location/detail/' + this.cryptoService.encrypt({ID: this.task.LID})
                },
                {
                    label: {title: 'Dokument ' + this.task.documentName},
                    url: '/app/document/detail/' + this.cryptoService.encrypt({ID: this.task.DID})
                },
                {label: {title: 'Aufgabe ' + this.task.title}},
            ]);

            this.DID = this.task.DID;

            this.dataService.request('Document/get', {DID: this.task.DID}).subscribe(response2 => {
                this.document = response2.document;

                this.attributes = response2.attributes.filter((attribute: any) => attribute.type === 'date');
                this.attributes.forEach((attribute: any) => {
                    attribute.value = attribute.value_date ? dayjs(attribute.value_date).toDate() : null;
                });
            });

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            TID: this.task.TID,
            DID: this.task.DID,
            DPID: this.task.DPID,
            TOID: this.task.TOID,
            TTID: this.task.TTID,
            UID_external: this.task.UID_external,
            created: this.task.created,
            title: this.task.title,
            text: this.task.text,
            number: this.task.number,
            type: this.task.type,
            due_date: this.task.due_date,
            interval: this.task.interval,
            weekly_day: this.task.weekly_day,
            monthly_yearly_date: this.task.monthly_yearly_date,
            yearly_month: this.task.yearly_month,
            recurring_start: this.task.recurring_start,
            recurring_step: this.task.recurring_step,
        });
    }

    save(): void {
        this.dataService.request('Task/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Aufgabe wurde gespeichert');

            const urlData: UrlData = {
                ID: this.DID,
                hash: 'tasks'
            };
            this.router.navigate(['/app/document/detail/', this.cryptoService.encrypt(urlData)]);
        });
    }

    update(): void {
        this.dataService.request('Task/update', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Aufgabe wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
            // reload task so responsible user is updated
            this.getTask();
        });
    }

    cancel(): void {
        const urlData: UrlData = {
            ID: this.DID,
            hash: 'tasks'
        };
        this.router.navigate(['/app/document/detail/', this.cryptoService.encrypt(urlData)]);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
        this.tagConfig.editable = this.viewMode === ViewMode.EDIT;
        this.tagConfig = Object.assign({}, this.tagConfig);
    }

    delete(TID: string): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Die Aufgabe und dessen Termine werden gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('Task/delete', {TID}).subscribe(() => {
                    this.toastrService.success('Aufgabe wurde gelöscht');
                    this.cancel();
                });
            }
        });
    }

    /**
     * Sets the due date from an attribute on a singular task
     */
    setDueDate(attribute: any): void {
        this.form.patchValue({
            due_date: attribute.value_date
        });
    }

    /**
     * Sets the recurring start from an attribute on a recurring task
     */
    setRecurringStart(attribute: any): void {
        this.form.patchValue({
            recurring_start: attribute.value_date,
            monthly_yearly_date: dayjs(attribute.value_date).date(),
            yearly_month: dayjs(attribute.value_date).month() + 1
        });
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    getTabCount(): void {
        this.dataService.request('Task/getTabCount', {TID: this.TID}).subscribe(response => {
            this.tabCount = response.tabCount;
        });
    }

    exportTask(): void {
        // this.exporting = true;
        this.dataService.request('Export/task', {
            TID: this.TID
        }).subscribe(response => {
            // thx to https://stackoverflow.com/a/52091804/757218
            const byteCharacters = atob(response.pdfData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            // this.exporting = false;
        });
    }
}
