import {Component, OnInit, inject} from '@angular/core';
import {CryptoService} from '../../../global/services/crypto.service';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {UserService} from '../../../global/services/user.service';
import Swal from 'sweetalert2';
import {ViewMode} from '../../../interfaces';
import {NgIf, NgFor} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {TooltipDirective} from "ngx-bootstrap/tooltip";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-department-detail',
    templateUrl: './department-detail.component.html',
    styleUrl: './department-detail.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, TranslateModule, RightPipe, TooltipDirective]
})
export class DepartmentDetailComponent implements OnInit {

    userService = inject(UserService);
    private cryptoService = inject(CryptoService);
    private router = inject(Router);
    private fb = inject(UntypedFormBuilder);
    private dataService = inject(DataService);
    private toastrService = inject(ToastrService);

    ViewMode = ViewMode;

    form!: UntypedFormGroup;
    DPID!: string;
    viewMode!: ViewMode;
    users!: any[];
    department: any;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.DPID = urlData.ID;
        this.viewMode = urlData.viewMode;

        this.form = this.fb.group({
            DPID: [null],
            name: ['', Validators.required],
            UID: [null, Validators.required],
            username: [null]
        });

        if (this.DPID !== '0') {
            this.getDepartment();
        }

        this.dataService.request('User/listForAssignment').subscribe(response => {
            this.users = response.users;
        });
    }

    getDepartment(): void {
        this.dataService.request('Department/get', {DPID: this.DPID}).subscribe(response => {
            this.department = response.department;
            this.department.used = parseInt(this.department.used, 10);

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            DPID: this.department.DPID,
            name: this.department.name,
            UID: this.department.UID,
            username: this.department.username
        });
    }

    save(): void {
        this.dataService.request('Department/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Abteilung wurde gespeichert');
            this.router.navigate(['/app/department/list']);
        });
    }

    update(): void {
        this.dataService.request('Department/update', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Abteilung wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
            this.getDepartment();
        });
    }

    cancel(): void {
        this.router.navigate(['/app/department/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Die Abteilung wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('Department/delete', {
                    DPID: this.DPID
                }).subscribe(() => {
                    this.toastrService.success('Abteilung wurde gelöscht');
                    this.cancel();
                });
            }
        });
    }
}
