import {Component, EventEmitter, Input, OnInit, Output, inject} from '@angular/core';
import {DataService} from '../../../../global/services/data.service';
import {FileService} from '../file.service';
import {NgIf, NgStyle} from '@angular/common';

@Component({
    selector: 'app-file-viewer-single',
    templateUrl: './file-viewer-single.component.html',
    styleUrl: './file-viewer-single.component.scss',
    imports: [NgIf, NgStyle]
})
export class FileViewerSingleComponent implements OnInit {

    private dataService = inject(DataService);
    private fileService = inject(FileService);

    @Input() FID: any; // Index of files table
    @Input() viewThumbnail = false; // Fullscreen or thumbnail
    @Input() imageWidth: string | null = null;
    @Input() imageHeight: string | null = null;
    @Input() maxWidth: string | null = null;
    @Input() maxHeight: string | null = null;

    @Output() fileStatus = new EventEmitter();

    imageData: any;

    ngOnInit(): void {
        this.dataService.request('framework.File/getFile', {
            FID: this.FID,
            thumbnail: this.viewThumbnail
        }).subscribe(response => {
            this.imageData = response.imageData;
            this.fileStatus.emit({loaded: true, FID: this.FID});
        });
    }

    openModal(): void {
        this.fileService.openModal.next(this.FID);
    }
}
