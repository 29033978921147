<ng-container *ngIf="!config.dataConfig.columnFilter.active">
    <button class="btn btn-sm btn-dark" (click)="toggleFilter()">
        <i class="fa fa-filter"></i> {{config.dataConfig.columnFilter.buttonOn ? (config.dataConfig.columnFilter.buttonOn | translateLabel) : ''}}
    </button>
</ng-container>

<ng-container *ngIf="config.dataConfig.columnFilter.active">
    <button class="btn btn-sm btn-dark mr-1" (click)="resetFilter()" [disabled]="!checkFiltered()">
        <i class="fa fa-trash"></i> {{config.dataConfig.columnFilter.buttonReset ? (config.dataConfig.columnFilter.buttonReset | translateLabel) : ''}}
    </button>
    <button class="btn btn-sm btn-dark" (click)="toggleFilter()" [class.fw-table-button-filtered]="checkFiltered()">
        <i class="fa fa-times"></i> {{config.dataConfig.columnFilter.buttonOff ? (config.dataConfig.columnFilter.buttonOff | translateLabel) : ''}}
    </button>
</ng-container>
