<div class="container">
    <div class="row">
        <div class="col">
            <h1 class="display-4 text-center my-3">Allgemeine Geschäftsbedingungen (AGB)</h1>

            <p class="font-weight-bold">1. Software as a Service (“SaaS”) Vertrag</p>

            <p>
                Willkommen bei NoteRadar, der browserbasierten Applikation für Bescheid-, Vertragsmanagement
                und FileShare.</p>
            <p>
                Die Vertragspartner sind einerseits die Betreiber der Webseiten „www.noteradar.at“ und alle
                Subdomains , die NoteRadar GmbH (im Folgenden “wir” oder “NoteRadar”) und andererseits der
                Benutzer als gewerblicher Kunde, der demnach kein Verbraucher oder Konsument im Sinne des
                österreichischen Konsumentenschutzgesetzes KSchG ist („Sie“ oder „Benutzer“ bzw. „Nutzer“).</p>

            <p>Mit der Registrierung eines Benutzers oder die Annahme der Einladung für den Zugang zu und der
                Nutzung von NoteRadar erklärt der Benutzer seine verbindliche Zustimmung zu den folgenden
                allgemeinen Geschäftsbedingungen (AGB). Die Verletzung von wesentlichen Vertragspunkten kann
                zur Schließung Ihres Benutzerkontos führen. Die Nutzung von NoteRadar verbietet insbesondere
                verantwortungslose und illegale Aktivitäten und Sie stimmen zu, dass wir für solche Aktivitäten und
                deren Konsequenzen nicht verantwortlich gemacht werden können.</p>

            <p class="font-weight-bold">2. Vertragsgegenstand</p>

            <ol>
                <li>NoteRadar erbringt internetbasierte Software-as-a-Service-Dienstleistungen (“SaaS”) im Bereich
                    der Speicherung von Bescheiden, Verträgen und Dokumenten in der Cloud.
                </li>
                <li>NoteRadar läuft auf Hoch-Redundanten Distributed-Computing-Systemen. Die Daten werden
                    ausschließlich innerhalb der EU gespeichert (Österreich / Deutschland).
                </li>
                <li>Gegenstand des Vertrages ist insbesondere
                    <ol type="a">
                        <li>die Bereitstellung des Software as a Service (SaaS) „NoteRadar“ zur Nutzung über das
                            Internet.
                        </li>
                        <li>Der Benutzer erhält das nichtausschließliche, auf die Nutzungszeit bzw. Vertragslaufzeit
                            beschränkte Recht, auf die Softwarefunktionalitäten via Internet zuzugreifen. Darüber hinaus
                            gehende Rechte erhält der Benutzer nicht.
                        </li>
                        <li>die Speicherung von Daten, die vom Benutzer übermittelt wurden, für die vereinbarte
                            Dauer.
                        </li>
                    </ol>
                </li>
            </ol>

            <p class="font-weight-bold">3. Bereitstellung der Software</p>
            <ol>
                <li>Wir stellen Ihnen für die Dauer dieses Vertrags die Software NoteRadar in der von Ihnen bezahlten
                    Version und deren aktuellen Umfang zur Verfügung. Die Bereitstellung erfolgt auf einem Server, der
                    für Sie als Nutzer über das Internet erreichbar ist.
                </li>
                <li>Die Funktionstüchtigkeit von NoteRadar wird von uns laufend überprüft. Sämtliche Softwarefehler
                    werden nach Maßgabe der technischen und gestalterischen Möglichkeiten in angemessenen
                    Zeitspannen behoben. Ein solcher Softwarefehler liegt dann vor, wenn angegebene Funktionen nicht
                    erfüllt werden, zu falschen Ergebnissen führen bzw. die Nutzbarkeit von NoteRadar unmöglich
                    machen.
                </li>
                <li>Wir entwickeln und verbessern NoteRadar kontinuierlich.</li>
                <li>Die Funktionstüchtigkeit der Software kann nur über die technischen Voraussetzungen, die in der
                    Produktbeschreibung des Angebots beschrieben ist gewährleistet werden.
                </li>
            </ol>

            <p class="font-weight-bold">4. Leistungen und Preise</p>
            <ol>
                <li>NoteRadar bietet Versionen an, die aufgrund der laufenden Entwicklung in Ihrem
                    Umfang modifiziert werden können. Art und Umfang der vertraglichen Leistungen ergeben sich aus
                    den Funktionen der jeweiligen Version von NoteRadar.
                </li>
                <li>Die Registrierung für die Demoversion von NoteRadar ist unentgeltlich, erst bei der Bestellung
                    werden Kosten fällig. Auch die Nutzung der Demoversion unterliegt diesen AGB.
                </li>
                <li>Bei NoteRadar handelt es sich um eine entgeltliche Dienstleistung, den aktuellen Preis erhalten Sie
                    auf ihre Bedürfnisse angepasst von unserem Vertriebsteam.
                </li>
                <li>Die genauen Leistungsbeschreibung Ihrer Vereinbarung entnehmen Sie bitte Ihrem Angebot.</li>
            </ol>

            <p class="font-weight-bold">5. Nutzungsbedingungen - Pflichten und Obliegenheiten des Benutzers</p>

            <ol>
                <li>Sie müssen mindestens 18 Jahre alt sein, um sich bei NoteRadar registrieren zu dürfen. Sie müssen
                    eine natürliche Person oder ein im Firmenbuch eingetragenes Unternehmen, jedenfalls aber ein
                    Unternehmer iSd. KSchG sein: Registrierungen oder Anmeldung durch “bots” oder ähnliche
                    automatische Methoden sind unzulässig. Die Anmeldung erfolgt durch Angabe Ihrer E-Mailadresse
                    und eines Passwortes.
                </li>
                <li>Sie sind für die Erstellung, sichere Verwahrung und Geheimhaltung Ihrer Zugangsdaten
                    (insbesondere des Passworts) selbst verantwortlich. Für einen etwaigen Verlust Ihrer Zugangsdaten
                    und daraus resultierende Zugriffe und Manipulationen von Dritten haften wir ausdrücklich nicht.
                </li>
                <li>NoteRadar haftet trotz aller technischen und administrativen Maßnahmen nicht für einen
                    Datenverlust. Um einen Datenverlust ausschließen zu können, haben Sie die Möglichkeit selbst
                    regelmäßig einen vollständigen Datenexport durchzuführen. Weiters sind Sie verpflichtet rechtzeitig
                    vor Kündigung Ihres Vertrags einen vollständigen Datenexport zu erstellen (siehe auch Punkt
                    Gewährleistung/Haftung/Schadenersatz).
                </li>
                <li>Sie dürfen NoteRadar nur im Rahmen des Vertragszwecks (siehe Punkt 2. Vertragsgegenstand) und
                    nach Maßgabe dieser AGB und der geltenden Gesetze verwenden. Insbesondere haben Sie das
                    Datenschutzgesetz 2000 in der jeweils geltenden Fassung (DSG) und die Vorschriften zum Schutz des
                    geistigen Eigentums zu beachten.
                </li>
                <li>Eine Bearbeitung oder Vervielfältigung oder Veräußerung des NoteRadar SaaS oder von Teilen
                    davon, sowie das Speichern und die Installation der Software auf Datenträgern, ist unzulässig,
                    ausgenommen ist nur das temporäre Laden der Software in den Arbeitsspeicher ohne Anfertigung
                    von (Sicherungs-)Kopien.
                </li>
                <li>Wir sind zur sofortigen Sperre Ihres Zugangs zu NoteRadar berechtigt, wenn ein begründeter
                    Verdacht besteht, dass Ihr Benutzerkonto missbräuchlich oder in gesetzwidriger oder Ihre
                    vertraglichen Pflichten verletzender Weise verwendet wird, insbesondere auch, wenn NoteRadar
                    durch Dritte benützt wird, oder wenn Sie das Urheberrecht von NoteRadar verletzen. In einem
                    solchen Fall verpflichten Sie sich ausdrücklich NoteRadar schad- und klaglos zu halten.
                </li>
                <li>Sollte Ihr Zugang zu NoteRadar gesperrt werden, benachrichtigen wir Sie umgehend über die
                    Sperre und deren Gründe. Eine allfällige Sperre Ihres Zugangs wird aufgehoben, sobald der Verdacht
                    einer rechtswidrigen Nutzung bzw. rechtswidriger Daten ausgeschlossen werden kann.
                </li>
                <li>Bei vertragswidriger Nutzung durch Dritte hat der Benutzer NoteRadar zudem auf Verlangen
                    sämtliche Angaben zur Geltendmachung der Ansprüche gegen Dritte zu machen, insbesondere deren
                    Namen und Anschrift mitzuteilen sowie Art und Umfang seiner gegen Dritte aus der unberechtigten
                    Programmüberlassung bestehenden Ansprüche unverzüglich mitzuteilen.
                </li>
            </ol>

            <p class="font-weight-bold">6. Entgelt</p>
            <ol>
                <li>Für jedes Paket von NoteRadar ist ein einmaliger Betrag (monatlich oder jährlich) zu entrichten.
                    Die Vertragslaufzeit verlängert sich automatisch bis zum Zeitpunkt der Kündigung.
                </li>
                <li>Sie verpflichten sich, bei der Bestellung von NoteRadar das jeweilige monatliche bzw. jährliche
                    Entgelt zzgl. der gesetzlich vorgeschriebenen Umsatzsteuer und allfälliger sonstiger Abgaben zu
                    bezahlen. Den jeweiligen Preis der Paketversionen finden Sie in Ihrem Angebot.
                </li>
                <li>Vor Überweisung des Entgelts erhalten Sie eine Rechnung, die Bezahlung ist mittels
                    Banküberweisung oder via Bankeinzug möglich.
                </li>
                <li>Falls die Bezahlung nicht erfolgt oder rückgängig gemacht wird, sind wir dazu berechtigt, Ihren
                    Zugang mit sofortiger Wirkung zu sperren.
                </li>
            </ol>

            <p class="font-weight-bold">7. Erreichbarkeit &amp; Kundendienst</p>
            <ol>
                <li>NoteRadar ist bei bestehender Internetverbindung grundsätzlich immer erreichbar. Hiervon
                    ausgenommen sind Zeiten, in denen der Server aufgrund von technischen oder sonstigen Problemen,
                    die nicht im Einflussbereich von NoteRadar liegen (höhere Gewalt, Verschulden Dritter etc.) nicht zu
                    erreichen ist. Von Wartungsarbeiten werden Sie im Vorfeld entsprechend informiert.
                </li>
                <li>NoteRadar kann unter Umständen dann nicht erreichbar sein, wenn vertragsgegenständliche
                    Adaptionen oder die Feststellung und Behebung von Fehlfunktionen aus technischen und
                    gestalterischen Gründen eine vorübergehende Unterbrechung der Bereitstellung erfordern.
                    NoteRadar ist berechtigt, zu Wartungszwecken oder infolge anderer technischer Erfordernisse, die
                    Verfügbarkeit der Software zu unterbrechen. Über vorhersehbare Unterbrechungen der
                    Erreichbarkeit von NoteRadar informieren wir Sie rechtzeitig (mindestens 24 Stunden davor). Sollte
                    die Behebung von Fehlern nicht innerhalb eines Werktages ab der Meldung des Fehlers möglich sein,
                    verständigen wir Sie per E-Mail und teilen Ihnen mit, welchen Zeitraum wir für die Fehlerbehebung
                    veranschlagen, wann NoteRadar wieder erreichbar sein wird und wie Sie in der Zwischenzeit das
                    Problem eventuell umgehen können.
                </li>
                <li>Die Erreichbarkeit und Nutzungsfähigkeit von NoteRadar wird permanent überwacht.</li>
                <li>Kontaktaufnahmen per E-Mail oder Support-Formular werden zu den Bürozeiten bearbeitet. Auch
                    der Telefon- bzw. Premiumsupport wird zu diesen Bürozeiten geleistet.
                </li>
                <li>NoteRadar haftet nicht für zeitliche Ausfälle der Server, Datenverluste (soweit keine andere
                    Vereinbarung getroffen wird), die korrekte Funktionsfähigkeit einzelner Programme oder
                    Übertragungsstörungen vom Server zum Nutzer selbst.
                </li>
            </ol>

            <p class="font-weight-bold">8. Gewährleistung /Haftung/Schadenersatz</p>
            <ol>
                <li>Wir gewährleisten die Funktionsbereitschaft und die Nutzungsfähigkeit von NoteRadar nach
                    Maßgabe des Punkts 7 Erreichbarkeit &amp; Kundendienst während der Vertragsdauer.
                </li>
                <li>NoteRadar haftet für sämtliche Schäden nur nach Maßgabe der folgenden Punkte:
                    <ol type="a">
                        <li>Bei vorsätzlicher Schadensverursachung haftet NoteRadar nach den gesetzlichen Vorschriften. b)
                            Bei grober Fahrlässigkeit ist die Haftung von NoteRadar, soweit dem nicht zwingendes
                            Recht entgegensteht, mit dem zehnfachen Preis des günstigsten Jahrespreises, gegenüber der
                            Gesamtheit solcher Geschädigter mit einem Höchstbetrag von EUR 5.000,00 begrenzt. Übersteigt der
                            Gesamtschaden die Höchstgrenze, so verringern sich die Ersatzansprüche der einzelnen
                            Geschädigten anteilsmäßig.
                        </li>
                        <li>Bei leichter Fahrlässigkeit ist die Haftung von NoteRadar ausgeschlossen. Die Haftung von
                            NoteRadar ist demnach auf solche Fälle beschränkt, die vorsätzlich oder grob fahrlässig verschuldet
                            wurden, wobei dem Nutzer als Geschädigten jedem Fall der Nachweis des Verschuldens obliegt.
                        </li>
                    </ol>
                    <p>Sämtliche Schadenersatzansprüche müssen - sollten diese Ansprüche nicht vorher vom NoteRadar
                        dem Grunde und der Höhe nachschriftlich anerkannt worden sein - bei sonstigem Verfall innerhalb
                        eines Jahres ab Schadenseintritt gerichtlich geltend gemacht werden.</p>
                </li>
                <li>NoteRadar haftet nicht für entgangenen Gewinn, Strafen, reine Vermögensschäden,
                    Verdienstentgang, frustrierte Aufwendungen, entgangene Ersparnisse, immaterielle Schäden,
                    mittelbare Schäden sowie Mangelfolgeschäden, Schäden aus Ansprüchen Dritter, Datenverlust und
                    Schäden, deren Eintritt auf höherer Gewalt oder Streik beruht. NoteRadar haftet nicht für
                    Handlungen und Unterlassungen von Dritten, insbesondere Externe Benutzer. Weiters haftet
                    NoteRadar nicht für die Verwendung des eingebundenen E-Mailversands, sollte es zu
                    Übertragungsfehlern oder Ähnlichem kommen.
                    Eine Haftung für Datenverluste ist jedenfalls ausgeschlossen, insoweit Sie bestehenden Verpflichtung
                    zum Datenexport nicht nachgekommen sind.
                </li>
                <li>Sie sind dazu verpflichtet, Ihre Daten sowie auch jene der Ihnen zuzurechnenden Personen wie
                    beispielsweise externe Benutzer vor der Benutzung bei NoteRadar frei von Viren, Trojanern oder
                    sonstigen schädlichen Komponenten zu halten. Sollten schadhafte bzw. schädliche Daten NoteRadar
                    oder den Server, auf dem diese Daten gespeichert sind, beeinträchtigen bzw. beschädigen, oder
                    sonstige Schäden hervorrufen, behalten wir uns rechtliche Schritte und Schadenersatzansprüche vor.
                    Sollten derartige schadhafte bzw. schädliche Daten Schäden oder sonstige Nachteile bei Dritten
                    bewirken haftet hierfür nicht NoteRadar, sondern ausschließlich die jeweiligen Benutzer.
                </li>
                <li>Im Fall der Ziffer 4. und in dem Fall, dass NoteRadar wegen allfälliger Rechtsverletzungen durch Sie
                    von Dritten verantwortlich gemacht werden sollte, verpflichten Sie sich, NoteRadar für alle dadurch
                    entstandenen Nachteile und Schäden schad- und klaglos zu halten.
                </li>
                <li>Unsere Gewährleistungspflicht setzt das Erfüllen der Vertragspflichten durch den Nutzer voraus.
                    Auftretende Mängel sind bei sonstigem Anspruchsverlust unverzüglich schriftlich mitzuteilen. Die
                    Vermutungsregel des § 924 ABGB wird ausdrücklich ausgeschlossen. Der Nutzer hat stets zu
                    beweisen, dass der Mangel zum Zeitpunkt der Übergabe bereits vorhanden war. Im Falle der
                    Gewährleistung hat Verbesserung jedenfalls Vorrang vor Preisminderung oder Wandlung. Bei
                    gerechtfertigter Mängelrüge werden die Mängel in angemessener Frist behoben, wobei der Nutzer
                    uns alle zur Untersuchung und Mängelbehebung erforderlichen Maßnahmen zu ermöglichen hat.
                    Ersatzvornahmen sind nicht von unserer Gewährleistung erfasst und übernimmt NoteRadar hierfür
                    auch keine wie auch immer gearteten Kosten.
                </li>
            </ol>

            <p class="font-weight-bold">9. Vertragsabschluss, Laufzeit, Kündigung und Auflösung</p>
            <p>Die jeweilige Vereinbarung finden Sie in Ihrem umfangreichen Angebot.</p>

            <p class="font-weight-bold">10. Datenschutz und Geheimhaltung</p>
            <ol>
                <li>Die Parteien werden die jeweils anwendbaren, insb. die in Österreich gültigen
                    datenschutzrechtlichen Bestimmungen beachten.
                </li>
                <li>NoteRadar stellt einen Mustervertrag zur Auftragsdatenverarbeitung bereit, der jederzeit vom
                    Kunden angefordert und abgeschlossen werden kann.
                </li>
            </ol>

            <p class="font-weight-bold">11. Änderungen und Ergänzungen der AGB</p>
            <ol>
                <li>Neben diesem Vertrag bestehen keine anderweitigen (mündlichen oder schriftlichen)
                    Vereinbarungen. Sollten vor Abschluss dieses Vertrags anderweitige Vereinbarungen bestehen,
                    verlieren diese bei Abschluss dieses Vertrags ihre Gültigkeit. Allgemeine Geschäftsbedingungen des
                    Nutzers werden, auch wenn diese Angebotsaufforderungen, Bestellungen, Annahmeerklärungen
                    usw. beigefügt sind und diesen nicht widersprochen wird, keinesfalls Vertragsinhalt. Unsere AGB
                    gelten auch für zukünftige Geschäftsbeziehungen.
                </li>
                <li>Wir behalten uns vor, Änderungen und Ergänzungen an diesem Vertrag vorzunehmen, um z.B.
                    Anpassungen an geänderte rechtliche Rahmenbedingungen vorzunehmen oder neue Leistungen zu
                    integrieren. Alle Entwicklungen und Adaptionen von NoteRadar – neue Funktionen, etc. –
                    unterliegen den jeweils geltenden AGB.
                </li>
                <li>Änderungen und Ergänzungen dieses Vertrags bedürfen in allen Fällen bei sonstiger
                    Rechtsunwirksamkeit der Schriftform (in elektronischer Form z. B. als E-Mail). Die Änderungen
                    werden wirksam, wenn Sie nicht innerhalb einer Frist von 14 Tagen (einlangend) schriftlich dagegen
                    Einspruch erheben. Wir werden Sie auf Änderungen der AGB sowie auf Ihr Einspruchsrecht per E-
                    Mail hinweisen. Bei Unterlassung eines Widerspruchs fassen wir Ihre Weiternutzung von NoteRadar
                    nach Änderungen jedenfalls als Zustimmung zu diesen Änderungen auf. Die aktuellen AGB können
                    jederzeit unter www.noteradar.at eingesehen werden.
                </li>
                <li>Wenn Sie Einspruch gegen Änderungen der AGB erheben, können Sie eine bereits erworbene
                    Lizenz zu Ende nutzen, es gelten, soweit technisch zutreffend, die von Ihnen zuletzt angenommenen
                    AGB. Wir behalten uns jedoch vor, mit Ihnen keinen Vertrag über eine neue Lizenz abzuschließen.
                </li>
                <li>Alle Mitteilungen sind in schriftlicher Form elektronisch an die bei Firmenbuch hinterlegenden E-
                    Mail-Adresse zu richten und genügen so dem Schriftlichkeitserfordernis.
                </li>
                <li>Die Vertragspartner sind verpflichtet, sich gegenseitig allfällige Änderungen der E-Mail-Adressen
                    unverzüglich mitzuteilen. Wird dies verabsäumt, so gelten Mitteilungen an die jeweils zuletzt
                    bekannt gegebene E-Mail-Adresse als gültig und rechtswirksam.
                </li>
                <li>Falls Sie Fragen oder Anmerkungen zu NoteRadar haben, oder uns auf Fehler hinweisen wollen,
                    können Sie uns unter info&#64;noteradar.at erreichen - wir versuchen alle Anfragen gründlich und in
                    angemessenen Zeitspannen zu beantworten.
                </li>
                <li>Salvatorische Klause sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, so
                    wird dadurch die Gültigkeit der restlichen Bestimmungen nicht berührt. Die Vertragsparteien
                    verpflichten sich jetzt schon eine Ersatzregelung – ausgehend vom Verständnis redlicher
                    Vertragsparteien – zu treffen, die dem wirtschaftlichen Ergebnis unter Berücksichtigung der
                    Branchenüblichkeit der unwirksamen Bedingung am nächsten kommt.
                </li>
                <li>Erfüllungsort und Gerichtsstand Erfüllungsort ist der Firmensitz der Noteradar GmbH.</li>
            </ol>
            <p>Als Gerichtsstand für alle sich aus dem Vertragsverhältnis oder künftigen Verträgen zwischen den
                Vertragsparteien ergebenden Rechtsstreitigkeiten wird das sachlich für Handelsrechtssachen
                zuständige Gericht am Sitz der NoteRadar GmbH in Wr. Neustadt vereinbart.</p>

            <p class="font-weight-bold">12. Sonstiges</p>
            <ol>
                <li>Der Vertrag und alle daraus abzuleitenden Rechte, Pflichten und Ansprüche unterliegen dem
                    österreichischen materiellen Recht unter Ausschluss seiner Verweisungsnormen. Die Anwendbarkeit
                    des UN-Kaufrechts wird ausdrücklich ausgeschlossen.
                </li>
                <li>Als Dritter im Sinne der Verwendung dieses Begriffs innerhalb dieses Vertrags gilt jede natürliche
                    bzw. juristische Person, die sich von den Vertragspartnern im rechtlichen Sinne unterscheidet.
                </li>
                <li>Alle Bild-, Urheber- und Markenrechte für die eigenen Inhalte aller Webseiten unter noteradar.at
                    oder noteradar.de (z.B. Grafiken, Sounds, Texte, Datenbanken, bewegte Bilder) sowie das
                    Urheberrecht am NoteRadar SaaS liegen bei NoteRadar. Jegliche von diesen AGB nichtausdrücklich
                    gestattete Verwertung, Vervielfältigung und Verbreitung oder Bearbeitung der Inhalte und Software
                    und/oder Verwendung der Marken und sonstigen Inhalte ohne ausdrückliche Zustimmung von
                    NoteRadar ist nicht gestattet.
                </li>
            </ol>

            <p class="mt-4">Stand: 20.04.2021</p>
        </div>
    </div>
</div>
