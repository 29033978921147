import {Component, OnInit, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {UserService} from '../../../global/services/user.service';
import {ViewMode} from '../../../interfaces';
import {NgIf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {PaymentTypePipe} from '../../../global/pipes/payment-type.pipe';

@Component({
    selector: 'app-user-account',
    templateUrl: './user-account.component.html',
    styleUrl: './user-account.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, TranslateModule, PaymentTypePipe]
})
export class UserAccountComponent implements OnInit {

    userService = inject(UserService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);

    readonly ViewMode = ViewMode;

    form!: UntypedFormGroup;
    client: any = {};
    viewMode: ViewMode = ViewMode.VIEW;

    ngOnInit(): void {
        this.form = this.fb.group({
            CLID: [null],
            name: ['', Validators.required],
            zip: ['', Validators.required],
            city: ['', Validators.required],
            street: ['', Validators.required],
            register_number: ['', Validators.required],
            invoice_zip: ['', Validators.required],
            invoice_city: ['', Validators.required],
            invoice_street: ['', Validators.required],
            invoice_text: ['', Validators.required],
            invoice_email: ['', Validators.required],
            invoice_UID: ['', Validators.required],
            email: ['', Validators.required],
            phone: ['', Validators.required],
            cost_unit: ['', Validators.required],
            number_locations: ['', Validators.required],
            payment_type: ['', Validators.required],
        });

        this.getClient();
    }

    getClient(): void {
        this.dataService.request('universe.Client/getSettings', {
            CLID: this.userService.currentUser.CLID
        }).subscribe(response => {
            this.client = response.client;

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            CLID: this.client.CLID,
            name: this.client.name,
            zip: this.client.zip,
            city: this.client.city,
            street: this.client.street,
            register_number: this.client.register_number,
            invoice_zip: this.client.invoice_zip,
            invoice_city: this.client.invoice_city,
            invoice_street: this.client.invoice_street,
            invoice_text: this.client.invoice_text,
            invoice_email: this.client.invoice_email,
            invoice_UID: this.client.invoice_UID,
            email: this.client.email,
            phone: this.client.phone,
            cost_unit: this.client.cost_unit,
            number_locations: this.client.number_locations,
            payment_type: this.client.payment_type,
        });
    }

    update(): void {
        this.dataService.request('universe.Client/updateSettings', this.form.getRawValue()).subscribe(() => {
            this.setMode(ViewMode.VIEW);
        });
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }
}
