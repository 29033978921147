<div class="card">
    <div class="card-header">
        <i class="fas fa-user-tie mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.INSERT">Abteilung anlegen</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Abteilung bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Abteilung</ng-container>
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.name }}</p>
            </div>
            <div class="form-group">
                <label>Verantwortliche(r)</label>
                <ng-container *ngIf="viewMode != ViewMode.VIEW">
                    <select class="form-control" formControlName="UID">
                        <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                        <option *ngFor="let user of users" [ngValue]="user.UID">
                            {{ user.firstname }} {{ user.lastname }} ({{ user.email }})
                        </option>
                    </select>
                    <small class="form-text text-danger" *ngIf="users?.length == 0">
                        Bitte vorher einen Benutzer anlegen, der dieser Abteilung zugeordnet werden kann.
                    </small>
                    <small class="form-text text-muted">
                        Person, die für die Erledigung der Aufgaben dieser Abteilung verantwortlich ist.
                    </small>
                </ng-container>
                <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.username }}</p>
            </div>
        </form>

        <ng-container *ngIf="viewMode == ViewMode.VIEW">
            <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">Bearbeiten</button>
            <button type="button" class="btn btn-secondary" (click)="cancel()">Zurück</button>
            <button type="button" class="btn btn-danger float-right"
                    [attr.data-right]="'MASTER_DATA_EDIT' | right"
                    *ngIf="userService.hasRight('MASTER_DATA_EDIT')"
                    (click)="delete()"
                    [disabled]="department?.used > 0"
                    [tooltip]="department?.used > 0 ? 'Diese Abteilung wird noch verwendet und kann daher nicht gelöscht werden.' : ''"
            >Löschen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.INSERT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
        </ng-container>

    </div>
</div>
