<input style="display: none" type="file" (change)="selectFiles($event)" #fileInput [accept]="uploadAccept" [multiple]="uploadSelectMultiple" [(ngModel)]="fileInputModel">
<div *ngIf="(selectedFiles == null || (uploadedCount == '0')) && ( !uploadMaxFileCount || countAlreadyUploaded < uploadMaxFileCount )" class="row pb-3">
    <div class="pl-3 pr-3">
        <ng-container *ngIf="showDragAndDrop">
            <div class="fw-file-upload-drop-zone" appDnd (fileDropped)="onFileDropped($event)" [ngClass]="{'fw-file-upload-disabled': uploadDisabled}"><p>Datei(en) hier reinziehen</p>oder
                <button type="button" class="btn btn-info mr-2" (click)="fileInput.click()" [disabled]="uploadDisabled">
                    <i class="fa fa-mouse-pointer fa-sm"></i>
                    Datei(en) auswählen <span *ngIf="uploadMaxFilesize > 0">({{(uploadMaxFilesize) | number: '1.0-2'}} MB)</span>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="!showDragAndDrop">
            <button type="button" class="btn btn-info mr-2" (click)="fileInput.click()" [disabled]="uploadDisabled">
                <i class="fa fa-mouse-pointer fa-sm"></i>
                Datei(en) auswählen <span *ngIf="uploadMaxFilesize > 0">({{(uploadMaxFilesize) | number: '1.0-2'}} MB)</span>
            </button>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="selectedFiles != null">
    <div *ngIf="upload.status !== 'finished' && !showError" class="alert alert-info">
        {{activeFiles}} ausgewählte Datei(en):
        <form class="form" #uploadForm="ngForm">
            <table class="table table-sm table-borderless table-hover mb-0">
                <thead>
                <tr>
                    <th></th>
                    <th>Dateiname</th>
                    <th style="width: 50%">Beschreibung</th>
                    <th>Dateigröße</th>
                    <th *ngIf="activeFiles > 1"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let file of selectedFiles; let i = index" [hidden]="file.deleted">
                    <td><img *ngIf="file.type.match('image.*')" [src]="file.src" style="height: 30px"/></td>
                    <td><input type="text" class="form-control form-control-sm" name="name_{{i}}" [(ngModel)]="file.display_name" required/></td>
                    <td><input type="text" class="form-control form-control-sm" name="description_{{i}}" [(ngModel)]="file.description"/></td>
                    <td>{{ (file.size / 1024 / 1024) | number: '1.0-2'}} MB</td>
                    <td *ngIf="activeFiles > 1">
                        <button type="button" class="btn btn-danger btn-sm" (click)="removeSelectedFile(file)"><i class="fas fa-times"></i></button>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
        <div class="pt-2" *ngIf="upload.status === 'pending'">
            <button type="submit" class="btn btn-success btn-sm mr-2" [disabled]="uploading || uploadForm.invalid" (click)="onUpload()" *ngIf="errors.length == 0">
                <i class="fa fa-upload fa-sm"></i>
                Upload
            </button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="deselectFiles()">
                <i class="fa fa-remove fa-sm"></i>
                Abbrechen
            </button>
        </div>
        <ng-container *ngIf="upload.status === 'progress' && !showError">
            <div class="progress" style="height: 20px;">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style.width.%]="upload.progress" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{ upload.progress }}%</div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="errors.length > 0" class="alert alert-danger">
        <ul class="mb-0">
            <li *ngFor="let error of errors">
                <ng-container *ngIf="error.errorType == 'INVALID_TYPE'">
                    {{ error.filename }}: Der Dateityp {{ error.type }} ist nicht erlaubt. Erlaubt sind {{ acceptString }}.
                </ng-container>
                <ng-container *ngIf="error.errorType == 'INVALID_SIZE'">
                    {{ error.filename }}: Die Größe von <strong>{{(error.size / (1024 * 1024)) | number: '1.0-2'}}MB</strong> ist zu groß. Erlaubt ist eine maximale Größe von {{(uploadMaxSingleFilesize) | number: '1.0-2'}}MB.
                </ng-container>
                <ng-container *ngIf="error.errorType == 'INVALID_MAX_FILESIZE'">
                    Die Größe von <strong>{{(error.maxFilesizeUpload / (1024 * 1024)) | number: '1.0-2'}}MB</strong> ist zu groß. Erlaubt ist eine maximale Größe von {{(error.maxFilesizeAllowed) | number: '1.0-2'}}MB.
                </ng-container>
            </li>
        </ul>
    </div>
    <div *ngIf="showSuccess && upload.progress === 100 && !showError" [@simpleFadeAnimation]="'in'" class="alert alert-success">
        Der Upload wurde erfolgreich abgeschlossen.
    </div>
    <div *ngIf="showError" class="alert alert-danger">
        Der Upload ist fehlgeschlagen.
    </div>
    <div *ngIf="(showError || (upload.progress === 100)) && ( !uploadMaxFileCount || countAlreadyUploaded < uploadMaxFileCount) && !uploading" class="pb-3">
        <button type="button" class="btn btn-info btn-sm" (click)="deselectFiles()">Neuer Upload</button>
    </div>
</ng-container>
