import {UntypedFormControl} from '@angular/forms';
import dayjs from 'dayjs';

/**
 * Custom validator to check if a date is valid using Day.js
 * Used to catch 29.02 in non-leap years
 *
 * @param control a FormControl
 */
export function validateDate(control: UntypedFormControl): { validateDate: { valid: boolean } } | null {
    if (control.value === null) {
        return null;
    }

    return dayjs(control.value).isValid() ? null : {
        validateDate: {
            valid: false
        }
    };
}
