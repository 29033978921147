<app-table #tableComponent [config]="tableConfig"/>

<button type="button" class="btn btn-warning"
        (click)="listStandardSet()"
        [hidden]="standardSet"
        *ngIf="count == 0"
>Standardset anzeigen</button>

<div class="card" *ngIf="standardSet">
    <div class="card-body">
        <ul>
            <li *ngFor="let element of standardSet">{{ element.name }}</li>
        </ul>

        <button type="button" class="btn btn-warning"
                (click)="loadSet()"
                [attr.data-right]="'MASTER_DATA_EDIT' | right"
                *ngIf="userService.hasRight('MASTER_DATA_EDIT')"
        >Standardset laden</button>
    </div>
</div>
