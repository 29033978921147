<div class="container">
    <div class="row">
        <div class="col">
            <h1 class="display-4 text-center my-3">Vorteile</h1>

            <div class="row my-5">
                <div class="col-md-7">
                    <h3 class="mt-0">Warum NoteRadar?</h3>
                    <p>
                        NoteRadar ist keine komplizierte Bescheid-, Vertrags- oder Rechtsmanagementplattform, sondern der Spezialist für das einfache Management von Bescheid- und Vertragsdokumenten.
                        Auf Basis von Dokumenten können einfach und schnell dazugehörige Aufgaben erstellt und in Ihrer Organisation den entsprechenden Abteilungen und Mitarbeitern zugeordnet werden.
                        Die Umsetzung von Aufgaben wird durch Erstellung von einmaligen oder wiederkehrenden Terminen geplant und durch Erinnerungen der Bearbeiter an die Erledigung zeitgerecht hingewiesen - Fristen können nicht mehr übersehen werden.
                    </p>
                </div>
                <div class="col-md-5">
                    <img src="assets/img/benefits/1.png" class="ml-3 mb-2 img-fluid">
                </div>
            </div>

            <div class="row my-5">
                <div class="col-md-12">
                    <h3 class="mt-0">Günstig durch Fokussierung auf das Wesentliche</h3>
                    <p>
                        Da sich NoteRadar auf die wesentlichen Funktionen fokussiert, kann Ihnen dieser Preisvorteil weitergegeben werden.
                        Unsere Leistung kostet weniger als 3 Stunden in einer KFZ-Vertragswerkstatt pro Jahr – die Lizenzbasis ist immer die Anzahl der Betriebsstätten (egal wie viele Mitarbeiter/Benutzer und Dokumente Sie managen wollen).
                    </p>
                </div>
            </div>

            <div class="row my-5">
                <div class="col-md-5">
                    <img src="assets/img/benefits/3.png" class="mr-3 mb-2 img-fluid">
                </div>
                <div class="col-md-7">
                    <h3 class="mt-0">Keine Einschulung, keine Installation – einfach den Browser öffnen und los</h3>
                    <p>
                        NoteRadar läuft auf jedem Computer, Mac oder Tablet in Ihrem Web-Browser. Durch die intuitive Oberfläche ist NoteRadar für jeden Mitarbeiter kinderleicht zu bedienen.
                    </p>
                    <p>
                        Das moderne und flexible Layout passt sich dynamisch an die Bildschirmgröße an, die Seitennavigation lässt sich einklappen und wird bei mobilen Geräten bei Bedarf komplett ausgeblendet.
                    </p>
                    <p>
                        Daten und Informationen werden sinnvoll in Reitern gruppiert um jederzeit eine Übersicht zu gewährleisten.
                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
