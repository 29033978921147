import {Component, OnInit, inject} from '@angular/core';
import {TableColumnFilterType, TableConfig} from "../../../global/components/table/table.interfaces";
import {UserService} from "../../../global/services/user.service";
import {TableComponent} from '../../../global/components/table/table.component';

@Component({
    selector: 'app-visibility-group-list',
    templateUrl: './visibility-group-list.component.html',
    styleUrl: './visibility-group-list.component.scss',
    imports: [TableComponent]
})
export class VisibilityGroupListComponent implements OnInit {

    userService = inject(UserService);

    tableConfig!: TableConfig;

    ngOnInit(): void {
        this.tableConfig = {
            titleIcon: 'fas fa-eye',
            title: 'Sichtbarkeitsgruppen',
            openDatasetRouterLink: '/app/visibility-group/detail',
            enableCreate: this.userService.hasRight('ADMIN_ACCESS'),
            enableExportExcel: true,
            description: 'Sichtbarkeitsgruppen für Dokumente. Ist ein Dokument einer Sichtbarkeitsgruppe zugewiesen, ist es nur für Benutzer sichtbar, die ebenfalls dieser Gruppe zugewiesen wurden.',
            dataConfig: {
                tableName: 'visibility_group',
                tableIndexName: 'VISID',
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {key: 'description', name: 'description', title: 'Beschreibung', searchable: true},
                    {
                        key: 'documents',
                        title: '# Dokumente zugewiesen',
                        subquery: '(SELECT COUNT(*) FROM visibility_group_document WHERE VISID = visibility_group.VISID)'
                    },
                    {
                        key: 'users',
                        title: '# Benutzer zugewiesen',
                        subquery: '(SELECT COUNT(*) FROM visibility_group_user WHERE VISID = visibility_group.VISID)'
                    },
                ],
                reverse: false,
                order: 'name',
                columnFilter: {
                    enabled: false,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'visibility-group-list',
                enabled: true
            },
            paginConfig: {
                id: 'visibilityGroupList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }

}
