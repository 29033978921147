import {Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    TableColumnFilterType,
    TableConfig,
    TableFilterOperator,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {UserService} from '../../../global/services/user.service';
import {LocalStorageService} from '../../../global/services/local-storage.service';
import {NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TableComponent} from '../../../global/components/table/table.component';

@Component({
    selector: 'app-task-list',
    templateUrl: './task-list.component.html',
    styleUrl: './task-list.component.scss',
    imports: [NgIf, FormsModule, TableComponent]
})
export class TaskListComponent implements OnInit {

    private userService = inject(UserService);
    private activatedRoute = inject(ActivatedRoute);
    private localStorageService = inject(LocalStorageService);

    tableConfig!: TableConfig;

    status: any;
    topic: any;
    tasktype: any;
    department: any;
    hideCompleted = true;

    ngOnInit(): void {
        this.status = this.activatedRoute.snapshot.queryParamMap.get('status') || null;
        this.topic = this.activatedRoute.snapshot.queryParamMap.get('topic') || null;
        this.tasktype = this.activatedRoute.snapshot.queryParamMap.get('tasktype') || null;
        this.department = this.activatedRoute.snapshot.queryParamMap.get('department') || null;

        this.setupTable();
    }

    setupTable(): void {
        this.tableConfig = {
            titleIcon: 'fas fa-tasks',
            title: 'Aufgaben',
            openDatasetRouterLink: '/app/task/detail',
            enableCreate: false,
            enableExportExcel: true,
            description: 'Das sind die Ihnen zugewiesenen Aufgaben zu den hochgeladenen Dokumenten.',
            dataConfig: {
                tableName: 'task',
                tableIndexName: 'TID',
                tableJoins: [
                    {left: 'document', right: 'task', key: 'DID'},
                    {left: 'location', right: 'document', key: 'LID'},
                    {left: 'department', right: 'task', key: 'DPID'},
                    {left: 'tasktopic', right: 'task', key: 'TOID'},
                    {left: 'tasktype', right: 'task', key: 'TTID'},
                ],
                tableFilter: [
                    {table: 'document', field: 'deleted', values: ['0'], operator: TableFilterOperator.EQUAL}
                ],
                tableFields: [
                    {key: 'number', name: 'number', title: 'Nummer', sortable: true, searchable: true},
                    {key: 'title', name: 'title', title: 'Titel', sortable: true, searchable: true},
                    {
                        key: 'type',
                        name: 'type',
                        title: 'Art',
                        sortable: true,
                        translate: true,
                        translateValuePrefix: 'DUETYPE'
                    },
                    {
                        key: 'location',
                        table: 'location',
                        name: 'name',
                        title: 'LOCATION',
                        translateTitle: true,
                        translateTitlePrefix: 'LOCATION',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_wait',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "wait" AND TID = task.TID)',
                        title: 'Termine warten',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_open',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "open" AND TID = task.TID)',
                        title: 'Termine offen',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_progress',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "in_progress" AND TID = task.TID)',
                        title: 'Termine in Bearbeitung',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_done',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID = task.TID)',
                        title: 'Termine erledigt',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'created',
                        name: 'created',
                        title: 'Erstellt am',
                        sortable: true,
                        searchable: true,
                        dateFormat: {en: '%Y.%m.%d', others: '%d.%m.%Y'}
                    },
                    {
                        key: 'document',
                        table: 'document',
                        name: 'name',
                        title: 'Dokument',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'department',
                        table: 'department',
                        name: 'name',
                        title: 'Abteilung',
                        sortable: true,
                        searchable: true
                    },
                    {key: 'topic', table: 'tasktopic', name: 'name', title: 'Thema', sortable: true, searchable: true},
                    {key: 'tasktype', table: 'tasktype', name: 'name', title: 'Typ', sortable: true, searchable: true},
                ],
                rowImage: [
                    {
                        key: 'status_wait',
                        icon: 'fa-circle',
                        color: '#cccccc',
                        conditions: [
                            {key: 'status_wait', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_open',
                        icon: 'fa-circle',
                        color: '#f86c6b',
                        conditions: [
                            {key: 'status_open', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_progress',
                        icon: 'fa-circle',
                        color: '#ffc107',
                        conditions: [
                            {
                                key: 'status_progress',
                                value: '0',
                                operator: TableRowColorConditionOperator.SMALLER_INT
                            },
                        ]
                    },
                    {
                        key: 'status_done',
                        icon: 'fa-circle',
                        color: '#4dbd74',
                        conditions: [
                            {key: 'status_done', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                ],
                reverse: false,
                order: 'created',
                columnFilter: {
                    enabled: true,
                    active: this.topic != null || this.tasktype != null || this.department != null,
                    fields: {
                        title: {value: '', type: TableColumnFilterType.INPUT},
                        type: {value: '', type: TableColumnFilterType.GROUP},
                        location: {value: '', type: TableColumnFilterType.GROUP},
                        status_wait: {value: '', type: TableColumnFilterType.INPUT},
                        status_open: {value: '', type: TableColumnFilterType.INPUT},
                        status_progress: {value: '', type: TableColumnFilterType.INPUT},
                        status_done: {value: '', type: TableColumnFilterType.INPUT},
                        document: {value: '', type: TableColumnFilterType.INPUT},
                        department: {value: this.department ?? '', type: TableColumnFilterType.GROUP},
                        topic: {value: this.topic ?? '', type: TableColumnFilterType.GROUP},
                        tasktype: {value: this.tasktype ?? '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'task-list',
                enabled: true
            },
            paginConfig: {
                id: 'taskList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };

        if (!this.userService.isAdmin()) {
            if (this.userService.hasRight('TASKS_ONLY_OWN_VIEW')) {
                this.tableConfig.dataConfig.tableFilter.push({
                    table: 'department',
                    field: 'UID',
                    values: [this.userService.currentUser.UID],
                    operator: TableFilterOperator.EQUAL
                });
            } else if (this.userService.hasRight('TASKS_ONLY_EXTERNAL_VIEW')) {
                this.tableConfig.dataConfig.tableFilter.push({
                    table: 'task',
                    field: 'UID_external',
                    values: [this.userService.currentUser.UID],
                    operator: TableFilterOperator.EQUAL
                });
            }
        }

        if (this.status !== null) {
            // clear filter to avoid problems
            this.localStorageService.removeItem('task-list');

            if (this.status === 'wait') {
                this.tableConfig.dataConfig.tableFilter.push({
                    field: '(SELECT COUNT(*) FROM taskdate WHERE status = "wait" AND TID = task.TID)',
                    values: ['0'],
                    operator: TableFilterOperator.SMALLER
                });
            }
            if (this.status === 'open') {
                this.tableConfig.dataConfig.tableFilter.push({
                    field: '(SELECT COUNT(*) FROM taskdate WHERE status = "open" AND TID = task.TID)',
                    values: ['0'],
                    operator: TableFilterOperator.SMALLER
                });
            }
            if (this.status === 'in_progress') {
                this.tableConfig.dataConfig.tableFilter.push({
                    field: '(SELECT COUNT(*) FROM taskdate WHERE status = "in_progress" AND TID = task.TID)',
                    values: ['0'],
                    operator: TableFilterOperator.SMALLER
                });
            }
            if (this.status === 'done') {
                this.tableConfig.dataConfig.tableFilter.push({
                    field: '(SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID = task.TID)',
                    values: ['0'],
                    operator: TableFilterOperator.SMALLER
                });
            }
        }

        // hide completed singular tasks
        if (this.hideCompleted) {
            this.tableConfig.dataConfig.tableFilter.push({
                field: '((task.type = "singular" AND (SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID = task.TID) = 0) OR task.type = "recurring")',
                values: ['1'],
                operator: TableFilterOperator.EQUAL
            });
        }
    }

    showAll(): void {
        this.status = null;
        this.setupTable();
    }
}
