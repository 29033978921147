<div class="reset p-3 rounded">
    <div class="d-flex">
        <div class="my-auto"><h3>Passwort zurücksetzen</h3></div>
    </div>
    <form [formGroup]="resetForm" class="form" *ngIf="hashValid">

        <div class="form-group">
            <label>Passwort</label>
            <input class="form-control" type="password" formControlName="password" minlength="6" autocomplete="off">
            <small class="text-danger" *ngIf="resetForm.controls['password'].touched && resetForm.controls['password'].errors?.required">Eingabe erforderlich</small>
            <small class="text-danger" *ngIf="resetForm.controls['password'].errors?.minlength">Das Passwort muss mindestens 6 Zeichen lang sein</small>
            <div class="pt-1">
                <label>Passwort wiederholen</label>
                <input class="form-control" type="password" formControlName="confirmPassword" autocomplete="off" required>
                <small class="text-danger" *ngIf="resetForm.controls.confirmPassword.errors?.nomatch">Passwort stimmt nicht überein</small>
            </div>
        </div>

        <div class="text-center">
            <button class="btn btn-dark mr-2 pb-2" type="submit" [disabled]="resetForm.invalid" (click)="reset()">
                <i class="fas fa-check"></i> Zurücksetzen
            </button>
        </div>

    </form>

    <div class="text-center mt-3" *ngIf="!hashValid">
        <p>Dieser Link ist nicht mehr gültig</p>
        <button type="button" class="btn btn-dark" [routerLink]="[myAppConfig.auth.loginDestination]">Zum Login</button>
    </div>

</div>
