<div class="container">
    <div class="row">
        <div class="col">
            <h1 class="display-4 text-center my-3">Preise</h1>

            <div class="row mb-5">
                <div class="col-lg-6 mb-4 mb-lg-0">
                    <div class="card card-lg">
                        <div class="card-body">
                            <div class="mb-3">
                                <h4 class="mb-1">Kleinunternehmen</h4>
                                <p>Unternehmen mit weniger als 20 Standorten</p>
                            </div>

                            <div class="d-flex mb-5">
                                <div class="flex-shrink-0">
                                    <span class="display-4 lh-1 text-dark">€30<span class="fs-4">.00</span></span>
                                </div>
                                <div class="flex-grow-1 align-self-end ms-3">
                                    <span class="d-block">EUR / Monat pro Betriebsstätte</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <ul class="list-checked list-checked-soft-bg-warning fs-4 mb-2">
                                        <li class="list-checked-item">Unlimitierte Benutzer</li>
                                        <li class="list-checked-item">Unbegrenzte Dokumente (1 GB pro Betriebsstätte)</li>
                                    </ul>
                                </div>

                                <div class="col-sm-6">
                                    <ul class="list-checked list-checked-soft-bg-warning fs-4 mb-2">
                                        <li class="list-unchecked-item">Automatische Digitalisierung</li>
                                        <li class="list-unchecked-item">E-Mail-Support</li>
                                        <li class="list-unchecked-item">Exporte nach Excel</li>
                                    </ul>
                                </div>
                            </div>

                            <button type="button" class="btn btn-primary float-right btn-lg mt-3" [routerLink]="['/offer']">Angebot anfordern</button>

                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="card card-lg">
                        <div class="card-body">
                            <div class="mb-3">
                                <h4 class="mb-1">Mittelgroße Unternehmen</h4>
                                <p>Unternehmen ab 20 Standorten</p>
                            </div>

                            <div class="d-flex mb-5">
                                <div class="flex-shrink-0">
                                    <span class="display-4 lh-1 text-dark">€24<span class="fs-4">.00</span></span>
                                </div>
                                <div class="flex-grow-1 align-self-end ms-3">
                                    <span class="d-block">EUR / Monat pro Betriebsstätte</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <ul class="list-checked list-checked-soft-bg-warning fs-4 mb-2">
                                        <li class="list-checked-item">Unlimitierte Benutzer</li>
                                        <li class="list-checked-item">Unbegrenzte Dokumente (1 GB pro Betriebsstätte)</li>
                                    </ul>
                                </div>

                                <div class="col-sm-6">
                                    <ul class="list-checked list-checked-soft-bg-warning fs-4 mb-2">
                                        <li class="list-unchecked-item">Automatische Digitalisierung</li>
                                        <li class="list-unchecked-item">E-Mail Support</li>
                                        <li class="list-unchecked-item">Exporte nach Excel</li>
                                    </ul>
                                </div>
                            </div>

                            <button type="button" class="btn btn-primary float-right btn-lg mt-3" [routerLink]="['/offer']">Angebot anfordern</button>

                        </div>
                    </div>
                </div>

                <div class="col text-center font-2xl">
                    Gerne erstellen wir Ihnen auch ein individuelles Angebot - Bitte kontaktieren Sie uns <a [routerLink]="['/offer']">hier</a>.
                </div>
            </div>

            <h1 class="display-4 text-center my-3">Funktionsumfang</h1>

            <div class="table-responsive-sm">
                <table class="table table-lg table-striped table-borderless">
                    <thead>
                    <tr class="table-align-middle">
                        <th scope="col"></th>
                        <th scope="col" class="table-text-center h5"></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <th scope="row" class="fw-bold">Verwaltung</th>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Unbegrenzte Dokumente</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>Unbegrenzte Aufgaben</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>Einmalige Termine</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>Wiederkehrende Termine</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>Erinnerungen</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" class="fw-bold">Berichte</th>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Exporte nach Excel</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>Monatsbericht als PDF</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>Monatsvorschau als PDF</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" class="fw-bold">Zusatzfunktionen</th>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Tags</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>FileShare</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>Beliebige Sichtbarkeitsgruppen</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    <tr>
                        <td>Flexible Rechteverwaltung</td>
                        <td class="table-text-center">
                            <i class="fa fa-check text-success"></i>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
