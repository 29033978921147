<ng-container *ngIf="document">
    <div *ngIf="document.active == '0'">
        <div class="bg-warning text-dark p-2 mb-3 d-inline-block">
            <i class="fa-solid fa-exclamation-circle mr-2"></i>Das Dokument ist inaktiv, es können keine Termine angelegt werden und es werden keine zukünftigen Termine automatisch erzeugt.
        </div>
    </div>
    <button type="button" class="btn btn-primary mb-3"
            (click)="createTaskDate()"
            [attr.data-right]="'TASKDATE_CREATE' | right"
            *ngIf="userService.hasRight('ADMIN_ACCESS') || (userService.hasRight('TASKDATE_CREATE') && ownTask)"
            [disabled]="document.active == '0'"
    ><i class="fas fa-plus"></i> Termin anlegen</button>

    <ng-container *ngIf="taskRecurring">
        <div class="input-group mb-3" style="width: fit-content">
            <div class="input-group-prepend">
                <span class="input-group-text">Zeitraum Terminvorschau</span>
            </div>
            <input type="number" class="form-control" min="1" max="12" [(ngModel)]="taskDateFilter.number" (ngModelChange)="listTaskDates()" [disabled]="document.active == '0'">
            <select class="form-control" [(ngModel)]="taskDateFilter.interval" (ngModelChange)="listTaskDates()" [disabled]="document.active == '0'" style="width: 80px">
                <option value="week">Woche(n)</option>
                <option value="month">Monat(e)</option>
                <option value="year">Jahr(e)</option>
            </select>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="taskDates?.length > 0">
    <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" [(ngModel)]="hideCompleted">
        <label class="form-check-label">Erledigte Termine ausblenden</label>
    </div>

    <div class="row mb-3">
        <div class="col"><div class="bg-success text-center py-1">{{ done }} Erledigt</div></div>
        <div class="col"><div class="bg-warning text-dark text-center py-1">{{ inProgress }} In Bearbeitung</div></div>
        <div class="col"><div class="bg-danger text-center py-1">{{ open }} Offen</div></div>
        <div class="col"><div class="bg-secondary text-dark text-center py-1">{{ wait }} Warten</div></div>
        <div class="col"><div class="bg-dark text-center py-1">{{ generated }} Zukünftig</div></div>
    </div>
</ng-container>

<div class="text-muted" *ngIf="taskDates?.length == 0">Es wurden noch keine Termine geplant!</div>

<div class="table-responsive" *ngIf="taskDates?.length > 0">
    <table class="table table-sm table-hover mb-0" >
        <thead>
        <tr>
            <th style="width: 150px">Status</th>
            <th style="width: 100px">Start</th>
            <th style="width: 100px">Fälligkeit</th>
            <th>Info</th>
            <th>Status Info</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let taskDate of taskDates">
        <tr *ngIf="!hideCompleted || (hideCompleted && taskDate.status != 'done')" (click)="openTaskDate(taskDate)" [ngClass]="{'bg-overdue-open': taskDate.overdue && taskDate.status == 'open', 'bg-overdue-in-progress': taskDate.overdue && taskDate.status == 'in_progress'}" style="cursor: pointer">
            <td>
                <i class="fas fa-circle" [ngClass]="{'text-success': taskDate.status == 'done', 'text-warning': taskDate.status == 'in_progress', 'text-danger': taskDate.status == 'open', 'text-secondary': taskDate.status == 'wait'}"></i>
                {{ taskDate.status | taskStatus }}
            </td>
            <td>
                {{ taskDate.start_date | date: 'dd.MM.yyyy' }}
                <span class="text-muted" *ngIf="!taskDate.start_date">Nicht gesetzt</span>
            </td>
            <td>
                <i class="fas fa-exclamation-triangle" style="color: #ff9800" *ngIf="taskDate.overdue" tooltip="Die Erledigung ist überfällig"></i>
                {{ taskDate.end_date | date: 'dd.MM.yyyy' }}
            </td>
            <td>
                {{ taskDate.description }}
                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
            </td>
            <td>
                {{ taskDate.status_info }}
                <span class="text-muted" *ngIf="!taskDate.status_info">Nicht gesetzt</span>
            </td>
        </tr>
        </ng-container>
        </tbody>
    </table>
</div>
