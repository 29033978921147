import {Component, OnInit, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {DataService} from '../../global/services/data.service';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    styleUrl: './offer.component.scss',
    imports: [FormsModule, ReactiveFormsModule, NgIf]
})
export class OfferComponent implements OnInit {

    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);
    private router = inject(Router);

    form!: UntypedFormGroup;

    ngOnInit(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            zip: [''],
            city: [''],
            street: [''],
            email: ['', [Validators.email]],
            phone: [''],
            number_locations: ['', Validators.required],
        });

        this.form.setValidators(this.atLeastOneValidator(['email', 'phone']))
    }

    private atLeastOneValidator = (checkedControls: any) => {
        return (controlGroup: any) => {
            let controls = Object.keys(controlGroup.controls).filter(key => checkedControls.includes(key));

            if (controls) {
                let filledControl = controls.find(key => controlGroup.controls[key].value !== '');

                // if not one control is filled return error
                if (!filledControl) {
                    return {
                        atLeastOneRequired: {
                            text: 'Bitte E-Mail oder Telefon ausfüllen'
                        }
                    }
                }
            }
            return null;
        };
    };

    requestOffer(): void {
        this.dataService.request('universe.Client/requestOffer',
            this.form.getRawValue()
        ).subscribe(response => {
            Swal.fire(
                'Anfrage wurde abgeschickt!',
                'Sie erhalten innerhalb von 24h ein Angebot',
                'success'
            );
            this.router.navigate(['/']);
        });
    }
}
