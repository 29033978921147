<nav class="navbar navbar-expand-lg sticky-top navbar-dark" style="background-color: #485159">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/admin">
            <img src="assets/img/brand/logo_small.png" width="20" height="20" class="d-inline-block align-text-top mr-1" alt="">
            NoteRadar Adminpanel
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/admin"><i class="fas fa-file-alt"></i>Dashboard</a>
                </li>
            </ul>
        </div>
        <span class="navbar-text ml-auto mr-2">
            v{{ currentApplicationVersion }}
        </span>
    </div>
</nav>

<app-admin-breadcrumbs/>

<div class="container-fluid">
    <router-outlet/>
</div>
