import {Component, inject} from '@angular/core';
import {UserService} from "../../global/services/user.service";
import {Router} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {DataService} from "../../global/services/data.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-admin-login',
    imports: [
        FormsModule
    ],
  templateUrl: './admin-login.component.html',
  styleUrl: './admin-login.component.scss'
})
export class AdminLoginComponent {

    private userService = inject(UserService);
    private dataService = inject(DataService);
    private router = inject(Router);
    private toastrService = inject(ToastrService);

    token: string = '';

    login() {
        if (!this.token) return;

        this.dataService.request('universe.Admin/checkToken', {
            token: this.token
        }).subscribe(response => {
            if (response.valid) {
                this.userService.adminToken = this.token;
                this.router.navigate(['/admin/dashboard']);
            } else {
                this.toastrService.error('Ungültiges Passwort', 'Fehler');
            }
        });
    }
}
