import {Component, OnInit, inject} from '@angular/core';
import {CryptoService} from '../../../global/services/crypto.service';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {FormService} from '../../../global/services/form.service';
import {UserService} from '../../../global/services/user.service';
import Swal from 'sweetalert2';
import {ViewMode} from '../../../interfaces';
import {NgIf, NgFor} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FileTypePipe} from '../../../global/pipes/file-type.pipe';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {YesNoPipe} from '../../../global/pipes/yes-no.pipe';
import {TooltipDirective} from "ngx-bootstrap/tooltip";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-file-type-detail',
    templateUrl: './file-type-detail.component.html',
    styleUrl: './file-type-detail.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, TranslateModule, FileTypePipe, RightPipe, YesNoPipe, TooltipDirective]
})
export class FileTypeDetailComponent implements OnInit {

    formService = inject(FormService);
    userService = inject(UserService);
    private cryptoService = inject(CryptoService);
    private router = inject(Router);
    private fb = inject(UntypedFormBuilder);
    private dataService = inject(DataService);
    private toastrService = inject(ToastrService);

    readonly ViewMode = ViewMode;

    form!: UntypedFormGroup;
    FTID!: string;
    viewMode!: ViewMode;
    filetype: any;
    attributes!: any[];

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.FTID = urlData.ID;
        this.viewMode = urlData.viewMode;

        this.form = this.fb.group({
            FTID: [null],
            name: ['', Validators.required],
            attributes: this.fb.array([])
        });

        if (this.FTID !== '0') {
            this.getFileType();
        }
    }

    getFileType(): void {
        this.dataService.request('FileType/get', {FTID: this.FTID}).subscribe(response => {
            this.filetype = response.filetype;
            this.filetype.used = parseInt(this.filetype.used, 10);
            this.attributes = response.attributes;
            this.attributes.forEach(attribute => attribute.required = attribute.required === '1');

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            FTID: this.filetype.FTID,
            name: this.filetype.name
        });

        this.formService.getFormArray(this.form, 'attributes').clear();
        this.attributes.forEach(attribute => {
            this.addAttribute(attribute);
        });
    }

    save(): void {
        this.dataService.request('FileType/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Dokumenttyp wurde gespeichert');
            this.router.navigate(['/app/filetype/list']);
        });
    }

    update(): void {
        this.dataService.request('FileType/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Dokumenttyp wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
            this.getFileType();
        });
    }

    cancel(): void {
        this.router.navigate(['/app/filetype/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.viewMode = ViewMode.VIEW;
    }

    addAttribute(data: any = {}): void {
        this.formService.getFormArray(this.form, 'attributes').push(
            this.fb.group({
                ATTID: [data.ATTID || null],
                FTID: [data.FTID || null],
                name: [data.name || '', Validators.required],
                type: [data.type || '', Validators.required],
                required: [data.required],
            })
        );
    }

    deleteAttribute(index: number, ATTID: any): void {
        this.dataService.request('AttributeTemplate/deleteTypeDocument', {ATTID, FTID: this.FTID}).subscribe(() => {
            this.formService.removeFormArrayItem(this.form, 'attributes', index);
        });
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Der Dokumenttyp wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('FileType/delete', {FTID: this.FTID}).subscribe(() => {
                    this.toastrService.success('Dokumenttyp wurde gelöscht');
                    this.cancel();
                });
            }
        });
    }
}
