import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild, inject} from '@angular/core';
import {myAppConfig} from '../../../../settings';
import {DataService} from '../../../services/data.service';
import dayjs from 'dayjs';
import {FileService} from '../file.service';
import {UserService} from '../../../services/user.service';
import {animate, group, state, style, transition, trigger} from '@angular/animations';
import {BackupService} from '../../../services/backup.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {environment} from '../../../../../environments/environment';
import {FormService} from '../../../services/form.service';
import {validateDate} from '../../../validators/validateDate';
import {Subscription} from 'rxjs';
import {ViewMode} from '../../../../interfaces';
import {FwFileExternalData} from '../file.component';
import {DomSanitizer} from '@angular/platform-browser';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {ModalDirective, ModalModule} from "ngx-bootstrap/modal";
import {NgIf, NgStyle, NgClass, NgFor, DatePipe} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {YesNoPipe} from '../../../pipes/yes-no.pipe';
import {FileSizePipe} from '../../../pipes/file-size.pipe';

dayjs.extend(customParseFormat);

enum TabsEnum {
    PREVIEW,
    FILELINKS,
}

@Component({
    selector: 'app-file-modal',
    templateUrl: './file-modal.component.html',
    styleUrl: './file-modal.component.scss',
    animations: [
        // the fade-in/fade-out animation.
        trigger('slideInOut', [
            state('in', style({ height: '*', opacity: 1 })),
            transition(':leave', [style({
                    height: '*',
                    opacity: 1
                }), group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '1' }))])]),
            transition(':enter', [style({
                    height: '0',
                    opacity: 0
                }), group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))])])
        ])
    ],
    imports: [NgIf, NgStyle, NgClass, NgFor, ModalModule, FormsModule, ReactiveFormsModule, TranslateModule, DatePipe, YesNoPipe, FileSizePipe]
})
export class FileModalComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    private dataService = inject(DataService);
    private fileService = inject(FileService);
    private backupService = inject(BackupService);
    private fb = inject(UntypedFormBuilder);
    private formService = inject(FormService);
    private sanitizer = inject(DomSanitizer);

    @Input() files: any = [];
    @Input() viewExternalData: FwFileExternalData | null = null; // Required for overlay
    @Input() viewFileInfo = true;
    @Input() viewFlipImage = false;
    @Input() navigation: any = {};
    @Input() enableLinks = false; // Enable fileshare

    protected readonly myAppConfig = myAppConfig;
    protected readonly environment = environment;

    ViewMode = ViewMode;
    TABS = TabsEnum;
    tab!: TabsEnum;

    @ViewChild('fileModal') fileModal!: ModalDirective;

    clientHeight = document.body.clientHeight;
    clientWidth = document.body.clientWidth;
    imageClass = 'bgDefault';
    resizeTimeout: any = null;
    hideFileInfo = false;
    viewImageModal = false;
    loading = false;
    result: any = {};
    fileData: any;
    viewMode = ViewMode.VIEW;
    currentLink = false;
    linkForm!: UntypedFormGroup;
    fileLinks: any = [];
    savingLink = false;
    embedString: any;

    private linkFormChanges!: Subscription;

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        if (this.result.openFile) {
            if (this.resizeTimeout === null) {
                this.resizeTimeout = setTimeout(() => {
                    this.calcImageSize();
                    this.resizeTimeout = null;
                }, 150);
            }
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent): void {
        if (event.code === 'ArrowRight') {
            this.goNextImage();
        }
        if (event.code === 'ArrowLeft') {
            this.goPrevImage();
        }
        if (event.code === 'ArrowDown') {
            if (this.hideFileInfo !== true) {
                this.toggleFileInfo();
            }
        }
        if (event.code === 'ArrowUp') {
            if (this.hideFileInfo === true) {
                this.toggleFileInfo();
            }
        }
        if (event.code === 'Escape') {
            this.closeFileModal();
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.linkFormChanges?.unsubscribe();
    }

    closeFileModal(): void {
        this.viewImageModal = false;

        document.body.style.height = "auto";
        document.body.style.overflowY = "visible";
    }

    goPrevImage(): void {
        if (this.navigation.focus !== this.navigation.first) {
            this.loading = true;
            this.openFile(this.navigation.prev);
        }
    }

    goNextImage(): void {
        if (this.navigation.focus !== this.navigation.last) {
            this.loading = true;
            this.openFile(this.navigation.next);
        }
    }

    toggleFileInfo(): void {
        this.hideFileInfo = this.hideFileInfo !== true;
    }

    rotateImage(degrees: number): void {
        this.loading = true;
        this.dataService.request('framework.File/rotateImageSet', {
            degrees,
            doctype: this.result.openFile.doctype,
            FID: this.result.openFile.FID,
            name: this.result.openFile.name,
            path: this.result.openFile.path,
            mimetype: this.result.openFile.mimetype,
            extension: this.result.openFile.extension,
            encrypted: this.result.openFile.encrypted
        }).subscribe(() => {
            this.fileService.loadFiles.next(true);
            this.openFile(this.result.openFile.FID);
        });
    }

    calcImageSize(): void {
        this.clientHeight = document.body.clientHeight;
        this.clientWidth = document.body.clientWidth;

        const fileWidth = this.result.openFile.width ? +this.result.openFile.width : 0;
        const fileHeight = this.result.openFile.height ? +this.result.openFile.height : 0;
        if (this.result) {
            if (fileWidth <= this.clientWidth && fileHeight <= this.clientHeight) {
                // if both file dimensions are smaller than client apply automatic background size
                this.imageClass = 'bgDefault';
            } else {
                // else apply background size based on ratio
                const ratioX = this.clientWidth / fileWidth;
                const ratioY = this.clientHeight / fileHeight;

                if (ratioX > ratioY) {
                    this.imageClass = 'bgVertical';
                } else {
                    this.imageClass = 'bgHorizontal';
                }
            }
        }
    }

    openFile(FID: string): void {
        this.navigation.focus = FID;
        this.embedString = null;
        this.dataService.request('framework.File/getFile', {
            FID,
            thumbnail: false,
        }).subscribe(response => {
            this.loading = false;
            this.result.openFile = response.file;
            this.fileData = response.fileData;

            // set name format of file creator
            if (this.result.openFile.firstname !== null) {
                this.result.openFile.creator_name = this.result.openFile.firstname + ' ' + this.result.openFile.lastname;
            }

            // format file date
            if (this.userService.currentUser.language === 'de') {
                this.result.openFile.create_date = dayjs(this.result.openFile.create_date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm');
            }
            this.result.openFile.imageData = response.imageData;

            if (this.enableLinks) {
                this.setTab(TabsEnum.FILELINKS);
            }

            if (this.result.openFile.mimetype.startsWith('image')) {
                document.body.style.height = "100%";
                document.body.style.overflowY = "hidden";

                this.calcImageSize();
                this.viewImageModal = true;

                let indexFirstSet = false;
                let indexPrevFound = false;
                let indexNextWrite = false;
                this.files.forEach((element: any) => {
                    if (!indexFirstSet) {
                        this.navigation.first = this.files[0].FID;
                        indexFirstSet = true;
                    }
                    if (element.mimetype.startsWith('image')) {
                        if (!indexPrevFound) {
                            if (element.FID === this.result.openFile.FID) {
                                indexPrevFound = true;
                            } else {
                                this.navigation.prev = element.FID;
                            }
                        }
                        if (indexNextWrite) {
                            this.navigation.next = element.FID;
                            indexNextWrite = false;
                        }
                        if (element.FID === this.result.openFile.FID) {
                            indexNextWrite = true;
                        }
                        this.navigation.last = element.FID;
                    }
                });
            } else {
                if (this.result.openFile.mimetype === 'application/pdf') {
                    if (this.fileData) {
                        const byteCharacters = atob(this.fileData.split(',')[1]);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const file = new Blob([byteArray], {type: 'application/pdf'});
                        this.embedString = URL.createObjectURL(file);
                        this.embedString = this.sanitizer.bypassSecurityTrustResourceUrl(this.embedString);

                        this.setTab(TabsEnum.PREVIEW);
                    }
                }
                this.openInfoModal();
            }
        });
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;
        this.cancelCurrentLink();
    }

    downloadFile(): void {
        this.dataService.request('framework.File/download', {
            FK_ID: this.result.openFile.FID,
            FK_name: 'FID',
            FK_table: 'files',
            type: 'download',
            data: {}
        }).subscribe();

        const linkSource = this.fileData != null ? this.fileData : this.result.openFile.imageData;
        const downloadLink = document.createElement('a');
        const fileName = this.result.openFile.display_name + '.' + this.result.openFile.extension;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    setView(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    updateFile(): void {
        // aggregate editable fields
        const fields: any[] = [];
        myAppConfig.fileFields.forEach(field => {
            if (field.editable === true) {
                fields.push({
                    key: field.key,
                    value: this.result.openFile[field.key]
                });
            }
        });

        this.dataService.request('framework.File/update', {FID: this.result.openFile.FID, fields}).subscribe(() => {
            this.fileService.loadFiles.next(true);
            this.setView(this.ViewMode.VIEW);
        });
    }

    editFile(): void {
        this.backupService.backup('filemodal', this.result.openFile);
        this.viewMode = this.ViewMode.EDIT;
    }

    cancelEditFile(): void {
        this.result.openFile = this.backupService.reset('filemodal', this.result.openFile);
        this.viewMode = this.ViewMode.VIEW;
    }

    // open info modal for non images
    openInfoModal(): void {
        this.fileModal.show();
        this.loadLinks();
    }

    // custom file link stuff
    createLink(): void {
        this.currentLink = true;

        this.linkForm = this.fb.group({
            FLID: [null],
            expiry: [null, validateDate],
            password: [null],
            single_use: [false],
            recipients: [null],
            phone: [null],
            password_transport: ['mail', this.formService.conditionalValidator(() => this.linkForm.get('password').value !== null && this.linkForm.get('password').value !== '', Validators.required)]
        });

        this.linkFormChanges = this.linkForm.get('password_transport').valueChanges.subscribe(val => {
            if (val === 'phone') {
                this.formService.setValidators(this.linkForm, ['phone'], [Validators.required]);
            } else {
                this.formService.removeValidators(this.linkForm, ['phone'], [Validators.required]);
            }
        });
    }

    cancelCurrentLink(): void {
        this.currentLink = false;
    }

    saveFileLink(): void {
        this.savingLink = true;
        this.dataService.request('FileLink/save', {
            FID: this.result.openFile.FID,
            form: this.linkForm.getRawValue(),
        }).subscribe(response => {
            this.savingLink = false;
            this.cancelCurrentLink();
            this.loadLinks();
        });
    }

    updateFileLink(): void {
        this.dataService.request('FileLink/update', {
            form: this.linkForm.getRawValue(),
        }).subscribe(response => {
            this.cancelCurrentLink();
            this.loadLinks();
        });
    }

    loadLinks(): void {
        if (!this.enableLinks) return;

        this.dataService.request('FileLink/listByFile', {
            FID: this.result.openFile.FID,
        }).subscribe(response => {
            this.fileLinks = response.fileLinks;
            this.fileLinks.forEach((fileLink: any) => {
                fileLink.single_use = fileLink.single_use === '1';
                fileLink.hasPassword = fileLink.password !== null;
            });
        });
    }

    deleteFileLink(fileLink: any): void {
        this.dataService.request('FileLink/delete', {
            FLID: fileLink.FLID
        }).subscribe(response => {
            this.loadLinks();
        });
    }

    editFileLink(fileLink: any): void {
        this.currentLink = true;

        this.linkForm = this.fb.group({
            FLID: [fileLink.FLID],
            expiry: [fileLink.expiry, validateDate],
            password: [null],
            single_use: [fileLink.single_use],
            recipients: [fileLink.recipients],
            phone: [fileLink.phone],
            password_transport: [fileLink.password_transport, this.formService.conditionalValidator(
                () => this.linkForm.get('password').value !== null && this.linkForm.get('password').value !== '',
                Validators.required
            )]
        });

        this.linkFormChanges = this.linkForm.get('password_transport').valueChanges.subscribe(val => {
            if (val === 'phone') {
                this.formService.setValidators(this.linkForm, ['phone'], [Validators.required]);
            } else {
                this.formService.removeValidators(this.linkForm, ['phone'], [Validators.required]);
            }
        });
    }
}
