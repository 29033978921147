import {Component, OnInit, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {Router} from '@angular/router';
import {CryptoService} from '../../../global/services/crypto.service';
import {
    TableColumnFilterType,
    TableConfig,
    TableDesign,
    TableFilterOperator,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {TagConfig} from '../../../global/components/tag/tag.interfaces';
import {UserService} from '../../../global/services/user.service';
import Swal from 'sweetalert2';
import {ViewMode} from '../../../interfaces';
import {BreadcrumbService} from "../../../global/components/breadcrumb/breadcrumb.service";
import {NgIf, NgClass} from '@angular/common';
import {FileComponent} from '../../../global/components/file/file.component';
import {TagComponent} from '../../../global/components/tag/tag.component';
import {TableComponent} from '../../../global/components/table/table.component';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {ToastrService} from "ngx-toastr";

enum TabsEnum {
    LOCATIONS = 'locations',
    DETAILS = 'details',
}

@Component({
    selector: 'app-company-detail',
    templateUrl: './company-detail.component.html',
    styleUrl: './company-detail.component.scss',
    imports: [NgIf, FileComponent, NgClass, FormsModule, ReactiveFormsModule, TagComponent, TableComponent, RightPipe]
})
export class CompanyDetailComponent implements OnInit {

    userService = inject(UserService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);
    private breadcrumbService = inject(BreadcrumbService);
    private toastrService = inject(ToastrService);

    readonly ViewMode = ViewMode;
    readonly TABS = TabsEnum;

    form!: UntypedFormGroup;
    company: any = {};
    CID: any;
    viewMode!: ViewMode;
    tableConfig!: TableConfig;
    tagConfig!: TagConfig;
    tab!: TabsEnum;
    tabCount: any = {};

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.CID = urlData.ID;
        this.viewMode = urlData.viewMode;

        this.tagConfig = {
            FK_ID: this.CID,
            FK_name: 'company',
            FK_table: 'company',
            editable: false,
        };

        this.form = this.fb.group({
            CID: [''],
            name: ['', Validators.required],
            country: ['', Validators.required],
            zip: ['', Validators.required],
            city: ['', Validators.required],
            street: ['', Validators.required],
            CEO_name: ['', Validators.required],
            CEO_mail: [null],
            register_number: [null],
            description: [null],
            gisa_number: [null],
        });


        if (this.CID !== '0') {
            this.getCompany();
            this.setupLocationsTable();
            this.setTab(TabsEnum.LOCATIONS);
        } else {
            this.setTab(TabsEnum.DETAILS);
        }
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;
    }

    getCompany(): void {
        this.dataService.request('Company/get', {CID: this.CID}).subscribe(response => {
            this.company = response.company;
            this.company.used_document = parseInt(this.company.used_document, 10);
            this.company.used_location = parseInt(this.company.used_location, 10);

            this.breadcrumbService.setManually([
                {label: {title: 'Unternehmen ' + this.company.name}},
            ]);

            this.tabCount = response.tabCount;

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            CID: this.company.CID,
            name: this.company.name,
            country: this.company.country,
            zip: this.company.zip,
            city: this.company.city,
            street: this.company.street,
            CEO_name: this.company.CEO_name,
            CEO_mail: this.company.CEO_mail,
            register_number: this.company.register_number,
            description: this.company.description,
            gisa_number: this.company.gisa_number,
        });
    }

    save(): void {
        this.dataService.request('Company/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Unternehmen wurde gespeichert');
            this.router.navigate(['/app/company/list']);
        });
    }

    update(): void {
        this.dataService.request('Company/update', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Unternehmen wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
        });
    }

    cancel(): void {
        this.router.navigate(['/app/company/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
        this.tagConfig.editable = this.viewMode === ViewMode.EDIT;
        this.tagConfig = Object.assign({}, this.tagConfig);
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    setupLocationsTable(): void {
        this.tableConfig = {
            design: TableDesign.NONE,
            title: '',
            openDatasetRouterLink: '/app/location/detail',
            routerData: {CID: this.CID},
            enableCreate: true,
            dataConfig: {
                tableName: 'location',
                tableIndexName: 'LID',
                tableFilter: [
                    {
                        table: 'location', field: 'CID', values: [this.CID], operator: TableFilterOperator.EQUAL
                    }
                ],
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {key: 'country', name: 'country', title: 'Land', sortable: true, searchable: true},
                    {key: 'zip', name: 'zip', title: 'PLZ', sortable: true, searchable: true},
                    {key: 'city', name: 'city', title: 'Stadt', sortable: true, searchable: true},
                    {key: 'street', name: 'street', title: 'Straße', sortable: true, searchable: true},
                    {
                        key: 'attributes',
                        subquery: '(SELECT COUNT(*) FROM attribute WHERE LID = location.LID)',
                        title: 'Attribute'
                    },
                    {
                        key: 'tasks',
                        subquery: '(SELECT COUNT(*) FROM task t LEFT JOIN document d ON d.DID = t.DID WHERE type = "singular" AND d.LID = location.LID)',
                        title: 'Einmalige Aufgaben',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'tasks2',
                        subquery: '(SELECT COUNT(*) FROM task t LEFT JOIN document d ON d.DID = t.DID WHERE type = "recurring" AND d.LID = location.LID)',
                        title: 'Wiederkehrende Aufgaben',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_wait',
                        subquery: '(SELECT COUNT(*) FROM taskdate td LEFT JOIN task t ON td.TID = t.TID LEFT JOIN document d on t.DID = d.DID WHERE status = "wait" AND d.LID = location.LID)',
                        title: 'Termine warten',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_open',
                        subquery: '(SELECT COUNT(*) FROM taskdate td LEFT JOIN task t ON td.TID = t.TID LEFT JOIN document d on t.DID = d.DID WHERE status = "open" AND d.LID = location.LID)',
                        title: 'Termine offen',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_progress',
                        subquery: '(SELECT COUNT(*) FROM taskdate td LEFT JOIN task t ON td.TID = t.TID LEFT JOIN document d on t.DID = d.DID WHERE status = "in_progress" AND d.LID = location.LID)',
                        title: 'Termine in Bearbeitung',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_done',
                        subquery: '(SELECT COUNT(*) FROM taskdate td LEFT JOIN task t ON td.TID = t.TID LEFT JOIN document d on t.DID = d.DID WHERE status = "done" AND d.LID = location.LID)',
                        title: 'Termine erledigt',
                        sortable: true,
                        searchable: true
                    },
                ],
                rowImage: [
                    {
                        key: 'status_wait',
                        icon: 'fa-circle',
                        color: '#cccccc',
                        conditions: [
                            {key: 'status_wait', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_open',
                        icon: 'fa-circle',
                        color: '#f86c6b',
                        conditions: [
                            {key: 'status_open', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_progress',
                        icon: 'fa-circle',
                        color: '#ffc107',
                        conditions: [
                            {
                                key: 'status_progress',
                                value: '0',
                                operator: TableRowColorConditionOperator.SMALLER_INT
                            },
                        ]
                    },
                    {
                        key: 'status_done',
                        icon: 'fa-circle',
                        color: '#4dbd74',
                        conditions: [
                            {key: 'status_done', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                ],
                reverse: false,
                order: 'name',
                columnFilter: {
                    enabled: true,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'company-location-list',
                enabled: true
            },
            paginConfig: {
                id: 'companylocationList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Das Unternehmen wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('Company/delete', {
                    CID: this.CID
                }).subscribe(() => {
                    this.toastrService.success('Unternehmen wurde gelöscht');
                    this.cancel();
                });
            }
        });
    }
}
