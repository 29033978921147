<div class="card">
    <div class="card-header">
        <i class="fas fa-envelope mr-2"></i>Posteingang
    </div>
    <div class="card-body">
        <p class="text-muted">
            Hier finden Sie Dateien, die über <a [href]="'mailto:' + email">{{ email }}</a> hochgeladen wurden.
            <button type="button" class="btn btn-primary" (click)="reload()">Neu laden</button>
        </p>

        <div class="alert alert-info alert-dismissible fade show" role="alert" *ngIf="selectedFiles.length == 0">
            Bitte ein oder mehrere Dateien auswählen, um daraus Dokumente zu erstellen.
        </div>

        <form class="mb-3" [formGroup]="form" *ngIf="selectedFiles.length > 0">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Dokumenttyp</label>
                        <select class="form-control" formControlName="FTID">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let fileType of fileTypes" [ngValue]="fileType.FTID">
                                {{ fileType.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Unternehmen</label>
                        <select class="form-control" formControlName="CID">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let company of companies" [ngValue]="company.CID">
                                {{ company.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>{{ 'LOCATION.LOCATION' | translate }}</label>
                        <select class="form-control" formControlName="LID">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let location of locations" [ngValue]="location.LID">
                                {{ location.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.value.FTID">
                <h4 class="mt-3">Attribute setzen</h4>

                <div class="text-muted" *ngIf="formService.getFormArray(form, 'attributes').length == 0">Dem gewählten Dokumenttyp sind keine Attribute zugeordnet!</div>

                <table class="table table-striped table-sm mb-0" *ngIf="formService.getFormArray(form, 'attributes').length > 0">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Typ</th>
                        <th>Wert</th>
                    </tr>
                    </thead>
                    <tbody formArrayName="attributes">
                    <tr [formGroupName]="i" *ngFor="let attribute of formService.getFormArray(form, 'attributes').controls; let i = index">
                        <td>
                            {{ attribute.value.name }}
                        </td>
                        <td>
                            {{ attribute.value.type | fileType }}
                        </td>
                        <td>
                            <input type="number" class="form-control form-control-sm" formControlName="value_int" *ngIf="attribute.value.type == 'int'">
                            <input type="number" step="0.01" class="form-control form-control-sm" formControlName="value_dec" *ngIf="attribute.value.type == 'dec'">
                            <input type="text" class="form-control form-control-sm" formControlName="value_text" *ngIf="attribute.value.type == 'text'">
                            <input type="checkbox" class="form-check-input" formControlName="value_boolean" *ngIf="attribute.value.type == 'boolean'">
                            <input type="date" class="form-control form-control-sm" formControlName="value_date" *ngIf="attribute.value.type == 'date'">
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-container>

            <button type="button" class="btn btn-primary mt-2" [disabled]="form.invalid" (click)="finish()">Speichern</button>

        </form>

        <app-file #fileComponent
                  [doctype]="'inbox'"
                  [fkName]="'INBOX'"
                  [fkTable]="'INBOX'"
                  [viewThumbnails]="true"
                  [viewThumbnailsSize]="'50px'"
                  [viewThumbnailsTilesSize]="'100px'"
                  [viewReadonly]="true"
                  [viewLayout]="fileViewLayout.TABLE"
                  [viewNoFilesText]="'Es sind derzeit keine Dateien im Posteingang.'"
                  [showViewLayoutSwitcher]="true"
                  [isAdmin]="userService.hasRight('ADMIN_ACCESS')"
                  [viewCheckbox]="true"
                  (selectedChange)="handleSelectedChange($event)"
        />
    </div>
</div>
