import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, inject} from '@angular/core';
import {TagConfig} from './tag.interfaces';
import {TagService} from './tag.service';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {NgIf, NgFor, NgClass} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrl: './tag.component.scss',
    imports: [NgIf, NgFor, NgClass, FormsModule, TypeaheadModule]
})
export class TagComponent implements OnInit, OnChanges {

    userService = inject(UserService);
    private dataService = inject(DataService);
    private tagService = inject(TagService);
    private router = inject(Router);

    @Input() config!: TagConfig;
    @ViewChild('tagInput') tagInput!: ElementRef;

    tags: any[] = [];
    allTags: any[] = [];
    newTag: any;
    saving = false;

    ngOnInit(): void {
        this.dataService.request('Tag/listAll').subscribe(response => {
            this.allTags = response.tags;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.userService.hasRight('TAGS_VIEW')) {
            this.resetNewTag();
            this.listTags();
        }
    }

    listTags(): void {
        this.dataService.request('Tag/listAssigned', {
            config: this.config
        }).subscribe(response => {
            this.tags = response.tags;
        });
    }

    addTag(): void {
        this.saving = true;
        this.dataService.request('Tag/save', this.newTag).subscribe(() => {
            this.saving = false;
            this.resetNewTag();
            this.listTags();
            this.tagService.reloadViewer.next(true);
            setTimeout(() => this.tagInput.nativeElement.focus());
        });
    }

    deleteTag(tag: any): void {
        this.dataService.request('Tag/delete', {
            TAGRID: tag.TAGRID
        }).subscribe(() => {
            this.tags.splice(this.tags.indexOf(tag), 1);
            this.tagService.reloadViewer.next(true);
        });
    }

    private resetNewTag(): void {
        this.newTag = {
            FK_ID: this.config.FK_ID,
            FK_name: this.config.FK_name,
            FK_table: this.config.FK_table,
            name: ''
        };
    }

    searchTag(tag: any): void {
        if (!this.config.editable) {
            this.router.navigate(['/app/tag'], {queryParams: {name: tag.name}});
        }
    }
}
