import {AfterViewInit, Directive, ElementRef, inject} from '@angular/core';

@Directive({
    selector: '[appAutofocus]',
    standalone: true
})
export class AutofocusDirective implements AfterViewInit {

    private host = inject(ElementRef);

    ngAfterViewInit(): void {
        this.host.nativeElement.focus();
    }
}
