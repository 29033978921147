import {Component, inject} from '@angular/core';
import {NgIf} from "@angular/common";
import {
    AbstractControl,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup, ValidationErrors,
    Validators
} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {DataService} from "../../global/services/data.service";
import {ToastrService} from "ngx-toastr";
import {myAppConfig} from "../../settings";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.scss',
    imports: [NgIf, ReactiveFormsModule, RouterLink]
})
export class ResetPasswordComponent {

    private route = inject(ActivatedRoute);
    private dataService = inject(DataService);
    private toastrService = inject(ToastrService);
    private router = inject(Router);
    private formBuilder = inject(UntypedFormBuilder);

    protected readonly myAppConfig = myAppConfig;

    hash!: string;
    resetForm!: UntypedFormGroup;
    hashValid = false;

    ngOnInit(): void {
        this.hash = this.route.snapshot.paramMap.get('hash');

        this.dataService.request('framework.Auth/checkHash', {
            hash: this.hash
        }).subscribe(response => {
            if (!response.error) {
                this.hashValid = true;
                const password = new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
                const confirmPassword = new UntypedFormControl('', [
                    Validators.required,
                    (confirmControl: AbstractControl): ValidationErrors | null => {
                        if (!confirmControl.parent) {
                            return null;
                        }

                        const passwordControl = confirmControl.parent.get('password');
                        if (!passwordControl || !confirmControl) return null;
                        return passwordControl.value === confirmControl.value ? null : {nomatch: true};
                    }]
                );

                this.resetForm = this.formBuilder.group({
                        password,
                        confirmPassword
                    }
                );
            }
        });

    }

    reset(): void {
        this.dataService.request('framework.Auth/updatePassword', {
            hash: this.hash,
            password: this.resetForm.value.password
        }).subscribe(response => {
            if (!response.error) {
                this.toastrService.success("Ihr Passwort wurde zurückgesetzt");
                this.router.navigate([myAppConfig.auth.loginDestination]);
            } else {
                this.hashValid = false;
            }
        });
    }
}
