import {Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit, inject} from '@angular/core';
import {Subject} from 'rxjs';
import {
    CalendarDateFormatter,
    CalendarEvent,
    CalendarEventAction,
    CalendarView,
    DAYS_OF_WEEK,
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarWeekModule
} from 'angular-calendar';
import {CustomDateFormatter} from './custom-date-formatter.provider';
import {DataService} from '../../../global/services/data.service';
import {UserService} from '../../../global/services/user.service';
import dayjs from 'dayjs';
import IsoWeek from 'dayjs/plugin/isoWeek';
import {Router} from '@angular/router';
import {CryptoService} from '../../../global/services/crypto.service';
import {ViewMode, UrlData} from '../../../interfaces';
import {NgSwitch, NgSwitchCase} from '@angular/common';

dayjs.extend(IsoWeek);

const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF'
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    },
    black: {
        primary: '#000000',
        secondary: '#666666'
    },
    green: {
        primary: '#00cc00',
        secondary: '#a4d7a4'
    },
    gray: {
        primary: '#999999',
        secondary: '#dddddd'
    }
};

@Component({
    selector: 'app-task-date-calendar',
    templateUrl: './task-date-calendar.component.html',
    styleUrl: './task-date-calendar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,
        },
    ],
    imports: [
        CalendarCommonModule,
        NgSwitch,
        NgSwitchCase,
        CalendarMonthModule,
        CalendarWeekModule,
    ]
})
export class TaskDateCalendarComponent implements OnInit {

    private dataService = inject(DataService);
    private userService = inject(UserService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);

    readonly CalendarView = CalendarView;

    view: CalendarView = CalendarView.Month;
    viewDate: Date = new Date();
    locale = 'de';
    weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
    taskDates!: any[];

    actions: CalendarEventAction[] = [/*{
        label: '<i class="fas fa-fw fa-eye"></i>',
        onClick: ({event}: { event: CalendarEvent }): void => {
            console.dir(event);

            // navigate to task
            const data: UrlData = {
                ID: event.meta.TID
            }
            this.router.navigate(['/app/task/detail/', this.cryptoService.encrypt(JSON.stringify(data))]);
        }
    } , {
        label: '<i class="fas fa-fw fa-times"></i>',
        onClick: ({event}: { event: CalendarEvent }): void => {
            this.events = this.events.filter(iEvent => iEvent !== event);
        }
    }*/];

    refresh: Subject<any> = new Subject();

    events: CalendarEvent[] = [];

    activeDayIsOpen = true;

    ngOnInit(): void {
        this.loadTasks();
    }

    loadTasks(): void {
        this.events = [];

        let start;
        let end;

        if (this.view === 'week') {
            start = dayjs(this.viewDate).startOf('isoWeek').format('YYYY-MM-DD');
            end = dayjs(this.viewDate).endOf('isoWeek').format('YYYY-MM-DD');
        }
        if (this.view === 'month') {
            start = dayjs(this.viewDate).startOf('month').format('YYYY-MM-DD');
            end = dayjs(this.viewDate).endOf('month').format('YYYY-MM-DD');
        }

        this.dataService.request('TaskDate/listByUser', {
            start,
            end
        }).subscribe(response => {
            this.taskDates = response.taskDates;

            const statusMap: any = {
                generated: 'Zukünftig',
                wait: 'Warten',
                open: 'Offen',
                in_progress: 'In Bearbeitung',
                done: 'Erledigt'
            };

            this.taskDates.forEach((taskDate: any) => {

                let color;
                if (taskDate.status === 'generated') {
                    color = colors.black;
                } else if (taskDate.status === 'wait') {
                    color = colors.gray;
                } else if (taskDate.status === 'open') {
                    color = colors.red;
                } else if (taskDate.status === 'in_progress') {
                    color = colors.yellow;
                } else if (taskDate.status === 'done') {
                    color = colors.green;
                }
                this.events.push({
                    start: dayjs(taskDate.end_date).add(6, 'hours').toDate(),
                    end: dayjs(taskDate.end_date).add(23, 'hours').toDate(),
                    title: '(' + statusMap[taskDate.status] + ') Dokument "' + taskDate.documentName + '", Aufgabe "' + taskDate.taskTitle + '"' + (taskDate.description ? (', Termininfo "' + taskDate.description + '"') : ''),
                    color,
                    // actions: this.actions,
                    // allDay: true,
                    meta: {
                        TDID: taskDate.TDID,
                        TID: taskDate.TID
                    }
                });
            });
            this.refresh.next(true);
        });
    }

    dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
        if (dayjs(date).isSame(this.viewDate, 'month')) {
            if (
                (dayjs(date).isSame(this.viewDate, 'day') && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    handleEvent(action: string, event: CalendarEvent): void {
        // navigate to taskDate
        const urlData: UrlData = {
            ID: event.meta.TDID,
            data: {
                TID: event.meta.TID,
                start_date: dayjs(event.start).format('YYYY-MM-DD'),
                end_date: dayjs(event.end).format('YYYY-MM-DD'),
            },
            viewMode: event.meta.TDID === '0' ? ViewMode.INSERT : ViewMode.VIEW,
        };

        this.router.navigate(['/app/taskdate/detail/', this.cryptoService.encrypt(urlData)]);
    }

    setView(view: CalendarView): void {
        this.view = view;

        this.loadTasks();
    }

    closeOpenMonthViewDay(): void {
        this.loadTasks();

        this.activeDayIsOpen = false;
    }

}
