<div class="card">
    <div class="card-header card-header-nr d-flex justify-content-between">
        <span>
            <i class="fas fa-tasks mr-2"></i>
            <ng-container *ngIf="viewMode == ViewMode.INSERT">Termin anlegen</ng-container>
            <ng-container *ngIf="viewMode == ViewMode.EDIT">Termin bearbeiten</ng-container>
            <ng-container *ngIf="viewMode == ViewMode.VIEW">Termin</ng-container>
        </span>
    </div>
    <div class="card-header">
        <form [formGroup]="form">
            <ng-container *ngIf="task">
                <h4 class="mb-3">Termin zu Aufgabe "{{ task.title}}"</h4>

                <div class="alert alert-warning" role="alert" *ngIf="form.value.status != 'done' && inPast()">
                    Dieser Termin ist überfällig!
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Status</label>
                            <ng-container *ngIf="startInFuture">
                                <select class="form-control" formControlName="status" *ngIf="viewMode != ViewMode.VIEW">
                                    <option value="wait">Warten</option>
                                </select>
                            </ng-container>
                            <ng-container *ngIf="!startInFuture">
                                <select class="form-control" formControlName="status" *ngIf="viewMode != ViewMode.VIEW">
                                    <option value="open">Offen</option>
                                    <option value="in_progress">In Bearbeitung</option>
                                    <option value="done">Erledigt</option>
                                </select>
                            </ng-container>
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <i class="fas fa-circle" [ngClass]="{'text-success': form.value.status == 'done', 'text-warning': form.value.status == 'in_progress', 'text-danger': form.value.status == 'open', 'text-secondary': form.value.status == 'wait'}"></i> {{ form.value.status | taskStatus }}
                                <ng-container *ngIf="form.value.status == 'open'">seit {{ taskdate.status_date_open | date: "dd.MM.yyyy" }}</ng-container>
                                <ng-container *ngIf="form.value.status == 'in_progress'">seit {{ taskdate.status_date_progress | date: "dd.MM.yyyy" }}</ng-container>
                                <ng-container *ngIf="form.value.status == 'done'">seit {{ taskdate.status_date_done | date: "dd.MM.yyyy" }}</ng-container>
                            </p>
                            <small class="form-text text-muted" *ngIf="viewMode != ViewMode.VIEW && form.value.status == 'wait'">
                                Wartende Termine werden beim Erreichen des Startzeitpunktes automatisch auf den Status "Offen" gestellt.
                            </small>
                        </div>
                        <div class="form-group" *ngIf="viewMode != ViewMode.INSERT && form.value.status !== form.value.oldStatus">
                            <label>Status Info</label>
                            <input type="text" class="form-control" formControlName="status_info" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.status_info }}</p>
                            <small class="form-text text-muted" *ngIf="viewMode != ViewMode.VIEW">
                                Optionale Informationen betreffend zum Statuswechsel.
                            </small>
                        </div>
                        <div class="form-group">
                            <label>Info</label>
                            <input type="text" class="form-control" formControlName="description" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                {{ form.value.description }}
                                <span class="text-muted" *ngIf="!form.value.description">Nicht gesetzt</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Start</label>
                            <input type="date" class="form-control" formControlName="start_date" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="form.value.start_date">{{ form.value.start_date | date: 'dd.MM.yyyy' }}</ng-container>
                                <span class="text-muted" *ngIf="!form.value.start_date">Nicht gesetzt</span>
                            </p>
                            <small class="form-text text-muted" *ngIf="viewMode != ViewMode.VIEW">
                                Zeitpunkt ab dem mit der Bearbeitung begonnen werden kann.
                            </small>
                        </div>
                        <div class="form-group">
                            <label>Fälligkeit</label>
                            <input type="date" class="form-control" formControlName="end_date" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <i class="fas fa-exclamation-triangle" style="color: #ff9800" *ngIf="form.value.status != 'done' && inPast()" tooltip="Die Erledigung ist überfällig"></i>
                                {{ form.value.end_date | date: 'dd.MM.yyyy' }}
                            </p>
                            <small class="form-text text-muted" *ngIf="viewMode != ViewMode.VIEW">
                                Zeitpunkt an dem der Termin stattfindet.
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Unternehmen</label>
                            <p>
                                <a [attr.data-right]="'COMPANY_VIEW' | right" *ngIf="userService.hasRight('COMPANY_VIEW')" [routerLink]="[]" (click)="routerHelperService.openCompany(task.CID)">{{ task.companyName }}</a>
                                <span *ngIf="!userService.hasRight('COMPANY_VIEW')">{{ task.companyName }}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>{{ 'LOCATION.LOCATION' | translate }}</label>
                            <p>
                                <a [attr.data-right]="'LOCATION_VIEW' | right" *ngIf="userService.hasRight('LOCATION_VIEW')" [routerLink]="[]" (click)="routerHelperService.openLocation(task.LID)">{{ task.locationName }}</a>
                                <span *ngIf="!userService.hasRight('LOCATION_VIEW')">{{ task.locationName }}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Dokument</label>
                            <p>
                                <i class="fas fa-exclamation-circle text-warning mr-1" *ngIf="task.documentActive == '0'" tooltip="Inaktiv"></i>
                                <a [attr.data-right]="'DOCUMENT_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_VIEW')" [routerLink]="[]" (click)="routerHelperService.openDocument(task.DID)">{{ task.documentName }}</a>
                                <span *ngIf="!userService.hasRight('DOCUMENT_VIEW')">{{ task.documentName }}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Aufgabe</label>
                            <p>
                                <a [routerLink]="[]" (click)="routerHelperService.openTask(task.TID)">{{ task.title }}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>

        <ng-container *ngIf="viewMode == ViewMode.VIEW">
            <button type="button" class="btn btn-primary mr-2" (click)="edit()" [attr.data-right]="'TASKDATE_EDIT' | right" *ngIf="userService.hasRight('ADMIN_ACCESS') || (userService.hasRight('TASKDATE_EDIT') && (userService.isOwner(task.responsible_UID) || userService.isOwner(task.UID_external)))">Bearbeiten</button>
            <button type="button" class="btn btn-secondary mr-2" (click)="routerHelperService.openTask(task.TID)">Zurück</button>
            <button type="button" class="btn btn-danger float-right" (click)="delete()" [attr.data-right]="'TASKDATE_DELETE' | right" *ngIf="userService.hasRight('ADMIN_ACCESS') || (userService.hasRight('TASKDATE_DELETE') && userService.isOwner(task.responsible_UID))">Löschen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.INSERT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
        </ng-container>

        <ul class="nav nav-tabs card-header-tabs mt-3" *ngIf="viewMode != ViewMode.INSERT">
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab == TABS.REMINDERS}" href="" data-toggle="tab" (click)="setTab(TABS.REMINDERS)">Erinnerungen ({{tabCount.taskdatereminders}})</a></li>
            <li class="nav-item" [attr.data-right]="'COMMENT_VIEW' | right" *ngIf="userService.hasRight('COMMENT_VIEW')">
                <a class="nav-link" [ngClass]="{active: tab == TABS.COMMENTS}" href="" data-toggle="tab" (click)="setTab(TABS.COMMENTS)">Kommentare ({{tabCount.comments}})</a>
            </li>
            <li class="nav-item" [attr.data-right]="'TASKDATE_ATTACHMENTS_VIEW' | right" *ngIf="userService.hasRight('TASKDATE_ATTACHMENTS_VIEW')">
                <a class="nav-link" [ngClass]="{active: tab == TABS.ATTACHMENTS}" href="" data-toggle="tab" (click)="setTab(TABS.ATTACHMENTS)">Anhänge ({{tabCount.attachments}})</a>
            </li>
        </ul>
    </div>
    <div class="card-body" *ngIf="taskdate && viewMode != ViewMode.INSERT">
        <div *ngIf="tab == TABS.REMINDERS" role="tabpanel" class="tab-pane active">
            <app-task-date-reminder #taskDateReminderComponent [TDID]="TDID" (reloadEvent)="getTabCount()"/>
        </div>

        <div *ngIf="tab == TABS.COMMENTS" role="tabpanel" class="tab-pane active">
            <app-comment [TDID]="TDID" (reloadEvent)="getTabCount()"/>
        </div>

        <div *ngIf="tab == TABS.ATTACHMENTS" role="tabpanel" class="tab-pane active">
            <app-file
                [viewReadonly]="!userService.hasRight('TASKDATE_ATTACHMENTS_EDIT')"
                [doctype]="'taskdate'"
                [fkId]="TDID"
                [fkName]="'TDID'"
                [fkTable]="'taskdate'"
                [viewThumbnails]="true"
                [viewThumbnailsSize]="'50px'"
                [viewThumbnailsTilesSize]="'100px'"
                [uploadSelectMultiple]="true"
                [uploadMaxFilesize]="300"
                [viewEditModeFlipImage]="true"
                [viewLayout]="FileViewLayout.TABLE"
                [viewNoFilesText]="'Es wurden noch keine Anhänge hochgeladen.'"
                [showViewLayoutSwitcher]="true"
                (reloadEvent)="getTabCount()"
                [showDragAndDrop]="true"
            />
        </div>
    </div>
</div>
