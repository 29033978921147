<input
  [(ngModel)]="config.dataConfig.search"
  class="form-control form-control-sm validation-field"
  type="text"
  autocomplete="off"
  placeholder="Suche"
  appDebounceKeyup
  [class.fw-table-field-filtered]="config.dataConfig.search != ''"
  [debounceTime]="500"
  (debounceKeyup)="changeSearch.emit()"
  (keyup.enter)="changeSearch.emit()"
>

<!--keyup.enter might work for touch devices-->
