import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableConfig} from '../table.interfaces';
import {FormsModule} from '@angular/forms';
import {DebounceKeyupDirective} from '../../../../global/directives/debounce-keyup.directive';

@Component({
    selector: 'app-table-searchbox',
    templateUrl: './table-searchbox.component.html',
    styleUrl: './table-searchbox.component.scss',
    imports: [FormsModule, DebounceKeyupDirective]
})
export class TableSearchboxComponent {

    @Input() config!: TableConfig;
    @Output() changeSearch = new EventEmitter();

}
