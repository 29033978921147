<ng-container *ngIf="userService.hasRight('MASTER_DATA_EDIT')">
    <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="count.department == 0">
        Es wurden noch keine <a [routerLink]="['/app/department/list']">Abteilungen</a> angelegt, diese sind beim Anlegen einer Aufgabe notwendig!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="count.company == 0">
        Es wurden noch keine <a [routerLink]="['/app/company/list']">Unternehmen</a> angelegt, diese sind beim Erstellen eines Dokumentes notwendig!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="count.location == 0">
        Es wurden noch keine <a [routerLink]="['/app/location/list']">{{ 'LOCATION.LOCATIONS' | translate }}</a> angelegt, diese sind beim Erstellen eines Dokumentes notwendig!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="count.fileType == 0">
        Es wurden noch keine <a [routerLink]="['/app/filetype/list']">Dokumenttypen</a> angelegt, diese sind beim Erstellen eines Dokumentes notwendig!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="count.topic == 0">
        Es wurden noch keine <a [routerLink]="['/app/tasktopic/list']">Aufgabenthemen</a> angelegt, diese sind beim Anlegen einer Aufgabe notwendig!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="count.tasktype == 0">
        Es wurden noch keine <a [routerLink]="['/app/tasktype/list']">Aufgabentypen</a> angelegt, diese sind beim Anlegen einer Aufgabe notwendig!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</ng-container>

<div class="row mb-3">
    <div class="col" [attr.data-right]="'DOCUMENT_SEARCH_DASHBOARD' | right" *ngIf="userService.hasRight('DOCUMENT_SEARCH_DASHBOARD')">
        <div class="input-group mb-4">
            <input type="text" class="form-control form-control-lg" placeholder="Suche in Dokumenttext.." (keyup.enter)="searchNow()" [(ngModel)]="search">
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" (click)="searchNow()">Suchen</button>
            </div>
        </div>
    </div>
    <div class="col" [attr.data-right]="'DOCUMENT_UPLOAD' | right" *ngIf="userService.hasRight('DOCUMENT_UPLOAD')">
        <button class="btn btn-primary btn-block btn-lg" type="button" routerLink="/app/document/upload"><i class="fas fa-file-upload mr-2"></i>Dokument hochladen</button>
    </div>
</div>

<div class="accordion" id="accordionTaskDates">
    <div class="card mb-0" *ngIf="taskDatesCompleted">
        <div class="card-header" id="headingOne">
            <h5 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" (click)="uncollapse('completed')">
                <i class="fas fa-check text-success"></i> {{taskDatesCompleted.length}} Zuletzt erledigte Termine
                <ng-container *ngIf="taskDatesCompleted.length > 0">
                    <i class="fa-solid fa-chevron-right ml-1" [hidden]="uncollapsed === 'completed'"></i>
                    <i class="fa-solid fa-chevron-down ml-1" [hidden]="uncollapsed !== 'completed'"></i>
                </ng-container>
            </h5>
        </div>

        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionTaskDates">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-sm table-hover mb-0" *ngIf="taskDatesCompleted.length > 0">
                        <thead>
                        <tr>
                            <th>Dokument</th>
                            <th>Aufgabe</th>
                            <th>Art</th>
                            <!--<th style="width: 90px">Start</th>-->
                            <th>Fälligkeit</th>
                            <th>Info</th>
                            <th>Status Info</th>
                            <th>Status seit</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let taskDate of taskDatesCompleted"
                            (click)="openTaskDate(taskDate)"
                            style="cursor: pointer">
                            <td>{{ taskDate.documentName }}</td>
                            <td>{{ taskDate.taskTitle }}</td>
                            <td>{{ taskDate.type | taskType }}</td>
                            <!--<td>{{ taskDate.start_date | date: 'dd.MM.yyyy' }}</td>-->
                            <td>{{ taskDate.end_date | date: 'dd.MM.yyyy' }}</td>
                            <td>
                                {{ taskDate.description }}
                                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                            </td>
                            <td>
                                {{ taskDate.status_info }}
                                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                            </td>
                            <td>{{ taskDate.status_date_done | date: 'dd.MM.yyyy' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0" *ngIf="taskDatesProgress">
        <div class="card-header" id="headingTwo">
            <h5 class="mb-0" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" (click)="uncollapse('in_progress')">
                <i class="fas fa-tasks text-warning"></i> {{taskDatesProgress.length}} Termine in Bearbeitung
                <ng-container *ngIf="taskDatesProgress.length > 0">
                    <i class="fa-solid fa-chevron-right ml-1" [hidden]="uncollapsed === 'in_progress'"></i>
                    <i class="fa-solid fa-chevron-down ml-1" [hidden]="uncollapsed !== 'in_progress'"></i>
                </ng-container>
            </h5>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionTaskDates">
            <div class="card-body">
                <div class="text-muted" *ngIf="taskDatesProgress.length == 0">Derzeit sind keine Termine in Bearbeitung.</div>

                <div class="table-responsive">
                    <table class="table table-sm table-hover mb-0" *ngIf="taskDatesProgress.length > 0">
                        <thead>
                        <tr>
                            <th>Dokument</th>
                            <th>Aufgabe</th>
                            <th>Art</th>
                            <!--<th style="width: 90px">Start</th>-->
                            <th>Fälligkeit</th>
                            <th>Info</th>
                            <th>Status Info</th>
                            <th>Status seit</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let taskDate of taskDatesProgress"
                            (click)="openTaskDate(taskDate)"
                            [ngClass]="{'bg-overdue-in-progress': taskDate.overdue}" style="cursor: pointer">
                            <td>{{ taskDate.documentName }}</td>
                            <td>{{ taskDate.taskTitle }}</td>
                            <td>{{ taskDate.type | taskType }}</td>
                            <!--<td>{{ taskDate.start_date | date: 'dd.MM.yyyy' }}</td>-->
                            <td><i class="fas fa-exclamation-triangle" style="color: #ff9800" *ngIf="taskDate.overdue" tooltip="Die Erledigung ist überfällig"></i> {{ taskDate.end_date | date: 'dd.MM.yyyy' }}</td>
                            <td>
                                {{ taskDate.description }}
                                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                            </td>
                            <td>
                                {{ taskDate.status_info }}
                                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                            </td>
                            <td>{{ taskDate.status_date_progress | date: 'dd.MM.yyyy' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0" *ngIf="taskDatesOpen">
        <div class="card-header" id="headingThree">
            <h5 class="mb-0" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" (click)="uncollapse('open')">
                <i class="fas fa-tasks text-danger"></i> {{taskDatesOpen.length}} Offene Termine
                <ng-container *ngIf="taskDatesOpen.length > 0">
                    <i class="fa-solid fa-chevron-right ml-1" [hidden]="uncollapsed === 'open'"></i>
                    <i class="fa-solid fa-chevron-down ml-1" [hidden]="uncollapsed !== 'open'"></i>
                </ng-container>
            </h5>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionTaskDates">
            <div class="card-body">
                <div class="text-muted" *ngIf="taskDatesOpen.length == 0">Derzeit sind keine Termine offen.</div>

                <div class="table-responsive">
                    <table class="table table-sm table-hover mb-0" *ngIf="taskDatesOpen.length > 0">
                        <thead>
                        <tr>
                            <th>Dokument</th>
                            <th>Aufgabe</th>
                            <th>Art</th>
                            <!--<th style="width: 90px">Start</th>-->
                            <th>Fälligkeit</th>
                            <th>Info</th>
                            <th>Status Info</th>
                            <th>Status seit</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let taskDate of taskDatesOpen"
                            (click)="openTaskDate(taskDate)"
                            [ngClass]="{'bg-overdue-open': taskDate.overdue}" style="cursor: pointer">
                            <td>{{ taskDate.documentName }}</td>
                            <td>{{ taskDate.taskTitle }}</td>
                            <td>{{ taskDate.type | taskType }}</td>
                            <!--<td>{{ taskDate.start_date | date: 'dd.MM.yyyy' }}</td>-->
                            <td><i class="fas fa-exclamation-triangle" style="color: #ff9800" *ngIf="taskDate.overdue" tooltip="Die Erledigung ist überfällig"></i> {{ taskDate.end_date | date: 'dd.MM.yyyy' }}</td>
                            <td>
                                {{ taskDate.description }}
                                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                            </td>
                            <td>
                                {{ taskDate.status_info }}
                                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                            </td>
                            <td>{{ taskDate.status_date_open | date: 'dd.MM.yyyy' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0" *ngIf="taskDatesWaiting">
        <div class="card-header" id="headingFour">
            <h5 class="mb-0" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" (click)="uncollapse('waiting')">
                <i class="fas fa-list-ul text-secondary"></i> {{ taskDatesWaiting.length }} Wartende Termine
                <ng-container *ngIf="taskDatesWaiting.length > 0">
                    <i class="fa-solid fa-chevron-right ml-1" [hidden]="uncollapsed === 'waiting'"></i>
                    <i class="fa-solid fa-chevron-down ml-1" [hidden]="uncollapsed !== 'waiting'"></i>
                </ng-container>
            </h5>
        </div>
        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionTaskDates">
            <div class="card-body">
                <div class="text-muted" *ngIf="taskDatesWaiting.length == 0">Derzeit gibt es keine wartenden Termine.</div>

                <div class="table-responsive">
                    <table class="table table-sm table-hover mb-0" *ngIf="taskDatesWaiting.length > 0">
                        <thead>
                        <tr>
                            <th>Dokument</th>
                            <th>Aufgabe</th>
                            <th>Art</th>
                            <th style="width: 90px">Start</th>
                            <th>Fälligkeit</th>
                            <th>Info</th>
                            <th>Status Info</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let taskDate of taskDatesWaiting"
                            (click)="openTaskDate(taskDate)"
                            style="cursor: pointer">
                            <td>{{ taskDate.documentName }}</td>
                            <td>{{ taskDate.taskTitle }}</td>
                            <td>{{ taskDate.type | taskType }}</td>
                            <td>{{ taskDate.start_date | date: 'dd.MM.yyyy' }}</td>
                            <td>{{ taskDate.end_date | date: 'dd.MM.yyyy' }}</td>
                            <td>
                                {{ taskDate.description }}
                                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                            </td>
                            <td>
                                {{ taskDate.status_info }}
                                <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="taskDatesFuture">
        <div class="card-header" id="headingFive">
            <h5 class="mb-0" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" (click)="uncollapse('generated')">
                <i class="fas fa-list-ul"></i> {{ taskDatesFuture.length }} Zukünftige Termine
                <ng-container *ngIf="taskDatesFuture.length > 0">
                    <i class="fa-solid fa-chevron-right ml-1" [hidden]="uncollapsed === 'generated'"></i>
                    <i class="fa-solid fa-chevron-down ml-1" [hidden]="uncollapsed !== 'generated'"></i>
                </ng-container>
            </h5>
        </div>
        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionTaskDates">
            <div class="card-body">
                <div class="text-muted" *ngIf="taskDatesFuture.length == 0">Keine zukünftigen Termine innerhalb der nächsten 30 Tage.</div>

                <p class="text-muted" *ngIf="taskDatesFuture.length > 0">Innerhalb der nächsten 30 Tage</p>

                <div class="table-responsive">
                    <table class="table table-sm table-hover mb-0" *ngIf="taskDatesFuture.length > 0">
                        <thead>
                        <tr>
                            <th>Dokument</th>
                            <th>Aufgabe</th>
                            <th>Fälligkeit</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let taskDate of taskDatesFuture"
                            (click)="openTaskDate(taskDate)"
                            style="cursor: pointer">
                            <td>{{ taskDate.documentName }}</td>
                            <td>{{ taskDate.taskTitle }}</td>
                            <td>{{ taskDate.end_date | date: 'dd.MM.yyyy' }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<app-activity/>

<div class="card" [attr.data-right]="'TASKDATE_OVERVIEW_COMPANY' | right" *ngIf="userService.hasRight('TASKDATE_OVERVIEW_COMPANY')">
    <h5 class="card-header">
        Übersicht Terminstatus nach Unternehmen
    </h5>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-sm table-striped table-hover table-clients mb-0">
                <thead>
                <tr>
                    <th>Unternehmen</th>
                    <th>Land</th>
                    <th>{{'LOCATION.LOCATIONS' | translate}}</th>
                    <th>Dokumente</th>
                    <th>Aufgaben</th>
                    <th>Termine warten</th>
                    <th>Termine offen</th>
                    <th>Termine in Bearbeitung</th>
                    <th>Termine erledigt</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let company of companies" (click)="routerHelperService.openCompany(company.CID)">
                    <td>{{ company.name }}</td>
                    <td>{{ company.country }}</td>
                    <td>{{ company.locations }}</td>
                    <td>{{ company.documents }}</td>
                    <td>{{ company.tasks }}</td>
                    <td><i class="fa fa-circle" style="color: #ccc" *ngIf="company.wait > 0"></i> {{ company.wait }}</td>
                    <td><i class="fa fa-circle" style="color: #f86c6b" *ngIf="company.open > 0"></i> {{ company.open }}</td>
                    <td><i class="fa fa-circle" style="color: #ffc107" *ngIf="company.in_progress > 0"></i> {{ company.in_progress }}</td>
                    <td><i class="fa fa-circle" style="color: #4dbd74" *ngIf="company.done > 0"></i> {{ company.done }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>{{ sums.locations }}</td>
                    <td>{{ sums.documents }}</td>
                    <td>{{ sums.tasks }}</td>
                    <td>{{ sums.wait }}</td>
                    <td>{{ sums.open }}</td>
                    <td>{{ sums.in_progress }}</td>
                    <td>{{ sums.done }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!--<div class="card-deck mb-3">
    <div class="card card-dashboard card-company" *ngFor="let company of companies" (click)="openCompany($event, company)">
        <div class="card-body">
            <h4 class="mb-2">{{ company.name}}</h4>
            <div><i class="fas fa-building fa-fw mr-2"></i>{{company.locations}} {{'LOCATION.LOCATIONS' | translate}}</div>
            <div><i class="fas fa-file-invoice fa-fw mr-2"></i>{{company.documents}} Dokumente</div>
            <div><i class="fas fa-tasks fa-fw mr-2"></i>{{company.tasks}} Aufgaben</div>
        </div>
    </div>
</div>-->

<!--<div class="display-5 mb-3">Termine ({{ taskCount }})</div>

<div class="card-deck mb-3">
    <div class="card text-white bg-secondary card-task card-task-secondary" routerLink="/app/task/list" [queryParams]="{status: 'wait'}">
        <div class="card-body">
            <div class="h1 text-muted float-right mb-0"><i class="fas fa-list-ul"></i></div>
            <div class="h4 mb-0">{{ taskStatus.wait }}</div>
            <small class="text-muted text-uppercase font-weight-bold">Warten</small>
            <div class="progress progress-white progress-xs mt-3">
                <div aria-valuemax="100" aria-valuemin="0" class="progress-bar" role="progressbar" [style]="percent?.wait"></div>
            </div>
        </div>
    </div>

    <div class="card text-white bg-danger card-task card-task-danger" routerLink="/app/task/list" [queryParams]="{status: 'open'}">
        <div class="card-body">
            <div class="h1 text-muted float-right mb-0"><i class="fas fa-list-ul"></i></div>
            <div class="h4 mb-0">{{ taskStatus.open }}</div>
            <small class="text-muted text-uppercase font-weight-bold">Offen</small>
            <div class="progress progress-white progress-xs mt-3">
                <div aria-valuemax="100" aria-valuemin="0" class="progress-bar" role="progressbar" [style]="percent?.open"></div>
            </div>
        </div>
    </div>

    <div class="card text-white bg-warning card-task card-task-warning" routerLink="/app/task/list" [queryParams]="{status: 'in_progress'}">
        <div class="card-body">
            <div class="h1 text-muted float-right mb-0"><i class="fas fa-tasks"></i></div>
            <div class="h4 mb-0">{{ taskStatus.in_progress }}</div>
            <small class="text-muted text-uppercase font-weight-bold">In Bearbeitung</small>
            <div class="progress progress-white progress-xs mt-3">
                <div aria-valuemax="100" aria-valuemin="0" class="progress-bar" role="progressbar" [style]="percent?.in_progress"></div>
            </div>
        </div>
    </div>

    <div class="card text-white bg-success card-task card-task-success" routerLink="/app/task/list" [queryParams]="{status: 'done'}">
        <div class="card-body">
            <div class="h1 text-muted float-right mb-0"><i class="fas fa-check"></i></div>
            <div class="h4 mb-0">{{ taskStatus.done }}</div>
            <small class="text-muted text-uppercase font-weight-bold">Erledigt</small>
            <div class="progress progress-white progress-xs mt-3">
                <div aria-valuemax="100" aria-valuemin="0" class="progress-bar" role="progressbar" [style]="percent?.done"></div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="userService.hasRight('ADMIN_ACCESS')">
    <div class="display-5 mb-3">Administration</div>

    <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card card-dashboard" routerLink="/app/user/list">
                <div class="card-body p-3 clearfix">
                    <i class="fas fa-users bg-primary p-3 font-2xl mr-3 float-left"></i>
                    <div class="h5 text-primary mb-0 mt-2">{{ count.user }}</div>
                    <div class="text-muted text-uppercase font-weight-bold font-xs">Benutzer</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card card-dashboard" routerLink="/app/company/list">
                <div class="card-body p-3 clearfix">
                    <i class="fas fa-briefcase bg-info p-3 font-2xl mr-3 float-left"></i>
                    <div class="h5 text-info mb-0 mt-2">{{ count.company }}</div>
                    <div class="text-muted text-uppercase font-weight-bold font-xs">Unternehmen</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card card-dashboard" routerLink="/app/location/list">
                <div class="card-body p-3 clearfix">
                    <i class="fas fa-building bg-warning p-3 font-2xl mr-3 float-left"></i>
                    <div class="h5 text-warning mb-0 mt-2">{{ count.location }}</div>
                    <div class="text-muted text-uppercase font-weight-bold font-xs">{{ 'LOCATION.LOCATIONS' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card card-dashboard" routerLink="/app/tasktopic/list">
                <div class="card-body p-3 clearfix">
                    <i class="fas fa-tag bg-danger p-3 font-2xl mr-3 float-left"></i>
                    <div class="h5 text-danger mb-0 mt-2">{{ count.topic }}</div>
                    <div class="text-muted text-uppercase font-weight-bold font-xs">Aufgabenthemen</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card card-dashboard" routerLink="/app/tasktype/list">
                <div class="card-body p-3 clearfix">
                    <i class="fas fa-tag bg-secondary p-3 font-2xl mr-3 float-left"></i>
                    <div class="h5 text-secondary mb-0 mt-2">{{ count.tasktype }}</div>
                    <div class="text-muted text-uppercase font-weight-bold font-xs">Aufgabentypen</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card card-dashboard" routerLink="/app/department/list">
                <div class="card-body p-3 clearfix">
                    <i class="fas fa-user-tie bg-success p-3 font-2xl mr-3 float-left"></i>
                    <div class="h5 text-success mb-0 mt-2">{{ count.department }}</div>
                    <div class="text-muted text-uppercase font-weight-bold font-xs">Abteilungen</div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <div class="card card-dashboard" routerLink="/app/filetype/list">
                <div class="card-body p-3 clearfix">
                    <i class="fas fa-file-alt bg-primary p-3 font-2xl mr-3 float-left"></i>
                    <div class="h5 text-primary mb-0 mt-2">{{ count.fileType }}</div>
                    <div class="text-muted text-uppercase font-weight-bold font-xs">Dokumenttypen</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>-->

<div [attr.data-right]="'TASKS_TABLE_DASHBOARD_VIEW' | right" *ngIf="!userService.isAdmin() && userService.hasRight('TASKS_TABLE_DASHBOARD_VIEW')">
    <div class="display-5 mb-3">Aufgaben</div>

    <app-table [config]="tasksTableConfig"/>
</div>
