<button type="button" class="btn btn-secondary btn-sm mb-1" (click)="loadActivities()"><i class="fa-solid fa-arrows-rotate"></i></button>

<div class="table-responsive">
    <table class="table table-striped table-sm mb-0">
        <thead>
        <tr>
            <th style="width: 150px">
                Datum
            </th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let activity of activities">
            <td>
                <span *ngIf="activity.diff >= 60">{{ activity.created | date: "dd.MM.yyyy, HH:mm" }}</span>
                <span *ngIf="activity.diff < 60">Vor {{ activity.diff }} Minuten</span>
            </td>
            <td>
            <ng-container *ngIf="activity.type == ActivityType.COMMENT_TASK">
                <i class="fa-regular fa-comment fa-fw mr-1"></i>{{ activity.user }} hat die Aufgabe <span class="bold">{{ activity.task }}</span> kommentiert.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.COMMENT_TASKDATE">
                <i class="fa-regular fa-comment fa-fw mr-1"></i>{{ activity.user }} hat den <span class="bold">Termin mit Fälligkeit am {{ activity.taskdate | date: 'dd.MM.yyyy' }}</span> zur Aufgabe "{{ activity.task }}" kommentiert.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.CREATE_DOCUMENT">
                <i class="fa-solid fa-file-invoice fa-fw mr-1"></i>{{ activity.user }} hat das Dokument <span class="bold">{{ activity.document }}</span> erstellt.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.CREATE_TASK">
                <i class="fa-solid fa-tasks fa-fw mr-1"></i>{{ activity.user }} hat die Aufgabe <span class="bold">{{ activity.task }}</span> angelegt.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.CREATE_TASKDATE">
                <i class="fa-solid fa-business-time fa-fw mr-1"></i>{{ activity.user }} hat einen <span class="bold">Termin mit Fälligkeit am {{ activity.taskdate | date: 'dd.MM.yyyy' }}</span> mit Status "{{ activity.status | taskStatus }}" zur Aufgabe "{{ activity.task }}" angelegt.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.COMPLETE_TASK_CREATION">
                <i class="fa-solid fa-tasks fa-fw mr-1"></i>{{ activity.user }} hat die Aufgabenerstellung vom Dokument <span class="bold">{{ activity.document }}</span> abgeschlossen.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.STATUS_CHANGE">
                <i class="fa-solid fa-business-time fa-fw mr-1"></i>{{ activity.user }} hat den Status vom <span class="bold">Termin mit Fälligkeit am {{ activity.taskdate | date: 'dd.MM.yyyy' }}</span> zur Aufgabe "{{ activity.task }}" auf <ng-container [ngTemplateOutlet]="taskStatus" [ngTemplateOutletContext]="{status: activity.status}"></ng-container> gesetzt.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.ATTACHMENT_DOCUMENT">
                <i class="fa-solid fa-file-upload fa-fw mr-1"></i>{{ activity.user }} hat den Anhang "{{ activity.file }}" beim Dokument <span class="bold">{{ activity.document }}</span> hochgeladen.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.ATTACHMENT_TASK">
                <i class="fa-solid fa-file-upload fa-fw mr-1"></i>{{ activity.user }} hat den Anhang "{{ activity.file }}" bei der Aufgabe <span class="bold">{{ activity.task }}</span> hochgeladen.
            </ng-container>
            <ng-container *ngIf="activity.type == ActivityType.ATTACHMENT_TASKDATE">
                <i class="fa-solid fa-file-upload fa-fw mr-1"></i>{{ activity.user }} hat den Anhang "{{ activity.file }}" beim <span class="bold">Termin mit Fälligkeit am {{ activity.taskdate | date: 'dd.MM.yyyy' }}</span> zur Aufgabe "{{ activity.task }}" hochgeladen.
            </ng-container>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<span *ngIf="loading">
    <i class="fa-solid fa-spinner fa-pulse fa-2x fa-fw mt-3"></i>
</span>

<div class="text-muted" style="margin-top: 10px" *ngIf="activities.length == 0 && !loading">Keine Aktivitäten erfasst!</div>

<ng-template #taskStatus let-status='status'>
    <i class="fas fa-circle" [ngClass]="{'text-success': status == 'done', 'text-warning': status == 'in_progress', 'text-danger': status == 'open', 'text-secondary': status == 'wait'}"></i>
    {{ status | taskStatus }}
</ng-template>
