<div class="card mb-3">
    <div class="card-header">
        <h3>{{ document.name }}
            <span style="float: right">
                <small *ngIf="document" tooltip="Unternehmen" placement="top">{{ client.name }}</small>
            </span>
        </h3>

        <ul class="nav nav-tabs card-header-tabs mt-3">
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.DIGITIZATION}" href="" data-toggle="tab" (click)="$event.preventDefault(); setTab(TABS.DIGITIZATION)">Digitalisierung</a></li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab === TABS.CONSULTING}" href="" data-toggle="tab" (click)="$event.preventDefault(); setTab(TABS.CONSULTING)">Consulting</a></li>
        </ul>
    </div>
    <div class="card-body">
        <div *ngIf="document && tab === TABS.DIGITIZATION" role="tabpanel" class="tab-pane active">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" [(ngModel)]="tabs.pdf">
                <label class="form-check-label">PDF</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" [(ngModel)]="tabs.text">
                <label class="form-check-label">Text</label>
            </div>
            <!--<div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" [(ngModel)]="tabs.tasks">
                <label class="form-check-label">Aufgaben</label>
            </div>-->

            <button type="button" class="btn btn-primary btn-sm mb-3" (click)="update()">Speichern</button>
            <button type="button" class="btn btn-info btn-sm ml-2 mb-3" (click)="finishDigitization()">Digitalisierung abschließen</button>
            <button type="button" class="btn btn-secondary btn-sm ml-2 mb-3" (click)="listDigitization()">Zurück</button>

            <div class="row">
                <div class="col" *ngIf="tabs.pdf">
                    <div *ngIf="file && embedString" class="pdf-embed">
                        <embed [src]="embedString" type="application/pdf" width="100%" height="100%">
                    </div>
                    <div class="alert alert-info mb-0" *ngIf="!embedString">
                        <i class="fa fa-info-circle"></i>
                        PDF-Datei konnte nicht geladen werden.
                    </div>
                </div>
                <div class="col" *ngIf="tabs.text" style="height: calc(100vh - 225px); overflow-y: scroll">
                    <form [formGroup]="form">
                        <quill-editor formControlName="ocr_text"/>
                    </form>
                </div>
                <div class="col" *ngIf="tabs.tasks" style="height: calc(100vh - 225px); overflow-y: scroll">
                    <app-admin-task-list *ngIf="DID && dbName && CLID" [DID]="DID" [dbName]="dbName" [CLID]="CLID"/>
                </div>
            </div>
        </div>

        <div *ngIf="client && tab === TABS.CONSULTING" role="tabpanel" class="tab-pane active">
            <button type="button" class="btn btn-info btn-sm mb-3" *ngIf="document.consulting === '1' && document.consulting_complete === '0'" (click)="finishConsulting()">Consulting abschließen</button>
            <button type="button" class="btn btn-secondary btn-sm ml-2 mb-3" (click)="listConsulting()">Zurück</button>

            <h5>Kontaktdaten</h5>
            <div class="row">
                <div class="col-2">
                    <div class="form-group">
                        <label>Email</label>
                        <p>{{ client.email }}</p>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label>Telefon</label>
                        <p>{{ client.phone }}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
