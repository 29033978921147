<div class="card">
    <div class="card-header">
        <i class="fas fa-eye mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.INSERT">Sichtbarkeitsgruppe anlegen</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Sichtbarkeitsgruppe bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Sichtbarkeitsgruppe</ng-container>
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.name }}</p>
                    </div>

                    <div class="form-group">
                        <label>Beschreibung</label>
                        <textarea class="form-control" formControlName="description" *ngIf="viewMode != ViewMode.VIEW" rows="4"></textarea>
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.description }}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Zugewiesene Benutzer</label>
                        <ng-container *ngIf="viewMode != ViewMode.VIEW">
                            <select class="form-control" formControlName="userIDs" multiple>
                                <option *ngFor="let user of users" [value]="user.UID">
                                    {{ user.firstname }} {{ user.lastname }}
                                </option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="viewMode == ViewMode.VIEW">
                            <ul *ngIf="form.value.users?.length > 0">
                                <li *ngFor="let user of form.value.users">
                                    {{ user.firstname }} {{ user.lastname }}
                                </li>
                            </ul>
                            <p class="text-muted" *ngIf="form.value.users?.length == 0">Keine Benutzer zugewiesen</p>
                        </ng-container>
                    </div>

                    <div class="form-group" *ngIf="viewMode == ViewMode.VIEW">
                        <label>Zugewiesene Dokumente</label>
                        <ul *ngIf="form.value.documents?.length > 0">
                            <li *ngFor="let document of form.value.documents">
                                <a [routerLink]="[]" (click)="routerHelperService.openDocument(document.DID)">{{ document.name }}</a>
                            </li>
                        </ul>
                        <p class="text-muted" *ngIf="form.value.documents?.length == 0">Keine Dokumente zugewiesen</p>
                    </div>
                </div>
            </div>
        </form>

        <ng-container *ngIf="viewMode == ViewMode.VIEW">
            <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">Bearbeiten</button>
            <button type="button" class="btn btn-secondary" (click)="cancel()">Zurück</button>
            <button type="button" class="btn btn-danger float-right" [attr.data-right]="'ADMIN_ACCESS' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')" (click)="delete()">Löschen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.INSERT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
        </ng-container>

    </div>
</div>
