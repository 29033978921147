<div class="card mb-3">
    <div class="card-header">
        <i class="fas fa-file-alt mr-2"></i>Angefragtes Consulting ({{ documents.length }})
    </div>
    <div class="card-body fw-table-card-body">

        <span class="fst-italic" *ngIf="documents.length === 0">Derzeit keine Anfragen.</span>
        <table class="table table-striped table-hover table-sm mb-0" *ngIf="documents.length > 0">
            <thead>
            <tr>
                <th>Name</th>
                <th>Hochgeladen am</th>
                <th>Mandant</th>
                <th>Seiten</th>
                <th>Aufgaben</th>
                <!--<th>Digitalisierung</th>-->
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let document of documents" (click)="openDocument(document)" style="cursor: pointer">
                <td>{{ document.name }}</td>
                <td>{{ document.uploaded | date: "dd.MM.yyyy HH:mm"}}</td>
                <td>{{ document.clientName }}</td>
                <td>{{ document.pages }}</td>
                <td>{{ document.tasks }}</td>
                <!--<td>{{ document.digitization | digitization }}</td>-->
            </tr>
            </tbody>
        </table>
    </div>
</div>
