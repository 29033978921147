import {Component, OnInit, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {AutofocusDirective} from '../../../global/directives/autofocus.directive';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrl: './contact.component.scss',
    imports: [FormsModule, ReactiveFormsModule, AutofocusDirective]
})
export class ContactComponent implements OnInit {

    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);
    private router = inject(Router);

    form!: UntypedFormGroup;

    ngOnInit(): void {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            message: ['', Validators.required],
        });
    }

    sendMessage(): void {
        this.dataService.request('universe.Client/sendMessage', this.form.getRawValue()).subscribe(response => {
            Swal.fire(
                'Nachricht wurde abgeschickt!',
                'Vielen Dank! Wir werden Sie in Kürze kontaktieren',
                'success'
            );
            this.router.navigate(['/']);
        });
    }
}
