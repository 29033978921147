import {Component, OnInit, inject} from '@angular/core';
import {TagConfig} from '../tag/tag.interfaces';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {DataService} from '../../services/data.service';
import {RouterHelperService} from '../../services/router-helper.service';
import {UserService} from '../../services/user.service';
import {TagViewerComponent} from '../tag/tag-viewer/tag-viewer.component';
import {NgIf, NgFor} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RightPipe} from '../../pipes/right.pipe';

@Component({
    selector: 'app-tag-search',
    templateUrl: './tag-search.component.html',
    styleUrl: './tag-search.component.scss',
    imports: [TagViewerComponent, NgIf, NgFor, TranslateModule, RightPipe, RouterLink]
})
export class TagSearchComponent implements OnInit {

    routerHelperService = inject(RouterHelperService);
    userService = inject(UserService);
    private activatedRoute = inject(ActivatedRoute);
    private dataService = inject(DataService);

    config!: TagConfig;
    results: any;
    loading = false;
    found = 0;

    ngOnInit(): void {
        const name = this.activatedRoute.snapshot.queryParamMap.get('name') || null;

        this.config = {
            FK_ID: null,
            FK_name: null,
            FK_table: null,
            editable: false,
            selected: name
        };
    }

    handleClick(event: any): void {
        this.results = null;
        this.loading = true;
        this.dataService.request('Tag/search', event).subscribe(response => {
            this.loading = false;
            this.results = response.results;
        });
    }

    setFound($event: any): void {
        this.found = $event;
    }
}
