import {Component, OnInit, inject} from '@angular/core';
import {UntypedFormGroup, FormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {NgIf, NgFor, DecimalPipe, DatePipe} from '@angular/common';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {YesNoPipe} from '../../../global/pipes/yes-no.pipe';

@Component({
    selector: 'app-location-attributes-search',
    templateUrl: './location-attributes-search.component.html',
    styleUrl: './location-attributes-search.component.scss',
    imports: [NgIf, FormsModule, NgFor, RouterLink, DecimalPipe, DatePipe, TranslateModule, YesNoPipe]
})
export class LocationAttributesSearchComponent implements OnInit {

    routerHelperService = inject(RouterHelperService);
    private dataService = inject(DataService);

    form!: UntypedFormGroup;
    locations!: any[];
    templates!: any[];
    search: any = {};

    // used in filter
    allCompanies!: any[];
    allLocations!: any[];
    CID: any = '';
    LID: any = '';

    exporting = false;

    ngOnInit(): void {
        this.dataService.request('Location/listAll').subscribe(response => {
            this.allCompanies = [];
            response.locations.forEach((location: any) => {
                const companyFound = this.allCompanies.find(company => company.CID === location.CID);
                if (!companyFound) {
                    this.allCompanies.push({
                        CID: location.CID,
                        name: location.company,
                    });
                }
            });

            this.allLocations = response.locations.map((location: any) => {
                return {
                    CID: location.CID,
                    LID: location.LID,
                    name: location.name,
                };
            });
        });

        this.loadLocationsWithAttributes();
    }

    companyChanged(): void {
        if (this.CID != '') this.LID = '';

        this.loadLocationsWithAttributes();
    }

    loadLocationsWithAttributes(): void {
        this.locations = [];

        this.dataService.request('Location/listWithAttributes', {
            search: this.search,
            LID: this.LID,
            CID: this.CID,
        }).subscribe(response => {
            this.locations = response.locations;
            this.templates = response.templates;

            // if the search object is empty populate it
            if (Object.keys(this.search).length === 0) {
                this.templates.forEach(template => {
                    this.search[template.name] = '';
                });
            }
        });
    }

    exportExcel(): void {
        this.exporting = true;

        this.dataService.request('Location/exportAttributes', {
            search: this.search,
            LID: this.LID,
            CID: this.CID,
        }).subscribe(response => {
            this.exporting = false;

            const link = document.createElement('a');

            link.setAttribute('href', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data);
            link.setAttribute('download', response.path);
            link.click();
        });
    }
}
