<div class="card">
    <div class="card-header">
        <i class="fas fa-file-upload mr-2"></i>Mehrere Dokumente hochladen
    </div>
    <div class="card-body">

        <div ng2FileDrop
             [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
             (fileOver)="fileOverBase($event)"
             [uploader]="uploader"
             class="well my-drop-zone mb-3">
            PDFs hier reinziehen
        </div>

        <!--<input type="file" ng2FileSelect [uploader]="uploader" multiple accept="application/pdf"/>-->
        <input style="display: none" type="file" ng2FileSelect #fileInput multiple accept="application/pdf" [uploader]="uploader">
        <button type="button" class="btn btn-info" (click)="fileInput.click()">
            oder PDFs auswählen
        </button>

        <div class="mt-3" *ngIf="uploader?.queue?.length > 0">

            <h3>{{ uploader?.queue?.length }} ausgewählte Dokumente</h3>

            <table class="table">
                <thead>
                <tr *ngIf="uploader?.queue?.length > 1">
                    <th></th>
                    <th>
                        <select class="form-control" name="FTID" [(ngModel)]="filetype" (ngModelChange)="setTypeAll()">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let fileType of fileTypes" [ngValue]="fileType">
                                {{ fileType.name }}
                            </option>
                        </select>
                    </th>
                    <th>
                        <select class="form-control" name="CID" [(ngModel)]="company" (ngModelChange)="setCompanyAll()">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let company of companies" [ngValue]="company">
                                {{ company.name }}
                            </option>
                        </select>
                    </th>
                    <th>
                        <select class="form-control" name="LID" [(ngModel)]="LID" (ngModelChange)="setLocationAll()" [disabled]="!company">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let location of company?.locations" [ngValue]="location.LID">
                                {{ location.name }}
                            </option>
                        </select>
                    </th>
                    <th colspan="4"></th>
                </tr>
                <tr>
                    <th>Name</th>
                    <th>Dokumenttyp</th>
                    <th>Unternehmen</th>
                    <th>Betriebsstätte</th>
                    <th>Größe</th>
                    <th>Fortschritt</th>
                    <th>Status</th>
                    <th>Aktionen</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let item of uploader.queue; let i = index">
                    <tr>
                        <!--<td><strong>{{ item?.file?.name }}</strong></td>-->
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.formData.name" required [disabled]="item.isReady || item.isUploading || item.isSuccess">
                        </td>
                        <td>
                            <select class="form-control" name="filetype" [(ngModel)]="item.formData.filetype" (ngModelChange)="setType(item)" required [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let fileType of fileTypes" [ngValue]="fileType">
                                    {{ fileType.name }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select class="form-control" name="CID" [(ngModel)]="item.formData.company" (ngModelChange)="setCompany(item)" required [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let company of companies" [ngValue]="company">
                                    {{ company.name }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select class="form-control" name="LID" [(ngModel)]="item.formData.LID" required [disabled]="!item.formData.company || item.isReady || item.isUploading || item.isSuccess" (ngModelChange)="setLocation(item)">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let location of item.formData.company?.locations" [ngValue]="location.LID">
                                    {{ location.name }}
                                </option>
                            </select>
                        </td>
                        <td *ngIf="uploader.options.isHTML5" nowrap>
                            {{ item?.file?.size / 1024 / 1024 | number:'1.0-2' }} MB
                        </td>
                        <td *ngIf="uploader.options.isHTML5">
                            <div class="progress" style="margin-bottom: 0;">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                            </div>
                        </td>
                        <td class="text-center">
                            <span *ngIf="item.isSuccess"><i class="fas fa-check"></i></span>
                            <span *ngIf="item.isCancel"><i class="fas fa-ban"></i></span>
                            <span *ngIf="item.isError"><i class="fas fa-trash"></i></span>
                        </td>
                        <td nowrap>
                            <button type="button" class="btn btn-success btn-xs mr-1"
                                    (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess || !canUpload(item)">
                                Hochladen
                            </button>
                            <button type="button" class="btn btn-warning btn-xs mr-1"
                                    (click)="item.cancel()" [disabled]="!item.isUploading">
                                Abbrechen
                            </button>
                            <button type="button" class="btn btn-danger btn-xs"
                                    (click)="item.remove()">
                                Entfernen
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="item.formData.filetype">
                        <td colspan="8">
                            <h4>
                                Attribute ({{item.formData.filetype.attributes.length}}<ng-container *ngIf="!item.formData.showAttributes && getMissingAttributesCount(item) > 0">, <span style="color: red">{{getMissingAttributesCount(item)}} Pflichtfelder offen</span></ng-container>)
                                <i class="fa-solid" style="cursor: pointer"
                                   *ngIf="item.formData.filetype.attributes.length > 0"
                                   [class.fa-chevron-down]="item.formData.showAttributes"
                                   [class.fa-chevron-right]="!item.formData.showAttributes"
                                   (click)="toggleAttributes(item)"></i>
                            </h4>

                            <div class="text-muted" *ngIf="item.formData.filetype.attributes.length == 0">Dem gewählten Dokumenttyp sind keine Attribute zugeordnet!</div>

                            <ng-container *ngIf="item.formData.showAttributes">
                                <table class="table table-striped table-sm mb-0" *ngIf="item.formData.attributes.length > 0">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Typ</th>
                                        <th>Wert</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let attribute of item.formData.attributes; let ai = index">
                                        <td>
                                            {{ attribute.name }}
                                        </td>
                                        <td>
                                            {{ attribute.type | fileType }}
                                        </td>
                                        <td>
                                            <input type="number" class="form-control form-control-sm" name="{{i}}_{{ai}}_int" [(ngModel)]="attribute.value_int" *ngIf="attribute.type == 'int'" [required]="attribute.required">
                                            <input type="number" step="0.01" class="form-control form-control-sm" name="{{i}}_{{ai}}_dec" [(ngModel)]="attribute.value_dec" *ngIf="attribute.type == 'dec'" [required]="attribute.required">
                                            <input type="text" class="form-control form-control-sm" name="{{i}}_{{ai}}_text" [(ngModel)]="attribute.value_text" *ngIf="attribute.type == 'text'" [required]="attribute.required">
                                            <input type="checkbox" class="form-check-input" name="{{i}}_{{ai}}_boolean" [(ngModel)]="attribute.value_boolean" *ngIf="attribute.type == 'boolean'" [required]="attribute.required">
                                            <input type="date" class="form-control form-control-sm" name="{{i}}_{{ai}}_date" [(ngModel)]="attribute.value_date" *ngIf="attribute.type == 'date'" [required]="attribute.required">
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>

            <div>
                <div class="mb-3" *ngIf="uploader.progress > 0">
                   Fortschritt
                    <div class="progress" style="">
                        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                    </div>
                </div>
                <button type="button" class="btn btn-success btn-s mr-2"
                        (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length || !canUploadAll()">
                    Alle hochladen
                </button>
                <button type="button" class="btn btn-warning btn-s mr-2"
                        (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                    Alle abbrechen
                </button>
                <button type="button" class="btn btn-danger btn-s"
                        (click)="uploader.clearQueue(); clearResponses()" [disabled]="!uploader.queue.length">
                    Alle entfernen
                </button>
            </div>
        </div>

        <ng-container *ngIf="responses.length > 0">
            <hr>
            <div class="alert alert-success alert-dismissible fade show" role="alert">
                Es wurden {{responses.length}} von {{uploader.queue.length}} Dokumenten erfolgreich hochgeladen.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!--<pre *ngFor="let response of responses">
                {{ response | json }}
            </pre>-->
        </ng-container>

    </div>
</div>
