<div class="info" *ngIf="visible">
    <div class="user-info" *ngIf="userService.currentUser">
        <h4>User</h4>
        <pre>{{ userService.currentUser | json }}</pre>
    </div>
    <div class="jwt-info">
        <h4>Token <small class="font-italic">({{ myAppConfig.appID }})</small></h4>
        <pre>{{ jwtData | json }}</pre>
    </div>
</div>
