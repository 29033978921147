<div class="card">
    <div class="card-header card-header-nr">
        <i class="far fa-building mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.INSERT">{{'LOCATION.LOCATION' | translate}} anlegen</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">{{'LOCATION.LOCATION' | translate}} bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">{{'LOCATION.LOCATION' | translate}}</ng-container>
    </div>
    <div class="card-header">
        <h4 class="mb-3" *ngIf="viewMode != ViewMode.INSERT">Betriebsstätte "{{ form.value.name}}"</h4>

        <ul class="nav nav-tabs card-header-tabs mt-3">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{active: tab == TABS.DETAILS}" href="" data-toggle="tab" (click)="setTab(TABS.DETAILS)">Details</a>
            </li>
            <ng-container *ngIf="viewMode != ViewMode.INSERT">
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{active: tab == TABS.DOCUMENTS}" href="" data-toggle="tab" (click)="setTab(TABS.DOCUMENTS)">Dokumente ({{tabCount.documents}})</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{active: tab == TABS.ATTRIBUTES}" href="" data-toggle="tab" (click)="setTab(TABS.ATTRIBUTES)">Attribute ({{tabCount.attributes}})</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{active: tab == TABS.ATTACHMENTS}" href="" data-toggle="tab" (click)="setTab(TABS.ATTACHMENTS)">Anhänge ({{tabCount.attachments}})</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{active: tab == TABS.MAP}" href="" data-toggle="tab" (click)="setTab(TABS.MAP)">Karte</a>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="card-body">
        <div *ngIf="tab == TABS.DETAILS" role="tabpanel" class="tab-pane active">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Unternehmen</label>
                            <div class="input-group" *ngIf="viewMode != ViewMode.VIEW">
                                <select class="form-control" formControlName="CID">
                                    <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                    <option *ngFor="let company of companies" [ngValue]="company.CID">
                                        {{ company.name }}
                                    </option>
                                </select>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-primary" type="button" [disabled]="!form.value.CID" (click)="copyAddress()">Anschrift übernehmen</button>
                                </div>
                            </div>

                            <p *ngIf="viewMode == ViewMode.VIEW"><a [routerLink]="[]" (click)="routerHelperService.openCompany(form.value.CID)">{{ form.value.company }}</a></p>
                            <small class="form-text text-danger" *ngIf="companies?.length == 0">
                                Bitte vorher ein Unternehmen anlegen, dem diese {{'LOCATION.LOCATION' | translate}} zugeordnet werden kann.
                            </small>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.name }}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Land</label>
                            <input type="text" class="form-control" formControlName="country" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.country }}</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>PLZ</label>
                            <input type="number" class="form-control" formControlName="zip" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.zip }}</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Stadt</label>
                            <input type="text" class="form-control" formControlName="city" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.city }}</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Straße</label>
                            <input type="text" class="form-control" formControlName="street" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.street }}</p>
                        </div>
                    </div>
                </div>
                <app-tag *ngIf="viewMode != ViewMode.INSERT" [config]="tagConfig"/>
            </form>

            <ng-container *ngIf="viewMode == ViewMode.VIEW">
                <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">Bearbeiten</button>
                <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">Zurück</button>
                <button type="button" class="btn btn-danger float-right" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="location.countDocuments == 0 && userService.hasRight('MASTER_DATA_EDIT')" (click)="delete()">Löschen</button>
            </ng-container>

            <ng-container *ngIf="viewMode == ViewMode.EDIT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
            </ng-container>

            <ng-container *ngIf="viewMode == ViewMode.INSERT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
            </ng-container>
        </div>

        <div *ngIf="tab == TABS.DOCUMENTS" role="tabpanel" class="tab-pane active">

            <span [attr.data-right]="'DOCUMENT_UPLOAD' | right" *ngIf="userService.hasRight('DOCUMENT_UPLOAD')">
                <button type="button" class="btn btn-primary mr-2" (click)="uploadDocument()"><i class="fas fa-file-upload mr-2"></i>Dokument hochladen</button>
            </span>

            <button type="button" class="btn btn-highlight mr-2" [attr.data-right]="'TASKDATE_CHECKLIST_RECURRING_CREATE' | right" *ngIf="userService.hasRight('TASKDATE_CHECKLIST_RECURRING_CREATE')" (click)="getCheckListDocuments(); checkListModal.show()">
                <i class="fa-solid fa-file-circle-check mr-2"></i>Checkliste für wiederkehrende Aufgaben erstellen
            </button>

            <button type="button" class="btn btn-highlight" [attr.data-right]="'TASKDATE_CHECKLIST_RECURRING_CREATE' | right" *ngIf="userService.hasRight('TASKDATE_CHECKLIST_RECURRING_CREATE')" (click)="getCheckListDocuments(); overviewRecurringModal.show()">
                <i class="fa-solid fa-file-circle-check mr-2"></i>Zusammenfassung wiederkehrender Aufgaben erstellen
            </button>

            <p class="text-muted mt-2">Mit der Checkliste sehen Sie auf einen Blick, zu welchen Aufgaben es über die nächsten Jahre wann Termine gibt, bzw. deren aktuellen Status.</p>

            <app-table *ngIf="LID != '0'" [config]="tableConfig"/>
        </div>
        <div *ngIf="tab == TABS.ATTRIBUTES" role="tabpanel" class="tab-pane active">
            <app-attributes [LID]="location.LID"/>
        </div>
        <div *ngIf="tab == TABS.ATTACHMENTS" role="tabpanel" class="tab-pane active">
            <app-file
                [viewReadonly]="!userService.hasRight('LOCATION_ATTACHMENTS_EDIT')"
                [doctype]="'location'"
                [fkId]="location.LID"
                [fkName]="'LID'"
                [fkTable]="'location'"
                [viewThumbnails]="true"
                [viewThumbnailsSize]="'50px'"
                [viewThumbnailsTilesSize]="'100px'"
                [uploadSelectMultiple]="true"
                [uploadMaxFilesize]="300"
                [viewEditModeFlipImage]="true"
                [viewLayout]="fileViewLayout.TABLE"
                [viewNoFilesText]="'Es wurden noch keine Anhänge hochgeladen.'"
                [showViewLayoutSwitcher]="true"
                (reloadEvent)="getTabCount()"
                [showDragAndDrop]="true"
            />
        </div>
        <div [hidden]="tab != TABS.MAP" role="tabpanel" class="tab-pane active">
            <p class="text-muted" *ngIf="location.notFound">Koordinaten konnten nicht aus Adresse bestimmt werden!</p>
            <app-map #mapComponent [ID]="'location'"/>
        </div>
    </div>
</div>

<div bsModal #checkListModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="recurringDocuments">
            <form #checkListForm="ngForm" class="form">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Checkliste erstellen</h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="checkListModal.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>Bis zu welchem Jahr soll die Checkliste erstellt werden?</div>
                    <input type="number" class="form-control mt-1 mb-2" style="width: 100px" name="checkListYear" [min]="checkListYearMin" [max]="checkListYearMax" [(ngModel)]="checkListYear">

                    <p>Bitte die gewünschten Dokumente auswählen</p>

                    <button type="button" class="btn btn-primary btn-sm mr-2" (click)="selectAll()">Alle auswählen</button>
                    <button type="button" class="btn btn-primary btn-sm" (click)="deselectAll()">Keine Auswählen</button>

                    <table class="table table-striped table-sm mt-2 mb-0">
                        <thead>
                        <tr>
                            <th style="width: 100px">Auswahl</th>
                            <th>Name</th>
                            <th># Wiederkehrende Aufgaben</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let document of recurringDocuments; let i = index;">
                            <td><input type="checkbox" [name]="'selected' + i" [(ngModel)]="document.selected"></td>
                            <td>{{ document.name }}</td>
                            <td>{{ document.recurringTasks }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="text-muted mt-1" *ngIf="recurringDocuments.length == 0">Es wurden keine Dokumente mit wiederkehrenden Aufgaben gefunden.</div>

                    <div class="alert alert-warning mt-3 mb-0" role="alert" *ngIf="waitingForPDF">
                        Ihr PDF wird erstellt, bitte warten Sie einen Moment.
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary pull-right" type="button" [disabled]="waitingForPDF" (click)="checkListModal.hide()">
                        <i class="fas fa-times"></i> Abbrechen
                    </button>
                    <button class="btn btn-primary pull-right" type="button" [disabled]="checkListForm.invalid || !documentsSelected() || waitingForPDF" (click)="createChecklist()">
                        <i class="fas fa-check"></i> Erstellen
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div bsModal #overviewRecurringModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" *ngIf="recurringDocuments">
            <form #overviewRecurringForm="ngForm" class="form">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Zusammenfassung wiederkehrender Aufgaben erstellen</h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="overviewRecurringModal.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Bitte die gewünschten Dokumente auswählen</p>

                    <button type="button" class="btn btn-primary btn-sm mr-2" (click)="selectAll()">Alle auswählen</button>
                    <button type="button" class="btn btn-primary btn-sm" (click)="deselectAll()">Keine Auswählen</button>

                    <table class="table table-striped table-sm mt-2 mb-0">
                        <thead>
                        <tr>
                            <th style="width: 100px">Auswahl</th>
                            <th>Name</th>
                            <th># Wiederkehrende Aufgaben</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let document of recurringDocuments; let i = index;">
                            <td><input type="checkbox" [name]="'selected' + i" [(ngModel)]="document.selected"></td>
                            <td>{{ document.name }}</td>
                            <td>{{ document.recurringTasks }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="text-muted mt-1" *ngIf="recurringDocuments.length == 0">Es wurden keine Dokumente mit wiederkehrenden Aufgaben gefunden.</div>

                    <div class="alert alert-warning mt-3 mb-0" role="alert" *ngIf="waitingForPDF">
                        Ihr PDF wird erstellt, bitte warten Sie einen Moment.
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary pull-right" type="button" [disabled]="waitingForPDF" (click)="overviewRecurringModal.hide()">
                        <i class="fas fa-times"></i> Abbrechen
                    </button>
                    <button class="btn btn-primary pull-right" type="button" [disabled]="overviewRecurringForm.invalid || !documentsSelected() || waitingForPDF" (click)="createOverviewRecurring()">
                        <i class="fas fa-check"></i> Erstellen
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

