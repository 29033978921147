import {Injectable, inject} from '@angular/core';
import {DataService} from './data.service';
import {Observable, of} from 'rxjs';
import {UserService} from './user.service';

/**
 * Service for storing any type of data in the database.
 * Currently only used for storing count of displayed table rows.
 */
@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private userService = inject(UserService);
    private dataService = inject(DataService);

    set(type: string, key: string, value: any): Observable<any> {
        if (this.userService.currentUser.loggedIn) {
            return this.dataService.request('framework.Storage/setData', {
                type,
                key,
                value
            });
        } else {
            return of(false);
        }
    }

    get(type: string, key: string): Observable<any> {
        if (this.userService.currentUser.loggedIn) {
            return this.dataService.request('framework.Storage/getData', {
                type,
                key
            });
        } else {
            return of(false);
        }
    }

}
