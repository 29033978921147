import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {UserService} from '../services/user.service';
import {JwtService, FwTokenType} from '../services/jwt.service';

export const TokenGuard: CanActivateFn = (route, state) => {

    const userService = inject(UserService);
    const jwtService = inject(JwtService);

    // if there is no token, get it, else do nothing
    if (!jwtService.getToken(FwTokenType.ACCESS_TOKEN)) {
        return userService.newAnonymous();
    } else {
        return true;
    }
}
