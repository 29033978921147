import {Component, Input, OnInit, Output, EventEmitter, inject} from '@angular/core';
import {DataService} from '../../services/data.service';
import {UserService} from '../../services/user.service';
import Swal from 'sweetalert2';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormService} from '../../services/form.service';
import {NgIf, NgFor, DatePipe} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RightPipe} from '../../pipes/right.pipe';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrl: './comment.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, DatePipe, TranslateModule, RightPipe]
})
export class CommentComponent implements OnInit {

    formService = inject(FormService);
    userService = inject(UserService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);

    @Input() TID: any = null;
    @Input() TDID: any = null;

    @Output() reloadEvent = new EventEmitter();

    comments: any[] = [];
    newComment!: string | null;
    showAddComment = false;
    form!: UntypedFormGroup;

    ngOnInit(): void {
        this.form = this.fb.group({
            comments: this.fb.array([])
        });

        this.listComments();
    }

    isOwn(comment: any): boolean {
        return comment.value.UID === this.userService.currentUser.UID;
    }

    add(): void {
        this.newComment = '';
        this.showAddComment = true;
    }

    cancelAdd(): void {
        this.showAddComment = false;
    }

    listComments(): void {
        this.dataService.request('Comment/list', {
            TID: this.TID,
            TDID: this.TDID
        }).subscribe(response => {
            this.comments = response.comments;

            this.patchForm();
        });
    }

    patchForm(): void {
        this.formService.getFormArray(this.form, 'comments').clear();
        this.comments.forEach(comment => {
            this.formService.getFormArray(this.form, 'comments').push(
                this.fb.group({
                    CMID: [comment.CMID || null],
                    UID: [comment.UID || null],
                    user: [comment.user || null],
                    created: [comment.created || null],
                    content: [comment.content || '', Validators.required],
                    _editing: [false]
                })
            );
        });
    }

    save(): void {
        this.dataService.request('Comment/save', {
            TID: this.TID,
            TDID: this.TDID,
            content: this.newComment
        }).subscribe(() => {
            this.newComment = null;
            this.showAddComment = false;
            this.listComments();
            this.reloadEvent.emit();
        });
    }

    delete(comment: any): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Das Kommentar wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('Comment/delete', {CMID: comment.value.CMID}).subscribe(() => {
                    this.listComments();
                    this.reloadEvent.emit();
                });
            }
        });
    }

    edit(comment: any): void {
        comment.patchValue({
            _editing: true
        });
    }

    cancelEdit(comment: any): void {
        const originalComment = this.comments.find(original => original.CMID === comment.value.CMID);
        comment.patchValue({
            content: originalComment.content,
            _editing: false
        });
    }

    update(comment: any): void {
        this.dataService.request('Comment/update', {
            CMID: comment.CMID,
            content: comment.content
        }).subscribe(() => {
            comment.patchValue({
                _editing: false
            });
        });
    }

}
