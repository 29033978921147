import {Injectable, inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {JwtService, FwTokenType} from './jwt.service';
import {myAppConfig} from '../../settings';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

interface User {
    UID?: string;
    firstname: string;
    lastname: string;
    email: string;
    language: string;
    loggedIn?: boolean;
    CLID?: string;
    client_name?: string;
    client_demo?: string;
    client_consulting?: string;
    client_labels?: string;
    rights?: string[];
    id_role?: string;
    role?: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private http = inject(HttpClient);
    private translateService = inject(TranslateService);
    private router = inject(Router);
    private jwtService = inject(JwtService);

    tokenChanged: Subject<any> = new Subject<string>();
    currentUser!: User;
    rights: any = {};
    adminToken: string = '';

    currentUID(): string {
        return this.currentUser.UID;
    }

    isOwner(UID: string): boolean {
        return UID === this.currentUID();
    }

    /**
     * Since the admin role always exists we can use this
     */
    isAdmin(): boolean {
        return this.currentUser.id_role === '1';
    }

    hasRight(right: string): boolean {
        return this.currentUser.rights.includes(right);
    }

    logout(): Observable<any> {
        return this.http
            .post('api/api.php', {
                action: 'framework.Auth/logout',
                data: {
                    accessToken: this.jwtService.getToken(FwTokenType.ACCESS_TOKEN)
                },
                componentName: 'UserService.ts',
                methodName: 'logout()'
            })
            .pipe(
                map((response: any) => {
                    return this.logoutAutomatic(response.jwt_token.accessToken, response.jwt_token.refreshToken);
                })
            );
    }

    logoutAutomatic(accessToken: string, refreshToken: string): Observable<any> {
        this.jwtService.setToken(FwTokenType.ACCESS_TOKEN, accessToken);
        this.jwtService.setToken(FwTokenType.REFRESH_TOKEN, refreshToken);
        this.router.navigate([myAppConfig.auth.afterLogoutDestination]).then(() => {
            this.setNobodyUinfo();
        });
        return of(true);
    }

    /**
     * Retrieves userdata for either anonymous or logged in user
     */
    getUserData(): Observable<any> {
        return this.http
            .post('api/api.php', {
                action: 'framework.Auth/getUserData',
                data: {
                    accessToken: this.jwtService.getToken(FwTokenType.ACCESS_TOKEN),
                    refreshToken: this.jwtService.getToken(FwTokenType.REFRESH_TOKEN)
                },
                componentName: 'UserService.ts',
                methodName: 'getUserData()'
            })
            .pipe(
                map((response: any) => {
                    if (response.jwt_token) {
                        this.jwtService.setToken(FwTokenType.ACCESS_TOKEN, response.jwt_token.accessToken);
                        this.jwtService.setToken(FwTokenType.REFRESH_TOKEN, response.jwt_token.refreshToken);
                    }

                    if (response.status === true) {
                        this.currentUser = response.user;
                        this.currentUser.loggedIn = true;
                        this.tokenChanged.next('loggedIn');
                    } else {
                        this.setNobodyUinfo();
                        this.tokenChanged.next('anon');
                    }
                    return true;
                })
            );
    }

    newAnonymous(): Observable<any> {
        return this.http
            .post('api/api.php', {
                action: 'framework.Auth/newAnonymous',
                data: {},
                componentName: 'UserService.ts',
                methodName: 'newAnonymous()'
            })
            .pipe(
                map((response: any) => {
                    this.jwtService.setToken(FwTokenType.ACCESS_TOKEN, response.jwt_token.accessToken);
                    this.jwtService.setToken(FwTokenType.REFRESH_TOKEN, response.jwt_token.refreshToken);
                    this.setNobodyUinfo();
                    this.tokenChanged.next('anon');
                    return true;
                })
            );
    }

    setNobodyUinfo(): void {
        const browserLanguage = this.translateService.getBrowserLang() ?? 'de';
        this.translateService.use(browserLanguage);

        this.currentUser = null;
    }

    userDisplayName(): string {
        return this.currentUser.firstname + ' ' + this.currentUser.lastname;
    }
}
