import {Injectable} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';

/**
 * The FormService simplifies handling FormGroups and FormArrays
 */
@Injectable({
    providedIn: 'root'
})
export class FormService {

    moveFormArrayEntry(form: UntypedFormGroup, control: string, currentIndex: number, shift: number): void {
        const formArray = this.getFormArray(form, control);

        let newIndex = currentIndex + shift;
        if (newIndex === -1) {
            newIndex = formArray.length - 1;
        } else if (newIndex === formArray.length) {
            newIndex = 0;
        }

        const currentElement = formArray.at(currentIndex);
        formArray.removeAt(currentIndex);
        formArray.insert(newIndex, currentElement);
    }

    removeFormArrayItem(form: UntypedFormGroup, control: string, index: number): void {
        this.getFormArray(form, control).removeAt(index);
    }

    getFormArray(form: UntypedFormGroup, control: string): UntypedFormArray {
        return form.get(control) as UntypedFormArray;
    }

    /**
     * Set multiple validators for multiple controls.
     */
    setValidators(form: UntypedFormGroup, controls: string[], validators: ValidatorFn[]): void {
        controls.forEach(control => {
            form.get(control)?.setValidators(validators);
            form.get(control)?.updateValueAndValidity();
        });
    }

    /**
     * Remove multiple validators for multiple controls.
     */
    removeValidators(form: UntypedFormGroup, controls: string[], validators: ValidatorFn[]): void {
        controls.forEach(control => {
            form.get(control)?.removeValidators(validators);
            form.get(control)?.updateValueAndValidity();
        });
    }

    /**
     * Clear validators for multiple controls.
     */
    clearValidators(form: UntypedFormGroup, controls: string[]): void {
        controls.forEach(control => {
            form.get(control)?.clearValidators();
            form.get(control)?.updateValueAndValidity();
        });
    }

    /**
     * Disable multiple controls.
     */
    disableControls(form: UntypedFormGroup, controls: string[]): void {
        controls.forEach(control => {
            form.get(control)?.disable();
        });
    }

    /**
     * Enable multiple controls.
     */
    enableControls(form: UntypedFormGroup, controls: string[]): void {
        controls.forEach(control => {
            form.get(control)?.enable();
        });
    }

    /**
     * Easy way to setup validators based on a condition
     *
     * @example wear_mask: [null, this.formService.conditionalValidator(() => this.form.get('adult').value === true, Validators.required)],
     * @source https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325
     * @param predicate The condition to check
     * @param validator The validator to apply
     */
    conditionalValidator(predicate: any, validator: any): Validators {
        return ((formControl: any) => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return validator(formControl);
            }
            return null;
        });
    }
}
