{
  "name": "noteradar",
  "version": "1.3.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o",
    "build-dev": "ng build",
    "build-prod": "ng build --configuration=production",
    "copy-dev": "node transfer.js dev",
    "copy-prod": "node transfer.js prod"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.5",
    "@angular/cdk": "^19.1.3",
    "@angular/common": "^19.1.5",
    "@angular/compiler": "^19.1.5",
    "@angular/core": "^19.1.5",
    "@angular/forms": "^19.1.5",
    "@angular/platform-browser": "^19.1.5",
    "@angular/platform-browser-dynamic": "^19.1.5",
    "@angular/router": "^19.1.5",
    "@coreui/angular": "^2.18.0",
    "@coreui/coreui-pro": "^2.1.16",
    "@coreui/icons": "1.0.1",
    "@fortawesome/fontawesome-free": "^6.7.2",
    "@googlemaps/markerclusterer": "^2.5.3",
    "@ngx-translate/core": "^16.0.4",
    "angular-calendar": "^0.31.1",
    "bootstrap": "^4.6.2",
    "chart.js": "^4.4.7",
    "cookieconsent": "^3.1.1",
    "crypto-es": "^2.1.0",
    "date-fns": "^4.1.0",
    "dayjs": "^1.11.13",
    "html2canvas": "^1.4.1",
    "jquery": "^3.7.1",
    "jwt-decode": "^4.0.0",
    "ng2-charts": "^8.0.0",
    "ng2-file-upload": "^8.0.0",
    "ngx-bootstrap": "^19.0.2",
    "ngx-cookieconsent": "^8.0.0",
    "ngx-matomo-client": "^7.0.1",
    "ngx-quill": "^27.0.0",
    "ngx-scrollbar": "^18.0.0",
    "ngx-toastr": "^19.0.0",
    "ngx-translate-multi-http-loader": "^19.0.2",
    "rxjs": "^7.8.1",
    "stacktrace-js": "^2.0.2",
    "sweetalert2": "^11.15.10",
    "tslib": "^2.5.2",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular/build": "^19.1.6",
    "@angular/cli": "^19.1.6",
    "@angular/compiler-cli": "^19.1.5",
    "@types/google.maps": "^3.58.1",
    "@types/node": "^20.16.10",
    "node-ssh": "^13.2.0",
    "typescript": "~5.5.4",
    "zip-a-folder": "^3.1.8"
  }
}
