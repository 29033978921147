import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-visibility-group',
    templateUrl: './visibility-group.component.html',
    styleUrl: './visibility-group.component.scss',
    imports: [RouterOutlet]
})
export class VisibilityGroupComponent {

}
