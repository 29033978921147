<div class="footer text-center">
    <div class="container">
        <ul class="list-inline mb-0">
            <!--<li class="list-inline-item"><a routerLink="/faq">FAQ</a></li>-->
            <li class="list-inline-item"><a routerLink="/imprint">Impressum</a></li>
            <li class="list-inline-item"><a routerLink="/privacy">Datenschutz</a></li>
            <li class="list-inline-item"><a routerLink="/terms">AGB</a></li>
        </ul>
    </div>
</div>
