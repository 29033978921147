import {Component, OnInit, inject, OnDestroy} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {Router, RouterLink} from '@angular/router';
import {CryptoService} from '../../../global/services/crypto.service';
import {UserService} from '../../../global/services/user.service';
import Swal from 'sweetalert2';
import {ViewMode} from '../../../interfaces';
import {FwFileViewLayout, FileComponent} from '../../../global/components/file/file.component';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {
    TableColumnFilterType,
    TableConfig,
    TableDesign,
    TableFilterOperator,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {ToastrService} from 'ngx-toastr';
import {NgIf, NgFor} from '@angular/common';
import {TableComponent} from '../../../global/components/table/table.component';
import {TranslateModule} from '@ngx-translate/core';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {YesNoPipe} from '../../../global/pipes/yes-no.pipe';
import {Subscription} from "rxjs";

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrl: './user-detail.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, RouterLink, TableComponent, FileComponent, TranslateModule, RightPipe, YesNoPipe]
})
export class UserDetailComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    routerHelperService = inject(RouterHelperService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);
    private toastrService = inject(ToastrService);

    readonly ViewMode = ViewMode;
    readonly FileViewLayout = FwFileViewLayout;

    tableConfig!: TableConfig;
    form!: UntypedFormGroup;
    user: any = {};
    UID!: string;
    viewMode!: ViewMode;
    showDisableCard = false;
    fileShareCount = 0;
    roles: any[] = [];
    roleText!: string;
    private roleChanges$!: Subscription;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.UID = urlData.ID;
        this.viewMode = urlData.viewMode;

        let password;
        let confirmPassword;
        if (this.UID === '0') {
            password = new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
            confirmPassword = new UntypedFormControl('', [
                Validators.required,
                (confirmControl: UntypedFormControl): ValidationErrors | null => {
                    if (!confirmControl.parent) {
                        return null;
                    }

                    const passwordControl = confirmControl.parent.get('password');
                    if (!passwordControl || !confirmControl) return null;
                    return passwordControl.value === confirmControl.value ? null : {nomatch: true};
                }]
            );
        } else {
            password = new UntypedFormControl('', Validators.compose([Validators.minLength(6)]));
            confirmPassword = new UntypedFormControl('', [
                (confirmControl: AbstractControl): ValidationErrors | null => {
                    if (!confirmControl.parent) {
                        return null;
                    }

                    const passwordControl = confirmControl.parent.get('password');
                    if (!passwordControl || !confirmControl) return null;
                    return passwordControl.value === confirmControl.value ? null : {nomatch: true};
                }]
            );
        }

        this.form = this.fb.group({
            UID: null,
            CLID: [this.userService.currentUser.CLID],
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', Validators.required],
            id_role: ['', Validators.required],
            role: [''],
            active: [true],
            password,
            confirmPassword,
            sendMail: [false],
        });

        if (this.UID !== '0') {
            this.getUser();
        }

        this.dataService.request('Role/listAll').subscribe(response => {
            this.roles = response.roles;
            if (this.user) {
                this.updateRoleText(this.user.id_role);
            }
        })

        this.roleChanges$ = this.form.get('id_role').valueChanges.subscribe(val => {
            this.updateRoleText(val);
        });
    }

    ngOnDestroy(): void {
        this.roleChanges$.unsubscribe();
    }

    getUser(): void {
        this.dataService.request('User/get', {UID: this.UID}).subscribe(response => {
            this.user = response.user;
            this.user.tasks_responsible = parseInt(this.user.tasks_responsible, 10);
            this.user.tasks_external = parseInt(this.user.tasks_external, 10);
            this.user.totalTasks = this.user.tasks_responsible + this.user.tasks_external;

            this.patchForm();
            this.updateRoleText(this.user.id_role);
        });
    }

    patchForm(): void {
        this.form.patchValue({
            UID: this.user.UID,
            CLID: this.user.CLID,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            email: this.user.email,
            id_role: this.user.id_role,
            role: this.user.role,
            active: this.user.active === '1',
        });
    }

    save(): void {
        this.dataService.request('User/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Benutzer wurde gespeichert');
            this.router.navigate(['/app/user/list']);
        });
    }

    update(): void {
        this.dataService.request('User/update', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Benutzer wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
            this.getUser();
        });
    }

    cancel(): void {
        this.router.navigate(['/app/user/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    showDisable(): void {
        this.showDisableCard = true;

        this.tableConfig = {
            design: TableDesign.NONE,
            title: '',
            openDatasetRouterLink: '/app/task/detail',
            enableCreate: false,
            enableExportExcel: true,
            dataConfig: {
                tableName: 'task',
                tableIndexName: 'TID',
                tableJoins: [
                    {left: 'document', right: 'task', key: 'DID'},
                    {left: 'location', right: 'document', key: 'LID'},
                    {left: 'department', right: 'task', key: 'DPID'},
                    {left: 'tasktopic', right: 'task', key: 'TOID'},
                    {left: 'tasktype', right: 'task', key: 'TTID'},
                ],
                tableFilter: [],
                tableFields: [
                    {key: 'number', name: 'number', title: 'Nummer', sortable: true, searchable: true},
                    {key: 'title', name: 'title', title: 'Titel', sortable: true, searchable: true},
                    {
                        key: 'type',
                        name: 'type',
                        title: 'Fälligkeit',
                        sortable: true,
                        translate: true,
                        translateValuePrefix: 'DUETYPE'
                    },
                    {
                        key: 'location',
                        table: 'location',
                        name: 'name',
                        title: 'LOCATION',
                        translateTitle: true,
                        translateTitlePrefix: 'LOCATION',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_wait',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "wait" AND TID = task.TID)',
                        title: 'Termine warten',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_open',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "open" AND TID = task.TID)',
                        title: 'Termine offen',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_progress',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "in_progress" AND TID = task.TID)',
                        title: 'Termine in Bearbeitung',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_done',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID = task.TID)',
                        title: 'Termine erledigt',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'created',
                        name: 'created',
                        title: 'Erstellt am',
                        sortable: true,
                        searchable: true,
                        dateFormat: {en: '%Y.%m.%d', others: '%d.%m.%Y'}
                    },
                    {
                        key: 'document',
                        table: 'document',
                        name: 'name',
                        title: 'Dokument',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'department',
                        table: 'department',
                        name: 'name',
                        title: 'Abteilung',
                        sortable: true,
                        searchable: true
                    },
                    {key: 'topic', table: 'tasktopic', name: 'name', title: 'Thema', sortable: true, searchable: true},
                    {key: 'tasktype', table: 'tasktype', name: 'name', title: 'Typ', sortable: true, searchable: true},
                ],
                rowImage: [
                    {
                        key: 'status_wait',
                        icon: 'fa-circle',
                        color: '#cccccc',
                        conditions: [
                            {key: 'status_wait', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_open',
                        icon: 'fa-circle',
                        color: '#f86c6b',
                        conditions: [
                            {key: 'status_open', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_progress',
                        icon: 'fa-circle',
                        color: '#ffc107',
                        conditions: [
                            {
                                key: 'status_progress',
                                value: '0',
                                operator: TableRowColorConditionOperator.SMALLER_INT
                            },
                        ]
                    },
                    {
                        key: 'status_done',
                        icon: 'fa-circle',
                        color: '#4dbd74',
                        conditions: [
                            {key: 'status_done', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                ],
                reverse: false,
                order: 'created',
                columnFilter: {
                    enabled: true,
                    fields: {
                        title: {value: '', type: TableColumnFilterType.INPUT},
                        type: {value: '', type: TableColumnFilterType.GROUP},
                        location: {value: '', type: TableColumnFilterType.GROUP},
                        status_wait: {value: '', type: TableColumnFilterType.INPUT},
                        status_open: {value: '', type: TableColumnFilterType.INPUT},
                        status_progress: {value: '', type: TableColumnFilterType.INPUT},
                        status_done: {value: '', type: TableColumnFilterType.INPUT},
                        document: {value: '', type: TableColumnFilterType.INPUT},
                        department: {value: '', type: TableColumnFilterType.GROUP},
                        topic: {value: '', type: TableColumnFilterType.GROUP},
                        tasktype: {value: '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'task-list-user',
                enabled: true
            },
            paginConfig: {
                id: 'taskList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };

        if (this.userService.hasRight('TASKS_ONLY_EXTERNAL_VIEW')) {
            this.tableConfig.dataConfig.tableFilter = [{
                table: 'task', field: 'UID_external', values: [this.user.UID], operator: TableFilterOperator.EQUAL
            }];
        } else if (this.userService.hasRight('TASKS_ONLY_OWN_VIEW')) {
            this.tableConfig.dataConfig.tableFilter = [{
                table: 'department', field: 'UID', values: [this.user.UID], operator: TableFilterOperator.EQUAL
            }];
        }
    }

    cancelDisable(): void {
        this.showDisableCard = false;
    }

    disable(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Der Benutzer wird deaktiviert.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('User/disable', {
                    UID: this.user.UID,
                }).subscribe(() => {
                    this.form.patchValue({
                        active: false,
                    });
                    this.showDisableCard = false;
                    this.toastrService.success('Der Benutzer wurde deaktiviert');
                });
            }
        });
    }

    enable(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Der Benutzer wird aktiviert.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('User/enable', {
                    UID: this.user.UID,
                }).subscribe(() => {
                    this.form.patchValue({
                        active: true,
                    });
                    this.toastrService.success('Der Benutzer wurde aktiviert');
                });
            }
        });
    }

    updateRoleText(id_role: any) {
        this.roleText = this.roles.find(role => role.id === id_role)?.description;
    }
}
