import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'paymentType',
    standalone: true
})
export class PaymentTypePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === 'invoice') {
            return 'Überweisung';
        } else if (value === 'sepa') {
            return 'SEPA';
        } else {
            return '';
        }
    }

}
