<div class="container">
    <div class="row">
        <div class="col">
            <h1 class="display-4 text-center my-3">Impressum</h1>

            <p class="font-weight-bold">Für diese Website verantwortlicher Herausgeber</p>

            <p>
                NoteRadar GmbH<br/>
                UID-Nr. ATU76716957
            </p>

            <p class="font-weight-bold">Firmensitz laut Firmenbuch oder Standort der Gewerbeberechtigung</p>

            <p>
                Badener Straße 6/1b, A-2512 Tribuswinkel, Austria<br/>
                E-Mail: <a href="mailto:office@noteradar.at">office&#64;noteradar.at</a><br/>
                Telefon: +43 677 67701630<br/>
                Internet: <a href="https://www.noteradar.at">www.noteradar.at</a>
            </p>

            <p class="font-weight-bold">Angaben des Firmenbuches</p>

            <p>Firmenbuchnummer FN 553240p</p>

            <p class="font-weight-bold">Für das Firmenbuch zuständige Gericht</p>

            <p>Handelsgericht Wr. Neustadt</p>

            <p class="font-weight-bold">Bankverbindung</p>

            <p>
                Sparkasse Neunkirchen<br/>
                IBAN: AT76 2024 1050 0008 6149<br/>
                BIC: SPNGAT21XXX
            </p>

            <p class="font-weight-bold">Bildernachweis</p>

            <p>https://unsplash.com/</p>

            <p class="font-weight-bold">Webdesign / Entwicklung</p>

            <p>2021-{{ currentYear }} by NoteRadar GmbH</p>
        </div>
    </div>
</div>
