import {ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpBackend, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {BrowserAnimationsModule, provideAnimations} from "@angular/platform-browser/animations";
import {registerLocaleData} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {provideRouter, withRouterConfig} from "@angular/router";
import localeDE from '@angular/common/locales/de';

import {NgcCookieConsentConfig, NgcCookieConsentModule} from "ngx-cookieconsent";
import {ModalModule} from "ngx-bootstrap/modal";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {provideToastr} from "ngx-toastr";
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateLoader,
    TranslateModule
} from "@ngx-translate/core";
import {provideCharts, withDefaultRegisterables} from "ng2-charts";
import {provideMatomo, withRouter} from "ngx-matomo-client";
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";

import {environment} from "../environments/environment";
import {routes} from "./app.routes";
import {UserService} from "./global/services/user.service";
import {TokenInterceptor} from "./global/guards/token.interceptor";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {SidebarNavHelper} from "@coreui/angular";
import {QuillModule} from "ngx-quill";

export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/i18n/', suffix: '.json'},
    ]);
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams): string {
        if (!environment.production) {
            console.log('Missing translation for "' + params.key + '"');
        }
        return params.key;
    }
}

const cookieConfig: NgcCookieConsentConfig = {
    enabled: environment.production,
    cookie: {
        domain: environment.domain
    },
    position: 'bottom-left',
    theme: 'classic',
    palette: {
        popup: {
            background: '#000000',
            text: '#ffffff',
            link: '#ffffff'
        },
        button: {
            background: '#f1d600',
            text: '#000000',
            border: 'transparent'
        }
    },
    type: 'info',
    content: {
        message: 'Diese Webseite verwendet Cookies. Genauere Informationen findest du in unserer Datenschutzerklärung.',
        dismiss: 'Ich stimme zu',
        deny: 'Refuse cookies',
        link: 'Mehr erfahren',
        href: 'privacy',
        policy: 'Cookie Policy'
    }
};

registerLocaleData(localeDE);

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({
            eventCoalescing: true
        }),
        provideRouter(routes,
            withRouterConfig({
                onSameUrlNavigation: 'reload'
            })
        ),
        provideHttpClient(withInterceptorsFromDi()),
        provideCharts(withDefaultRegisterables()),
        provideAnimations(),
        provideToastr(),
        provideMatomo({
            disabled: environment.matomoTrackerUrl === '',
            siteId: environment.matomoSiteId,
            trackerUrl: environment.matomoTrackerUrl,
        }, withRouter()),
        importProvidersFrom(
            NgcCookieConsentModule.forRoot(cookieConfig),
            BrowserModule,
            FormsModule,
            ReactiveFormsModule,
            BrowserAnimationsModule, // required for angular-calendar
            QuillModule.forRoot(),
            ModalModule.forRoot(),
            TooltipModule.forRoot(),
            AccordionModule.forRoot(),
            TypeaheadModule.forRoot(),
            PaginationModule.forRoot(),
            CalendarModule.forRoot({
                provide: DateAdapter,
                useFactory: adapterFactory
            }),
            TranslateModule.forRoot({
                missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpBackend]
                }
            })
        ),
        UserService,
        SidebarNavHelper,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'de'
        },
    ]
}
