import {Component, OnInit, inject} from '@angular/core';
import {DataService} from '../../services/data.service';
import {UserService} from '../../services/user.service';
import {RouterHelperService} from '../../services/router-helper.service';
import {Router, RouterLink} from '@angular/router';
import {
    TableColumnFilterType,
    TableConfig,
    TableFilterOperator,
    TableRowColorConditionOperator
} from '../table/table.interfaces';
import {ViewMode, UrlData} from '../../../interfaces';
import dayjs from 'dayjs';
import {CryptoService} from '../../services/crypto.service';
import {NgIf, NgFor, NgClass, DatePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ActivityComponent} from '../activity/activity.component';
import {TableComponent} from '../table/table.component';
import {TranslateModule} from '@ngx-translate/core';
import {TaskTypePipe} from '../../pipes/task-type.pipe';
import {RightPipe} from '../../pipes/right.pipe';

@Component({
    selector: 'app-dashboard-custom', // INFO: renamed to avoid conflict with CoreUI style naming
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss',
    imports: [NgIf, FormsModule, RouterLink, NgFor, NgClass, TooltipModule, ActivityComponent, TableComponent, DatePipe, TranslateModule, TaskTypePipe, RightPipe]
})
export class DashboardComponent implements OnInit {

    userService = inject(UserService);
    routerHelperService = inject(RouterHelperService);
    private dataService = inject(DataService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);

    count: any = {};
    taskCount = 0;
    taskStatus: any = {};
    percent: any;
    companies!: any[];
    search = '';
    sums: any = {};
    tasksTableConfig!: TableConfig;
    uncollapsed!: string | null;

    taskDatesCompleted!: any[];
    taskDatesProgress!: any[];
    taskDatesOpen!: any[];
    taskDatesWaiting!: any[];
    taskDatesFuture!: any[];

    ngOnInit(): void {
        this.dataService.request('Dashboard/getData').subscribe(response => {
            this.count = response.count;
            this.companies = response.companies;

            this.taskDatesCompleted = response.taskDatesCompleted;

            if (response.taskDatesProgress) {
                this.taskDatesProgress = response.taskDatesProgress;
                this.taskDatesProgress.forEach(taskdate => {
                    taskdate.overdue = taskdate.overdue === '1';
                });
            }

            if (response.taskDatesOpen) {
                this.taskDatesOpen = response.taskDatesOpen;
                this.taskDatesOpen.forEach(taskdate => {
                    taskdate.overdue = taskdate.overdue === '1';
                });
            }

            this.taskDatesWaiting = response.taskDatesWaiting;
            this.taskDatesFuture = response.taskDatesFuture;

            ['locations', 'documents', 'tasks', 'wait', 'open', 'in_progress', 'done'].forEach(type => {
                this.sums[type] = this.companies.reduce((prev: number, next: any) => {
                    return prev + parseInt(next[type], 10);
                }, 0);
            });

            /*this.taskStatus = response.taskStatus;
            this.taskCount = parseInt(response.taskStatus.wait, 10) + parseInt(response.taskStatus.open, 10) + parseInt(response.taskStatus.in_progress, 10) + parseInt(response.taskStatus.done, 10);

            this.percent = {
                wait: {width: Math.round(this.taskStatus.wait / this.taskCount * 100) + '%'},
                open: {width: Math.round(this.taskStatus.open / this.taskCount * 100) + '%'},
                in_progress: {width: Math.round(this.taskStatus.in_progress / this.taskCount * 100) + '%'},
                done: {width: Math.round(this.taskStatus.done / this.taskCount * 100) + '%'}
            };*/
        });

        this.setupTasksTable();
    }

    uncollapse(type: string): void {
        this.uncollapsed = this.uncollapsed === type ? null : type;
    }

    openTaskDate(taskDate: any): void {
        const urlData: UrlData = {
            ID: taskDate.TDID,
            data: {
                TID: taskDate.TID
            },
            viewMode: taskDate.TDID === '0' ? ViewMode.INSERT : ViewMode.VIEW,
        };

        // pass start_date and end_date of generated taskdates
        if (taskDate.TDID === '0') {
            // there is no start_date for singular tasks
            urlData.data.start_date = taskDate.start_date ? dayjs(taskDate.start_date).format('YYYY-MM-DD') : null;
            urlData.data.end_date = dayjs(taskDate.end_date).format('YYYY-MM-DD');
        }
        this.router.navigate(['/app/taskdate/detail/', this.cryptoService.encrypt(urlData)]);
    }

    searchNow(): void {
        this.router.navigate(['app/document/list'], {queryParams: {search: this.search}});
    }

    private setupTasksTable(): void {
        if (!this.userService.isAdmin() && this.userService.hasRight('TASKS_TABLE_DASHBOARD_VIEW')) {
            this.tasksTableConfig = {
                titleIcon: 'fas fa-tasks',
                title: 'Aufgaben',
                openDatasetRouterLink: '/app/task/detail',
                enableCreate: false,
                enableExportExcel: true,
                description: 'Das sind Ihre zugewiesenen Aufgaben zu den hochgeladenen Dokumenten.',
                dataConfig: {
                    tableName: 'task',
                    tableIndexName: 'TID',
                    tableJoins: [
                        {left: 'document', right: 'task', key: 'DID'},
                        {left: 'location', right: 'document', key: 'LID'},
                        {left: 'department', right: 'task', key: 'DPID'},
                        {left: 'tasktopic', right: 'task', key: 'TOID'},
                        {left: 'tasktype', right: 'task', key: 'TTID'},
                    ],
                    tableFilter: [
                        {table: 'document', field: 'deleted', values: ['0'], operator: TableFilterOperator.EQUAL}
                    ],
                    tableFields: [
                        {key: 'number', name: 'number', title: 'Nummer', sortable: true, searchable: true},
                        {key: 'title', name: 'title', title: 'Titel', sortable: true, searchable: true},
                        {
                            key: 'type',
                            name: 'type',
                            title: 'Fälligkeit',
                            sortable: true,
                            translate: true,
                            translateValuePrefix: 'DUETYPE'
                        },
                        {
                            key: 'location',
                            table: 'location',
                            name: 'name',
                            title: 'LOCATION',
                            translateTitle: true,
                            translateTitlePrefix: 'LOCATION',
                            sortable: true,
                            searchable: true
                        },
                        {
                            key: 'status_wait',
                            subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "wait" AND TID = task.TID)',
                            title: 'Termine warten',
                            sortable: true,
                            searchable: true
                        },
                        {
                            key: 'status_open',
                            subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "open" AND TID = task.TID)',
                            title: 'Termine offen',
                            sortable: true,
                            searchable: true
                        },
                        {
                            key: 'status_progress',
                            subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "in_progress" AND TID = task.TID)',
                            title: 'Termine in Bearbeitung',
                            sortable: true,
                            searchable: true
                        },
                        {
                            key: 'status_done',
                            subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID = task.TID)',
                            title: 'Termine erledigt',
                            sortable: true,
                            searchable: true
                        },
                        {
                            key: 'created',
                            name: 'created',
                            title: 'Erstellt am',
                            sortable: true,
                            searchable: true,
                            dateFormat: {en: '%Y.%m.%d', others: '%d.%m.%Y'}
                        },
                        {
                            key: 'document',
                            table: 'document',
                            name: 'name',
                            title: 'Dokument',
                            sortable: true,
                            searchable: true
                        },
                        {
                            key: 'department',
                            table: 'department',
                            name: 'name',
                            title: 'Abteilung',
                            sortable: true,
                            searchable: true
                        },
                        {
                            key: 'topic',
                            table: 'tasktopic',
                            name: 'name',
                            title: 'Thema',
                            sortable: true,
                            searchable: true
                        },
                        {
                            key: 'tasktype',
                            table: 'tasktype',
                            name: 'name',
                            title: 'Typ',
                            sortable: true,
                            searchable: true
                        },
                    ],
                    rowImage: [
                        {
                            key: 'status_wait',
                            icon: 'fa-circle',
                            color: '#cccccc',
                            conditions: [
                                {
                                    key: 'status_wait',
                                    value: '0',
                                    operator: TableRowColorConditionOperator.SMALLER_INT
                                },
                            ]
                        },
                        {
                            key: 'status_open',
                            icon: 'fa-circle',
                            color: '#f86c6b',
                            conditions: [
                                {
                                    key: 'status_open',
                                    value: '0',
                                    operator: TableRowColorConditionOperator.SMALLER_INT
                                },
                            ]
                        },
                        {
                            key: 'status_progress',
                            icon: 'fa-circle',
                            color: '#ffc107',
                            conditions: [
                                {
                                    key: 'status_progress',
                                    value: '0',
                                    operator: TableRowColorConditionOperator.SMALLER_INT
                                },
                            ]
                        },
                        {
                            key: 'status_done',
                            icon: 'fa-circle',
                            color: '#4dbd74',
                            conditions: [
                                {
                                    key: 'status_done',
                                    value: '0',
                                    operator: TableRowColorConditionOperator.SMALLER_INT
                                },
                            ]
                        },
                    ],
                    reverse: false,
                    order: 'created',
                    columnFilter: {
                        enabled: true,
                        fields: {
                            title: {value: '', type: TableColumnFilterType.INPUT},
                            type: {value: '', type: TableColumnFilterType.GROUP},
                            location: {value: '', type: TableColumnFilterType.GROUP},
                            status_wait: {value: '', type: TableColumnFilterType.INPUT},
                            status_open: {value: '', type: TableColumnFilterType.INPUT},
                            status_progress: {value: '', type: TableColumnFilterType.INPUT},
                            status_done: {value: '', type: TableColumnFilterType.INPUT},
                            document: {value: '', type: TableColumnFilterType.INPUT},
                            department: {value: '', type: TableColumnFilterType.GROUP},
                            topic: {value: '', type: TableColumnFilterType.GROUP},
                            tasktype: {value: '', type: TableColumnFilterType.GROUP},
                        }
                    }
                },
                localStorage: {
                    version: 1,
                    name: 'task-list',
                    enabled: false
                },
                paginConfig: {
                    id: 'taskList',
                    rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                    itemsPerPageDb: true,
                    itemsPerPage: 10,
                    currentPage: 1,
                }
            };

            if (this.userService.hasRight('TASKS_ONLY_OWN_VIEW')) {
                this.tasksTableConfig.dataConfig.tableFilter.push({
                    table: 'department',
                    field: 'UID',
                    values: [this.userService.currentUser.UID],
                    operator: TableFilterOperator.EQUAL
                });
            } else if (this.userService.hasRight('TASKS_ONLY_EXTERNAL_VIEW')) {
                this.tasksTableConfig.dataConfig.tableFilter.push({
                    table: 'task',
                    field: 'UID_external',
                    values: [this.userService.currentUser.UID],
                    operator: TableFilterOperator.EQUAL
                });
            }
        }
    }
}
