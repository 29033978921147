<div class="card">
    <div class="card-header">
        <i class="fas fa-user-lock mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.INSERT">Benutzerrolle anlegen</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Benutzerrolle bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Benutzerrolle</ng-container>
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.name }}</p>
                    </div>
                </div>

                <div class="col" *ngIf="viewMode != ViewMode.INSERT">
                    <div class="form-group">
                        <label>{{role?.users.length}} Benutzer mit dieser Rolle</label>
                        <ul>
                            <li *ngFor="let user of role?.users">{{user.firstname}} {{user.lastname}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label>Beschreibung</label>
                <textarea class="form-control" formControlName="description" *ngIf="viewMode != ViewMode.VIEW" rows="4"></textarea>
                <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.description }}</p>
            </div>

        </form>

        <ng-container *ngIf="viewMode == ViewMode.VIEW">
            <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'ADMIN_ACCESS' | right" *ngIf="userService.hasRight('ADMIN_ACCESS')">Bearbeiten</button>
            <button type="button" class="btn btn-secondary" (click)="cancel()">Zurück</button>
            <button type="button" class="btn btn-info ml-2" (click)="copy()" [attr.data-right]="'ADMIN_ACCESS' | right" *ngIf="userService.hasRight('ADMIN_ACCESS')">Kopieren</button>
            <button type="button" class="btn btn-danger float-right"
                    [attr.data-right]="'ADMIN_ACCESS' | right"
                    *ngIf="userService.hasRight('ADMIN_ACCESS') && role?.id != 1"
                    (click)="delete()"
                    [disabled]="role?.used > 0"
                    [tooltip]="role?.used > 0 ? 'Dieses Benutzerrolle wird noch verwendet und kann daher nicht gelöscht werden.' : ''"
            >Löschen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.INSERT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
        </ng-container>

    </div>
</div>

<div class="card" *ngIf="viewMode != ViewMode.INSERT && role?.name !== 'Administrator'">
    <div class="card-header">
        <i class="fas fa-circle-check mr-2"></i>
        Rechte
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-4">
                <div class="list-group">
                    <button type="button" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                            (click)="setCurrentGroup(group)"
                            [class.active]="currentGroup == group"
                            *ngFor="let group of rightGroups">
                        <div class="ms-2 me-auto">
                            {{ group.name }}
                        </div>
                        <span class="badge bg-dark rounded-pill">{{ group.granted }}/{{ group.count }}</span>
                    </button>
                </div>
            </div>
            <div class="col-8">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Erlaubt</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let right of rights">
                            <td>{{right.name}}</td>
                            <td style="width: 100px">
                                <input type="checkbox" [(ngModel)]="right.granted" (ngModelChange)="updateGroupGrantedCount()">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="button" class="btn btn-primary" (click)="saveRights()">Speichern</button>
                <button type="button" class="btn btn-secondary float-right" (click)="toggle(true)">Alle</button>
                <button type="button" class="btn btn-secondary float-right mr-2" (click)="toggle(false)">Keine</button>
            </div>
        </div>
    </div>
</div>
