import {Component, OnInit, inject} from '@angular/core';
import {DataService} from '../../services/data.service';
import {TranslateService} from "@ngx-translate/core";
import {NgIf, NgFor} from '@angular/common';
import {BaseChartDirective} from 'ng2-charts';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrl: './overview.component.scss',
    imports: [NgIf, NgFor, BaseChartDirective]
})
export class OverviewComponent implements OnInit {

    private dataService = inject(DataService);
    private translateService = inject(TranslateService);

    stats: any;
    documents: any;
    tasks: any;

    charts: any[] = [];

    ngOnInit(): void {
        this.dataService.request('Overview/getStats').subscribe(response => {
            this.stats = response.stats;
            this.documents = response.documents;
            this.tasks = response.tasks;
            this.createCharts();
        });
    }

    createCharts(): void {

        const keyMapping: any = {
            'filetypes': {
                'name': 'Nach Dokumenttyp',
                'category': 'documents',
            },
            'companies': {
                'name': 'Nach Unternehmen',
                'category': 'documents',
            },
            'locations': {
                'name': 'Nach ' + this.translateService.instant('LOCATION.LOCATION'),
                'category': 'documents',
            },
            'topics': {
                'name': 'Nach Thema',
                'category': 'tasks',
            },
            'types': {
                'name': 'Nach Typ',
                'category': 'tasks',
            },
            'departments': {
                'name': 'Nach Abteilung',
                'category': 'tasks',
            },
        }
        for (let [key, value] of Object.entries(this.stats)) {
            // @ts-ignore
            const data = value as any[];
            const chart = {
                name: keyMapping[key].name ?? key,
                category: keyMapping[key].category ?? 'default',
                data: {
                    labels: data.map(filetype => filetype.name ?? 'Nicht gesetzt'),
                    datasets: [
                        {
                            data: data.map(filetype => filetype.count),
                        }
                    ],
                },
                options: {
                    plugins: {
                        legend: {
                            position: 'bottom'
                        }
                    },
                },
                type: 'pie',
            };

            this.charts.push(chart);
        }
    }

}
