import {Component, inject, Input, OnInit} from '@angular/core';
import dayjs from 'dayjs';
import {DataService} from "../../../global/services/data.service";
import {NgFor, NgIf, NgTemplateOutlet, NgClass, DatePipe} from '@angular/common';
import {TaskStatusPipe} from '../../../global/pipes/task-status.pipe';

enum ActivityType {
    COMMENT_TASK = 'comment_task',
    COMMENT_TASKDATE = 'comment_taskdate',
    CREATE_DOCUMENT = 'create_document',
    CREATE_TASK = 'create_task',
    CREATE_TASKDATE = 'create_taskdate',
    COMPLETE_TASK_CREATION = 'complete_task_creation',
    STATUS_CHANGE = 'status_change',
    ATTACHMENT_DOCUMENT = 'attachment_document',
    ATTACHMENT_TASK = 'attachment_task',
    ATTACHMENT_TASKDATE = 'attachment_taskdate',
}

@Component({
    selector: 'app-admin-client-activity',
    templateUrl: './admin-client-activity.component.html',
    styleUrls: ['./admin-client-activity.component.scss'],
    imports: [NgFor, NgIf, NgTemplateOutlet, NgClass, DatePipe, TaskStatusPipe]
})
export class AdminClientActivityComponent implements OnInit {

    readonly ActivityType = ActivityType;

    private dataService = inject(DataService);

    @Input({required: true}) CLID!: string;

    activities: any[] = [];
    loading = false;

    ngOnInit(): void {
        this.loadActivities();
    }

    loadActivities(): void {
        this.activities = [];
        this.loading = true;
        this.dataService.request("universe.Admin/listAllActivities", {
            CLID: this.CLID
        }).subscribe(response => {
            this.loading = false;
            this.activities = response.activities;

            this.activities.forEach(activity => {
                activity.diff = dayjs().diff(dayjs(activity.created, "YYYY-MM-DD HH:mm:ss"), "minutes");
                activity.created = dayjs(activity.created, "YYYY-MM-DD HH:mm:ss").toDate();
            });
        });
    };

}
