<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <form>
                                    <h1>Anmelden</h1>
                                    <form #loginForm="ngForm" (submit)="login()" class="pt-3">
                                        <div class="form-group">
                                            <input type="email" class="form-control" name="username"
                                                   [(ngModel)]="loginData.username"
                                                   [disabled]="loginInProgress"
                                                   placeholder="E-Mail"
                                                   email
                                                   autocomplete="off" required
                                                   appAutofocus>
                                        </div>
                                        <div class="form-group">
                                            <input type="password" class="form-control" name="password"
                                                   [(ngModel)]="loginData.password"
                                                   [disabled]="loginInProgress"
                                                   placeholder="Passwort"
                                                   autocomplete="off" required>
                                        </div>
                                        <button [disabled]="loginInProgress || loginForm.invalid" type="submit" class="btn btn-primary btn-block">Anmelden</button>
                                        <button [disabled]="loginInProgress" type="button" class="btn btn-secondary btn-block" (click)="openForgotPasswordModal()">Passwort vergessen</button>
                                    </form>

                                    <ng-template #deniedTemplate>
                                        <div class="modal-header bg-danger text-white">
                                            <h4 class="modal-title pull-left"><i class="fa fa-warning"></i> Anmeldefehler</h4>
                                        </div>
                                        <div class="modal-body">
                                            {{deniedMessage}}
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" appAutofocus (click)="modalDeniedRef?.hide()">OK</button>
                                        </div>
                                    </ng-template>

                                    <ng-template #resetTemplate>
                                        <div class="modal-header bg-danger text-white">
                                            <h4 class="modal-title pull-left"><i class="fa fa-warning"></i> Passwort zurücksetzen</h4>
                                        </div>
                                        <div class="modal-body">
                                            Sie erhalten eine Mail mit einem Link um das Passwort zurückzusetzen
                                            <input type="email" class="form-control mt-1" required
                                                   email
                                                   #resetPasswordInput="ngModel"
                                                   [(ngModel)]="resetPasswordMail"
                                                   placeholder="E-Mail eingeben"
                                                   (keyup.enter)="resetPasswordInput.valid ? resetPasswordNow() : null"/>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-primary" [disabled]="resetPasswordInput.invalid" (click)="resetPasswordNow()">Senden</button>
                                            <button type="button" class="btn btn-secondary" (click)="modalResetRef?.hide()">Abbrechen</button>
                                        </div>
                                    </ng-template>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
