import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import {allNavItems} from './_nav';
import {UrlData} from '../../../interfaces';
import {Router, RouterLinkActive, RouterLink, RouterOutlet} from '@angular/router';
import {UserService} from '../../../global/services/user.service';
import {CryptoService} from '../../../global/services/crypto.service';
import {DataService} from '../../../global/services/data.service';
import {ZeiFeedback} from '../../../feedback/feedback';
import {ToastrService} from 'ngx-toastr';
import {
    INavData,
    AppHeaderComponent,
    AppSidebarComponent,
    AppSidebarNavComponent,
    AppSidebarMinimizerComponent,
    AppAsideComponent
} from '@coreui/angular';
import {environment} from '../../../../environments/environment';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {Subscription} from 'rxjs';
import {NgIf, NgClass, NgFor} from '@angular/common';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgScrollbar} from 'ngx-scrollbar';
import {BreadcrumbComponent} from '../../../global/components/breadcrumb/breadcrumb.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {FormsModule} from '@angular/forms';
import {RightPipe} from '../../../global/pipes/right.pipe';

@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html',
    styleUrl: './default-layout.component.scss',
    imports: [AppHeaderComponent, RouterLinkActive, RouterLink, NgIf, BsDropdownModule, AppSidebarComponent, NgScrollbar, AppSidebarNavComponent, AppSidebarMinimizerComponent, BreadcrumbComponent, RouterOutlet, AppAsideComponent, NgClass, TabsModule, FormsModule, NgFor, RightPipe]
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);
    private dataService = inject(DataService);
    private toastrService = inject(ToastrService);
    private routerHelperService = inject(RouterHelperService);

    sidebarMinimized = false;
    navItems: INavData[] = [];

    currentApplicationVersion = environment.appVersion;

    private status$!: Subscription;
    private logout$!: Subscription;
    count: any;
    client: any;
    tasks: any;

    tasksToday!: any[];
    tasksThisWeek!: any[];
    tasksNextWeek!: any[];

    settings: any = {};
    exporting = false;

    roles!: any[];
    selectedRole: any;

    zeiFeedback: any = null;

    showRights = false;
    environment = environment;

    toggleMinimize(e: any): void {
        this.sidebarMinimized = e;
    }

    ngOnInit(): void {
        if (!environment.production) {
            //this.zeiFeedback = new ZeiFeedback('#1d79d4', 'DE', 5000);

            this.dataService.request('Role/listAll').subscribe(response => {
                this.roles = response.roles;
                this.selectedRole = this.roles.find(role => role.id == this.userService.currentUser.id_role);
            });
        }

        this.refreshCurrentUser();
        this.status$ = this.userService.tokenChanged.subscribe((status) => {
            this.refreshCurrentUser();
        });

        /*this.dataService.request('Task/listByUserForNavigation', {UID: this.userService.currentUser.UID}).subscribe(response => {
            this.tasks = response.tasks;

            this.tasksToday = this.tasks.filter(task => dayjs(task.due_date).isSame(dayjs().startOf('day')));
            this.tasksThisWeek = this.tasks.filter(task => dayjs(task.due_date).isoWeek() === dayjs().startOf('day').isoWeek());
            this.tasksNextWeek = this.tasks.filter(task => dayjs(task.due_date).isoWeek() === dayjs().startOf('day').isoWeek() + 1);
        });*/

        this.dataService.request('User/getSettings').subscribe(response => {
            this.settings.reminders = response.settings.s_reminders === '1';
            this.settings.monthly_report = response.settings.s_monthly_report === '1';
        });
    }

    ngOnDestroy(): void {
        if (this.status$) this.status$.unsubscribe();
        if (this.logout$) {
            this.logout$.unsubscribe();
        }

        if (this.zeiFeedback) {
            this.zeiFeedback.destroy();
        }
    }

    logout(event: any): void {
        event.preventDefault();
        if (this.status$) this.status$.unsubscribe();
        this.logout$ = this.userService.logout().subscribe();
    }

    refreshCurrentUser(): void {
        // only display navitems where the user has rights
        // @ts-ignore
        this.navItems = allNavItems.filter(navItem => !navItem.right || this.userService.hasRight(navItem.right));
        // filter children
        this.navItems.forEach(navItem => {
            if (navItem.children) {
                // @ts-ignore
                navItem.children = navItem.children.filter(child => !child.right || this.userService.hasRight(child.right));
            }
        });

        // change navitem name for location
        const labels = this.userService.currentUser.client_labels;
        if (labels) {
            const labelsObject = JSON.parse(labels);
            const navItem = this.navItems.find(item => item.name === 'Betriebsstätten');
            if (navItem) {
                navItem.name = labelsObject.LOCATION.LOCATIONS;
            }
        }
    }

    openProfile(): void {
        const urlData: UrlData = {
            ID: this.userService.currentUser.UID
        };
        this.router.navigate(['/app/user/detail/', this.cryptoService.encrypt(urlData)]);
    }

    updateSettings(): void {
        this.dataService.request('User/updateSettings', this.settings).subscribe(() => {
            this.toastrService.success('Einstellungen wurden aktualisiert');
        });
    }

    exportClient(): void {
        this.exporting = true;
        this.dataService.request('Export/client').subscribe(response => {
            // thx to https://stackoverflow.com/a/52091804/757218
            const byteCharacters = atob(response.pdfData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            this.exporting = false;
        });
    }

    setRole() {
        this.dataService.request('Role/set', {
            id_role: this.selectedRole.id
        }).subscribe((response) => {
            this.router.navigate([], {
                skipLocationChange: true,
                queryParamsHandling: 'merge'
            })
        });
    }

    toggleRightsDebug() {
        if (this.showRights) {
            this.showRights = false;
            document.querySelector('.style_to_delete')?.remove();
        } else {
            const style = document.createElement('style');
            style.innerHTML = '[data-right] { border: 1px solid #F00; } [data-right]:before { content: attr(data-right); font-size: 10px; color: red; }';
            style.classList.add('style_to_delete')
            document.getElementsByTagName('head')[0].appendChild(style);
            this.showRights = true;
        }
    }
}
