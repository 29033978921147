import * as i0 from '@angular/core';
import { Injectable, Inject, Directive, Input, HostListener, NgModule, Component, HostBinding, EventEmitter, Output, Pipe, forwardRef } from '@angular/core';
import { DOCUMENT, NgIf, NgClass, AsyncPipe, NgForOf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgFor } from '@angular/common';
import * as i1 from '@angular/router';
import { NavigationEnd, RouterLink, RouterModule } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
const _c0 = ["*"];
const _c1 = a0 => ({
  active: a0
});
function AppBreadcrumbComponent_ng_template_0_li_0_a_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const breadcrumb_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("routerLink", breadcrumb_r1.url);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(breadcrumb_r1.label.title);
  }
}
function AppBreadcrumbComponent_ng_template_0_li_0_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const breadcrumb_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("routerLink", breadcrumb_r1.url);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(breadcrumb_r1.label.title);
  }
}
function AppBreadcrumbComponent_ng_template_0_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 2);
    i0.ɵɵtemplate(1, AppBreadcrumbComponent_ng_template_0_li_0_a_1_Template, 2, 2, "a", 3)(2, AppBreadcrumbComponent_ng_template_0_li_0_span_2_Template, 2, 2, "span", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const last_r2 = i0.ɵɵnextContext().last;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, last_r2));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !last_r2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", last_r2);
  }
}
function AppBreadcrumbComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, AppBreadcrumbComponent_ng_template_0_li_0_Template, 3, 5, "li", 1);
  }
  if (rf & 2) {
    const breadcrumb_r1 = ctx.$implicit;
    const last_r2 = ctx.last;
    i0.ɵɵproperty("ngIf", breadcrumb_r1.label.title && (breadcrumb_r1.url.slice(-1) == "/" || last_r2));
  }
}
function CuiBreadcrumbComponent_ng_template_1_li_0_a_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const breadcrumb_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("routerLink", breadcrumb_r1.url);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(breadcrumb_r1.label.title);
  }
}
function CuiBreadcrumbComponent_ng_template_1_li_0_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const breadcrumb_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("routerLink", breadcrumb_r1.url);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(breadcrumb_r1.label.title);
  }
}
function CuiBreadcrumbComponent_ng_template_1_li_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 3);
    i0.ɵɵtemplate(1, CuiBreadcrumbComponent_ng_template_1_li_0_a_1_Template, 2, 2, "a", 4)(2, CuiBreadcrumbComponent_ng_template_1_li_0_span_2_Template, 2, 2, "span", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const last_r2 = i0.ɵɵnextContext().last;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, last_r2));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !last_r2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", last_r2);
  }
}
function CuiBreadcrumbComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CuiBreadcrumbComponent_ng_template_1_li_0_Template, 3, 5, "li", 2);
  }
  if (rf & 2) {
    const breadcrumb_r1 = ctx.$implicit;
    const last_r2 = ctx.last;
    i0.ɵɵproperty("ngIf", breadcrumb_r1.label.title && (breadcrumb_r1.url.slice(-1) == "/" || last_r2));
  }
}
function AppHeaderComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 2);
    i0.ɵɵelement(1, "span", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("navbar-toggler ", ctx_r0.sidebarTogglerMobileClass, "");
  }
}
function AppHeaderComponent_ng_template_2_img_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("appHtmlAttr", ctx_r0.navbarBrand)("ngClass", "navbar-brand");
  }
}
function AppHeaderComponent_ng_template_2_img_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("appHtmlAttr", ctx_r0.navbarBrandFull)("ngClass", "navbar-brand-full");
  }
}
function AppHeaderComponent_ng_template_2_img_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("appHtmlAttr", ctx_r0.navbarBrandMinimized)("ngClass", "navbar-brand-minimized");
  }
}
function AppHeaderComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, AppHeaderComponent_ng_template_2_img_0_Template, 1, 2, "img", 4)(1, AppHeaderComponent_ng_template_2_img_1_Template, 1, 2, "img", 4)(2, AppHeaderComponent_ng_template_2_img_2_Template, 1, 2, "img", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r0.navbarBrand);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.navbarBrandFull);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.navbarBrandMinimized);
  }
}
function AppHeaderComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 6)(1, "div", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r0.navbarBrandText.text, i0.ɵɵsanitizeHtml);
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", ctx_r0.navbarBrandText.icon, i0.ɵɵsanitizeHtml);
  }
}
function AppHeaderComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 8);
    i0.ɵɵelement(1, "span", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("navbar-toggler ", ctx_r0.sidebarTogglerClass, "");
    i0.ɵɵproperty("appSidebarToggler", ctx_r0.sidebarToggler);
  }
}
function AppHeaderComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵelement(1, "span", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("navbar-toggler ", ctx_r0.asideTogglerClass, "");
    i0.ɵɵproperty("appAsideMenuToggler", ctx_r0.asideMenuToggler);
  }
}
function AppHeaderComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵelement(1, "span", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("navbar-toggler ", ctx_r0.asideTogglerMobileClass, "");
  }
}
function AppSidebarNavLinkContentComponent_ng_container_0_i_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 2);
    i0.ɵɵpipe(1, "appSidebarNavIcon");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind1(1, 1, ctx_r0.item));
  }
}
function AppSidebarNavLinkContentComponent_ng_container_0_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2);
    i0.ɵɵpipe(1, "appSidebarNavBadge");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind1(1, 2, ctx_r0.item));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.item.badge == null ? null : ctx_r0.item.badge.text);
  }
}
function AppSidebarNavLinkContentComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, AppSidebarNavLinkContentComponent_ng_container_0_i_1_Template, 2, 3, "i", 1);
    i0.ɵɵelementContainerStart(2);
    i0.ɵɵtext(3);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵtemplate(4, AppSidebarNavLinkContentComponent_ng_container_0_span_4_Template, 3, 4, "span", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.helper.hasIcon(ctx_r0.item));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.item.name);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.helper.hasBadge(ctx_r0.item));
  }
}
const _c2 = () => ({
  exact: false
});
function AppSidebarNavLinkComponent_a_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵpipe(1, "appSidebarNavLink");
    i0.ɵɵelement(2, "app-sidebar-nav-link-content", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind1(1, 3, ctx_r0.item))("appHtmlAttr", ctx_r0.item.attributes);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("item", ctx_r0.item);
  }
}
function AppSidebarNavLinkComponent_a_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 6);
    i0.ɵɵpipe(1, "appSidebarNavLink");
    i0.ɵɵlistener("click", function AppSidebarNavLinkComponent_a_2_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.linkClicked());
    });
    i0.ɵɵelement(2, "app-sidebar-nav-link-content", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind1(1, 4, ctx_r0.item))("href", ctx_r0.href, i0.ɵɵsanitizeUrl)("appHtmlAttr", ctx_r0.item.attributes);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("item", ctx_r0.item);
  }
}
function AppSidebarNavLinkComponent_a_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 7);
    i0.ɵɵpipe(1, "appSidebarNavLink");
    i0.ɵɵlistener("click", function AppSidebarNavLinkComponent_a_3_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.linkClicked());
    });
    i0.ɵɵelement(2, "app-sidebar-nav-link-content", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_14_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("active", ctx_r0.linkActive && !(ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.routerLinkActiveOptions));
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind1(1, 16, ctx_r0.item))("appHtmlAttr", ctx_r0.item.attributes)("target", ctx_r0.item.attributes == null ? null : ctx_r0.item.attributes.target)("queryParams", ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.queryParams)("fragment", ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.fragment)("queryParamsHandling", ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.queryParamsHandling)("preserveFragment", !!(ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.preserveFragment))("skipLocationChange", !!(ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.skipLocationChange))("replaceUrl", !!(ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.replaceUrl))("state", ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.state)("routerLink", ctx_r0.item.url)("routerLinkActive", (ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.routerLinkActive) || "active")("routerLinkActiveOptions", (tmp_14_0 = ctx_r0.item.linkProps == null ? null : ctx_r0.item.linkProps.routerLinkActiveOptions) !== null && tmp_14_0 !== undefined ? tmp_14_0 : i0.ɵɵpureFunction0(18, _c2));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("item", ctx_r0.item);
  }
}
function AppSidebarNavLabelComponent_i_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.getLabelIconClass());
  }
}
function AppSidebarNavLabelComponent_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2);
    i0.ɵɵpipe(1, "appSidebarNavBadge");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind1(1, 2, ctx_r0.item));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.item.badge == null ? null : ctx_r0.item.badge.text);
  }
}
function AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_dropdown_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-sidebar-nav-dropdown", 7);
    i0.ɵɵpipe(1, "appSidebarNavItemClass");
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("open", ctx_r1.helper.isActive(ctx_r1.router, item_r1));
    i0.ɵɵproperty("item", item_r1)("ngClass", i0.ɵɵpipeBind1(1, 4, item_r1));
  }
}
function AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_divider_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-sidebar-nav-divider", 8);
    i0.ɵɵpipe(1, "appSidebarNavItemClass");
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("item", item_r1)("ngClass", i0.ɵɵpipeBind1(1, 3, item_r1))("appHtmlAttr", item_r1.attributes);
  }
}
function AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_title_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-sidebar-nav-title", 8);
    i0.ɵɵpipe(1, "appSidebarNavItemClass");
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("item", item_r1)("ngClass", i0.ɵɵpipeBind1(1, 3, item_r1))("appHtmlAttr", item_r1.attributes);
  }
}
function AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_label_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-sidebar-nav-label", 9);
    i0.ɵɵpipe(1, "appSidebarNavItemClass");
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("item", item_r1)("ngClass", i0.ɵɵpipeBind1(1, 2, item_r1));
  }
}
function AppSidebarNavItemsComponent_ng_container_0_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_link_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-sidebar-nav-link", 10);
    i0.ɵɵpipe(1, "appSidebarNavItemClass");
    i0.ɵɵlistener("linkClick", function AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_link_7_Template_app_sidebar_nav_link_linkClick_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.hideMobile());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("item", item_r1)("ngClass", i0.ɵɵpipeBind1(1, 2, item_r1));
  }
}
function AppSidebarNavItemsComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0)(1, 1);
    i0.ɵɵtemplate(2, AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_dropdown_2_Template, 2, 6, "app-sidebar-nav-dropdown", 2)(3, AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_divider_3_Template, 2, 5, "app-sidebar-nav-divider", 3)(4, AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_title_4_Template, 2, 5, "app-sidebar-nav-title", 3)(5, AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_label_5_Template, 2, 4, "app-sidebar-nav-label", 4)(6, AppSidebarNavItemsComponent_ng_container_0_ng_container_6_Template, 1, 0, "ng-container", 5)(7, AppSidebarNavItemsComponent_ng_container_0_app_sidebar_nav_link_7_Template, 2, 4, "app-sidebar-nav-link", 6);
    i0.ɵɵelementContainerEnd()();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitch", ctx_r1.helper.itemType(item_r1));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "dropdown");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "divider");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "title");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "label");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", "empty");
  }
}
const _c3 = () => [];
function AppSidebarNavDropdownComponent_i_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 3);
    i0.ɵɵpipe(1, "appSidebarNavIcon");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind1(1, 1, ctx_r0.item));
  }
}
function AppSidebarNavDropdownComponent_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵpipe(1, "appSidebarNavBadge");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind1(1, 2, ctx_r0.item));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.item.badge == null ? null : ctx_r0.item.badge.text);
  }
}
const sidebarCssClasses = ['sidebar-show', 'sidebar-sm-show', 'sidebar-md-show', 'sidebar-lg-show', 'sidebar-xl-show'];
const asideMenuCssClasses = ['aside-menu-show', 'aside-menu-sm-show', 'aside-menu-md-show', 'aside-menu-lg-show', 'aside-menu-xl-show'];
const RemoveClasses = NewClassNames => {
  const MatchClasses = NewClassNames.map(Class => document.body.classList.contains(Class));
  return MatchClasses.indexOf(true) !== -1;
};
const ToggleClasses = (Toggle, ClassNames) => {
  const Level = ClassNames.indexOf(Toggle);
  const NewClassNames = ClassNames.slice(0, Level + 1);
  if (RemoveClasses(NewClassNames)) {
    NewClassNames.map(Class => document.body.classList.remove(Class));
  } else {
    document.body.classList.add(Toggle);
  }
};
class ClassToggler {
  constructor(document, renderer) {
    this.document = document;
    this.renderer = renderer;
  }
  removeClasses(NewClassNames) {
    const MatchClasses = NewClassNames.map(Class => this.document.body.classList.contains(Class));
    return MatchClasses.indexOf(true) !== -1;
  }
  toggleClasses(Toggle, ClassNames) {
    const Level = ClassNames.indexOf(Toggle);
    const NewClassNames = ClassNames.slice(0, Level + 1);
    if (this.removeClasses(NewClassNames)) {
      NewClassNames.map(Class => this.renderer.removeClass(this.document.body, Class));
    } else {
      this.renderer.addClass(this.document.body, Toggle);
    }
  }
  static {
    this.ɵfac = function ClassToggler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ClassToggler)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i0.Renderer2));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ClassToggler,
      factory: ClassToggler.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClassToggler, [{
    type: Injectable
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], null);
})();

/**
 * Allows the sidebar to be toggled via click.
 */
class SidebarToggleDirective {
  constructor(classToggler) {
    this.classToggler = classToggler;
    this.breakpoint = false;
  }
  ngOnInit() {
    this.bp = this.breakpoint;
  }
  toggleOpen($event) {
    $event.preventDefault();
    const cssClass = this.bp ? `sidebar-${this.bp}-show` : sidebarCssClasses[0];
    this.classToggler.toggleClasses(cssClass, sidebarCssClasses);
  }
  static {
    this.ɵfac = function SidebarToggleDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SidebarToggleDirective)(i0.ɵɵdirectiveInject(ClassToggler));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SidebarToggleDirective,
      selectors: [["", "appSidebarToggler", ""]],
      hostBindings: function SidebarToggleDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SidebarToggleDirective_click_HostBindingHandler($event) {
            return ctx.toggleOpen($event);
          });
        }
      },
      inputs: {
        breakpoint: [0, "appSidebarToggler", "breakpoint"]
      },
      features: [i0.ɵɵProvidersFeature([ClassToggler])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarToggleDirective, [{
    type: Directive,
    args: [{
      selector: '[appSidebarToggler]',
      providers: [ClassToggler],
      standalone: true
    }]
  }], () => [{
    type: ClassToggler
  }], {
    breakpoint: [{
      type: Input,
      args: ['appSidebarToggler']
    }],
    toggleOpen: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class SidebarMinimizeDirective {
  constructor(document, renderer) {
    this.document = document;
    this.renderer = renderer;
  }
  toggleOpen($event) {
    $event.preventDefault();
    const body = this.document.body;
    body.classList.contains('sidebar-minimized') ? this.renderer.removeClass(body, 'sidebar-minimized') : this.renderer.addClass(body, 'sidebar-minimized');
  }
  static {
    this.ɵfac = function SidebarMinimizeDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SidebarMinimizeDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SidebarMinimizeDirective,
      selectors: [["", "appSidebarMinimizer", ""]],
      hostBindings: function SidebarMinimizeDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SidebarMinimizeDirective_click_HostBindingHandler($event) {
            return ctx.toggleOpen($event);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarMinimizeDirective, [{
    type: Directive,
    args: [{
      selector: '[appSidebarMinimizer]',
      standalone: true
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    toggleOpen: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class MobileSidebarToggleDirective {
  constructor(document, renderer) {
    this.document = document;
    this.renderer = renderer;
  }
  toggleOpen($event) {
    $event.preventDefault();
    const body = this.document.body;
    body.classList.contains('sidebar-show') ? this.renderer.removeClass(body, 'sidebar-show') : this.renderer.addClass(body, 'sidebar-show');
  }
  static {
    this.ɵfac = function MobileSidebarToggleDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MobileSidebarToggleDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MobileSidebarToggleDirective,
      selectors: [["", "appMobileSidebarToggler", ""]],
      hostBindings: function MobileSidebarToggleDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function MobileSidebarToggleDirective_click_HostBindingHandler($event) {
            return ctx.toggleOpen($event);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MobileSidebarToggleDirective, [{
    type: Directive,
    args: [{
      selector: '[appMobileSidebarToggler]',
      standalone: true
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    toggleOpen: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
/**
 * Allows the off-canvas sidebar to be closed via click.
 */
class SidebarOffCanvasCloseDirective {
  constructor(document, renderer) {
    this.document = document;
    this.renderer = renderer;
  }
  toggleOpen($event) {
    $event.preventDefault();
    const body = this.document.body;
    if (body.classList.contains('sidebar-off-canvas')) {
      body.classList.contains('sidebar-show') ? this.renderer.removeClass(body, 'sidebar-show') : this.renderer.addClass(body, 'sidebar-show');
    }
  }
  static {
    this.ɵfac = function SidebarOffCanvasCloseDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SidebarOffCanvasCloseDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SidebarOffCanvasCloseDirective,
      selectors: [["", "appSidebarClose", ""]],
      hostBindings: function SidebarOffCanvasCloseDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SidebarOffCanvasCloseDirective_click_HostBindingHandler($event) {
            return ctx.toggleOpen($event);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarOffCanvasCloseDirective, [{
    type: Directive,
    args: [{
      selector: '[appSidebarClose]',
      standalone: true
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    toggleOpen: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class BrandMinimizeDirective {
  constructor(document, renderer) {
    this.document = document;
    this.renderer = renderer;
  }
  toggleOpen($event) {
    $event.preventDefault();
    const body = this.document.body;
    body.classList.contains('brand-minimized') ? this.renderer.removeClass(body, 'brand-minimized') : this.renderer.addClass(body, 'brand-minimized');
  }
  static {
    this.ɵfac = function BrandMinimizeDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrandMinimizeDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrandMinimizeDirective,
      selectors: [["", "appBrandMinimizer", ""]],
      hostBindings: function BrandMinimizeDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function BrandMinimizeDirective_click_HostBindingHandler($event) {
            return ctx.toggleOpen($event);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrandMinimizeDirective, [{
    type: Directive,
    args: [{
      selector: '[appBrandMinimizer]',
      standalone: true
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    toggleOpen: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
/**
 * Allows the aside to be toggled via click.
 */
class AsideToggleDirective {
  constructor(classToggler) {
    this.classToggler = classToggler;
    this.breakpoint = false;
  }
  ngOnInit() {
    this.bp = this.breakpoint;
  }
  toggleOpen($event) {
    $event.preventDefault();
    const cssClass = this.bp ? `aside-menu-${this.bp}-show` : asideMenuCssClasses[0];
    this.classToggler.toggleClasses(cssClass, asideMenuCssClasses);
  }
  static {
    this.ɵfac = function AsideToggleDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AsideToggleDirective)(i0.ɵɵdirectiveInject(ClassToggler));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AsideToggleDirective,
      selectors: [["", "appAsideMenuToggler", ""]],
      hostBindings: function AsideToggleDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function AsideToggleDirective_click_HostBindingHandler($event) {
            return ctx.toggleOpen($event);
          });
        }
      },
      inputs: {
        breakpoint: [0, "appAsideMenuToggler", "breakpoint"]
      },
      features: [i0.ɵɵProvidersFeature([ClassToggler])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AsideToggleDirective, [{
    type: Directive,
    args: [{
      selector: '[appAsideMenuToggler]',
      providers: [ClassToggler],
      standalone: true
    }]
  }], () => [{
    type: ClassToggler
  }], {
    breakpoint: [{
      type: Input,
      args: ['appAsideMenuToggler']
    }],
    toggleOpen: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class HtmlAttributesDirective {
  constructor(renderer, el) {
    this.renderer = renderer;
    this.el = el;
    this.appHtmlAttr = {};
  }
  ngOnInit() {
    const attribs = this.appHtmlAttr;
    for (const attr in attribs) {
      if (attr === 'style' && typeof attribs[attr] === 'object') {
        this.setStyle(attribs[attr]);
      } else if (attr === 'class') {
        this.addClass(attribs[attr]);
      } else {
        this.setAttrib(attr, attribs[attr]);
      }
    }
  }
  setStyle(styles) {
    for (const style in styles) {
      if (style) {
        this.renderer.setStyle(this.el.nativeElement, style, styles[style]);
      }
    }
  }
  addClass(classes) {
    const classArray = Array.isArray(classes) ? classes : classes.split(' ');
    classArray.filter(element => element.length > 0).forEach(element => {
      this.renderer.addClass(this.el.nativeElement, element);
    });
  }
  setAttrib(key, value) {
    value !== null ? this.renderer.setAttribute(this.el.nativeElement, key, value) : this.renderer.removeAttribute(this.el.nativeElement, key);
  }
  static {
    this.ɵfac = function HtmlAttributesDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HtmlAttributesDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: HtmlAttributesDirective,
      selectors: [["", "appHtmlAttr", ""]],
      inputs: {
        appHtmlAttr: "appHtmlAttr"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HtmlAttributesDirective, [{
    type: Directive,
    args: [{
      selector: '[appHtmlAttr]',
      standalone: true
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    appHtmlAttr: [{
      type: Input
    }]
  });
})();
class LayoutModule {
  static {
    this.ɵfac = function LayoutModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LayoutModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LayoutModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [ClassToggler]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayoutModule, [{
    type: NgModule,
    args: [{
      imports: [AsideToggleDirective, BrandMinimizeDirective, SidebarToggleDirective, SidebarMinimizeDirective, SidebarOffCanvasCloseDirective, MobileSidebarToggleDirective, HtmlAttributesDirective],
      exports: [AsideToggleDirective, BrandMinimizeDirective, MobileSidebarToggleDirective, SidebarToggleDirective, SidebarMinimizeDirective, SidebarOffCanvasCloseDirective, HtmlAttributesDirective],
      providers: [ClassToggler]
    }]
  }], null, null);
})();
function Replace(el) {
  const nativeElement = el.nativeElement;
  // @ts-ignore
  const parentElement = nativeElement.parentElement;
  // move all children out of the element
  while (nativeElement.firstChild) {
    parentElement.insertBefore(nativeElement.firstChild, nativeElement);
  }
  // remove the empty element(the host)
  parentElement.removeChild(nativeElement);
}
class AppAsideComponent {
  constructor(document, renderer) {
    this.document = document;
    this.renderer = renderer;
    this.fixedClass = 'aside-menu-fixed';
    this.asideMenuClass = true;
  }
  ngOnInit() {
    this.isFixed(this.fixed);
    this.isOffCanvas(this.offCanvas);
    this.displayBreakpoint(this.display);
  }
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, this.fixedClass);
  }
  isFixed(fixed = this.fixed) {
    if (fixed) {
      this.renderer.addClass(this.document.body, this.fixedClass);
    }
  }
  isOffCanvas(offCanvas = this.offCanvas) {
    if (offCanvas) {
      this.renderer.addClass(this.document.body, 'aside-menu-off-canvas');
    }
  }
  displayBreakpoint(display = this.display) {
    if (display !== false) {
      const cssClass = this.display ? `aside-menu-${this.display}-show` : asideMenuCssClasses[0];
      this.renderer.addClass(this.document.body, cssClass);
    }
  }
  static {
    this.ɵfac = function AppAsideComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppAsideComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppAsideComponent,
      selectors: [["app-aside"], ["cui-aside"]],
      hostVars: 2,
      hostBindings: function AppAsideComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("aside-menu", ctx.asideMenuClass);
        }
      },
      inputs: {
        display: "display",
        fixed: "fixed",
        offCanvas: "offCanvas"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function AppAsideComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppAsideComponent, [{
    type: Component,
    args: [{
      selector: 'app-aside, cui-aside',
      template: `<ng-content></ng-content>`,
      standalone: true
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    display: [{
      type: Input
    }],
    fixed: [{
      type: Input
    }],
    offCanvas: [{
      type: Input
    }],
    asideMenuClass: [{
      type: HostBinding,
      args: ['class.aside-menu']
    }]
  });
})();
class AppAsideModule {
  static {
    this.ɵfac = function AppAsideModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppAsideModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppAsideModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppAsideModule, [{
    type: NgModule,
    args: [{
      imports: [AppAsideComponent],
      exports: [AppAsideComponent]
    }]
  }], null, null);
})();
class AppBreadcrumbService {
  constructor(router, route) {
    this.router = router;
    this.route = route;
    this.breadcrumbSubject = new BehaviorSubject(new Array());
    this.breadcrumbs = this.breadcrumbSubject.asObservable();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const breadcrumbs = [];
      let currentRoute = this.route.root;
      let url = '';
      do {
        const childrenRoutes = currentRoute.children;
        currentRoute = null;
        // tslint:disable-next-line:no-shadowed-variable
        childrenRoutes.forEach(route => {
          if (route.outlet === 'primary') {
            const routeSnapshot = route.snapshot;
            url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
            breadcrumbs.push({
              label: route.snapshot.data,
              url
            });
            currentRoute = route;
          }
        });
      } while (currentRoute);
      this.breadcrumbSubject.next(Object.assign([], breadcrumbs));
      return breadcrumbs;
    });
  }
  static {
    this.ɵfac = function AppBreadcrumbService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppBreadcrumbService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AppBreadcrumbService,
      factory: AppBreadcrumbService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBreadcrumbService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Router
  }, {
    type: i1.ActivatedRoute
  }], null);
})();
class AppBreadcrumbComponent {
  constructor(document, renderer, service, el) {
    this.document = document;
    this.renderer = renderer;
    this.service = service;
    this.el = el;
    this.fixedClass = 'breadcrumb-fixed';
  }
  ngOnInit() {
    Replace(this.el);
    this.isFixed(this.fixed);
    this.breadcrumbs = this.service.breadcrumbs;
  }
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, this.fixedClass);
  }
  isFixed(fixed = this.fixed) {
    if (fixed) {
      this.renderer.addClass(this.document.body, this.fixedClass);
    }
  }
  static {
    this.ɵfac = function AppBreadcrumbComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppBreadcrumbComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(AppBreadcrumbService), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppBreadcrumbComponent,
      selectors: [["app-breadcrumb"]],
      inputs: {
        fixed: "fixed"
      },
      decls: 2,
      vars: 3,
      consts: [["ngFor", "", 3, "ngForOf"], ["class", "breadcrumb-item", 3, "ngClass", 4, "ngIf"], [1, "breadcrumb-item", 3, "ngClass"], [3, "routerLink", 4, "ngIf"], [3, "routerLink"]],
      template: function AppBreadcrumbComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, AppBreadcrumbComponent_ng_template_0_Template, 1, 1, "ng-template", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(1, 1, ctx.breadcrumbs));
        }
      },
      dependencies: [NgIf, NgClass, RouterLink],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBreadcrumbComponent, [{
    type: Component,
    args: [{
      selector: 'app-breadcrumb',
      template: `
    <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs | async" let-last = last>
      <li class="breadcrumb-item"
          *ngIf="breadcrumb.label.title && (breadcrumb.url.slice(-1) == '/' || last)"
          [ngClass]="{active: last}">
        <a *ngIf="!last" [routerLink]="breadcrumb.url">{{breadcrumb.label.title}}</a>
        <span *ngIf="last" [routerLink]="breadcrumb.url">{{breadcrumb.label.title}}</span>
      </li>
    </ng-template>
  `,
      standalone: true,
      imports: [NgIf, NgClass, RouterLink]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: AppBreadcrumbService
  }, {
    type: i0.ElementRef
  }], {
    fixed: [{
      type: Input
    }]
  });
})();
class CuiBreadcrumbComponent {
  constructor(document, renderer, service) {
    this.document = document;
    this.renderer = renderer;
    this.service = service;
    this.fixedClass = 'breadcrumb-fixed';
  }
  ngOnInit() {
    this.isFixed(this.fixed);
    this.breadcrumbs = this.service.breadcrumbs;
  }
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, this.fixedClass);
  }
  isFixed(fixed = this.fixed) {
    if (fixed) {
      this.renderer.addClass(this.document.body, this.fixedClass);
    }
  }
  static {
    this.ɵfac = function CuiBreadcrumbComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CuiBreadcrumbComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(AppBreadcrumbService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CuiBreadcrumbComponent,
      selectors: [["cui-breadcrumb"]],
      inputs: {
        fixed: "fixed"
      },
      ngContentSelectors: _c0,
      decls: 4,
      vars: 3,
      consts: [[1, "breadcrumb"], ["ngFor", "", 3, "ngForOf"], ["class", "breadcrumb-item", 3, "ngClass", 4, "ngIf"], [1, "breadcrumb-item", 3, "ngClass"], [3, "routerLink", 4, "ngIf"], [3, "routerLink"]],
      template: function CuiBreadcrumbComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "ol", 0);
          i0.ɵɵtemplate(1, CuiBreadcrumbComponent_ng_template_1_Template, 1, 1, "ng-template", 1);
          i0.ɵɵpipe(2, "async");
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(2, 1, ctx.breadcrumbs));
        }
      },
      dependencies: [NgIf, NgClass, RouterLink, AsyncPipe, NgForOf],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CuiBreadcrumbComponent, [{
    type: Component,
    args: [{
      selector: 'cui-breadcrumb',
      standalone: true,
      imports: [NgIf, NgClass, RouterLink, AsyncPipe, NgForOf],
      template: "<ol class=\"breadcrumb\">\n  <ng-template ngFor let-breadcrumb [ngForOf]=\"breadcrumbs | async\" let-last = last>\n    <li class=\"breadcrumb-item\"\n        *ngIf=\"breadcrumb.label.title && (breadcrumb.url.slice(-1) == '/' || last)\"\n        [ngClass]=\"{active: last}\">\n      <a *ngIf=\"!last\" [routerLink]=\"breadcrumb.url\">{{breadcrumb.label.title}}</a>\n      <span *ngIf=\"last\" [routerLink]=\"breadcrumb.url\">{{breadcrumb.label.title}}</span>\n    </li>\n  </ng-template>\n  <ng-content></ng-content>\n</ol>\n"
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: AppBreadcrumbService
  }], {
    fixed: [{
      type: Input
    }]
  });
})();

// @dynamic
class AppBreadcrumbModule {
  static forRoot(config) {
    return {
      ngModule: AppBreadcrumbModule,
      providers: [AppBreadcrumbService]
    };
  }
  static {
    this.ɵfac = function AppBreadcrumbModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppBreadcrumbModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppBreadcrumbModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBreadcrumbModule, [{
    type: NgModule,
    args: [{
      imports: [AppBreadcrumbComponent, CuiBreadcrumbComponent],
      exports: [AppBreadcrumbComponent, CuiBreadcrumbComponent]
    }]
  }], null, null);
})();
class AppFooterComponent {
  constructor(document, renderer) {
    this.document = document;
    this.renderer = renderer;
    this.fixedClass = 'footer-fixed';
    this.appFooterClass = true;
  }
  ngOnInit() {
    this.isFixed(this.fixed);
  }
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, this.fixedClass);
  }
  isFixed(fixed = this.fixed) {
    if (fixed) {
      this.renderer.addClass(this.document.body, this.fixedClass);
    }
  }
  static {
    this.ɵfac = function AppFooterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppFooterComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppFooterComponent,
      selectors: [["app-footer"], ["cui-footer"]],
      hostVars: 2,
      hostBindings: function AppFooterComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("app-footer", ctx.appFooterClass);
        }
      },
      inputs: {
        fixed: "fixed"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function AppFooterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppFooterComponent, [{
    type: Component,
    args: [{
      selector: 'app-footer, cui-footer',
      template: `<ng-content></ng-content>`,
      standalone: true
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    fixed: [{
      type: Input
    }],
    appFooterClass: [{
      type: HostBinding,
      args: ['class.app-footer']
    }]
  });
})();
class AppFooterModule {
  static {
    this.ɵfac = function AppFooterModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppFooterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppFooterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppFooterModule, [{
    type: NgModule,
    args: [{
      imports: [AppFooterComponent],
      exports: [AppFooterComponent]
    }]
  }], null, null);
})();
class AppHeaderComponent {
  constructor(document, renderer) {
    this.document = document;
    this.renderer = renderer;
    this.navbarBrandText = {
      icon: '🅲',
      text: '🅲 CoreUI'
    };
    this.navbarBrandHref = ''; // deprecated, use navbarBrandRouterLink instead
    this.navbarBrandRouterLink = '';
    this.sidebarToggler = 'lg';
    this.mobileSidebarToggler = true;
    this.asideMenuToggler = 'lg';
    this.mobileAsideMenuToggler = true;
    this.fixedClass = 'header-fixed';
    this.appHeaderClass = true;
    this.navbarClass = true;
    this.breakpoints = ['xl', 'lg', 'md', 'sm', 'xs'];
    this.sidebarTogglerClass = 'd-none d-md-block';
    this.sidebarTogglerMobileClass = 'd-lg-none';
    this.asideTogglerClass = 'd-none d-md-block';
    this.asideTogglerMobileClass = 'd-lg-none';
  }
  ngOnInit() {
    this.isFixed(this.fixed);
    this.navbarBrandImg = Boolean(this.navbarBrand || this.navbarBrandFull || this.navbarBrandMinimized);
    this.navbarBrandRouterLink = this.navbarBrandRouterLink[0] ? this.navbarBrandRouterLink : this.navbarBrandHref;
    this.sidebarTogglerClass = this.setTogglerBreakpointClass(this.sidebarToggler);
    this.sidebarTogglerMobileClass = this.setTogglerMobileBreakpointClass(this.sidebarToggler);
    this.asideTogglerClass = this.setTogglerBreakpointClass(this.asideMenuToggler);
    this.asideTogglerMobileClass = this.setTogglerMobileBreakpointClass(this.asideMenuToggler);
  }
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, this.fixedClass);
  }
  isFixed(fixed = this.fixed) {
    if (fixed) {
      this.renderer.addClass(this.document.body, this.fixedClass);
    }
  }
  setTogglerBreakpointClass(breakpoint = 'md') {
    let togglerClass = 'd-none d-md-block';
    if (this.breakpoints.includes(breakpoint)) {
      const breakpointIndex = this.breakpoints.indexOf(breakpoint);
      togglerClass = `d-none d-${breakpoint}-block`;
    }
    return togglerClass;
  }
  setTogglerMobileBreakpointClass(breakpoint = 'lg') {
    let togglerClass = 'd-lg-none';
    if (this.breakpoints.includes(breakpoint)) {
      togglerClass = `d-${breakpoint}-none`;
    }
    return togglerClass;
  }
  static {
    this.ɵfac = function AppHeaderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppHeaderComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppHeaderComponent,
      selectors: [["app-header"], ["cui-header"]],
      hostVars: 4,
      hostBindings: function AppHeaderComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("app-header", ctx.appHeaderClass)("navbar", ctx.navbarClass);
        }
      },
      inputs: {
        fixed: "fixed",
        navbarBrand: "navbarBrand",
        navbarBrandFull: "navbarBrandFull",
        navbarBrandMinimized: "navbarBrandMinimized",
        navbarBrandText: "navbarBrandText",
        navbarBrandHref: "navbarBrandHref",
        navbarBrandRouterLink: "navbarBrandRouterLink",
        sidebarToggler: "sidebarToggler",
        mobileSidebarToggler: "mobileSidebarToggler",
        asideMenuToggler: "asideMenuToggler",
        mobileAsideMenuToggler: "mobileAsideMenuToggler"
      },
      ngContentSelectors: _c0,
      decls: 8,
      vars: 7,
      consts: [[3, "ngIf"], [1, "navbar-brand", 3, "routerLink"], ["type", "button", "appSidebarToggler", ""], [1, "navbar-toggler-icon"], [3, "appHtmlAttr", "ngClass", 4, "ngIf"], [3, "appHtmlAttr", "ngClass"], [1, "navbar-brand-full", 3, "innerHTML"], [1, "navbar-brand-minimized", 3, "innerHTML"], ["type", "button", 3, "appSidebarToggler"], ["type", "button", 3, "appAsideMenuToggler"], ["type", "button", "appAsideMenuToggler", ""]],
      template: function AppHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, AppHeaderComponent_ng_template_0_Template, 2, 3, "ng-template", 0);
          i0.ɵɵelementStart(1, "a", 1);
          i0.ɵɵtemplate(2, AppHeaderComponent_ng_template_2_Template, 3, 3, "ng-template", 0)(3, AppHeaderComponent_ng_template_3_Template, 2, 2, "ng-template", 0);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, AppHeaderComponent_ng_template_4_Template, 2, 4, "ng-template", 0);
          i0.ɵɵprojection(5);
          i0.ɵɵtemplate(6, AppHeaderComponent_ng_template_6_Template, 2, 4, "ng-template", 0)(7, AppHeaderComponent_ng_template_7_Template, 2, 3, "ng-template", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.mobileSidebarToggler != false);
          i0.ɵɵadvance();
          i0.ɵɵproperty("routerLink", ctx.navbarBrandRouterLink);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.navbarBrandImg);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.navbarBrandImg);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.sidebarToggler != false);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.asideMenuToggler != false);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.mobileAsideMenuToggler != false);
        }
      },
      dependencies: [RouterLink, NgIf, NgClass, SidebarToggleDirective, HtmlAttributesDirective, AsideToggleDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'app-header, cui-header',
      standalone: true,
      imports: [RouterLink, NgIf, NgClass, SidebarToggleDirective, HtmlAttributesDirective, AsideToggleDirective],
      template: "<ng-template [ngIf]=\"mobileSidebarToggler != false\">\n  <button class=\"navbar-toggler {{sidebarTogglerMobileClass}}\" type=\"button\" appSidebarToggler>\n    <span class=\"navbar-toggler-icon\"></span>\n  </button>\n</ng-template>\n<a class=\"navbar-brand\" [routerLink]=\"navbarBrandRouterLink\">\n  <ng-template [ngIf]=\"navbarBrandImg\">\n    <img *ngIf=\"navbarBrand\"\n         [appHtmlAttr]=\"navbarBrand\"\n         [ngClass]=\"'navbar-brand'\">\n    <img *ngIf=\"navbarBrandFull\"\n         [appHtmlAttr]=\"navbarBrandFull\"\n         [ngClass]=\"'navbar-brand-full'\">\n    <img *ngIf=\"navbarBrandMinimized\"\n         [appHtmlAttr]=\"navbarBrandMinimized\"\n         [ngClass]=\"'navbar-brand-minimized'\">\n  </ng-template>\n  <ng-template [ngIf]=\"!navbarBrandImg\">\n    <div class=\"navbar-brand-full\" [innerHTML]=\"navbarBrandText.text\"></div>\n    <div class=\"navbar-brand-minimized\" [innerHTML]=\"navbarBrandText.icon\"></div>\n  </ng-template>\n</a>\n<ng-template [ngIf]=\"sidebarToggler != false\">\n  <button class=\"navbar-toggler {{sidebarTogglerClass}}\" type=\"button\" [appSidebarToggler]=\"sidebarToggler\">\n    <span class=\"navbar-toggler-icon\"></span>\n  </button>\n</ng-template>\n<ng-content></ng-content>\n<ng-template [ngIf]=\"asideMenuToggler != false\">\n  <button class=\"navbar-toggler {{asideTogglerClass}}\" type=\"button\" [appAsideMenuToggler]=\"asideMenuToggler\">\n    <span class=\"navbar-toggler-icon\"></span>\n  </button>\n</ng-template>\n<ng-template [ngIf]=\"mobileAsideMenuToggler != false\">\n  <button class=\"navbar-toggler {{asideTogglerMobileClass}}\" type=\"button\" appAsideMenuToggler>\n    <span class=\"navbar-toggler-icon\"></span>\n  </button>\n</ng-template>\n"
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    fixed: [{
      type: Input
    }],
    navbarBrand: [{
      type: Input
    }],
    navbarBrandFull: [{
      type: Input
    }],
    navbarBrandMinimized: [{
      type: Input
    }],
    navbarBrandText: [{
      type: Input
    }],
    navbarBrandHref: [{
      type: Input
    }],
    navbarBrandRouterLink: [{
      type: Input
    }],
    sidebarToggler: [{
      type: Input
    }],
    mobileSidebarToggler: [{
      type: Input
    }],
    asideMenuToggler: [{
      type: Input
    }],
    mobileAsideMenuToggler: [{
      type: Input
    }],
    appHeaderClass: [{
      type: HostBinding,
      args: ['class.app-header']
    }],
    navbarClass: [{
      type: HostBinding,
      args: ['class.navbar']
    }]
  });
})();
class AppHeaderModule {
  static {
    this.ɵfac = function AppHeaderModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppHeaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppHeaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppHeaderModule, [{
    type: NgModule,
    args: [{
      imports: [AppHeaderComponent],
      exports: [AppHeaderComponent]
    }]
  }], null, null);
})();
class AppSidebarService {
  constructor() {
    this.events = new BehaviorSubject({});
    this.events$ = this.events.asObservable();
  }
  toggle(action) {
    this.events.next(action);
  }
  static {
    this.ɵfac = function AppSidebarService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AppSidebarService,
      factory: AppSidebarService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class AppSidebarComponent {
  get minimized() {
    return this._minimized;
  }
  set minimized(value) {
    // only update / emit events when the value changes
    if (this._minimized !== value) {
      this._minimized = value;
      this._updateMinimized(value);
      this.minimizedChange.emit(value);
      this.sidebarService.toggle({
        minimize: value
      });
    }
  }
  constructor(document, renderer, sidebarService) {
    this.document = document;
    this.renderer = renderer;
    this.sidebarService = sidebarService;
    this._minimized = false;
    /**
     * Emits whenever the minimized state of the sidebar changes.
     * Primarily used to facilitate two-way binding.
     */
    this.minimizedChange = new EventEmitter();
    this.sidebarClass = true;
  }
  ngOnInit() {
    this.displayBreakpoint(this.display);
    this.isCompact(this.compact);
    this.isFixed(this.fixed);
    this.isOffCanvas(this.offCanvas);
    this.sidebarService.toggle({
      minimize: this.minimized
    });
    this.subscriptionEvents = this.sidebarService.events$.subscribe(action => {
      if (action.minimize !== undefined) {
        action.minimize === 'toggle' ? this.toggleMinimized() : this.minimized = !!action.minimize;
      }
    });
  }
  ngOnDestroy() {
    this.subscriptionEvents.unsubscribe();
    this.minimizedChange.complete();
    this.renderer.removeClass(this.document.body, 'sidebar-fixed');
    this._updateMinimized(false);
  }
  isCompact(compact = this.compact) {
    if (compact) {
      this.renderer.addClass(this.document.body, 'sidebar-compact');
    }
  }
  isFixed(fixed = this.fixed) {
    if (fixed) {
      this.renderer.addClass(this.document.body, 'sidebar-fixed');
    }
  }
  toggleMinimized() {
    this.minimized = !this._minimized;
  }
  isOffCanvas(offCanvas = this.offCanvas) {
    if (offCanvas) {
      this.renderer.addClass(this.document.body, 'sidebar-off-canvas');
    }
  }
  displayBreakpoint(display = this.display) {
    if (display !== false) {
      const cssClass = display ? `sidebar-${display}-show` : sidebarCssClasses[0];
      this.renderer.addClass(this.document.body, cssClass);
    }
  }
  _updateMinimized(minimized) {
    const body = this.document.body;
    if (minimized) {
      this.renderer.addClass(body, 'sidebar-minimized');
      this.renderer.addClass(body, 'brand-minimized');
    } else {
      this.renderer.removeClass(body, 'sidebar-minimized');
      this.renderer.removeClass(body, 'brand-minimized');
    }
  }
  static {
    this.ɵfac = function AppSidebarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(AppSidebarService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarComponent,
      selectors: [["app-sidebar"], ["cui-sidebar"]],
      hostVars: 2,
      hostBindings: function AppSidebarComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("sidebar", ctx.sidebarClass);
        }
      },
      inputs: {
        compact: "compact",
        display: "display",
        fixed: "fixed",
        offCanvas: "offCanvas",
        minimized: "minimized"
      },
      outputs: {
        minimizedChange: "minimizedChange"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function AppSidebarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar, cui-sidebar',
      template: `<ng-content></ng-content>`,
      standalone: true
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: AppSidebarService
  }], {
    compact: [{
      type: Input
    }],
    display: [{
      type: Input
    }],
    fixed: [{
      type: Input
    }],
    offCanvas: [{
      type: Input
    }],
    minimized: [{
      type: Input
    }],
    minimizedChange: [{
      type: Output
    }],
    sidebarClass: [{
      type: HostBinding,
      args: ['class.sidebar']
    }]
  });
})();
class SidebarNavService {
  static {
    this.ɵfac = function SidebarNavService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SidebarNavService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SidebarNavService,
      factory: SidebarNavService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarNavService, [{
    type: Injectable
  }], null, null);
})();
class SidebarNavHelper {
  constructor() {
    this.hasBadge = item => Boolean(item.badge);
    this.hasIcon = item => Boolean(item.icon);
  }
  itemType(item) {
    if (item.divider) {
      return 'divider';
    } else if (item.title) {
      return 'title';
    } else if (item.children) {
      return 'dropdown';
    } else if (item.label) {
      return 'label';
    } else if (!Object.keys(item).length) {
      return 'empty';
    }
    return 'link';
  }
  isActive(router, item) {
    return router.isActive(item.url, false);
  }
  getIconClass(item) {
    const classes = {
      'nav-icon': true
    };
    if (this.hasIcon(item)) {
      const icon = item.icon;
      // @ts-ignore
      classes[icon] = this.hasIcon(item);
    }
    return classes;
  }
  static {
    this.ɵfac = function SidebarNavHelper_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SidebarNavHelper)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SidebarNavHelper,
      factory: SidebarNavHelper.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarNavHelper, [{
    type: Injectable
  }], null, null);
})();
class AppSidebarNavItemClassPipe {
  constructor(helper) {
    this.helper = helper;
  }
  transform(item, ...args) {
    const itemType = this.helper.itemType(item);
    let itemClass;
    if (['divider', 'title'].includes(itemType)) {
      itemClass = `nav-${itemType}`;
    } else if (itemType === 'dropdown') {
      itemClass = 'nav-item nav-dropdown';
    } else {
      itemClass = 'nav-item';
    }
    return item.class ? `${itemClass} ${item.class}` : itemClass;
  }
  static {
    this.ɵfac = function AppSidebarNavItemClassPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavItemClassPipe)(i0.ɵɵdirectiveInject(SidebarNavHelper, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "appSidebarNavItemClass",
      type: AppSidebarNavItemClassPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavItemClassPipe, [{
    type: Pipe,
    args: [{
      name: 'appSidebarNavItemClass',
      standalone: true
    }]
  }], () => [{
    type: SidebarNavHelper
  }], null);
})();
class AppSidebarNavBadgePipe {
  transform(item, args) {
    const classes = {
      badge: true
    };
    const variant = `badge-${item.badge.variant}`;
    classes[variant] = !!item.badge.variant;
    classes[item.badge.class] = !!item.badge.class;
    return classes;
  }
  static {
    this.ɵfac = function AppSidebarNavBadgePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavBadgePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "appSidebarNavBadge",
      type: AppSidebarNavBadgePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavBadgePipe, [{
    type: Pipe,
    args: [{
      name: 'appSidebarNavBadge',
      standalone: true
    }]
  }], null, null);
})();
class AppSidebarNavIconPipe {
  transform(item, args) {
    const classes = {
      'nav-icon': true
    };
    const icon = item.icon;
    classes[icon] = !!item.icon;
    return classes;
  }
  static {
    this.ɵfac = function AppSidebarNavIconPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavIconPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "appSidebarNavIcon",
      type: AppSidebarNavIconPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavIconPipe, [{
    type: Pipe,
    args: [{
      name: 'appSidebarNavIcon',
      standalone: true
    }]
  }], null, null);
})();
class AppSidebarNavLinkPipe {
  transform(item) {
    const classes = {
      'nav-link': true
    };
    const disabled = item.attributes && item.attributes.disabled;
    classes['disabled'] = disabled;
    classes['btn-link'] = disabled;
    classes[`nav-link-${item.variant}`] = !!item.variant;
    return classes;
  }
  static {
    this.ɵfac = function AppSidebarNavLinkPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavLinkPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "appSidebarNavLink",
      type: AppSidebarNavLinkPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavLinkPipe, [{
    type: Pipe,
    args: [{
      name: 'appSidebarNavLink',
      standalone: true
    }]
  }], null, null);
})();
class AppSidebarNavLinkContentComponent {
  constructor(helper) {
    this.helper = helper;
    this.item = {};
  }
  static {
    this.ɵfac = function AppSidebarNavLinkContentComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavLinkContentComponent)(i0.ɵɵdirectiveInject(SidebarNavHelper));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarNavLinkContentComponent,
      selectors: [["app-sidebar-nav-link-content"], ["cui-sidebar-nav-link-content"]],
      inputs: {
        item: "item"
      },
      features: [i0.ɵɵProvidersFeature([SidebarNavHelper])],
      decls: 1,
      vars: 1,
      consts: [[4, "ngIf"], [3, "ngClass", 4, "ngIf"], [3, "ngClass"]],
      template: function AppSidebarNavLinkContentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, AppSidebarNavLinkContentComponent_ng_container_0_Template, 5, 3, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", true);
        }
      },
      dependencies: [NgIf, NgClass, AppSidebarNavIconPipe, AppSidebarNavBadgePipe],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavLinkContentComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-nav-link-content, cui-sidebar-nav-link-content',
      template: `
    <ng-container *ngIf="true">
      <i *ngIf="helper.hasIcon(item)" [ngClass]="item | appSidebarNavIcon"></i>
      <ng-container>{{item.name}}</ng-container>
      <span *ngIf="helper.hasBadge(item)" [ngClass]="item | appSidebarNavBadge">{{ item.badge?.text }}</span>
    </ng-container>
  `,
      providers: [SidebarNavHelper],
      standalone: true,
      imports: [NgIf, NgClass, AppSidebarNavIconPipe, AppSidebarNavBadgePipe]
    }]
  }], () => [{
    type: SidebarNavHelper
  }], {
    item: [{
      type: Input
    }]
  });
})();
class AppSidebarNavLinkComponent {
  set item(item) {
    this._Item = JSON.parse(JSON.stringify(item));
  }
  get item() {
    return this._Item;
  }
  constructor(router) {
    this.router = router;
    this.linkClick = new EventEmitter();
    this.navigationEndObservable = router.events.pipe(filter(event => {
      return event instanceof NavigationEnd;
    }));
  }
  ngOnInit() {
    // @ts-ignore
    this.url = typeof this.item.url === 'string' ? this.item.url : this.router.serializeUrl(this.router.createUrlTree(this.item.url));
    this.linkType = this.getLinkType();
    this.href = this.isDisabled() ? '' : this.item.href || this.url;
    this.linkActive = this.router.url.split(/[?#(;]/)[0] === this.href.split(/[?#(;]/)[0];
    this.navSubscription = this.navigationEndObservable.subscribe(event => {
      const itemUrlArray = this.href.split(/[?#(;]/)[0].split('/');
      const urlArray = event.urlAfterRedirects.split(/[?#(;]/)[0].split('/');
      this.linkActive = itemUrlArray.every((value, index) => value === urlArray[index]);
    });
  }
  ngOnDestroy() {
    this.navSubscription.unsubscribe();
  }
  getLinkType() {
    return this.isDisabled() ? 'disabled' : this.isExternalLink() ? 'external' : 'link';
  }
  isDisabled() {
    return this.item.attributes && this.item.attributes.disabled ? true : null;
  }
  isExternalLink() {
    return !!this.item.href || this.url.substring(0, 4) === 'http';
  }
  linkClicked() {
    this.linkClick.emit();
  }
  static {
    this.ɵfac = function AppSidebarNavLinkComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavLinkComponent)(i0.ɵɵdirectiveInject(i1.Router));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarNavLinkComponent,
      selectors: [["app-sidebar-nav-link"], ["cui-sidebar-nav-link"]],
      inputs: {
        item: "item"
      },
      outputs: {
        linkClick: "linkClick"
      },
      features: [i0.ɵɵProvidersFeature([SidebarNavHelper])],
      decls: 4,
      vars: 3,
      consts: [[3, "ngSwitch"], [3, "ngClass", "appHtmlAttr", 4, "ngSwitchCase"], [3, "ngClass", "href", "appHtmlAttr", "click", 4, "ngSwitchCase"], [3, "ngClass", "appHtmlAttr", "target", "queryParams", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "routerLink", "routerLinkActive", "routerLinkActiveOptions", "active", "click", 4, "ngSwitchDefault"], [3, "ngClass", "appHtmlAttr"], [3, "item"], [3, "click", "ngClass", "href", "appHtmlAttr"], [3, "click", "ngClass", "appHtmlAttr", "target", "queryParams", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "routerLink", "routerLinkActive", "routerLinkActiveOptions"]],
      template: function AppSidebarNavLinkComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainerStart(0, 0);
          i0.ɵɵtemplate(1, AppSidebarNavLinkComponent_a_1_Template, 3, 5, "a", 1)(2, AppSidebarNavLinkComponent_a_2_Template, 3, 6, "a", 2)(3, AppSidebarNavLinkComponent_a_3_Template, 3, 19, "a", 3);
          i0.ɵɵelementContainerEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngSwitch", ctx.linkType);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "disabled");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "external");
        }
      },
      dependencies: [NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass, AppSidebarNavLinkPipe, HtmlAttributesDirective, AppSidebarNavLinkContentComponent, RouterModule, i1.RouterLink, i1.RouterLinkActive],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavLinkComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-nav-link, cui-sidebar-nav-link',
      providers: [SidebarNavHelper],
      standalone: true,
      imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass, AppSidebarNavLinkPipe, HtmlAttributesDirective, AppSidebarNavLinkContentComponent, RouterModule],
      template: "<ng-container [ngSwitch]=\"linkType\">\n  <a *ngSwitchCase=\"'disabled'\"\n     [ngClass]=\"item | appSidebarNavLink\"\n     [appHtmlAttr]=\"item.attributes\"\n  >\n    <app-sidebar-nav-link-content [item]=\"item\"></app-sidebar-nav-link-content>\n  </a>\n  <a *ngSwitchCase=\"'external'\"\n     [ngClass]=\"item | appSidebarNavLink\"\n     [href]=\"href\"\n     [appHtmlAttr]=\"item.attributes\"\n     (click)=\"linkClicked()\"\n  >\n    <app-sidebar-nav-link-content [item]=\"item\"></app-sidebar-nav-link-content>\n  </a>\n  <a *ngSwitchDefault\n     [ngClass]=\"item | appSidebarNavLink\"\n     [appHtmlAttr]=\"item.attributes\"\n     [target]=\"item.attributes?.target\"\n     [queryParams]=\"item.linkProps?.queryParams\"\n     [fragment]=\"item.linkProps?.fragment\"\n     [queryParamsHandling]=\"item.linkProps?.queryParamsHandling\"\n     [preserveFragment]=\"!!item.linkProps?.preserveFragment\"\n     [skipLocationChange]=\"!!item.linkProps?.skipLocationChange\"\n     [replaceUrl]=\"!!item.linkProps?.replaceUrl\"\n     [state]=\"item.linkProps?.state\"\n     [routerLink]=\"item.url\"\n     [routerLinkActive]=\"item.linkProps?.routerLinkActive || 'active'\"\n     [routerLinkActiveOptions]=\"item.linkProps?.routerLinkActiveOptions ?? {exact: false}\"\n     [class.active]=\"linkActive && !item.linkProps?.routerLinkActiveOptions\"\n     (click)=\"linkClicked()\"\n  >\n    <app-sidebar-nav-link-content [item]=\"item\"></app-sidebar-nav-link-content>\n  </a>\n</ng-container>\n"
    }]
  }], () => [{
    type: i1.Router
  }], {
    item: [{
      type: Input
    }],
    linkClick: [{
      type: Output
    }]
  });
})();
class AppSidebarNavLabelComponent {
  constructor(helper) {
    this.helper = helper;
    this.item = {};
    this.classes = {
      'nav-label': true,
      active: true
    };
    this.iconClasses = {};
  }
  ngOnInit() {
    this.iconClasses = this.helper.getIconClass(this.item);
  }
  getItemClass() {
    const itemClass = this.item.class;
    // @ts-ignore
    this.classes[itemClass] = !!itemClass;
    return this.classes;
  }
  getLabelIconClass() {
    const variant = `text-${this.item.label?.variant}`;
    // @ts-ignore
    this.iconClasses[variant] = !!this.item.label?.variant;
    const labelClass = this.item.label?.class;
    // @ts-ignore
    this.iconClasses[labelClass] = !!labelClass;
    return this.iconClasses;
  }
  static {
    this.ɵfac = function AppSidebarNavLabelComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavLabelComponent)(i0.ɵɵdirectiveInject(SidebarNavHelper));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarNavLabelComponent,
      selectors: [["app-sidebar-nav-label"], ["cui-sidebar-nav-label"]],
      inputs: {
        item: "item"
      },
      decls: 5,
      vars: 6,
      consts: [[3, "ngClass", "href", "appHtmlAttr"], [3, "ngClass", 4, "ngIf"], [3, "ngClass"]],
      template: function AppSidebarNavLabelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "a", 0);
          i0.ɵɵtemplate(1, AppSidebarNavLabelComponent_i_1_Template, 1, 1, "i", 1);
          i0.ɵɵelementContainerStart(2);
          i0.ɵɵtext(3);
          i0.ɵɵelementContainerEnd();
          i0.ɵɵtemplate(4, AppSidebarNavLabelComponent_span_4_Template, 3, 4, "span", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵpropertyInterpolate("href", ctx.item.url, i0.ɵɵsanitizeUrl);
          i0.ɵɵproperty("ngClass", ctx.getItemClass())("appHtmlAttr", ctx.item.attributes);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.helper.hasIcon(ctx.item));
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.item.name);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.helper.hasBadge(ctx.item));
        }
      },
      dependencies: [AppSidebarNavBadgePipe, HtmlAttributesDirective, NgIf, NgClass],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavLabelComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-nav-label, cui-sidebar-nav-label',
      standalone: true,
      imports: [AppSidebarNavBadgePipe, HtmlAttributesDirective, NgIf, NgClass],
      template: "<a [ngClass]=\"getItemClass()\"\n   href=\"{{item.url}}\"\n   [appHtmlAttr]=\"item.attributes\">\n  <i *ngIf=\"helper.hasIcon(item)\" [ngClass]=\"getLabelIconClass()\"></i>\n  <ng-container>{{item.name}}</ng-container>\n  <span *ngIf=\"helper.hasBadge(item)\" [ngClass]=\"item | appSidebarNavBadge\">{{ item.badge?.text }}</span>\n</a>\n"
    }]
  }], () => [{
    type: SidebarNavHelper
  }], {
    item: [{
      type: Input
    }]
  });
})();
class AppSidebarNavTitleComponent {
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
    this.item = {};
  }
  ngOnInit() {
    const nativeElement = this.el.nativeElement;
    const name = this.renderer.createText(this.item.name);
    if (this.item.class) {
      const classes = this.item.class;
      this.renderer.addClass(nativeElement, classes);
    }
    if (this.item.wrapper) {
      const wrapper = this.renderer.createElement(this.item.wrapper.element);
      this.addAttribs(this.item.wrapper.attributes, wrapper);
      this.renderer.appendChild(wrapper, name);
      this.renderer.appendChild(nativeElement, wrapper);
    } else {
      this.renderer.appendChild(nativeElement, name);
    }
  }
  addAttribs(attribs, element) {
    if (attribs) {
      for (const attr in attribs) {
        if (attr === 'style' && typeof attribs[attr] === 'object') {
          this.setStyle(attribs[attr], element);
        } else if (attr === 'class') {
          this.addClass(attribs[attr], element);
        } else {
          this.setAttrib(attr, attribs[attr], element);
        }
      }
    }
  }
  setStyle(styles, el) {
    for (const style in styles) {
      this.renderer.setStyle(el, style, styles[style]);
    }
  }
  addClass(classes, el) {
    const classArray = Array.isArray(classes) ? classes : classes.split(' ');
    classArray.filter(element => element.length > 0).forEach(element => {
      this.renderer.addClass(el, element);
    });
  }
  setAttrib(key, value, el) {
    this.renderer.setAttribute(el, key, value);
  }
  static {
    this.ɵfac = function AppSidebarNavTitleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavTitleComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarNavTitleComponent,
      selectors: [["app-sidebar-nav-title"], ["cui-sidebar-nav-title"]],
      inputs: {
        item: "item"
      },
      decls: 0,
      vars: 0,
      template: function AppSidebarNavTitleComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavTitleComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-nav-title, cui-sidebar-nav-title',
      template: '',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    item: [{
      type: Input
    }]
  });
})();
class AppSidebarNavDividerComponent {
  constructor() {}
  static {
    this.ɵfac = function AppSidebarNavDividerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavDividerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarNavDividerComponent,
      selectors: [["app-sidebar-nav-divider"], ["cui-sidebar-nav-divider"]],
      inputs: {
        item: "item"
      },
      decls: 0,
      vars: 0,
      template: function AppSidebarNavDividerComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavDividerComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-nav-divider, cui-sidebar-nav-divider',
      template: ``,
      standalone: true
    }]
  }], () => [], {
    item: [{
      type: Input
    }]
  });
})();
class NavDropdownDirective {
  constructor(elementRef, renderer) {
    this.elementRef = elementRef;
    this.renderer = renderer;
  }
  toggle() {
    this.elementRef.nativeElement.classList.contains('open') ? this.renderer.removeClass(this.elementRef.nativeElement, 'open') : this.renderer.addClass(this.elementRef.nativeElement, 'open');
  }
  static {
    this.ɵfac = function NavDropdownDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NavDropdownDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NavDropdownDirective,
      selectors: [["", "appNavDropdown", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavDropdownDirective, [{
    type: Directive,
    args: [{
      selector: '[appNavDropdown]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], null);
})();
/**
 * Allows the dropdown to be toggled via click.
 */
class NavDropdownToggleDirective {
  constructor(dropdown) {
    this.dropdown = dropdown;
  }
  toggleOpen($event) {
    $event.preventDefault();
    this.dropdown.toggle();
  }
  static {
    this.ɵfac = function NavDropdownToggleDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NavDropdownToggleDirective)(i0.ɵɵdirectiveInject(NavDropdownDirective));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NavDropdownToggleDirective,
      selectors: [["", "appNavDropdownToggle", ""]],
      hostBindings: function NavDropdownToggleDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function NavDropdownToggleDirective_click_HostBindingHandler($event) {
            return ctx.toggleOpen($event);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavDropdownToggleDirective, [{
    type: Directive,
    args: [{
      selector: '[appNavDropdownToggle]',
      standalone: true
    }]
  }], () => [{
    type: NavDropdownDirective
  }], {
    toggleOpen: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();

// lightweight injection token
class DropdownToken {}
class AppSidebarNavItemsComponent {
  set items(items) {
    this._items = [...items];
  }
  get items() {
    return this._items;
  }
  constructor(document, renderer, router, helper) {
    this.document = document;
    this.renderer = renderer;
    this.router = router;
    this.helper = helper;
    this._items = [];
  }
  hideMobile() {
    if (this.document.body.classList.contains('sidebar-show')) {
      this.renderer.removeClass(this.document.body, 'sidebar-show');
    }
  }
  static {
    this.ɵfac = function AppSidebarNavItemsComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavItemsComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(SidebarNavHelper));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarNavItemsComponent,
      selectors: [["app-sidebar-nav-items"], ["cui-sidebar-nav-items"]],
      inputs: {
        items: "items"
      },
      decls: 1,
      vars: 1,
      consts: [[4, "ngFor", "ngForOf"], [3, "ngSwitch"], ["appNavDropdown", "", "routerLinkActive", "open", 3, "item", "open", "ngClass", 4, "ngSwitchCase"], [3, "item", "ngClass", "appHtmlAttr", 4, "ngSwitchCase"], ["class", "nav-item", 3, "item", "ngClass", 4, "ngSwitchCase"], [4, "ngSwitchCase"], ["class", "nav-item", 3, "item", "ngClass", "linkClick", 4, "ngSwitchDefault"], ["appNavDropdown", "", "routerLinkActive", "open", 3, "item", "ngClass"], [3, "item", "ngClass", "appHtmlAttr"], [1, "nav-item", 3, "item", "ngClass"], [1, "nav-item", 3, "linkClick", "item", "ngClass"]],
      template: function AppSidebarNavItemsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, AppSidebarNavItemsComponent_ng_container_0_Template, 8, 6, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", ctx.items);
        }
      },
      dependencies: () => [NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass, HtmlAttributesDirective, AppSidebarNavItemClassPipe, AppSidebarNavDropdownComponent, AppSidebarNavLinkComponent, AppSidebarNavLabelComponent, AppSidebarNavTitleComponent, AppSidebarNavDividerComponent, NavDropdownDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavItemsComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-nav-items, cui-sidebar-nav-items',
      template: `
    <ng-container *ngFor="let item of items">
      <ng-container [ngSwitch]="helper.itemType(item)">
        <app-sidebar-nav-dropdown
          *ngSwitchCase="'dropdown'"
          [item]="item"
          [class.open]="helper.isActive(router, item)"
          [ngClass]="item | appSidebarNavItemClass"
          appNavDropdown
          routerLinkActive="open">
        </app-sidebar-nav-dropdown>
        <app-sidebar-nav-divider
          *ngSwitchCase="'divider'"
          [item]="item"
          [ngClass]="item | appSidebarNavItemClass"
          [appHtmlAttr]="item.attributes">
        </app-sidebar-nav-divider>
        <app-sidebar-nav-title
          *ngSwitchCase="'title'"
          [item]="item"
          [ngClass]="item | appSidebarNavItemClass"
          [appHtmlAttr]="item.attributes">
        </app-sidebar-nav-title>
        <app-sidebar-nav-label
          *ngSwitchCase="'label'"
          [item]="item"
          class="nav-item"
          [ngClass]="item | appSidebarNavItemClass">
        </app-sidebar-nav-label>
        <ng-container
          *ngSwitchCase="'empty'">
        </ng-container>
        <app-sidebar-nav-link
          *ngSwitchDefault
          [item]="item"
          class="nav-item"
          [ngClass]="item | appSidebarNavItemClass"
          (linkClick)="hideMobile()"
        >
        </app-sidebar-nav-link>
      </ng-container>
    </ng-container>
  `,
      standalone: true,
      // providers: [{provide: DropdownToken, useExisting: forwardRef(()=> AppSidebarNavDropdownComponent)}],
      imports: [NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass, HtmlAttributesDirective, AppSidebarNavItemClassPipe, forwardRef(() => AppSidebarNavDropdownComponent), AppSidebarNavLinkComponent, AppSidebarNavLabelComponent, AppSidebarNavTitleComponent, AppSidebarNavDividerComponent, NavDropdownToggleDirective, NavDropdownDirective]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i1.Router
  }, {
    type: SidebarNavHelper
  }], {
    items: [{
      type: Input
    }]
  });
})();
class AppSidebarNavDropdownComponent {
  constructor(helper) {
    this.helper = helper;
    this.item = {};
  }
  static {
    this.ɵfac = function AppSidebarNavDropdownComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavDropdownComponent)(i0.ɵɵdirectiveInject(SidebarNavHelper));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarNavDropdownComponent,
      selectors: [["app-sidebar-nav-dropdown"], ["cui-sidebar-nav-dropdown"]],
      inputs: {
        item: "item"
      },
      features: [i0.ɵɵProvidersFeature([SidebarNavHelper])],
      decls: 6,
      vars: 6,
      consts: [["appNavDropdownToggle", "", 1, "nav-link", "nav-dropdown-toggle", 3, "appHtmlAttr"], [3, "ngClass", 4, "ngIf"], [1, "nav-dropdown-items", 3, "items"], [3, "ngClass"]],
      template: function AppSidebarNavDropdownComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "a", 0);
          i0.ɵɵtemplate(1, AppSidebarNavDropdownComponent_i_1_Template, 2, 3, "i", 1);
          i0.ɵɵelementContainerStart(2);
          i0.ɵɵtext(3);
          i0.ɵɵelementContainerEnd();
          i0.ɵɵtemplate(4, AppSidebarNavDropdownComponent_span_4_Template, 3, 4, "span", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(5, "app-sidebar-nav-items", 2);
        }
        if (rf & 2) {
          let tmp_4_0;
          i0.ɵɵproperty("appHtmlAttr", ctx.item.attributes);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.helper.hasIcon(ctx.item));
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.item.name);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.helper.hasBadge(ctx.item));
          i0.ɵɵadvance();
          i0.ɵɵproperty("items", (tmp_4_0 = ctx.item.children) !== null && tmp_4_0 !== undefined ? tmp_4_0 : i0.ɵɵpureFunction0(5, _c3));
        }
      },
      dependencies: [NgIf, NgClass, HtmlAttributesDirective, AppSidebarNavBadgePipe, AppSidebarNavIconPipe, AppSidebarNavItemsComponent, NavDropdownToggleDirective],
      styles: [".nav-dropdown-toggle[_ngcontent-%COMP%]{cursor:pointer}", ".nav-dropdown-items[_ngcontent-%COMP%]{display:block}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavDropdownComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-nav-dropdown, cui-sidebar-nav-dropdown',
      template: `
    <a class="nav-link nav-dropdown-toggle"
       appNavDropdownToggle
       [appHtmlAttr]="item.attributes">
      <i *ngIf="helper.hasIcon(item)" [ngClass]="item | appSidebarNavIcon"></i>
      <ng-container>{{item.name}}</ng-container>
      <span *ngIf="helper.hasBadge(item)" [ngClass]="item | appSidebarNavBadge">{{ item.badge?.text }}</span>
    </a>
    <app-sidebar-nav-items
      class="nav-dropdown-items"
      [items]="item.children ?? []">
    </app-sidebar-nav-items>
  `,
      providers: [SidebarNavHelper],
      standalone: true,
      imports: [NgIf, NgClass, HtmlAttributesDirective, AppSidebarNavBadgePipe, AppSidebarNavIconPipe, AppSidebarNavItemsComponent, NavDropdownToggleDirective],
      styles: [".nav-dropdown-toggle{cursor:pointer}\n", ".nav-dropdown-items{display:block}\n"]
    }]
  }], () => [{
    type: SidebarNavHelper
  }], {
    item: [{
      type: Input
    }]
  });
})();
class AppSidebarNavComponent {
  constructor(router) {
    this.router = router;
    this.navItems = [];
    this.sidebarNavClass = true;
    this.role = 'nav';
    this.navItemsArray = [];
  }
  ngOnChanges(changes) {
    this.navItemsArray = Array.isArray(this.navItems) ? this.navItems.slice() : [];
  }
  static {
    this.ɵfac = function AppSidebarNavComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarNavComponent)(i0.ɵɵdirectiveInject(i1.Router));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarNavComponent,
      selectors: [["app-sidebar-nav"], ["cui-sidebar-nav"]],
      hostVars: 3,
      hostBindings: function AppSidebarNavComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("role", ctx.role);
          i0.ɵɵclassProp("sidebar-nav", ctx.sidebarNavClass);
        }
      },
      inputs: {
        navItems: "navItems",
        role: "role"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 1,
      vars: 1,
      consts: [[1, "nav", 3, "items"]],
      template: function AppSidebarNavComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "app-sidebar-nav-items", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("items", ctx.navItemsArray);
        }
      },
      dependencies: [AppSidebarNavItemsComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarNavComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-nav, cui-sidebar-nav',
      standalone: true,
      imports: [AppSidebarNavItemsComponent],
      template: "<app-sidebar-nav-items\n  class=\"nav\"\n  [items]=\"navItemsArray\">\n</app-sidebar-nav-items>\n"
    }]
  }], () => [{
    type: i1.Router
  }], {
    navItems: [{
      type: Input
    }],
    sidebarNavClass: [{
      type: HostBinding,
      args: ['class.sidebar-nav']
    }],
    role: [{
      type: HostBinding,
      args: ['attr.role']
    }, {
      type: Input
    }]
  });
})();
class AppSidebarMinimizerComponent {
  toggleOpen($event) {
    $event.preventDefault();
    this.sidebarService.toggle({
      minimize: 'toggle'
    });
  }
  constructor(sidebarService) {
    this.sidebarService = sidebarService;
    this.role = 'button';
    this.sidebarMinimizerClass = true;
  }
  static {
    this.ɵfac = function AppSidebarMinimizerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarMinimizerComponent)(i0.ɵɵdirectiveInject(AppSidebarService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarMinimizerComponent,
      selectors: [["app-sidebar-minimizer"], ["cui-sidebar-minimizer"]],
      hostVars: 3,
      hostBindings: function AppSidebarMinimizerComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function AppSidebarMinimizerComponent_click_HostBindingHandler($event) {
            return ctx.toggleOpen($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("role", ctx.role);
          i0.ɵɵclassProp("sidebar-minimizer", ctx.sidebarMinimizerClass);
        }
      },
      inputs: {
        role: "role"
      },
      decls: 0,
      vars: 0,
      template: function AppSidebarMinimizerComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarMinimizerComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-minimizer, cui-sidebar-minimizer',
      template: ``,
      standalone: true
    }]
  }], () => [{
    type: AppSidebarService
  }], {
    role: [{
      type: HostBinding,
      args: ['attr.role']
    }, {
      type: Input
    }],
    sidebarMinimizerClass: [{
      type: HostBinding,
      args: ['class.sidebar-minimizer']
    }],
    toggleOpen: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class AppSidebarFooterComponent {
  constructor() {
    this.sidebarFooterClass = true;
  }
  static {
    this.ɵfac = function AppSidebarFooterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarFooterComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarFooterComponent,
      selectors: [["app-sidebar-footer"], ["cui-sidebar-footer"]],
      hostVars: 2,
      hostBindings: function AppSidebarFooterComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("sidebar-footer", ctx.sidebarFooterClass);
        }
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function AppSidebarFooterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarFooterComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-footer, cui-sidebar-footer',
      template: `<ng-content></ng-content>`,
      standalone: true
    }]
  }], () => [], {
    sidebarFooterClass: [{
      type: HostBinding,
      args: ['class.sidebar-footer']
    }]
  });
})();
class AppSidebarFormComponent {
  constructor() {
    this.sidebarFormClass = true;
  }
  static {
    this.ɵfac = function AppSidebarFormComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarFormComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarFormComponent,
      selectors: [["app-sidebar-form"], ["cui-sidebar-form"]],
      hostVars: 2,
      hostBindings: function AppSidebarFormComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("sidebar-form", ctx.sidebarFormClass);
        }
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function AppSidebarFormComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarFormComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-form, cui-sidebar-form',
      template: `<ng-content></ng-content>`,
      standalone: true
    }]
  }], () => [], {
    sidebarFormClass: [{
      type: HostBinding,
      args: ['class.sidebar-form']
    }]
  });
})();
class AppSidebarHeaderComponent {
  constructor() {
    this.sidebarHeaderClass = true;
  }
  static {
    this.ɵfac = function AppSidebarHeaderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarHeaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AppSidebarHeaderComponent,
      selectors: [["app-sidebar-header"], ["cui-sidebar-header"]],
      hostVars: 2,
      hostBindings: function AppSidebarHeaderComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("sidebar-header", ctx.sidebarHeaderClass);
        }
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function AppSidebarHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'app-sidebar-header, cui-sidebar-header',
      template: `<ng-content></ng-content>`,
      standalone: true
    }]
  }], () => [], {
    sidebarHeaderClass: [{
      type: HostBinding,
      args: ['class.sidebar-header']
    }]
  });
})();
class AppSidebarModule {
  static {
    this.ɵfac = function AppSidebarModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppSidebarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AppSidebarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [SidebarNavHelper, AppSidebarService],
      imports: [RouterModule, AppSidebarNavComponent, AppSidebarNavItemsComponent, AppSidebarNavDropdownComponent, AppSidebarNavLinkComponent, LayoutModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSidebarModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule, AppSidebarComponent, AppSidebarFooterComponent, AppSidebarFormComponent, AppSidebarHeaderComponent, AppSidebarMinimizerComponent, AppSidebarMinimizerComponent, AppSidebarNavComponent, NavDropdownDirective, AppSidebarNavItemsComponent, AppSidebarNavDividerComponent, AppSidebarNavDropdownComponent, AppSidebarNavLinkContentComponent, AppSidebarNavTitleComponent, AppSidebarNavLabelComponent, AppSidebarNavIconPipe, AppSidebarNavBadgePipe, AppSidebarNavLinkPipe, AppSidebarNavItemClassPipe, AppSidebarNavLinkComponent, NavDropdownToggleDirective, LayoutModule],
      exports: [AppSidebarFooterComponent, AppSidebarFormComponent, AppSidebarHeaderComponent, AppSidebarMinimizerComponent, AppSidebarComponent,
      // AppSidebarNavItemsComponent,
      AppSidebarNavComponent
      // AppSidebarNavDividerComponent,
      // AppSidebarNavDropdownComponent,
      // AppSidebarNavLabelComponent,
      // AppSidebarNavLinkComponent,
      // AppSidebarNavLinkContentComponent,
      // AppSidebarNavTitleComponent,
      // NavDropdownDirective,
      // NavDropdownToggleDirective,
      ],
      providers: [SidebarNavHelper, AppSidebarService]
    }]
  }], null, null);
})();

// export { NavDropdownDirective, NavDropdownToggleDirective } from './app-sidebar-nav.directive';
// export { AppSidebarNavDividerComponent } from './app-sidebar-nav/app-sidebar-nav-divider.component';
// export { AppSidebarNavDropdownComponent } from './app-sidebar-nav/app-sidebar-nav-dropdown.component';
// export { AppSidebarNavItemsComponent } from './app-sidebar-nav/app-sidebar-nav-items.component';
// export { AppSidebarNavLinkComponent, AppSidebarNavLinkContentComponent } from './app-sidebar-nav/app-sidebar-nav-link.component';
// export { AppSidebarNavTitleComponent } from './app-sidebar-nav/app-sidebar-nav-title.component';
// export { AppSidebarNavLabelComponent } from './app-sidebar-nav/app-sidebar-nav-label.component';

/*
 * Public API Surface of @coreui/angular
 */
// export * from './lib/shared/index';
// export * from './lib/coreui.module';

/**
 * Generated bundle index. Do not edit.
 */

export { AppAsideComponent, AppAsideModule, AppBreadcrumbComponent, AppBreadcrumbModule, AppFooterComponent, AppFooterModule, AppHeaderComponent, AppHeaderModule, AppSidebarComponent, AppSidebarFooterComponent, AppSidebarFormComponent, AppSidebarHeaderComponent, AppSidebarMinimizerComponent, AppSidebarModule, AppSidebarNavComponent, CuiBreadcrumbComponent, SidebarNavHelper };
