export const allNavItems = [
    {
        name: 'Dashboard',
        url: '/app/dashboard',
        icon: 'fas fa-tachometer-alt',
    },
    {
        name: 'Übersicht',
        url: '/app/overview',
        icon: 'fas fa-chart-pie',
        right: 'DASHBOARD_OVERVIEW_VIEW',
    },
    {
        name: 'Fileshare',
        url: '/app/fileshare',
        icon: 'fas fa-file-import',
    },
    {
        name: 'Tags',
        url: '/app/tag',
        icon: 'fas fa-tags',
    },
    {
        title: true,
        name: 'Verwaltung',
    },
    {
        name: 'Dokumente',
        url: '/app/document',
        icon: 'fas fa-file-invoice',
        right: "DOCUMENT_VIEW",
        children: [
            {
                name: 'Liste',
                url: '/app/document/list',
                icon: 'fas fa-list'
            },
            {
                name: 'Upload',
                url: '/app/document/upload',
                icon: 'fas fa-file-upload',
                right: "DOCUMENT_UPLOAD",
            },
            {
                name: 'Mehrfachupload',
                url: '/app/document/uploadmultiple',
                icon: 'fas fa-file-upload',
                right: "DOCUMENT_UPLOAD",
            },
            {
                name: 'Attributsuche',
                url: '/app/document/attributes',
                icon: 'fas fa-search'
            },
            {
                name: 'Posteingang',
                url: '/app/document/inbox',
                icon: 'fas fa-envelope'
            },
        ],
    },
    {
        name: 'Aufgaben',
        url: '/app/task/list',
        icon: 'fas fa-tasks',
    },
    {
        name: 'Termine',
        url: '/app/taskdate',
        icon: 'fas fa-business-time',
        children: [
            {
                name: 'Liste',
                url: '/app/taskdate/list',
                icon: 'fas fa-list'
            },
            {
                name: 'Kalender',
                url: '/app/taskdate/calendar',
                icon: 'far fa-calendar-alt'
            }
        ],
    },
    {
        title: true,
        name: 'Stammdaten',
        right: "MASTER_DATA_VIEW",
    },
    {
        name: 'Benutzer',
        url: '/app/user',
        icon: 'fas fa-user-friends',
        right: "MASTER_DATA_VIEW",
    },
    {
        name: 'Abteilungen',
        url: '/app/department',
        icon: 'fas fa-user-tie',
        right: "MASTER_DATA_VIEW",
    },
    {
        name: 'Unternehmen',
        url: '/app/company',
        icon: 'fas fa-briefcase',
        right: "MASTER_DATA_VIEW",
    },
    {
        name: 'Betriebsstätten',
        url: '/app/location',
        icon: 'fas fa-building',
        right: "MASTER_DATA_VIEW",
        children: [
            {
                name: 'Liste',
                url: '/app/location/list',
                icon: 'fas fa-list'
            },
            {
                name: 'Attribute',
                url: '/app/location/attributes',
                icon: 'fas fa-cubes'
            },
            {
                name: 'Attributsuche',
                url: '/app/location/search',
                icon: 'fas fa-search'
            },
        ],
    },
    {
        name: 'Dokumenttypen',
        url: '/app/filetype',
        icon: 'fas fa-file-alt',
        right: "MASTER_DATA_VIEW",
    },
    {
        name: 'Aufgabenthemen',
        url: '/app/tasktopic',
        icon: 'fas fa-tag',
        right: "MASTER_DATA_VIEW",
    },
    {
        name: 'Aufgabentypen',
        url: '/app/tasktype',
        icon: 'fas fa-tag',
        right: "MASTER_DATA_VIEW",
    },
    {
        title: true,
        name: 'Administration',
        right: "ADMIN_ACCESS",
    },
    {
        name: 'Mail Log',
        url: '/app/maillog',
        icon: 'fas fa-table',
        right: "ADMIN_ACCESS",
    },
    {
        name: 'Fileshare Log',
        url: '/app/filesharelog',
        icon: 'fas fa-share',
        right: "ADMIN_ACCESS",
    },
    {
        name: 'Rechteverwaltung',
        url: '/app/role',
        icon: 'fas fa-user-lock',
        right: "ADMIN_ACCESS",
    },
    {
        name: 'Sichtbarkeitsgruppen',
        url: '/app/visibility-group',
        icon: 'fas fa-eye',
        right: "ADMIN_ACCESS",
    },
];
