import {Component, OnInit, inject} from '@angular/core';
import {TableColumnFilterType, TableConfig} from '../table/table.interfaces';
import {UserService} from '../../services/user.service';
import {NgIf} from '@angular/common';
import {TableComponent} from '../table/table.component';

@Component({
    selector: 'app-file-share-log',
    templateUrl: './file-share-log.component.html',
    styleUrl: './file-share-log.component.scss',
    imports: [NgIf, TableComponent]
})
export class FileShareLogComponent implements OnInit {

    private userService = inject(UserService);

    tableConfig!: TableConfig;

    ngOnInit(): void {
        this.tableConfig = {
            titleIcon: 'fas fa-share',
            title: 'Fileshare Log',
            openDatasetRouterLink: null,
            enableCreate: false,
            enableExportExcel: true,
            description: 'Das Fileshare Log zeigt Ihnen, welche FileLink Dateien wann heruntergeladen wurden.',
            dataConfig: {
                tableName: 'file_download',
                tableIndexName: 'FDOID',
                tableJoins: [
                    {left: 'files', right: 'file_download', key: 'FID'},
                ],
                tableFields: [
                    {
                        key: 'filename',
                        table: 'files',
                        name: 'display_name',
                        title: 'Datei',
                        sortable: true,
                        searchable: true
                    },
                    {key: 'ip', name: 'ip', title: 'IP', sortable: true, searchable: true},
                    {key: 'browser', name: 'browser', title: 'Browser', sortable: true, searchable: true},
                    {
                        key: 'created',
                        name: 'created',
                        title: 'Datum',
                        sortable: true,
                        searchable: true,
                        dateFormat: {en: '%Y.%m.%d %H:%i', others: '%d.%m.%Y %H:%i'}
                    },
                ],
                reverse: false,
                order: 'created',
                columnFilter: {
                    enabled: true,
                    fields: {
                        filename: {value: '', type: TableColumnFilterType.INPUT},
                        ip: {value: '', type: TableColumnFilterType.INPUT},
                        browser: {value: '', type: TableColumnFilterType.INPUT},
                    }
                }
            },
            localStorage: {
                version: 2,
                name: 'fileshare-log-list',
                enabled: true
            },
            paginConfig: {
                id: 'fileshareLogList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }

}
