<div class="text-muted" *ngIf="attributes.length == 0">Es wurden noch keine Attribute erstellt!</div>

<form [formGroup]="form" *ngIf="attributes.length > 0">
    <table class="table table-striped table-hover table-sm mb-0">
        <thead>
        <tr>
            <th style="width: 100px" *ngIf="userService.hasRight('ADMIN_ACCESS')"></th>
            <th>Name</th>
            <th>Wert</th>
        </tr>
        </thead>
        <tbody formArrayName="attributes">
        <tr [formGroupName]="i" *ngFor="let attribute of formService.getFormArray(form, 'attributes').controls; let i = index">
            <td [attr.data-right]="'DOCUMENT_ATTRIBUTE_EDIT' | right" *ngIf="userService.hasRight('DOCUMENT_ATTRIBUTE_EDIT')">
                <ng-container *ngIf="!attribute.value._editing">
                    <button type="button" class="btn btn-primary btn-xs" (click)="edit(attribute)"><i class="fas fa-pencil-alt fa-fw"></i></button>
                </ng-container>
                <ng-container *ngIf="attribute.value._editing">
                    <button type="button" class="btn btn-secondary btn-xs mr-2" (click)="cancelEdit(attribute)"><i class="fas fa-times fa-fw"></i></button>
                    <button type="button" class="btn btn-primary btn-xs" (click)="update(attribute)"><i class="fas fa-check fa-fw"></i></button>
                </ng-container>
            </td>
            <td>{{ attribute.value.name }}</td>
            <td>
                <ng-container *ngIf="attribute.value.type == 'int'">
                    <ng-container *ngIf="!attribute.value._editing">
                        <ng-container *ngIf="attribute.value.value_int">{{ attribute.value.value_int | number: '1.0' }}</ng-container>
                        <span class="text-muted" *ngIf="!attribute.value.value_int">Nicht gesetzt</span>
                    </ng-container>
                    <input type="number" class="form-control" formControlName="value_int" *ngIf="attribute.value._editing">
                </ng-container>
                <ng-container *ngIf="attribute.value.type == 'dec'">
                    <ng-container *ngIf="!attribute.value._editing">
                        <ng-container *ngIf="attribute.value.value_dec">{{ attribute.value.value_dec | number: '1.0-2' }}</ng-container>
                        <span class="text-muted" *ngIf="!attribute.value.value_dec">Nicht gesetzt</span>
                    </ng-container>
                    <input type="number" step="0.01" class="form-control" formControlName="value_dec" *ngIf="attribute.value._editing">
                </ng-container>
                <ng-container *ngIf="attribute.value.type == 'text'">
                    <ng-container *ngIf="!attribute.value._editing">
                        <ng-container *ngIf="attribute.value.value_text">{{ attribute.value.value_text }}</ng-container>
                        <span class="text-muted" *ngIf="!attribute.value.value_text">Nicht gesetzt</span>
                    </ng-container>
                    <input type="text" step="0.01" class="form-control" formControlName="value_text" *ngIf="attribute.value._editing">
                </ng-container>
                <ng-container *ngIf="attribute.value.type == 'boolean'">
                    <ng-container *ngIf="!attribute.value._editing">
                        <ng-container *ngIf="attribute.value.value_boolean">{{ attribute.value.value_boolean | yesNo }}</ng-container>
                        <span class="text-muted" *ngIf="!attribute.value.value_boolean">Nicht gesetzt</span>
                    </ng-container>
                    <input type="checkbox" class="form-check-input" formControlName="value_boolean" *ngIf="attribute.value._editing">
                </ng-container>
                <ng-container *ngIf="attribute.value.type == 'date'">
                    <ng-container *ngIf="!attribute.value._editing">
                        <ng-container *ngIf="attribute.value.value_date">{{ attribute.value.value_date | date: "dd.MM.yyyy" }}</ng-container>
                        <span class="text-muted" *ngIf="!attribute.value.value_date">Nicht gesetzt</span>
                    </ng-container>
                    <input type="date" class="form-control" formControlName="value_date" *ngIf="attribute.value._editing">
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>
</form>
