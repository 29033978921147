import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'reminderInterval',
    standalone: true
})
export class ReminderIntervalPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === 'custom') {
            return 'Eigene Auswahl';
        } else if (value === 'daily') {
            return 'Täglich';
        } else if (value === 'weekly') {
            return 'Wöchentlich';
        } else if (value === 'monthly') {
            return 'Monatlich';
        } else if (value === 'yearly') {
            return 'Jährlich';
        } else if (value === 'week_earlier') {
            return 'Eine Woche vor Fälligkeit';
        } else if (value === 'month_earlier') {
            return 'Ein Monat vor Fälligkeit';
        } else {
            return '';
        }
    }

}
