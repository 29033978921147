import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrl: './imprint.component.scss',
    standalone: true
})
export class ImprintComponent implements OnInit {

    currentYear!: number;

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
    }

}
