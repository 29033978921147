import {Component, OnInit, ViewChild, inject} from '@angular/core';
import {UserService} from '../../../global/services/user.service';
import {TableColumnFilterType, TableConfig} from '../../../global/components/table/table.interfaces';
import {DataService} from '../../../global/services/data.service';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {MapService} from '../../../global/components/map/map.service';
import {MapComponent} from '../../../global/components/map/map.component';
import {NgIf} from '@angular/common';
import {TableComponent} from '../../../global/components/table/table.component';

@Component({
    selector: 'app-location-list',
    templateUrl: './location-list.component.html',
    styleUrl: './location-list.component.scss',
    imports: [NgIf, TableComponent, MapComponent, TranslateModule]
})
export class LocationListComponent implements OnInit {

    private userService = inject(UserService);
    private dataService = inject(DataService);
    private translateService = inject(TranslateService);
    private mapService = inject(MapService);

    @ViewChild('mapComponent') mapComponent!: MapComponent;

    tableConfig!: TableConfig;
    maxLocations: any;
    numLocations: any;

    ngOnInit(): void {
        this.dataService.request('Location/count').subscribe(response => {
            this.numLocations = parseInt(response.count, 10);

            this.dataService.request('universe.Client/getMaxLocations', {
                CLID: this.userService.currentUser.CLID
            }).subscribe(response2 => {
                this.maxLocations = parseInt(response2.maxLocations, 10);

                this.createConfig();
            });
        });

        this.dataService.request('Location/listAll').subscribe(response => {
            const locations = response.locations.filter((location: any) => location.lat != null);

            this.mapComponent.addMarkerData(locations.map((location: any) => {
                return {
                    latLng: new google.maps.LatLng(parseFloat(location.lat), parseFloat(location.lng)),
                    infoWindow:
                        '<b>Unternehmen:</b> ' + location.company + '<br/>' +
                        '<b>' + this.translateService.instant('LOCATION.LOCATION') + ':</b> ' + location.name
                };
            }));
        });
    }

    createConfig(): void {
        this.tableConfig = {
            titleIcon: 'far fa-building',
            title: this.translateService.instant('LOCATION.LOCATIONS'),
            openDatasetRouterLink: '/app/location/detail',
            enableCreate: this.userService.hasRight('MASTER_DATA_EDIT') && this.numLocations < this.maxLocations,
            enableExportExcel: true,
            description: 'Ihre ' + this.translateService.instant('LOCATION.LOCATIONS') + ' - jedes Dokument wird einer ' + this.translateService.instant('LOCATION.LOCATION') + ' zugeordnet.',
            dataConfig: {
                tableName: 'location',
                tableIndexName: 'LID',
                tableJoins: [
                    {left: 'company', right: 'location', key: 'CID'},
                ],
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {
                        key: 'company',
                        table: 'company',
                        name: 'name',
                        title: 'Unternehmen',
                        sortable: true,
                        searchable: true
                    },
                    {key: 'country', name: 'country', title: 'Land', sortable: true, searchable: true},
                    {key: 'zip', name: 'zip', title: 'PLZ', sortable: true, searchable: true},
                    {key: 'city', name: 'city', title: 'Stadt', sortable: true, searchable: true},
                    {key: 'street', name: 'street', title: 'Straße', sortable: true, searchable: true},
                ],
                reverse: false,
                order: 'name',
                columnFilter: {
                    enabled: true,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                        country: {value: '', type: TableColumnFilterType.GROUP},
                        city: {value: '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 2,
                name: 'location-list',
                enabled: true
            },
            paginConfig: {
                id: 'locationList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }

}
