import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {JwtService, FwTokenType} from '../services/jwt.service';
import {UserService} from '../services/user.service';
import {myAppConfig} from '../../settings';

export const LoggedInGuard: CanActivateFn = (route, state) => {

    const router = inject(Router);
    const userService = inject(UserService);
    const jwtService = inject(JwtService);

    if (!jwtService.getToken(FwTokenType.ACCESS_TOKEN)) {
        // if there is no token, get one, then redirect to login
        return userService.newAnonymous().pipe(
            map(() => {
                myAppConfig.auth.afterLoginDestination = state.url;

                router.navigate([myAppConfig.auth.loginDestination]);
                return false;
            })
        );
    } else {
        // if there is a token, update userdata
        return userService.getUserData().pipe(
            map(() => {
                // if there is a user continue, else redirect to login
                if (userService.currentUser) {
                    return true;
                } else {
                    myAppConfig.auth.afterLoginDestination = state.url;

                    router.navigate([myAppConfig.auth.loginDestination]);
                    return false;
                }
            })
        );
    }
}
