import {Component, OnInit, ViewChild, inject} from '@angular/core';
import {
    TableColumnFilterType,
    TableConfig, TableFieldAlignment,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {UserService} from '../../../global/services/user.service';
import {TableComponent} from '../../../global/components/table/table.component';
import {DataService} from '../../../global/services/data.service';
import {ToastrService} from "ngx-toastr";
import {NgIf, NgFor} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {FileTypePipe} from "../../../global/pipes/file-type.pipe";
import {YesNoPipe} from "../../../global/pipes/yes-no.pipe";

@Component({
    selector: 'app-file-type-list',
    templateUrl: './file-type-list.component.html',
    styleUrl: './file-type-list.component.scss',
    imports: [TableComponent, NgIf, FormsModule, NgFor, TranslateModule, RightPipe, FileTypePipe, YesNoPipe]
})
export class FileTypeListComponent implements OnInit {

    userService = inject(UserService);
    private dataService = inject(DataService);
    private toastrService = inject(ToastrService);

    @ViewChild('tableComponent') tableComponent!: TableComponent;

    tableConfig!: TableConfig;
    setDocumentType: any = '';
    standardSets!: any[];

    ngOnInit(): void {
        this.listStandardSets();

        this.tableConfig = {
            titleIcon: 'far fa-file-alt',
            title: 'Dokumenttypen',
            openDatasetRouterLink: '/app/filetype/detail/',
            enableCreate: this.userService.hasRight('MASTER_DATA_EDIT'),
            enableExportExcel: true,
            description: 'Zur Kategorisierung von Dokumenten, können Sie hier Typen mit beliebig vielen Attributen anlegen. Die Attribute erlauben es dann, Dokumente mit Metadaten zu versehen.',
            dataConfig: {
                tableName: 'filetype',
                tableIndexName: 'FTID',
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {
                        key: 'attributes',
                        subquery: '(SELECT COUNT(*) FROM attribute_template WHERE FTID = filetype.FTID)',
                        title: 'Attribute'
                    },
                    {
                        key: 'used',
                        subquery: '(SELECT COUNT(*) FROM document WHERE FTID = filetype.FTID)',
                        sortable: true,
                        title: '# Zugewiesen',
                        alignment: TableFieldAlignment.RIGHT
                    }
                ],
                rowImage: [
                    {
                        key: 'used',
                        icon: 'fa-exclamation-triangle',
                        color: '#ffc107',
                        conditions: [
                            {key: 'used', value: '0', operator: TableRowColorConditionOperator.EQUAL},
                        ],
                        tooltip: 'Dokumenttyp wurde nirgends zugewiesen'
                    },
                ],
                reverse: false,
                order: 'name',
                columnFilter: {
                    enabled: false,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'filetype-list',
                enabled: true
            },
            paginConfig: {
                id: 'fileTypeList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            },
        };
    }

    loadSet(set: any): void {
        this.dataService.request('FileType/loadSet', {
            client: this.userService.currentUser.client_name,
            set
        }).subscribe(response => {
            this.setDocumentType = '';
            this.tableComponent.load();
            this.toastrService.success('Das Standardset wurde geladen');
        });
    }

    listStandardSets() {
        this.dataService.request('FileType/listSets').subscribe(response => {
            this.standardSets = response.sets;

            // note: required is returned as number in this case, since it is loaded from a json file
            this.standardSets.forEach((set: any) => {
                set.elements.forEach((element: any) => {
                    element.required = element.required === 1;
                });
            });
        });
    }

}
