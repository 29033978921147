import {Routes, RunGuardsAndResolvers} from '@angular/router';
import {TokenGuard} from './global/guards/token.guard';
import {LoggedInGuard} from './global/guards/loggedIn.guard';
import {RightsLoadedGuard} from './global/guards/rights-loaded.guard';
import {PublicComponent} from "./public/public.component";
import {StartComponent} from "./public/start/start.component";
import {LoginComponent} from "./public/login/login.component";
import {OfferComponent} from "./public/offer/offer.component";
import {ResetPasswordComponent} from "./public/reset-password/reset-password.component";
import {FaqComponent} from "./public/other/faq/faq.component";
import {ImprintComponent} from "./public/other/imprint/imprint.component";
import {PrivacyComponent} from "./public/other/privacy/privacy.component";
import {TermsComponent} from "./public/other/terms/terms.component";
import {FileLinkComponent} from "./global/components/file-link/file-link.component";
import {ContactComponent} from "./public/other/contact/contact.component";
import {BenefitsComponent} from "./public/other/benefits/benefits.component";
import {FeaturesComponent} from "./public/other/features/features.component";
import {PricingComponent} from "./public/other/pricing/pricing.component";
import {ErrorsComponent} from "./errors/errors.component";
import {AccountDisabledComponent} from "./errors/account-disabled/account-disabled.component";
import {AccountErrorComponent} from "./errors/account-error/account-error.component";
import {PageNotFoundComponent} from "./errors/page-not-found/page-not-found.component";
import {DefaultLayoutComponent} from "./pages/containers";
import {DashboardComponent} from "./global/components/dashboard/dashboard.component";
import {OverviewComponent} from "./global/components/overview/overview.component";
import {FileShareComponent} from "./global/components/file-share/file-share.component";
import {TagSearchComponent} from "./global/components/tag-search/tag-search.component";
import {RightGuard} from "./global/guards/right.guard";
import {MailLogComponent} from "./global/components/mail-log/mail-log.component";
import {FileShareLogComponent} from "./global/components/file-share-log/file-share-log.component";
import {RoleComponent} from "./pages/role/role.component";
import {RoleListComponent} from "./pages/role/role-list/role-list.component";
import {RoleDetailComponent} from "./pages/role/role-detail/role-detail.component";
import {VisibilityGroupComponent} from "./pages/visibility-group/visibility-group.component";
import {
    VisibilityGroupListComponent
} from "./pages/visibility-group/visibility-group-list/visibility-group-list.component";
import {
    VisibilityGroupDetailComponent
} from "./pages/visibility-group/visibility-group-detail/visibility-group-detail.component";
import {FileTypeComponent} from "./pages/file-type/file-type.component";
import {FileTypeListComponent} from "./pages/file-type/file-type-list/file-type-list.component";
import {FileTypeDetailComponent} from "./pages/file-type/file-type-detail/file-type-detail.component";
import {BreadcrumbResolver} from "./global/components/breadcrumb/breadcrumb.resolver";
import {DepartmentComponent} from "./pages/department/department.component";
import {DepartmentListComponent} from "./pages/department/department-list/department-list.component";
import {DepartmentDetailComponent} from "./pages/department/department-detail/department-detail.component";
import {TaskTypeComponent} from "./pages/task-type/task-type.component";
import {TaskTypeListComponent} from "./pages/task-type/task-type-list/task-type-list.component";
import {TaskTypeDetailComponent} from "./pages/task-type/task-type-detail/task-type-detail.component";
import {TaskTopicComponent} from "./pages/task-topic/task-topic.component";
import {TaskTopicListComponent} from "./pages/task-topic/task-topic-list/task-topic-list.component";
import {TaskTopicDetailComponent} from "./pages/task-topic/task-topic-detail/task-topic-detail.component";
import {TaskDateComponent} from "./pages/task-date/task-date.component";
import {TaskDateListComponent} from "./pages/task-date/task-date-list/task-date-list.component";
import {TaskDateDetailComponent} from "./pages/task-date/task-date-detail/task-date-detail.component";
import {TaskDateCalendarComponent} from "./pages/task-date/task-date-calendar/task-date-calendar.component";
import {CompanyComponent} from "./pages/company/company.component";
import {CompanyListComponent} from "./pages/company/company-list/company-list.component";
import {CompanyDetailComponent} from "./pages/company/company-detail/company-detail.component";
import {TaskComponent} from "./pages/task/task.component";
import {TaskListComponent} from "./pages/task/task-list/task-list.component";
import {TaskDetailComponent} from "./pages/task/task-detail/task-detail.component";
import {DocumentComponent} from "./pages/document/document.component";
import {DocumentListComponent} from "./pages/document/document-list/document-list.component";
import {DocumentUploadComponent} from "./pages/document/document-upload/document-upload.component";
import {DocumentInboxComponent} from "./pages/document/document-inbox/document-inbox.component";
import {
    DocumentUploadmultipleComponent
} from "./pages/document/document-uploadmultiple/document-uploadmultiple.component";
import {DocumentAttributesComponent} from "./pages/document/document-attributes/document-attributes.component";
import {DocumentDetailComponent} from "./pages/document/document-detail/document-detail.component";
import {LocationComponent} from "./pages/location/location.component";
import {LocationListComponent} from "./pages/location/location-list/location-list.component";
import {LocationDetailComponent} from "./pages/location/location-detail/location-detail.component";
import {LocationAttributesComponent} from "./pages/location/location-attributes/location-attributes.component";
import {
    LocationAttributesSearchComponent
} from "./pages/location/location-attributes-search/location-attributes-search.component";
import {UserComponent} from "./pages/user/user.component";
import {UserDetailComponent} from "./pages/user/user-detail/user-detail.component";
import {UserAccountComponent} from "./pages/user/user-account/user-account.component";
import {UserListComponent} from "./pages/user/user-list/user-list.component";
import {AdminComponent} from "./admin/admin.component";
import {AdminDashboardComponent} from "./admin/admin-dashboard/admin-dashboard.component";
import {AdminClientDetailComponent} from "./admin/admin-client-detail/admin-client-detail.component";
import {AdminDocumentListComponent} from "./admin/admin-document-list/admin-document-list.component";
import {AdminConsultingListComponent} from "./admin/admin-consulting-list/admin-consulting-list.component";
import {AdminDocumentDetailComponent} from "./admin/admin-document-detail/admin-document-detail.component";
import {AdminTaskDetailComponent} from "./admin/admin-task-detail/admin-task-detail.component";
import {AdminGuard} from "./global/guards/admin.guard";
import {AdminLoginComponent} from "./admin/admin-login/admin-login.component";

export const routes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        data: {title: 'Systemadministration'},
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: 'login', component: AdminLoginComponent},
            {path: 'dashboard', component: AdminDashboardComponent, data: {title: 'Dashboard'}, canActivate: [AdminGuard]},
            {path: 'detail/:id', component: AdminClientDetailComponent, data: {title: 'Mandantendetail'}, canActivate: [AdminGuard]},
            {path: 'document/list', component: AdminDocumentListComponent, data: {title: 'Ungeprüfte OCR Texte'}, canActivate: [AdminGuard]},
            {path: 'consulting/list', component: AdminConsultingListComponent, data: {title: 'Angefragtes Consulting'}, canActivate: [AdminGuard]},
            {path: 'document/detail/:id', component: AdminDocumentDetailComponent, data: {title: 'Dokumentdetail'}, canActivate: [AdminGuard]},
            {path: 'task/detail/:id', component: AdminTaskDetailComponent, data: {title: 'Task'}, canActivate: [AdminGuard]},
        ]
    },
    {
        path: '',
        component: PublicComponent,
        canActivate: [TokenGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {path: '', component: StartComponent, pathMatch: 'full'},
            {path: 'login', component: LoginComponent},
            {path: 'offer', component: OfferComponent},
            {path: 'reset-password/:hash', component: ResetPasswordComponent},
            {path: 'faq', component: FaqComponent},
            {path: 'imprint', component: ImprintComponent},
            {path: 'privacy', component: PrivacyComponent},
            {path: 'terms', component: TermsComponent},
            {path: 'filelink/:hash', component: FileLinkComponent},
            {path: 'contact', component: ContactComponent},
            {path: 'benefits', component: BenefitsComponent},
            {path: 'features', component: FeaturesComponent},
            {path: 'pricing', component: PricingComponent},
        ],
    },
    {
        path: 'app',
        component: DefaultLayoutComponent,
        canActivate: [LoggedInGuard, RightsLoadedGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {
                path: 'user',
                component: UserComponent,
                data: {title: 'Benutzer'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'detail/:user', component: UserDetailComponent, resolve: {title: BreadcrumbResolver}},
                    {path: 'account', component: UserAccountComponent, data: {title: 'Account'}},
                    {path: 'list', component: UserListComponent, data: {title: 'Liste'}},
                ]
            },
            {
                path: 'location',
                component: LocationComponent,
                data: {title: 'LOCATION.LOCATIONS', translate: true},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: LocationListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:location', component: LocationDetailComponent, data: {skipBreadcrumbs: true}},
                    {path: 'attributes', component: LocationAttributesComponent, data: {title: 'Attribute'}},
                    {path: 'search', component: LocationAttributesSearchComponent, data: {title: 'Attributsuche'}},
                ]
            },
            {
                path: 'document',
                component: DocumentComponent,
                data: {title: 'Dokumente'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: DocumentListComponent, data: {title: 'Liste'}},
                    {path: 'upload', component: DocumentUploadComponent, data: {title: 'Upload'}},
                    {path: 'inbox', component: DocumentInboxComponent, data: {title: 'Posteingang'}},
                    {path: 'uploadmultiple', component: DocumentUploadmultipleComponent, data: {title: 'Mehrfachupload'}},
                    {path: 'attributes', component: DocumentAttributesComponent, data: {title: 'Attributsuche'}},
                    {path: 'detail/:id', component: DocumentDetailComponent, data: {skipBreadcrumbs: true}},
                ]
            },
            {
                path: 'task',
                component: TaskComponent,
                data: {title: 'Aufgaben'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: TaskListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:id', component: TaskDetailComponent, data: {skipBreadcrumbs: true}},
                ]
            },
            {
                path: 'company',
                component: CompanyComponent,
                data: {title: 'Unternehmen'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: CompanyListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:company', component: CompanyDetailComponent, data: {skipBreadcrumbs: true}},
                ]
            },
            {
                path: 'taskdate',
                component: TaskDateComponent,
                data: {title: 'Termine'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: TaskDateListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:id', component: TaskDateDetailComponent, data: {skipBreadcrumbs: true}},
                    {path: 'calendar', component: TaskDateCalendarComponent, data: {title: 'Kalendar'}},
                ]
            },
            {path: 'dashboard', component: DashboardComponent, data: {title: 'Dashboard'}},
            {path: 'overview', component: OverviewComponent, data: {title: 'Übersicht'}},
            {path: 'fileshare', component: FileShareComponent, data: {title: 'Fileshare'}},
            {path: 'tag', component: TagSearchComponent, data: {title: 'Tags'}},

            // Master Data
            {
                path: 'tasktopic',
                component: TaskTopicComponent,
                data: {title: 'Aufgabenthemen'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: TaskTopicListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:tasktopic', component: TaskTopicDetailComponent, resolve: {title: BreadcrumbResolver}}
                ]
            },
            {
                path: 'tasktype',
                component: TaskTypeComponent,
                data: {title: 'Aufgabentypen'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: TaskTypeListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:tasktype', component: TaskTypeDetailComponent, resolve: {title: BreadcrumbResolver}}
                ]
            },
            {
                path: 'department',
                component: DepartmentComponent,
                data: {title: 'Abteilungen'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: DepartmentListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:department', component: DepartmentDetailComponent, resolve: {title: BreadcrumbResolver}}
                ]
            },
            {
                path: 'filetype',
                component: FileTypeComponent,
                data: {title: 'Dokumenttypen'},
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: FileTypeListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:filetype', component: FileTypeDetailComponent, resolve: {title: BreadcrumbResolver}},
                ]
            },

            // Administration
            {
                path: 'visibility-group',
                component: VisibilityGroupComponent,
                data: {title: 'Sichtbarkeitsgruppen', right: 'ADMIN_ACCESS'},
                canActivate: [RightGuard],
                runGuardsAndResolvers: 'always' as RunGuardsAndResolvers,
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: VisibilityGroupListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:id', component: VisibilityGroupDetailComponent, data: {title: 'Detail'}}
                ],
            },
            {
                path: 'maillog',
                component: MailLogComponent,
                data: {title: 'Mail Log', right: 'ADMIN_ACCESS'},
                canActivate: [RightGuard],
                runGuardsAndResolvers: 'always' as RunGuardsAndResolvers
            },
            {
                path: 'filesharelog',
                component: FileShareLogComponent,
                data: {title: 'Fileshare Log', right: 'ADMIN_ACCESS'},
                canActivate: [RightGuard],
                runGuardsAndResolvers: 'always' as RunGuardsAndResolvers
            },
            {
                path: 'role',
                component: RoleComponent,
                data: {title: 'Rechteverwaltung', right: 'ADMIN_ACCESS'},
                canActivate: [RightGuard],
                runGuardsAndResolvers: 'always' as RunGuardsAndResolvers,
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {path: 'list', component: RoleListComponent, data: {title: 'Liste'}},
                    {path: 'detail/:id', component: RoleDetailComponent, data: {title: 'Detail'}},
                ],
            },
        ],
    },
    {
        path: '**',
        component: ErrorsComponent,
        runGuardsAndResolvers: 'always',
        children: [
            {path: 'disabled', component: AccountDisabledComponent},
            {path: 'error', component: AccountErrorComponent},
            {path: '**', component: PageNotFoundComponent},
        ],
    },
];
