import {Component, OnInit, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CryptoService} from "../../../global/services/crypto.service";
import {Router, RouterLink} from "@angular/router";
import {DataService} from "../../../global/services/data.service";
import {UserService} from "../../../global/services/user.service";
import Swal from "sweetalert2";
import {ViewMode} from '../../../interfaces';
import {RouterHelperService} from "../../../global/services/router-helper.service";
import {NgIf, NgFor} from '@angular/common';
import {RightPipe} from '../../../global/pipes/right.pipe';

@Component({
    selector: 'app-visibility-group-detail',
    templateUrl: './visibility-group-detail.component.html',
    styleUrl: './visibility-group-detail.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, RouterLink, RightPipe]
})
export class VisibilityGroupDetailComponent implements OnInit {

    userService = inject(UserService);
    routerHelperService = inject(RouterHelperService);
    private cryptoService = inject(CryptoService);
    private router = inject(Router);
    private fb = inject(UntypedFormBuilder);
    private dataService = inject(DataService);

    readonly ViewMode = ViewMode;

    form!: UntypedFormGroup;
    VISID!: string;
    viewMode!: ViewMode;
    users!: any[];
    group: any;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.VISID = urlData.ID;
        this.viewMode = urlData.viewMode;

        this.form = this.fb.group({
            VISID: [null],
            name: ['', Validators.required],
            description: ['', Validators.required],
            users: [],
            userIDs: [],
            documents: [],
        });

        if (this.VISID !== '0') {
            this.getGroup();
        }

        this.dataService.request('User/listForAssignment').subscribe(response => {
            this.users = response.users;
        });
    }

    getGroup(): void {
        this.dataService.request('VisibilityGroup/get', {VISID: this.VISID}).subscribe(response => {
            this.group = response.group;

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            VISID: this.group.VISID,
            name: this.group.name,
            description: this.group.description,
            users: this.group.users,
            userIDs: this.group.users.map((user: any) => user.UID),
            documents: this.group.documents,
        });
    }

    save(): void {
        this.dataService.request('VisibilityGroup/save', this.form.getRawValue()).subscribe(() => {
            this.router.navigate(['/app/visibility-group/list']);
        });
    }

    update(): void {
        this.dataService.request('VisibilityGroup/update', this.form.getRawValue()).subscribe(() => {
            this.setMode(ViewMode.VIEW);
            this.getGroup();
        });
    }

    cancel(): void {
        this.router.navigate(['/app/visibility-group/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Die Sichtbarkeitsgruppe wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('VisibilityGroup/delete', {
                    VISID: this.VISID
                }).subscribe(() => {
                    this.cancel();
                });
            }
        });
    }

}
