<div class="card">
    <div class="card-header card-header-nr">
        <i class="fas fa-briefcase mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.INSERT">Unternehmen anlegen</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Unternehmen bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Unternehmen</ng-container>
    </div>
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <h4 class="mb-3">Unternehmen "{{ form.value.name }}"</h4>
            </div>

            <div class="col-6">
                <app-file
                    *ngIf="viewMode != ViewMode.INSERT"
                    [viewReadonly]="viewMode == ViewMode.VIEW"
                    [viewShowFileInfo]="false"
                    [viewThumbnailsTitle]="false"
                    [doctype]="'company'"
                    [fkId]="CID"
                    [fkName]="'CID'"
                    [fkTable]="'company'"
                    [viewThumbnails]="false"
                    [viewThumbnailsSize]="'100px'"
                    [uploadMaxFileCount]="1"
                    [viewNoFilesText]="'Es wurde noch kein Logo hochgeladen.'"
                    [showDragAndDrop]="true"
                    class="float-right"
                />
            </div>
        </div>

        <ul class="nav nav-tabs card-header-tabs mt-1">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{active: tab == TABS.DETAILS}" href="" data-toggle="tab" (click)="setTab(TABS.DETAILS)">Details</a>
            </li>
            <li class="nav-item" *ngIf="viewMode != ViewMode.INSERT">
                <a class="nav-link" [ngClass]="{active: tab == TABS.LOCATIONS}" href="" data-toggle="tab" (click)="setTab(TABS.LOCATIONS)">Betriebsstätten ({{tabCount.locations}})</a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <div *ngIf="tab == TABS.DETAILS" role="tabpanel" class="tab-pane active">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.name }}</p>
                        </div>
                        <div class="form-group">
                            <label>Gewerberechtlicher Geschäftsführer</label>
                            <input type="text" class="form-control" formControlName="CEO_name" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.CEO_name }}</p>
                        </div>
                        <div class="form-group">
                            <label>Geschäftsführer Mail</label>
                            <input type="text" class="form-control" formControlName="CEO_mail" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="form.value.CEO_mail">{{ form.value.CEO_mail }}</ng-container>
                                <span class="text-muted" *ngIf="!form.value.CEO_mail">Nicht gesetzt</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Firmenbuchnummer</label>
                            <input type="text" class="form-control" formControlName="register_number" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="form.value.register_number">{{ form.value.register_number }}</ng-container>
                                <span class="text-muted" *ngIf="!form.value.register_number">Nicht gesetzt</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>GISA Nummer</label>
                            <input type="text" class="form-control" formControlName="gisa_number" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="form.value.gisa_number">{{ form.value.gisa_number }}</ng-container>
                                <span class="text-muted" *ngIf="!form.value.gisa_number">Nicht gesetzt</span>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Land</label>
                            <input type="text" class="form-control" formControlName="country" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.country }}</p>
                        </div>
                        <div class="form-group">
                            <label>PLZ</label>
                            <input type="number" class="form-control" formControlName="zip" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.zip }}</p>
                        </div>
                        <div class="form-group">
                            <label>Stadt</label>
                            <input type="text" class="form-control" formControlName="city" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.city }}</p>
                        </div>
                        <div class="form-group">
                            <label>Straße</label>
                            <input type="text" class="form-control" formControlName="street" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.street }}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Tätigkeitsbeschreibung</label>
                            <input type="text" class="form-control" formControlName="description" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="form.value.description">{{ form.value.description }}</ng-container>
                                <span class="text-muted" *ngIf="!form.value.description">Nicht gesetzt</span>
                            </p>
                        </div>
                    </div>
                </div>
                <app-tag *ngIf="viewMode != ViewMode.INSERT" [config]="tagConfig"/>
            </form>

            <ng-container *ngIf="viewMode == ViewMode.VIEW">
                <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">Bearbeiten</button>
                <button type="button" class="btn btn-secondary" (click)="cancel()">Zurück</button>
                <button type="button" class="btn btn-danger float-right" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')" (click)="delete()" [disabled]="company.used_document > 0 || company.used_location > 0">Löschen</button>
            </ng-container>

            <ng-container *ngIf="viewMode == ViewMode.EDIT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
            </ng-container>

            <ng-container *ngIf="viewMode == ViewMode.INSERT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
            </ng-container>
        </div>

        <div *ngIf="tab == TABS.LOCATIONS" role="tabpanel" class="tab-pane active">
            <app-table *ngIf="tableConfig" [config]="tableConfig"/>
        </div>
    </div>
</div>

