import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {myAppConfig} from '../../settings';

export const RightGuard: CanActivateFn = (route, state) => {

    const router = inject(Router);
    const userService = inject(UserService);

    if (!route.data['right']) {
        console.warn('RightGuard set without right for ' + state.url);
        return false;
    }

    // check if user has rights for route
    const hasRight = userService.currentUser.rights.includes(route.data['right']);
    //console.log('has right', hasRight);

    if (hasRight) {
        return true;
    } else {
        router.navigate([myAppConfig.auth.afterLoginDestination]);
        return false;
    }

}
