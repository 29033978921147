<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card mt-3">
                        <div class="card-header">
                            Angebot anfordern
                        </div>
                        <div class="card-body">
                            <form [formGroup]="form">
                                <p>Bitte geben Sie uns ein paar Informationen bekannt, damit wir Sie kontaktieren können.</p>

                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control" formControlName="name">
                                </div>

                                <h5>Adresse</h5>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>PLZ</label>
                                            <input type="number" class="form-control" formControlName="zip">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Ort</label>
                                            <input type="text" class="form-control" formControlName="city">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Straße</label>
                                            <input type="text" class="form-control" formControlName="street">
                                        </div>
                                    </div>
                                </div>

                                <h5>Nutzung</h5>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Anzahl Standorte</label>
                                            <input type="number" class="form-control" min="1" formControlName="number_locations">
                                            <small>Für wie viele Standorte möchten Sie NoteRadar einsetzen?</small>
                                        </div>
                                    </div>
                                </div>

                                <h5>Kontaktdaten</h5>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>E-Mail</label>
                                            <input type="text" class="form-control" formControlName="email" [class.ng-invalid]="form.hasError('atLeastOneRequired')">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Telefon</label>
                                            <input type="text" class="form-control" formControlName="phone" [class.ng-invalid]="form.hasError('atLeastOneRequired')">
                                        </div>
                                    </div>
                                </div>
                                <small *ngIf="form.hasError('atLeastOneRequired')" class="text-danger">
                                    {{form.getError('atLeastOneRequired').text}}
                                </small>
                            </form>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="requestOffer()">Angebot anfordern</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
