<ng-template #table>
    <p class="text-muted" *ngIf="config.description">{{ config.description }}</p>

    <button type="button" class="btn btn-primary mb-3" *ngIf="config.enableCreate" (click)="openDataset({row: null, viewMode: ViewMode.INSERT})">Anlegen</button>

    <app-table-table
        [config]="config"
        [result]="result"
        (changeOpenDataset)="openDataset($event)"
        (changeSort)="tableSort($event)"
        (changeColumnFilterValue)="changeColumnFilterValue()"
    />
</ng-template>

<div class="card" *ngIf="config.design === TableDesign.CARD">
    <div class="card-header">
        <div class="d-flex">
            <div class="fw-card-title"><i class="mr-2" *ngIf="config.titleIcon" [class]="config.titleIcon"></i>{{config.title}} ({{config.paginConfig._totalItems}})</div>
            <div class="d-flex flex-column ml-auto">
                <div class="d-flex justify-content-end">
                    <div *ngIf="config.enableExportExcel" class="mr-2">
                        <button type="button" class="btn btn-sm btn-secondary" [disabled]="exporting" (click)="exportExcel()">Excel-Export</button>
                    </div>
                    <div class="pr-2">
                        <app-table-searchbox [config]="config" (changeSearch)="load()"/>
                    </div>
                    <div>
                        <app-table-rowswitch *ngIf="result.table" [config]="config" (changeRows)="changeRows()"/>
                    </div>
                    <div *ngIf="config.dataConfig.columnFilter.enabled" class="pl-2">
                        <app-table-filterbutton [config]="config" (changeColumnFilterActive)="changeColumnFilterActive()" (changeColumnFilterReset)="changeColumnFilterValue()"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body fw-table-card-body">
        <ng-template [ngTemplateOutlet]="table"></ng-template>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-sm-6">
                <pagination
                    *ngIf="config.paginConfig._totalItems > config.paginConfig.itemsPerPage"
                    [totalItems]="config.paginConfig._totalItems"
                    [itemsPerPage]="config.paginConfig.itemsPerPage"
                    [(ngModel)]="config.paginConfig.currentPage"
                    (pageChanged)="pageChanged($event)"
                    [maxSize]="20"
                    [previousText]="config.paginConfig.prevLabel"
                    [nextText]="config.paginConfig.nextLabel"
                />
            </div>
            <div class="col-sm-6 text-right">
                <small>{{config.paginConfig._totalItems}} {{config.paginConfig.totalItemsLabel}}</small>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="config.design === TableDesign.NONE">
    <div>
        <div class="d-flex">
            <div class="fw-card-title"><i class="mr-2" *ngIf="config.titleIcon" [class]="config.titleIcon"></i>{{config.title}}</div>
            <div class="d-flex flex-column ml-auto">
                <div class="d-flex justify-content-end">
                    <div *ngIf="config.enableExportExcel" class="mr-2">
                        <button type="button" class="btn btn-sm btn-secondary" [disabled]="exporting" (click)="exportExcel()">Excel Export</button>
                    </div>
                    <div class="pr-2">
                        <app-table-searchbox [config]="config" (changeSearch)="load()"/>
                    </div>
                    <div>
                        <app-table-rowswitch *ngIf="result.table" [config]="config" (changeRows)="changeRows()"/>
                    </div>
                    <div *ngIf="config.dataConfig.columnFilter.enabled" class="pl-2">
                        <app-table-filterbutton [config]="config" (changeColumnFilterActive)="changeColumnFilterActive()" (changeColumnFilterReset)="changeColumnFilterValue()"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-3">
        <ng-template [ngTemplateOutlet]="table"></ng-template>
    </div>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <pagination
                    *ngIf="config.paginConfig._totalItems > config.paginConfig.itemsPerPage"
                    [totalItems]="config.paginConfig._totalItems"
                    [itemsPerPage]="config.paginConfig.itemsPerPage"
                    [(ngModel)]="config.paginConfig.currentPage"
                    (pageChanged)="pageChanged($event)"
                    [maxSize]="20"
                    [previousText]="config.paginConfig.prevLabel"
                    [nextText]="config.paginConfig.nextLabel"
                />
            </div>
            <div class="col-sm-6 text-right">
                <small>{{config.paginConfig._totalItems}} {{config.paginConfig.totalItemsLabel}}</small>
            </div>
        </div>
    </div>
</ng-container>
