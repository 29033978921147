import {Injectable, inject} from '@angular/core';
import {RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {CryptoService} from '../../services/crypto.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbResolver {

    private dataService = inject(DataService);
    private cryptoService = inject(CryptoService);
    private translateService = inject(TranslateService);

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
        const ID = route.paramMap.get(route.paramMap.keys[0]);
        const resolveType = route.paramMap.keys[0];

        const urlData = this.cryptoService.getUrlData(ID);

        switch (resolveType) {
            case 'user':
                return (urlData.ID === '0') ? of('Benutzer anlegen') : this.dataService.request('User/getName', {UID: urlData.ID}).pipe(map(response => {
                    return response.name;
                }));
            case 'company':
                return (urlData.ID === '0') ? of('Unternehmen anlegen') : this.dataService.request('Company/getName', {CID: urlData.ID}).pipe(map(response => {
                    return response.name;
                }));
            case 'location':
                return (urlData.ID === '0') ? of(this.translateService.instant('LOCATION.LOCATION') + ' anlegen') : this.dataService.request('Location/getName', {LID: urlData.ID}).pipe(map(response => {
                    return response.name;
                }));
            case 'filetype':
                return (urlData.ID === '0') ? of('Dokumenttyp anlegen') : this.dataService.request('FileType/getName', {FTID: urlData.ID}).pipe(map(response => {
                    return response.name;
                }));
            case 'department':
                return (urlData.ID === '0') ? of('Abteilung anlegen') : this.dataService.request('Department/getName', {DPID: urlData.ID}).pipe(map(response => {
                    return response.name;
                }));
            case 'tasktopic':
                return (urlData.ID === '0') ? of('Aufgabenthema anlegen') : this.dataService.request('TaskTopic/getName', {TOID: urlData.ID}).pipe(map(response => {
                    return response.name;
                }));
            case 'tasktype':
                return (urlData.ID === '0') ? of('Aufgabentyp anlegen') : this.dataService.request('TaskType/getName', {TTID: urlData.ID}).pipe(map(response => {
                    return response.name;
                }));
            default:
                return of('');
        }
    }
}
