import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'weekday',
    standalone: true
})
export class WeekdayPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === '0') {
            return 'Sonntag';
        } else if (value === '1') {
            return 'Montag';
        } else if (value === '2') {
            return 'Dienstag';
        } else if (value === '3') {
            return 'Mittwoch';
        } else if (value === '4') {
            return 'Donnerstag';
        } else if (value === '5') {
            return 'Freitag';
        } else if (value === '6') {
            return 'Samstag';
        } else {
            return '';
        }
    }

}
