import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TableColumnFilterType, TableConfig, TableStatus} from '../table/table.interfaces';
import {ModalDirective, ModalModule} from "ngx-bootstrap/modal";
import {NgIf} from '@angular/common';
import {TableComponent} from '../table/table.component';
import {AutofocusDirective} from '../../directives/autofocus.directive';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-mail-log',
    templateUrl: './mail-log.component.html',
    styleUrl: './mail-log.component.scss',
    imports: [NgIf, TableComponent, ModalModule, AutofocusDirective, TranslateModule]
})
export class MailLogComponent implements OnInit {

    @ViewChild('mailTemplate') mailTemplate!: TemplateRef<any>;
    @ViewChild('mailModal') mailModal!: ModalDirective;

    tableConfig!: TableConfig;

    message!: string;

    ngOnInit(): void {
        this.tableConfig = {
            titleIcon: 'fas fa-table',
            title: 'Mail Log',
            openDatasetRouterLink: null,
            enableCreate: false,
            enableExportExcel: true,
            description: 'Das Mail Log zeigt Ihnen, wann an welchen Benutzer eine E-Mail geschickt wurde.',
            dataConfig: {
                tableName: 'mail_log',
                tableIndexName: 'MLID',
                tableJoins: [
                    {left: 'user', right: 'mail_log', key: 'UID'},
                ],
                tableFields: [
                    // {key: 'from_name', name: 'from_name', title: 'Absender Name', sortable: true, searchable: true},
                    // {key: 'from_mail', name: 'from_mail', title: 'Absender Email', sortable: true, searchable: true},
                    {
                        key: 'created',
                        name: 'created',
                        title: 'Datum',
                        sortable: true,
                        searchable: true,
                        dateFormat: {en: '%Y.%m.%d %H:%i', others: '%d.%m.%Y %H:%i'}
                    },
                    {key: 'recipients', name: 'recipients', title: 'Empfänger', sortable: true, searchable: true},
                    {key: 'subject', name: 'subject', title: 'Betreff', sortable: true, searchable: true},
                    {key: 'result', name: 'result', title: 'Erfolgreich', sortable: true, searchable: true},
                    {key: 'message', name: 'message', title: 'Nachricht', visible: false},
                ],
                reverse: true,
                order: 'created',
                columnFilter: {
                    enabled: true,
                    fields: {
                        recipients: {value: '', type: TableColumnFilterType.INPUT},
                        result: {value: '', type: TableColumnFilterType.BOOLEAN},
                    }
                }
            },
            localStorage: {
                version: 2,
                name: 'mail-log-list',
                enabled: true
            },
            paginConfig: {
                id: 'mailLogList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }

    handleClick(event: TableStatus): void {
        this.message = event.data.message;
        this.message = this.message.replace("cid:logo'", "assets/img/brand/logo.png' width='100'");
        this.mailModal.show();
    }
}
