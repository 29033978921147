import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-task-type',
    templateUrl: './task-type.component.html',
    styleUrl: './task-type.component.scss',
    imports: [RouterOutlet]
})
export class TaskTypeComponent {

}
