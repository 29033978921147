export enum TableStatusType {
    ENTRY_CLICKED = 'entry_clicked'
}

// The filter type - enum, lookup and group are dropdowns
export enum TableColumnFilterType {
    INPUT = 'input',
    ENUM = 'enum',
    LOOKUP = 'lookup',
    GROUP = 'group',
    BOOLEAN = 'boolean',
    SUBQUERY_CONCAT = 'subquery_concat',
    SUBQUERY_COUNT = 'subquery_count',
    SUBQUERY_GROUP = 'subquery_group'
}

// Alignment of table fields in head, body and footer
export enum TableFieldAlignment {
    RIGHT = 'right',
    CENTER = 'center'
}

export enum TableRowColorConditionOperator {
    EQUAL = 'equal',
    BIGGER_INT = 'bigger_int',
    BIGGER_DATE = 'bigger_date',
    SMALLER_INT = 'smaller_int',
    SMALLER_DATE = 'smaller_date'
}

export enum TableFilterOperator {
    EQUAL = 'equal',
    BIGGER = 'bigger',
    SMALLER = 'smaller',
    NOT = 'not',
    IS_NULL = 'is_null',
    NOT_NULL = 'not_null',
    LIKE = 'like',
    IN = 'in'
}

// Name of the local storage key
interface TableLocalStorage {
    // The version can be used to clear the saved local storage data without having to delete it
    version: number;
    name: string;
    enabled: boolean;
}

export interface TableRowColorCondition {
    key: string;
    value: string;
    operator: TableRowColorConditionOperator;
}

interface TableRowColor {
    color: string;
    conditions: TableRowColorCondition[];
}

export interface TableRowImage {
    key: string;
    image?: string;
    height?: string;
    width?: string;
    icon?: string;
    color?: string;
    conditions: TableRowColorCondition[];
    tooltip?: string;
}

export interface TableDataConfig {
    // The name of the table
    tableName: string;
    // The name of the primary key of the table
    tableIndexName: string;
    // Table joins
    tableJoins?: TableJoin[];
    // Table filter (config only, no UI)
    tableFilter?: TableFilter[];
    // Table fields to display
    tableFields: TableField[];
    reverse: boolean;
    searchMinimumCharacter?: number;
    search?: string;
    order: string;
    // Defines the filter options
    columnFilter?: TableColumnFilter;
    rowColor?: TableRowColor[];
    rowImage?: TableRowImage[];
    externalFilter?: TableExternalFilter;
}

export interface TableFieldDateFormat {
    en: string;
    others: string;
}

interface TableColumnFilter {
    // If enabled shows the filter button
    enabled: boolean;
    // Toggles the active state of the filter
    active?: boolean;
    // The true value
    trueValue?: string;
    // The false value
    falseValue?: string;
    // Label for active filter button
    buttonOn?: FwTranslatable | string;
    // Label for inactive filter button
    buttonOff?: FwTranslatable | string;
    // Label for reset filter button
    buttonReset?: FwTranslatable | string;
    // The default dropdown value - TODO: rename to "defaultValue"? Can be used for inputs as well
    dropDownDefault?: string;
    // Defines the column fields that can be filtered
    fields: { [key: string]: TableColumnFilterField };
}

export interface FwTranslatable {
    label: string;
}

export interface TableColumnFilterField {
    value: string;
    type: TableColumnFilterType;
}

interface TableFileViewerFIDSettings {
    FID: string;
    width: string;
    height: string;
    viewThumbnail: boolean;
}

interface TableFileViewerSettings {
    FK_ID: string;
    FK_name: string;
    FK_table: string;
    doctype: string;
    width: string;
    height: string;
    viewThumbnail: boolean;
}

export interface TableField {
    // Unique Key - is used as alias in queries
    key: string;
    // Database of the field
    database?: string;
    // Table of the field, if not the original table
    table?: string;
    // The name of the primary key of the table, required for Lookup filter
    index?: string;
    // The alias is required when using a subquery
    alias?: string;
    // The column name in the database
    name?: string;
    // The title displayed in the table header
    title: string;
    // MySql DATE_FORMAT function
    dateFormat?: TableFieldDateFormat;
    // Translate fieldvalue with ngx-translate
    translate?: boolean;
    // Translate title with ngx-translate
    translateTitle?: boolean;
    // Translate prefix for column title
    translateTitlePrefix?: string;
    // Translate prefix for column values
    translateValuePrefix?: string;
    // Field is sortable
    sortable?: boolean;
    // Field is searchable by search field
    searchable?: boolean;
    // Used for custom subquery, requires an alias
    subquery?: string;
    // Prevents body text wrapping
    nowrap?: boolean;
    // Prevents header text wrapping
    nowrapHeader?: boolean;
    // Whether to calculate the sum
    footerSum?: boolean;
    alignment?: TableFieldAlignment;
    visible?: boolean;
    // viewer settings
    fileViewerFID?: TableFileViewerFIDSettings;
    fileViewer?: TableFileViewerSettings;
    _DB_type_name?: string;
    _DB_type_constraints?: any;
    _DB_type_constraints_translated?: any;
    _footerSumValue?: number;
    _hasImage?: boolean;
}

export interface TableJoin {
    // The table to be joined
    left: string;
    // The table to join from
    right: string;
    // The key to join the tables
    key: string;
    // The key to join the tables defined in property right
    keyRight?: string;
    // Extended filter-condition (condition: "and table.field='xyz'")
    condition?: string;
}

export interface TableFilter {
    // either use a combination of table (optionally) and field or subquery
    table?: string;
    field?: string;
    subquery?: string;
    values?: string[];
    operator: TableFilterOperator;
}

interface PaginConfig {
    id: string;
    rowFields: string[];
    itemsPerPageDb: boolean;
    itemsPerPage: number;
    currentPage: number;
    _totalItems?: number;
    totalItemsLabel?: string;
    prevLabel?: string;
    nextLabel?: string;
}

interface TableFileViewerConfig {
    viewFileInfo: boolean;
    viewFlipImage: boolean;
}

export enum TableDesign {
    NONE = 'none',
    CARD = 'card'
}

export interface TableConfig {
    design?: TableDesign;
    description?: string;
    language?: string;
    // Icon to display before the title
    titleIcon?: string;
    title: string;
    // The route to navigate to when clicking on an item, if set to null opens a modal
    openDatasetRouterLink: any;
    routerData?: any;
    relativeRouterLink?: boolean;
    dataConfig?: TableDataConfig;
    localStorage?: TableLocalStorage;
    paginConfig: PaginConfig;
    fileViewerConfig?: TableFileViewerConfig;
    // Enables the create button
    enableCreate: boolean;
    // Focus selected Row
    enableFocusRow?: boolean;
    // Show export Excel button
    enableExportExcel?: boolean;
}

// Used by the table event emitter for various status messages
export interface TableStatus {
    type: TableStatusType;
    data: any;
}

export interface TableExternalFilter {
    key: string;
    value: string;
}
