import {Component, inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {NgIf, NgClass} from '@angular/common';
import {Router} from '@angular/router';

import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ToastrService} from 'ngx-toastr';
import {QuillEditorComponent} from "ngx-quill";
import Swal from 'sweetalert2';

import {DataService} from '../../global/services/data.service';
import {CryptoService} from '../../global/services/crypto.service';
import {AdminTaskListComponent} from '../admin-task-list/admin-task-list.component';

enum TabsEnum {
    DIGITIZATION = 'digitization',
    CONSULTING = 'consulting',
}

@Component({
    selector: 'app-admin-document-detail',
    templateUrl: './admin-document-detail.component.html',
    styleUrls: ['./admin-document-detail.component.scss'],
    imports: [NgIf, TooltipModule, NgClass, FormsModule, ReactiveFormsModule, AdminTaskListComponent, QuillEditorComponent]
})
export class AdminDocumentDetailComponent implements OnInit {

    private dataService = inject(DataService)
    private fb = inject(UntypedFormBuilder)
    private router = inject(Router)
    private cryptoService = inject(CryptoService)
    private toastrService = inject(ToastrService)
    private sanitizer = inject(DomSanitizer)

    form!: UntypedFormGroup;
    document: any = {};
    client: any = {};
    DID!: string;
    CLID!: string;
    dbName!: string;
    embedString: any = null;

    TABS = TabsEnum;
    tab: TabsEnum = TabsEnum.DIGITIZATION;

    tabs: any;
    file: any;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.DID = urlData.ID;
        this.dbName = urlData.data.dbName;
        this.CLID = urlData.data.CLID;

        this.form = this.fb.group({
            DID: [null],
            ocr_text: [''],
        });

        this.getDocument();

        this.tabs = {
            pdf: true,
            text: true,
            tasks: false
        };
    }

    getDocument(): void {
        this.dataService.request('universe.Admin/getDocument', {
            CLID: this.CLID,
            DID: this.DID,
            dbName: this.dbName
        }).subscribe(response => {
            this.document = response.document;
            this.client = response.client;
            this.file = response.file;

            if (this.document.ocr_text === null) {
                this.document.ocr_text = '';
            }

            if (!this.document.ocr_text.startsWith('<p>')) {
                let result = '<p>' + this.document.ocr_text + '</p>';
                result = result.replace(/\r\n\r\n/g, '</p><p>').replace(/\n\n/g, '</p><p>');
                result = result.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
                this.document.ocr_text = result;
            }

            this.patchForm();

            if (this.file?.base64) {
                const byteCharacters = atob(this.file.base64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const file = new Blob([byteArray], {type: 'application/pdf;base64'});
                this.embedString = URL.createObjectURL(file);
                this.embedString = this.sanitizer.bypassSecurityTrustResourceUrl(this.embedString);
            }
        });
    }

    patchForm(): void {
        this.form.patchValue({
            DID: this.document.DID,
            ocr_text: this.document.ocr_text,
        });
    }

    update(): void {
        this.dataService.request('universe.Admin/updateDocument', {
            form: this.form.getRawValue(),
            dbName: this.dbName
        }).subscribe(() => {
            this.toastrService.success('OCR Text wurde aktualisiert!');
        });
    }

    listDigitization(): void {
        this.router.navigate(['/admin/document/list']);
    }

    listConsulting(): void {
        this.router.navigate(['/admin/consulting/list']);
    }

    finishDigitization(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Das Dokument wird als digitalisiert markiert und scheint in der Administration nicht mehr auf.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('universe.Admin/finishDocumentDigitization', {
                    form: this.form.getRawValue(),
                    dbName: this.dbName
                }).subscribe(() => {
                    this.toastrService.success('Das Dokument wurde als fertig digitalisiert markiert');
                    this.router.navigate(['/admin/dashboard']);
                });
            }
        });
    }

    finishConsulting(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Das Consulting für das Dokument wird als abgeschlossen markiert.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('universe.Admin/finishDocumentConsulting', {
                    form: this.form.getRawValue(),
                    dbName: this.dbName
                }).subscribe(() => {
                    this.toastrService.success('Das Consulting für das Dokument wurde als abgeschlossen markiert');
                    this.router.navigate(['/admin/dashboard']);
                });
            }
        });
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;
    }
}
