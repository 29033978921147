<app-table *ngIf="tableConfig" [config]="tableConfig" (status)="handleClick($event)"/>

<div bsModal #mailModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left"><i class="fa fa-mail"></i> Mailinhalt</h4>
            </div>
            <div class="modal-body">
                <div [innerHTML]="message"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" appAutofocus (click)="mailModal?.hide()">OK</button>
            </div>
        </div>
    </div>
</div>
