<div class="card">
    <div class="card-header card-header-nr">
        <i class="fas fa-file-import mr-2"></i>Fileshare
    </div>
    <div class="card-header">
        <p class="text-muted">Hier können Sie beliebige Dateien innerhalb von NoteRadar für Andere zur Verfügung stellen, oder für sich selbst hinterlegen.</p>

        <ul class="nav nav-tabs card-header-tabs mt-1">
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab == TABS.PUBLIC}" href="" data-toggle="tab" (click)="setTab(TABS.PUBLIC)">Für alle sichtbare Dateien</a></li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab == TABS.PRIVATE}" href="" data-toggle="tab" (click)="setTab(TABS.PRIVATE)">Nur für mich sichtbare Dateien</a></li>
        </ul>
    </div>
    <div class="card-body">
        <div *ngIf="tab == TABS.PUBLIC" role="tabpanel" class="tab-pane active">
            <div [attr.data-right]="'FILESHARE_UPLOAD_PUBLIC' | right"
                 *ngIf="userService.hasRight('FILESHARE_UPLOAD_PUBLIC')">

                <app-file [doctype]="'fileshare'"
                          [fkName]="'UID'"
                          [fkTable]="'user'"
                          [viewThumbnails]="true"
                          [viewThumbnailsSize]="'50px'"
                          [viewThumbnailsTilesSize]="'100px'"
                          [uploadSelectMultiple]="true"
                          [uploadMaxFilesize]="300"
                          [viewEditModeFlipImage]="true"
                          [viewLayout]="fileViewLayout.TILES"
                          [viewNoFilesText]="'Es wurden noch keine Dateien hochgeladen.'"
                          [showViewLayoutSwitcher]="true"
                          [onlyCreatorCanDelete]="true"
                          [isAdmin]="userService.hasRight('ADMIN_ACCESS')"
                          [showDragAndDrop]="true"
                          [enableLinks]="userService.hasRight('FILESHARE_LINK_CREATE')"
                />
            </div>

            <div class="alert alert-warning mb-0" role="alert" *ngIf="!userService.hasRight('FILESHARE_UPLOAD_PUBLIC')">
                Sie haben nicht die Berechtigung öffentliche Dateien zur Verfügung zu stellen.
            </div>
        </div>

        <div *ngIf="tab == TABS.PRIVATE" role="tabpanel" class="tab-pane active">
            <div [attr.data-right]="'FILESHARE_UPLOAD_PRIVATE' | right"
                 *ngIf="userService.hasRight('FILESHARE_UPLOAD_PRIVATE')">

                <app-file
                    [doctype]="'fileshare'"
                    [fkName]="'UID'"
                    [fkTable]="'user'"
                    [fkId]="userService.currentUser.UID"
                    [viewThumbnails]="true"
                    [viewThumbnailsSize]="'50px'"
                    [viewThumbnailsTilesSize]="'100px'"
                    [uploadSelectMultiple]="true"
                    [uploadMaxFilesize]="300"
                    [viewEditModeFlipImage]="true"
                    [viewLayout]="fileViewLayout.TILES"
                    [viewNoFilesText]="'Es wurden noch keine Dateien hochgeladen.'"
                    [showViewLayoutSwitcher]="true"
                    [onlyCreatorCanDelete]="true"
                    [isAdmin]="userService.hasRight('ADMIN_ACCESS')"
                    [showDragAndDrop]="true"
                    [enableLinks]="userService.hasRight('FILESHARE_LINK_CREATE')"
                />
            </div>
        </div>

        <div class="alert alert-warning mb-0" role="alert" *ngIf="!userService.hasRight('FILESHARE_UPLOAD_PRIVATE')">
            Sie haben nicht die Berechtigung private Dateien zu hinterlegen.
        </div>
    </div>
</div>
