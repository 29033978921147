<p class="text-muted">Um vor Fälligkeit des Termins erinnert zu werden, z.B. um vorbereitende Tätigkeiten zu erledigen, haben Sie hier die Möglichkeit beliebige Erinnerungen zu erstellen.</p>

<button type="button" class="btn btn-primary mb-3"
        (click)="add({TDID: TDID, _mode: 'insert'})"
        [attr.data-right]="'TASKDATE_REMINDER_CREATE' | right"
        *ngIf="userService.hasRight('ADMIN_ACCESS') || (userService.hasRight('TASKDATE_REMINDER_CREATE') && taskdate && userService.isOwner(taskdate.responsible_UID))"
>Erinnerung hinzufügen</button>

<div class="text-muted" *ngIf="formService.getFormArray(remindersForm, 'reminders').length == 0">Es wurden noch keine Erinnerungen erstellt!</div>

<form [formGroup]="remindersForm">
    <table class="table table-sm table-reminders mb-0">
        <tbody formArrayName="reminders">
        <tr [formGroupName]="i" *ngFor="let reminder of formService.getFormArray(remindersForm, 'reminders').controls; let i = index">
            <td style="width: 80px">
                <ng-container *ngIf="reminder.value._mode == 'insert'">
                    <button type="button" class="btn btn-primary btn-sm" [disabled]="reminder.invalid" (click)="save(reminder)"><i class="fas fa-check"></i></button>
                    <button type="button" class="btn btn-secondary btn-sm ml-2" (click)="cancelCreate(i)"><i class="fas fa-times"></i></button>
                </ng-container>
                <ng-container *ngIf="reminder.value._mode == 'edit'">
                    <button type="button" class="btn btn-primary btn-sm" [disabled]="reminder.invalid" (click)="update(reminder)"><i class="fas fa-check"></i></button>
                    <button type="button" class="btn btn-secondary btn-sm ml-2" (click)="cancelEdit(reminder)"><i class="fas fa-times"></i></button>
                </ng-container>
                <div [attr.data-right]="'ADMIN_ACCESS' | right" *ngIf="reminder.value._mode == 'view' && (userService.hasRight('ADMIN_ACCESS') || (taskdate && userService.isOwner(taskdate.responsible_UID)))">
                    <button type="button" class="btn btn-primary btn-sm" (click)="edit(reminder)"><i class="fas fa-pencil-alt"></i></button>
                    <button type="button" class="btn btn-danger btn-sm ml-2" (click)="delete(reminder.value.TDRID)"><i class="fas fa-trash"></i></button>
                </div>
            </td>
            <td>
                <label>Intervall</label>
                <select class="form-control" formControlName="interval" *ngIf="reminder.value._mode != 'view'">
                    <option value="custom">Eigene Auswahl</option>
                    <option value="daily">Täglich</option>
                    <option value="weekly">Wöchentlich</option>
                    <option value="monthly">Monatlich</option>
                    <option value="yearly">Jährlich</option>
                    <option value="week_earlier">Eine Woche vor Fälligkeit</option>
                    <option value="month_earlier">Ein Monat vor Fälligkeit</option>
                </select>
                <ng-container *ngIf="reminder.value._mode == 'view'">
                    <p>{{ reminder.value.interval | reminderInterval }}</p>
                </ng-container>
            </td>
            <td style="width: 200px">
                <ng-container *ngIf="reminder.value.interval == 'weekly'">
                    <label>Wochentag</label>
                    <select class="form-control" formControlName="weekly_day" *ngIf="reminder.value._mode != 'view'">
                        <option value="1">Montag</option>
                        <option value="2">Dienstag</option>
                        <option value="3">Mittwoch</option>
                        <option value="4">Donnerstag</option>
                        <option value="5">Freitag</option>
                        <option value="6">Samstag</option>
                        <option value="0">Sonntag</option>
                    </select>
                    <p *ngIf="reminder.value._mode == 'view'">{{ reminder.value.weekly_day | weekday }}</p>
                </ng-container>
                <ng-container *ngIf="reminder.value.interval == 'monthly' || reminder.value.interval == 'yearly'">
                    <label>Tag des Monats</label>
                    <input type="number" min="1" max="31" class="form-control" formControlName="monthly_yearly_date" *ngIf="reminder.value._mode != 'view'">
                    <p *ngIf="reminder.value._mode == 'view'">{{ reminder.value.monthly_yearly_date }}</p>
                </ng-container>
                <ng-container *ngIf="reminder.value.interval == 'yearly'">
                    <label>Monat</label>
                    <input type="number" min="1" max="12" class="form-control" formControlName="yearly_month" *ngIf="reminder.value._mode != 'view'">
                    <p *ngIf="reminder.value._mode == 'view'">{{ reminder.value.yearly_month }}</p>
                </ng-container>
            </td>
            <td style="width: 200px">
                <ng-container *ngIf="['custom'].includes(reminder.value.interval)">
                    <label>Datum</label>
                    <input type="date" class="form-control" formControlName="date" *ngIf="reminder.value._mode != 'view'">
                    <p *ngIf="reminder.value._mode == 'view'">{{ reminder.value.date | date: 'dd.MM.yyyy' }}</p>
                </ng-container>
                <ng-container *ngIf="['week_earlier', 'month_earlier'].includes(reminder.value.interval)">
                    <label>Datum</label>
                    <p>{{ reminder.value.date | date: 'dd.MM.yyyy' }}</p>
                </ng-container>
                <ng-container *ngIf="['daily', 'weekly', 'monthly', 'yearly'].includes(reminder.value.interval)">
                    <label>Nächstes Datum</label>
                    <p>{{ reminder.value.date | date: 'dd.MM.yyyy' }}</p>
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>
</form>
