<div class="card mb-3">
    <div class="card-header card-header-nr">
        <i class="fas fa-file-invoice mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Dokument bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Dokument</ng-container>
    </div>
    <div class="card-header">
        <h4 class="mb-3">Dokument "{{ form.value.name}}"</h4>

        <ul class="nav nav-tabs card-header-tabs mt-1">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{active: tab == TABS.DETAILS}" href="" data-toggle="tab" (click)="setTab(TABS.DETAILS)">Details</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{active: tab == TABS.TASKS}" href="" data-toggle="tab" (click)="setTab(TABS.TASKS)">
                    <i *ngIf="!form.value.tasks_created" class="fas fa-triangle-exclamation text-warning mr-2"></i>Aufgaben ({{tabCount.tasks}})
                </a>
            </li>
            <li class="nav-item" [attr.data-right]="'DOCUMENT_ATTRIBUTE_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_ATTRIBUTE_VIEW')">
                <a class="nav-link" [ngClass]="{active: tab == TABS.ATTRIBUTES}" href="" data-toggle="tab" (click)="setTab(TABS.ATTRIBUTES)">Attribute ({{tabCount.attributes}})</a>
            </li>
            <li class="nav-item" [attr.data-right]="'DOCUMENT_PDF_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_PDF_VIEW')">
                <a class="nav-link" [ngClass]="{active: tab == TABS.PDF}" href="" data-toggle="tab" (click)="setTab(TABS.PDF)">PDF</a>
            </li>
            <li class="nav-item" [attr.data-right]="'DOCUMENT_OCR_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_OCR_VIEW')">
                <a class="nav-link" [ngClass]="{active: tab == TABS.OCR}" href="" data-toggle="tab" (click)="setTab(TABS.OCR)">Digitalisierter Text</a>
            </li>
            <li class="nav-item" [attr.data-right]="'DOCUMENT_ATTACHMENTS_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_ATTACHMENTS_VIEW')">
                <a class="nav-link" [ngClass]="{active: tab == TABS.ATTACHMENTS}" href="" data-toggle="tab" (click)="setTab(TABS.ATTACHMENTS)">Anhänge ({{tabCount.attachments}})</a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <div *ngIf="document && tab == TABS.DETAILS" role="tabpanel" class="tab-pane active">
            <form [formGroup]="form">

                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.name }}</p>
                        </div>
                        <div class="form-group">
                            <label>Dokumenttyp</label>
                            <select class="form-control" formControlName="FTID" *ngIf="viewMode != ViewMode.VIEW">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let fileType of fileTypes" [ngValue]="fileType.FTID">
                                    {{ fileType.name }}
                                </option>
                            </select>
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.fileType }}</p>
                        </div>
                        <div class="form-group">
                            <label>Seiten</label>
                            <input type="number" class="form-control" formControlName="pages" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="form.value.pages">{{ form.value.pages }}</ng-container>
                                <span class="text-muted" *ngIf="!form.value.pages">Nicht gesetzt</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Aktiv</label>
                            <div class="form-check" *ngIf="viewMode != ViewMode.VIEW">
                                <input type="checkbox" class="form-check-input" formControlName="active"><br/>
                            </div>
                            <p *ngIf="viewMode == ViewMode.VIEW"><i class="fas fa-exclamation-circle text-warning" *ngIf="!form.value.active"></i> {{ form.value.active | yesNo }}</p>
                        </div>
                        <div class="form-group">
                            <label>Aufgabenerstellung abgeschlossen</label>
                            <div class="form-check" *ngIf="viewMode != ViewMode.VIEW">
                                <input type="checkbox" class="form-check-input" formControlName="tasks_created"><br/>
                            </div>
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <i *ngIf="!form.value.tasks_created" class="fas fa-triangle-exclamation text-warning"></i> {{ form.value.tasks_created | yesNo }}
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Anmerkung</label>
                            <textarea class="form-control" formControlName="comment" *ngIf="viewMode != ViewMode.VIEW" rows="4"></textarea>
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="form.value.comment">{{ form.value.comment | textarea }}</ng-container>
                                <span class="text-muted" *ngIf="!form.value.comment">Nicht eingetragen</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Sichtbarkeitsgruppen</label>

                            <select class="form-control" formControlName="groupIDs" multiple *ngIf="viewMode != ViewMode.VIEW">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let group of groups" [ngValue]="group.VISID">
                                    {{ group.name }}
                                </option>
                            </select>
                            <ng-container *ngIf="viewMode == ViewMode.VIEW">
                                <ul *ngIf="form.value.groups?.length > 0">
                                    <li *ngFor="let group of form.value.groups">
                                        {{ group.name }} <i class="fas fa-users" style="color: #666" [tooltip]="group.users" placement="right"></i>
                                    </li>
                                </ul>
                                <p class="text-muted" *ngIf="form.value.groups?.length == 0">Keiner Gruppe zugewiesen</p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                            <label>Unternehmen</label>
                            <select class="form-control" formControlName="CID" *ngIf="viewMode != ViewMode.VIEW">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let company of companies" [ngValue]="company.CID">
                                    {{ company.name }}
                                </option>
                            </select>
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <a [attr.data-right]="'COMPANY_VIEW' | right" *ngIf="userService.hasRight('COMPANY_VIEW')" [routerLink]="[]" (click)="routerHelperService.openCompany(form.value.CID)">{{ form.value.company }}</a>
                                <span *ngIf="!userService.hasRight('COMPANY_VIEW')">{{ form.value.company }}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>{{ 'LOCATION.LOCATION' | translate }}</label>
                            <select class="form-control" formControlName="LID" *ngIf="viewMode != ViewMode.VIEW">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let location of locations" [ngValue]="location.LID">
                                    {{ location.name }}
                                </option>
                            </select>
                            <p *ngIf="viewMode == ViewMode.VIEW">
                                <a [attr.data-right]="'LOCATION_VIEW' | right" *ngIf="userService.hasRight('LOCATION_VIEW')" [routerLink]="[]" (click)="routerHelperService.openLocation(form.value.LID)">{{ form.value.location }}</a>
                                <span *ngIf="!userService.hasRight('LOCATION_VIEW')">{{ form.value.location }}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Hochgeladen am</label>
                            <p>{{ form.value.create_date | date: "dd.MM.yyyy HH:mm" }}</p>
                        </div>
                        <div class="form-group">
                            <label>Hochgeladen von</label>
                            <p>{{ form.value.user }}</p>
                        </div>
                        <!--<div class="form-group">
                            <label>Digitalisierung</label>
                            <p>{{ form.value.digitization | digitization }}</p>
                        </div>-->
                        <div class="form-group">
                            <label>Consulting angefordert</label>
                            <p>{{ form.value.consulting | yesNo }}</p>
                        </div>
                        <div class="form-group" *ngIf="form.value.consulting == true">
                            <label>Consulting abgeschlossen</label>
                            <p>{{ form.value.consulting_complete | yesNo }}</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12" [attr.data-right]="'DOCUMENT_PDF_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_PDF_VIEW')">
                        <app-file
                            [viewReadonly]="viewMode == ViewMode.VIEW"
                            [viewShowFileInfo]="true"
                            [viewThumbnailsTitle]="true"
                            [doctype]="'document'"
                            [fkId]="DID"
                            [fkName]="'DID'"
                            [fkTable]="'document'"
                            [viewThumbnailsTilesSize]="'100px'"
                            [uploadAccept]="[[FileMimeType.DOCUMENT_PDF]]"
                            [uploadMaxFileCount]="1"
                            [viewNoFilesText]="'Es wurde noch keine Datei hochgeladen.'"
                            [showDragAndDrop]="true"
                            (uploadedEvent)="getFile()"
                            (deletedEvent)="updateDocumentInfo()"
                        />
                    </div>
                </div>

                <app-tag [config]="tagConfig"/>
            </form>

            <ng-container *ngIf="viewMode == ViewMode.VIEW">
                <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'DOCUMENT_EDIT' | right" *ngIf="userService.hasRight('DOCUMENT_EDIT')">Bearbeiten</button>
                <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">Zurück</button>
                <button type="button" class="btn btn-danger float-right" (click)="delete(form.value.DID)" [attr.data-right]="'DOCUMENT_DELETE' | right" *ngIf="userService.hasRight('DOCUMENT_DELETE')">Löschen</button>
            </ng-container>

            <ng-container *ngIf="viewMode == ViewMode.EDIT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
            </ng-container>
        </div>

        <div *ngIf="document && tab == TABS.ATTRIBUTES" role="tabpanel" class="tab-pane active">
            <app-attributes [DID]="document.DID"/>
        </div>

        <div *ngIf="tab == TABS.PDF" role="tabpanel" class="tab-pane active">
            <div *ngIf="file" class="pdf-embed">
                <embed [src]="embedString" type="application/pdf" width="100%" height="100%">
            </div>
            <span class="text-muted" *ngIf="!file">Es wurde noch keine Datei hochgeladen!</span>
        </div>

        <div *ngIf="tab == TABS.OCR" role="tabpanel" class="tab-pane active">
            <ng-container *ngIf="file">
                <ng-container *ngIf="document.ocr_status == 'done'">
                    <div>
                        <button type="button" class="btn btn-primary mb-3" [attr.data-right]="'DOCUMENT_OCR_EDIT' | right" *ngIf="userService.hasRight('DOCUMENT_OCR_EDIT')" (click)="updateOcrText()">Speichern</button>
                        <button type="button" class="btn btn-secondary mb-3 ml-2 mr-2" [attr.data-right]="'DOCUMENT_OCR_REDO' | right" *ngIf="userService.hasRight('DOCUMENT_OCR_REDO')" (click)="redoOcr(true)">Textdigitalisierung erneut ausführen</button>
                        <div class="text-muted">Zur Textsuche die browserintegrierte Suchfunktion verwenden (STRG+F)</div>
                    </div>

                    <hr/>

                    <ng-container *ngIf="userService.hasRight('DOCUMENT_OCR_EDIT')">
                        <quill-editor [modules]="quillModules" [(ngModel)]="document.ocr_text"/>
                    </ng-container>
                    <ng-container *ngIf="!userService.hasRight('DOCUMENT_OCR_EDIT')">
                        <div id="ocr_text" [innerHTML]="document.ocr_text"></div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="document.ocr_status == 'waiting'">
                    <div class="text-muted"><i class="fas fa-spinner fa-spin mr-2"></i>Das Dokument ist in der Warteschlange und wird in Kürze digitalisiert!</div>
                </ng-container>
                <ng-container *ngIf="document.ocr_status == 'running'">
                    <div class="text-muted"><i class="fas fa-spinner fa-spin mr-2"></i>Das Dokument wird gerade digitalisiert!</div>
                </ng-container>
                <ng-container *ngIf="document.ocr_status == 'encrypted'">
                    <div class="text-muted">Das Dokument ist verschlüsselt und kann nicht digitalisiert werden!</div>
                </ng-container>
                <ng-container *ngIf="document.ocr_status == 'error'">
                    <div class="text-muted">Das Dokument konnte nicht digitalisiert werden, bitte starten Sie den Vorgang manuell erneut!</div>
                    <button type="button" class="btn btn-secondary mt-2" (click)="redoOcr()">Textdigitalisierung erneut ausführen</button>
                </ng-container>
            </ng-container>
            <span class="text-muted" *ngIf="!file">Es wurde noch keine Datei hochgeladen!</span>
        </div>

        <div *ngIf="document && tab == TABS.TASKS" role="tabpanel" class="tab-pane active">
            <app-document-tasks [DID]="document.DID"/>
        </div>

        <div *ngIf="document && tab == TABS.ATTACHMENTS" role="tabpanel" class="tab-pane active">
            <div [attr.data-right]="'DOCUMENT_ATTACHMENTS_UPLOAD' | right">
                <app-file
                    [viewReadonly]="!userService.hasRight('DOCUMENT_ATTACHMENTS_EDIT')"
                    [doctype]="'attachment'"
                    [fkId]="document.DID"
                    [fkName]="'DID'"
                    [fkTable]="'document'"
                    [viewThumbnails]="true"
                    [viewThumbnailsSize]="'50px'"
                    [viewThumbnailsTilesSize]="'100px'"
                    [uploadSelectMultiple]="true"
                    [uploadMaxFilesize]="300"
                    [viewEditModeFlipImage]="true"
                    [viewLayout]="fileViewLayout.TABLE"
                    [viewNoFilesText]="'Es wurden noch keine Anhänge hochgeladen.'"
                    [showViewLayoutSwitcher]="true"
                    (reloadEvent)="getTabCount()"
                    [showDragAndDrop]="true"
                    [uploadDisabled]="!userService.hasRight('DOCUMENT_ATTACHMENTS_UPLOAD')"
                />
            </div>
        </div>
    </div>
</div>
