<div class="card mb-3">
    <div class="card-header">
        <div style="margin-top: 4px; display: inline-block">
            <i class="fas fa-search mr-2"></i>
            Attributsuche
        </div>
        <button type="button" class="btn btn-sm btn-secondary float-right" (click)="exportExcel()" [disabled]="exporting">Excel-Export</button>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-striped table-sm mb-0" *ngIf="locations">
                <thead>
                <tr>
                    <th>
                        <select class="form-control form-control-sm"
                                [(ngModel)]="CID"
                                (ngModelChange)="companyChanged()"
                                [class.fw-table-field-filtered]="CID != ''">
                            <option value="" selected>Alle</option>
                            <option *ngFor="let company of allCompanies" [value]="company.CID">{{ company.name }}</option>
                        </select>
                    </th>
                    <th>
                        <select class="form-control form-control-sm"
                                [(ngModel)]="LID"
                                (ngModelChange)="loadLocationsWithAttributes()"
                                [class.fw-table-field-filtered]="LID != ''">
                            <option value="" selected>Alle</option>
                            <ng-container *ngFor="let location of allLocations">
                                <option *ngIf="CID == '' || CID == location.CID" [value]="location.LID">{{ location.name }}</option>
                            </ng-container>
                        </select>
                    </th>
                    <th *ngFor="let template of templates">
                        <input type="text" class="form-control form-control-sm"
                               *ngIf="template.type != 'boolean'"
                               [(ngModel)]="search[template.name]"
                               (keyup.enter)="loadLocationsWithAttributes()"
                               [class.fw-table-field-filtered]="search[template.name] != ''">
                        <select class="form-control form-control-sm"
                                *ngIf="template.type == 'boolean'"
                                [(ngModel)]="search[template.name]"
                                (ngModelChange)="loadLocationsWithAttributes()"
                                [class.fw-table-field-filtered]="search[template.name] != ''">
                            <option value="" selected>Alle</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                        </select>
                    </th>
                </tr>
                <tr>
                    <th>Unternehmen</th>
                    <th>{{'LOCATION.LOCATION' | translate}}</th>
                    <th *ngFor="let template of templates">
                        {{ template.name }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let location of locations">
                    <td>
                        <a [routerLink]="[]" (click)="routerHelperService.openCompany(location.CID)">{{ location.company }}</a>
                    </td>
                    <td>
                        <a [routerLink]="[]" (click)="routerHelperService.openLocation(location.LID)">{{ location.name }}</a>
                    </td>
                    <td *ngFor="let attribute of location.attributes">
                        <ng-container *ngIf="attribute.type == 'int'">{{ attribute.value_int | number: '1.0' }}</ng-container>
                        <ng-container *ngIf="attribute.type == 'dec'">{{ attribute.value_dec | number: '1.0-2' }}</ng-container>
                        <ng-container *ngIf="attribute.type == 'date'">{{ attribute.value_date | date: "dd.MM.yyyy" }}</ng-container>
                        <ng-container *ngIf="attribute.type == 'text'">{{ attribute.value_text }}</ng-container>
                        <ng-container *ngIf="attribute.type == 'boolean'">{{ attribute.value_boolean | yesNo }}</ng-container>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
