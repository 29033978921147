<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card mt-3">
                        <div class="card-header">
                            Kontakt
                        </div>
                        <div class="card-body">
                            <form [formGroup]="form">
                                <p>Sie interessieren sich für NoteRadar, wünschen mehr Informationen oder eine persönliche Demo-Präsentation? – Wir sind für Sie da!</p>

                                <div class="form-group">
                                    <label>E-Mail</label>
                                    <input type="text" class="form-control" formControlName="email" appAutofocus>
                                </div>

                                <div class="form-group">
                                    <label>Nachricht</label>
                                    <textarea class="form-control" formControlName="message" rows="5"></textarea>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="sendMessage()">Absenden</button>
                        </div>
                    </div>

                    <div class="text-center">Alternativ schicken Sie uns einfach eine E-Mail an <a href="mailto:info@noteradar.at">info&#64;noteradar.at</a></div>
                </div>
            </div>
        </div>
    </main>
</div>
