import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, inject} from '@angular/core';
import {DOCUMENT, NgFor, NgIf, NgClass, AsyncPipe} from '@angular/common';

import {BreadcrumbService} from './breadcrumb.service';
import {Observable} from 'rxjs';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

function Replace(el: any): any {
    const nativeElement: HTMLElement = el.nativeElement;
    const parentElement: any = nativeElement.parentElement;

    // move all children out of the element
    while (nativeElement.firstChild) {
        parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }

    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);
}

@Component({
    selector: 'app-custom-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    imports: [
        NgFor,
        NgIf,
        NgClass,
        RouterLink,
        AsyncPipe,
        TranslateModule,
    ]
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

    service = inject(BreadcrumbService);
    el = inject(ElementRef);
    private document = inject(DOCUMENT);
    private renderer = inject(Renderer2);

    @Input() fixed: boolean = false;

    breadcrumbs!: Observable<Array<any>>;
    private readonly fixedClass = 'breadcrumb-fixed';

    ngOnInit(): void {
        Replace(this.el);
        this.isFixed(this.fixed);
        this.breadcrumbs = this.service.breadcrumbs;
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, this.fixedClass);
    }

    isFixed(fixed: boolean = this.fixed): void {
        if (fixed) {
            this.renderer.addClass(this.document.body, this.fixedClass);
        }
    }
}
