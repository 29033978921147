import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';
import {myAppConfig} from '../../settings';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-account-error',
    templateUrl: './account-error.component.html',
    styleUrl: './account-error.component.scss',
    imports: [TranslateModule]
})
export class AccountErrorComponent {

    private router = inject(Router);

    home(): void {
        this.router.navigate([myAppConfig.auth.loginDestination]);
    }
}
