import {Component} from '@angular/core';
import {PublicNavbarComponent} from './theme/navbar/navbar.component';
import {RouterOutlet} from '@angular/router';
import {PublicFooterComponent} from './theme/footer/footer.component';

@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrl: './public.component.scss',
    imports: [PublicNavbarComponent, RouterOutlet, PublicFooterComponent]
})
export class PublicComponent {

}
