import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewEncapsulation,
    inject
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {CryptoService} from '../../services/crypto.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {
    TableColumnFilterType,
    TableConfig,
    TableDesign,
    TableStatus,
    TableStatusType
} from './table.interfaces';
import {UserService} from '../../services/user.service';
import {StorageService} from '../../services/storage.service';
import {ViewMode} from '../../../interfaces';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {TableTableComponent} from './table-table/table-table.component';
import {TableSearchboxComponent} from './table-searchbox/table-searchbox.component';
import {TableRowswitchComponent} from './table-rowswitch/table-rowswitch.component';
import {TableFilterbuttonComponent} from './table-filterbutton/table-filterbutton.component';
import {PageChangedEvent, PaginationComponent} from "ngx-bootstrap/pagination";
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrl: './table.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, TableTableComponent, TableSearchboxComponent, TableRowswitchComponent, TableFilterbuttonComponent, NgTemplateOutlet, PaginationComponent, FormsModule]
})
export class TableComponent implements OnChanges {

    dataService = inject(DataService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);
    private localStorageService = inject(LocalStorageService);
    private userService = inject(UserService);
    private storageService = inject(StorageService);
    private route = inject(ActivatedRoute);

    @Input() config: TableConfig;
    @Output() status = new EventEmitter<TableStatus>();

    readonly ViewMode = ViewMode;
    readonly TableDesign = TableDesign;

    result: any = {};
    exporting = false;

    ngOnChanges(changes: SimpleChanges): void {
        // setup default values
        this.config.language = this.config.language || 'de';
        this.config.design = this.config.design || TableDesign.CARD;
        this.config.dataConfig.searchMinimumCharacter = this.config.dataConfig.searchMinimumCharacter || 3;
        this.config.dataConfig.search = this.config.dataConfig.search || '';
        this.config.dataConfig.columnFilter.buttonOn = this.config.dataConfig.columnFilter.buttonOn || 'Filter ein';
        this.config.dataConfig.columnFilter.buttonOff = this.config.dataConfig.columnFilter.buttonOff || 'Filter aus';
        this.config.dataConfig.columnFilter.trueValue = this.config.dataConfig.columnFilter.trueValue || 'Ja';
        this.config.dataConfig.columnFilter.falseValue = this.config.dataConfig.columnFilter.falseValue || 'Nein';
        this.config.dataConfig.columnFilter.dropDownDefault = this.config.dataConfig.columnFilter.dropDownDefault || 'Alle';
        this.config.dataConfig.columnFilter.active = this.config.dataConfig.columnFilter.active || false;
        this.config.paginConfig._totalItems = this.config.paginConfig._totalItems || 0;
        this.config.paginConfig.totalItemsLabel = this.config.paginConfig.totalItemsLabel || 'Zeilen';
        this.config.paginConfig.prevLabel = this.config.paginConfig.prevLabel || 'Zurück';
        this.config.paginConfig.nextLabel = this.config.paginConfig.nextLabel || 'Weiter';

        this.config.dataConfig.tableFields.forEach(field => {
            if (field.sortable === undefined) {
                field.sortable = false;
            }
            if (field.searchable === undefined) {
                field.searchable = false;
            }
            if (field.nowrap === undefined) {
                field.nowrap = false;
            }
            if (field.visible === undefined) {
                field.visible = true;
            }

            if (this.config.dataConfig.rowImage) {
                field._hasImage = this.config.dataConfig.rowImage.some(rowElement => rowElement.key === field.key);
            }
        });

        if (this.config.dataConfig.rowImage) {
            this.config.dataConfig.rowImage.forEach(field => {
                if (!field.width) {
                    field.width = 'auto';
                }
                if (!field.height) {
                    field.height = 'auto';
                }
            });
        }

        // end setup default values

        if (this.config.dataConfig.externalFilter?.key && this.config.dataConfig.externalFilter?.value) {
            this.config.dataConfig.columnFilter.active = true;
            this.config.dataConfig.columnFilter.fields[this.config.dataConfig.externalFilter.key].value = this.config.dataConfig.externalFilter.value;
        } else {
            // load config from localStorage
            if (this.config.localStorage.enabled) {
                const localStorage = this.localStorageService.getItem(this.config.localStorage.name);

                if (localStorage) {
                    if (localStorage.version !== this.config.localStorage.version) {
                        this.writeLocalStorage();
                    } else {
                        this.config.dataConfig.reverse = localStorage.reverse;
                        this.config.dataConfig.search = localStorage.search;
                        this.config.dataConfig.order = localStorage.order;
                        this.config.dataConfig.columnFilter = localStorage.columnFilter;
                    }
                }
            }
        }

        if (this.config.dataConfig.columnFilter) {
            Object.values(this.config.dataConfig.columnFilter.fields).forEach(field => {
                if (field.type === undefined) {
                    field.type = TableColumnFilterType.INPUT;
                }
            });
        }

        this.load(true);
    }

    writeLocalStorage(): void {
        if (!this.config.localStorage.enabled) {
            return;
        }

        const localStorage = {
            version: this.config.localStorage.version,
            reverse: this.config.dataConfig.reverse,
            search: this.config.dataConfig.search,
            order: this.config.dataConfig.order,
            columnFilter: this.config.dataConfig.columnFilter
        };
        this.localStorageService.setItem(this.config.localStorage.name, localStorage);
    }

    /**
     * Called when changing column order
     */
    tableSort(field: any): void {
        this.config.dataConfig.order = field.key;

        if (field.key === this.config.dataConfig.order) {
            this.config.dataConfig.reverse = !this.config.dataConfig.reverse;
        } else {
            this.config.dataConfig.reverse = false;
        }
        this.changeColumnFilterValue();
    }

    pageChanged(event: PageChangedEvent): void {
        this.config.paginConfig.currentPage = event.page;

        this.load();
    }

    load(firstLoad = false): void {
        this.dataService.request('framework.Table/listAll', {
            config: this.config,
            firstLoad
        }).subscribe(response => {
            this.config.paginConfig._totalItems = response.total;
            this.result.table = response.table.data;

            // set some values after first load
            if (firstLoad) {
                if (response.itemsPerPage) {
                    this.config.paginConfig.itemsPerPage = parseInt(response.itemsPerPage);
                }

                if (response.tableFields) {
                    Object.entries(response.tableFields).forEach(([key, field]: any) => {
                        const configTableField = this.config.dataConfig.tableFields.find(tableField => tableField.key === key);

                        configTableField._DB_type_name = field._DB_type_name;
                        configTableField._DB_type_constraints = field._DB_type_constraints;
                        configTableField._DB_type_constraints_translated = field._DB_type_constraints_translated;
                    });
                }
            }

            if (this.config.dataConfig.columnFilter.active) {
                this.result.selects = response.selects;
            }

            this.config.dataConfig.tableFields.forEach(field => {
                if (field.footerSum) {
                    field._footerSumValue = response.footerSums[field.name];
                }
            });
        });
    }

    /**
     * When a row is clicked emit status event and route to detail page if set
     */
    openDataset(data: any): void {
        if (data.row) {
            this.status.emit({
                type: TableStatusType.ENTRY_CLICKED,
                data: data.row
            });
        }

        if (this.config.openDatasetRouterLink != null) {
            const urlData: any = {
                ID: data.row ? data.row[this.config.dataConfig.tableIndexName] : '0',
                viewMode: data.viewMode
            }

            if (this.config.routerData) {
                urlData.data = this.config.routerData;
            }

            if (this.config.relativeRouterLink) {
                this.router.navigate([this.config.openDatasetRouterLink + '/', this.cryptoService.encrypt(urlData)], {relativeTo: this.route}).then();
            } else {
                this.router.navigate([this.config.openDatasetRouterLink + '/', this.cryptoService.encrypt(urlData)]).then();
            }
        }
    }

    /**
     * Toggle active state of column filter
     */
    changeColumnFilterActive(): void {
        this.config.dataConfig.columnFilter.active = !this.config.dataConfig.columnFilter.active;
        this.changeColumnFilterValue();
    }

    /**
     * When a column filter was changed save config and reload table
     */
    changeColumnFilterValue(): void {
        this.writeLocalStorage();
        this.load();
    }

    /**
     * When amount of rows was changed reload table
     */
    changeRows(): void {
        if (this.config.paginConfig.itemsPerPageDb) {
            this.storageService.set('tableRows', this.config.localStorage.name, this.config.paginConfig.itemsPerPage).subscribe();
        }
        this.load();
    }

    exportExcel(): void {
        this.exporting = true;

        this.dataService.request('framework.Table/exportExcel', {
            config: this.config,
            CLID: this.userService.currentUser.CLID
        }).subscribe(response => {
            this.exporting = false;

            const link = document.createElement('a');

            link.setAttribute('href', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data);
            link.setAttribute('download', response.path);
            link.click();
        });
    }
}
