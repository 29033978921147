import {Injectable} from '@angular/core';
import {myAppConfig} from "../../settings";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {


    setItem(itemName: string, value: any): void {
        localStorage.setItem(myAppConfig.appID + '_' + itemName, JSON.stringify(value));
    }

    getItem(itemName: string): any {
        const item = localStorage.getItem(myAppConfig.appID + '_' + itemName);
        if (item) {
            return JSON.parse(item);
        } else {
            return null;
        }
    }

    removeItem(itemName: string): void {
        localStorage.removeItem(myAppConfig.appID + '_' + itemName);
    }
}
