<div class="card">
    <div class="card-header">
        <i class="fas fa-tags"></i> Tags
    </div>
    <div class="card-body">
        <app-tag-viewer [config]="config" (tagClicked)="handleClick($event)" (found)="setFound($event)"/>

        <div class="text-muted mt-2" *ngIf="!results && !loading && found > 0">Bitte einen Tag auswählen, um dessen Zuordnungen anzuzeigen.</div>

        <div class="fa-2x mt-2" *ngIf="loading">
            <i class="fas fa-spinner fa-spin"></i>
        </div>

        <ng-container *ngIf="results">
            <h4 class="mt-3">Unternehmen</h4>
            <span class="text-muted" *ngIf="results.companies.length == 0">Keine Unternehmen gefunden</span>
            <div class="table-responsive" *ngIf="results.companies.length > 0">
                <table class="table table-sm mb-0">
                    <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let company of results.companies">
                        <td>
                            <a [attr.data-right]="'COMPANY_VIEW' | right" *ngIf="userService.hasRight('COMPANY_VIEW')" [routerLink]="[]" (click)="routerHelperService.openCompany(company.CID)">{{ company.name }}</a>
                            <span *ngIf="!userService.hasRight('COMPANY_VIEW')">{{ company.name }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <h4 class="mt-3">{{'LOCATION.LOCATIONS' | translate}}</h4>
            <span class="text-muted" *ngIf="results.locations.length == 0">Keine {{'LOCATION.LOCATIONS' | translate}} gefunden</span>
            <div class="table-responsive" *ngIf="results.locations.length > 0">
                <table class="table table-sm mb-0">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Unternehmen</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let location of results.locations">
                        <td>
                            <a [attr.data-right]="'LOCATION_VIEW' | right" *ngIf="userService.hasRight('LOCATION_VIEW')" [routerLink]="[]" (click)="routerHelperService.openLocation(location.LID)">{{ location.name }}</a>
                            <span *ngIf="!userService.hasRight('LOCATION_VIEW')">{{ location.name }}</span>
                        </td>
                        <td>{{location.company}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <h4 class="mt-3">Dokumente</h4>
            <span class="text-muted" *ngIf="results.documents.length == 0">Keine Dokumente gefunden</span>
            <div class="table-responsive" *ngIf="results.documents.length > 0">
                <table class="table table-sm mb-0">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>{{'LOCATION.LOCATION' | translate}}</th>
                        <th>Unternehmen</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let document of results.documents">
                        <td>
                            <a [attr.data-right]="'DOCUMENT_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_VIEW')" [routerLink]="[]" (click)="routerHelperService.openDocument(document.DID)">{{ document.name }}</a>
                            <span *ngIf="!userService.hasRight('DOCUMENT_VIEW')">{{ document.name }}</span>
                        </td>
                        <td>{{document.location}}</td>
                        <td>{{document.company}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <h4 class="mt-3">Aufgaben</h4>
            <span class="text-muted" *ngIf="results.tasks.length == 0">Keine Aufgaben gefunden</span>
            <div class="table-responsive" *ngIf="results.tasks.length > 0">
                <table class="table table-sm mb-0">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Dokument</th>
                        <th>{{'LOCATION.LOCATION' | translate}}</th>
                        <th>Unternehmen</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let task of results.tasks">
                        <td><a [routerLink]="[]" (click)="routerHelperService.openTask(task.TID)">{{ task.title }}</a></td>
                        <td>{{task.document}}</td>
                        <td>{{task.location}}</td>
                        <td>{{task.company}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</div>
