import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-role',
    templateUrl: './role.component.html',
    styleUrl: './role.component.scss',
    imports: [RouterOutlet]
})
export class RoleComponent {

}
