<div class="card">
    <div class="card-header">
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Account bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Account</ng-container>
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-sm-12 col-md-8">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.name }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                        <label>Firmenbuchnummer</label>
                        <input type="text" class="form-control" formControlName="register_number" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.register_number }}</p>
                    </div>
                </div>
            </div>

            <h5>Adresse</h5>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                        <label>PLZ</label>
                        <input type="number" class="form-control" formControlName="zip" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.zip }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                        <label>Ort</label>
                        <input type="text" class="form-control" formControlName="city" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.city }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                        <label>Straße</label>
                        <input type="text" class="form-control" formControlName="street" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.street }}</p>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="userService.currentUser.client_demo == '0'">
                <h5>Rechnungsdaten</h5>
                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                            <label>PLZ</label>
                            <input type="text" class="form-control" formControlName="invoice_zip" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.invoice_zip }}</p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                            <label>Ort</label>
                            <input type="text" class="form-control" formControlName="invoice_city" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.invoice_city }}</p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                            <label>Straße</label>
                            <input type="text" class="form-control" formControlName="invoice_street" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.invoice_street }}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                            <label>E-Mail</label>
                            <input type="text" class="form-control" formControlName="invoice_email" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.invoice_email }}</p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="form-group">
                            <label>UID</label>
                            <input type="text" class="form-control" formControlName="invoice_UID" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.invoice_UID }}</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Text</label>
                            <input type="text" class="form-control" formControlName="invoice_text" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.invoice_text }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>

            <h5>Kontaktdaten</h5>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" class="form-control" formControlName="email" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.email }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                        <label>Telefon</label>
                        <input type="text" class="form-control" formControlName="phone" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.phone }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                        <label>Kostenstelle</label>
                        <input type="text" class="form-control" formControlName="cost_unit" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.cost_unit }}</p>
                    </div>
                </div>
            </div>

            <h5>Vertragsvereinbarungen</h5>
            <div class="row">
                <div class="col-sm-12 col-md-8">
                    <div class="form-group">
                        <label>Anzahl freigegebener {{ 'LOCATION.LOCATIONS' | translate }}</label>
                        <p>{{ form.value.number_locations }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4" *ngIf="userService.currentUser.client_demo == '0'">
                    <div class="form-group">
                        <label>Zahlungsart</label>
                        <p>{{ form.value.payment_type | paymentType }}</p>
                    </div>
                </div>
            </div>
        </form>

        <div class="alert alert-warning fade show mb-0" role="alert">
            Bitte teilen Sie uns etwaige Änderungen Ihrer Daten per E-Mail an <a href="mailto:info@noteradar.at">info&#64;noteradar.at</a> mit!
        </div>

        <!--<ng-container *ngIf="viewMode == ViewMode.VIEW">
            <button type="button" class="btn btn-primary" *ngIf="userService.currentUser.client_demo == '0'" (click)="setMode(ViewMode.EDIT)">Bearbeiten</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>-->
    </div>
</div>
