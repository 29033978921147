<button type="button" class="btn btn-primary btn-sm mb-3" (click)="openTask('0')">Aufgabe anlegen</button>

<table class="table table-sm table-hover mb-0">
    <thead>
    <tr>
        <th>Nummer</th>
        <th>Titel</th>
        <th>Erstellt</th>
        <th>Abteilung</th>
        <th>Thema</th>
        <th>Typ</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let task of tasks" (click)="openTask(task.TID)">
        <td>{{ task.number }}</td>
        <td>{{ task.title }}</td>
        <td>{{ task.created | date: 'dd.MM.yyyy' }}</td>
        <td>{{ task.department }}</td>
        <td>{{ task.tasktopic }}</td>
        <td>{{ task.tasktype }}</td>
    </tr>
    </tbody>
</table>
