import {Component, OnInit, inject} from '@angular/core';
import {TableColumnFilterType, TableConfig} from '../../../global/components/table/table.interfaces';
import {UserService} from '../../../global/services/user.service';
import {TableComponent} from '../../../global/components/table/table.component';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrl: './user-list.component.scss',
    imports: [TableComponent]
})
export class UserListComponent implements OnInit {

    private userService = inject(UserService);

    tableConfig!: TableConfig;

    ngOnInit(): void {
        this.tableConfig = {
            titleIcon: 'fas fa-user-friends',
            title: 'Benutzer',
            openDatasetRouterLink: '/app/user/detail',
            enableCreate: this.userService.hasRight('MASTER_DATA_EDIT'),
            enableExportExcel: true,
            description: 'Ihre Benutzerzugänge - Sie können beliebig viele Benutzer anlegen.',
            dataConfig: {
                tableName: 'user',
                tableIndexName: 'UID',
                tableFilter: [],
                tableJoins: [
                    {left: 'role', right: 'user', key: 'id', keyRight: 'id_role'},
                ],
                tableFields: [
                    {key: 'firstname', name: 'firstname', title: 'Vorname', sortable: true, searchable: true},
                    {key: 'lastname', name: 'lastname', title: 'Nachname', sortable: true, searchable: true},
                    {key: 'email', name: 'email', title: 'E-Mail', sortable: true, searchable: true},
                    {
                        key: 'last_login',
                        name: 'last_login',
                        title: 'Letzter Login',
                        sortable: true,
                        searchable: true,
                        dateFormat: {en: '%Y.%m.%d %H:%i', others: '%d.%m.%Y %H:%i'}
                    },
                    {key: 'active', name: 'active', title: 'Aktiv', sortable: true, searchable: true},
                    {key: 'role', table: 'role', name: 'name', title: 'Rolle', sortable: true},
                ],
                reverse: false,
                order: 'lastname',
                columnFilter: {
                    enabled: true,
                    fields: {
                        firstname: {value: '', type: TableColumnFilterType.INPUT},
                        lastname: {value: '', type: TableColumnFilterType.INPUT},
                        email: {value: '', type: TableColumnFilterType.INPUT},
                        active: {value: '', type: TableColumnFilterType.BOOLEAN},
                        role: {value: '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 2,
                name: 'user-list',
                enabled: true
            },
            paginConfig: {
                id: 'userList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }
}
