import {Component, inject, OnInit} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';

import Swal from 'sweetalert2';

import {DataService} from '../../global/services/data.service';
import {CryptoService} from '../../global/services/crypto.service';
import {ViewMode, UrlData} from '../../interfaces';
import {NgIf, NgFor, DatePipe} from '@angular/common';
import {ReminderIntervalPipe} from '../../global/pipes/reminder-interval.pipe';
import {TaskTypePipe} from '../../global/pipes/task-type.pipe';
import {WeekdayPipe} from '../../global/pipes/weekday.pipe';

@Component({
    selector: 'app-admin-task-detail',
    templateUrl: './admin-task-detail.component.html',
    styleUrls: ['./admin-task-detail.component.scss'],
    imports: [NgIf, FormsModule, ReactiveFormsModule, RouterLink, NgFor, DatePipe, ReminderIntervalPipe, TaskTypePipe, WeekdayPipe]
})
export class AdminTaskDetailComponent implements OnInit {

    private dataService = inject(DataService);
    private router = inject(Router);
    private fb = inject(UntypedFormBuilder);
    private cryptoService = inject(CryptoService);

    private DID!: string;
    private TID!: string;
    private dbName!: string;
    private CLID!: string;

    readonly ViewMode = ViewMode;

    form!: UntypedFormGroup;
    task: any;
    viewMode!: ViewMode;

    document: any;
    attributes!: any[];
    departments!: any[];
    topics!: any[];
    taskTypes!: any[];

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.TID = urlData.ID;
        this.DID = urlData.data?.DID;
        this.dbName = urlData.data?.dbName;
        this.CLID = urlData.data?.CLID;
        this.viewMode = urlData.viewMode;

        this.form = this.fb.group({
            TID: [null],
            DID: [this.DID, Validators.required],
            DPID: ['', Validators.required],
            TOID: [''],
            TTID: [''],
            created: [null],
            title: [null, Validators.required],
            text: [null, Validators.required],
            number: [null, Validators.required],
            type: ['', Validators.required],
            due_date: [null, Validators.required],
            interval: [null],
            weekly_day: [null],
            monthly_yearly_date: [null],
            yearly_month: [null],
        });

        if (this.TID !== '0') {
            this.getTask();
        } else {
            this.viewMode = ViewMode.INSERT;
        }

        this.dataService.request('universe.Admin/listAllDepartments', {
            dbName: this.dbName
        }).subscribe(response => {
            this.departments = response.departments;
        });

        this.dataService.request('universe.Admin/listAllTaskTopics', {
            dbName: this.dbName
        }).subscribe(response => {
            this.topics = response.topics;
        });

        this.dataService.request('universe.Admin/listAllTaskTypes', {
            dbName: this.dbName
        }).subscribe(response => {
            this.taskTypes = response.tasktypes;
        });
    }

    getTask(): void {
        this.dataService.request('universe.Admin/getTask', {
            TID: this.TID,
            dbName: this.dbName
        }).subscribe(response => {
            this.task = response.task;

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            TID: this.task.TID,
            DID: this.task.DID,
            DPID: this.task.DPID,
            TOID: this.task.TOID,
            TTID: this.task.TTID,
            created: this.task.created,
            title: this.task.title,
            text: this.task.text,
            number: this.task.number,
            type: this.task.type,
            due_date: this.task.due_date,
            interval: this.task.interval,
            weekly_day: this.task.weekly_day,
            monthly_yearly_date: this.task.monthly_yearly_date,
            yearly_month: this.task.yearly_month,
        });
    }

    save(): void {
        this.dataService.request('universe.Admin/saveTask', {
            form: this.form.getRawValue(),
            dbName: this.dbName
        }).subscribe(() => {
            const data: UrlData = {
                ID: this.DID,
                data: {
                    dbName: this.dbName,
                    CLID: this.CLID
                }
            };
            this.router.navigate(['/admin/document/detail', this.cryptoService.encrypt(data)]);
        });
    }

    update(): void {
        this.dataService.request('universe.Admin/updateTask', {
            form: this.form.getRawValue(),
            dbName: this.dbName
        }).subscribe(() => {
            this.setMode(ViewMode.VIEW);
            // reload task so responsible user is updated
            this.getTask();
        });
    }

    cancel(): void {
        const data: UrlData = {
            ID: this.DID,
            data: {
                dbName: this.dbName,
                CLID: this.CLID
            }
        };
        this.router.navigate(['/admin/document/detail', this.cryptoService.encrypt(data)]);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    delete(TID: any): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Die Aufgabe wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('universe.Admin/deleteTask', {
                    TID,
                    dbName: this.dbName
                }).subscribe(() => {
                    this.cancel();
                });
            }
        });
    }

    setDueDate(attribute: any): void {
        this.form.patchValue({
            due_date: attribute.value_date
        });
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }
}
