<div class="fw-table">

    <div class="table-responsive">
        <table class="table table-sm table-hover smaller">
            <thead>
            <tr *ngIf="config.dataConfig.columnFilter.active">
                <ng-container *ngFor="let tf of config.dataConfig.tableFields">
                    <th
                        *ngIf="tf.visible"
                        [class.text-center]="tf.alignment === TableFieldAlignment.CENTER"
                        [class.text-right]="tf.alignment === TableFieldAlignment.RIGHT">

                        <input *ngIf="config.dataConfig.columnFilter.fields[tf.key] && config.dataConfig.columnFilter.fields[tf.key]?.type === TableColumnFilterType.INPUT"
                               [(ngModel)]="config.dataConfig.columnFilter.fields[tf.key].value"
                               class="form-control form-control-sm"
                               type="text"
                               autocomplete="off"
                               appDebounceKeyup
                               [debounceTime]="500"
                               (debounceKeyup)="changeColumnFilter()"
                               [class.fw-table-field-filtered]="config.dataConfig.columnFilter.fields[tf.key].value != ''">

                        <select
                            *ngIf="result.selects && (
                                config.dataConfig.columnFilter.fields[tf.key]?.type === TableColumnFilterType.LOOKUP ||
                                config.dataConfig.columnFilter.fields[tf.key]?.type === TableColumnFilterType.SUBQUERY_COUNT ||
                                config.dataConfig.columnFilter.fields[tf.key]?.type === TableColumnFilterType.SUBQUERY_CONCAT)"
                            class="form-control form-control-sm"
                            autocomplete="off"
                            [(ngModel)]="config.dataConfig.columnFilter.fields[tf.key].value"
                            (change)="changeColumnFilter()"
                            [class.fw-table-field-filtered]="config.dataConfig.columnFilter.fields[tf.key].value != ''">
                            <option value="">{{ config.dataConfig.columnFilter.dropDownDefault}}</option>
                            <option *ngFor="let item of result.selects[tf.key]" [ngValue]="item[tf.key]">
                                <ng-container *ngIf="!tf.translate">
                                    {{item[tf.key]}}
                                </ng-container>
                                <ng-container *ngIf="tf.translate">
                                    {{(tf.translateValuePrefix ? (tf.translateValuePrefix + '.' + item[tf.key]) : item[tf.key]) | translate}}
                                </ng-container>
                            </option>
                        </select>

                        <select *ngIf="result.selects && config.dataConfig.columnFilter.fields[tf.key]?.type === TableColumnFilterType.ENUM"
                                class="form-control form-control-sm"
                                autocomplete="off"
                                [(ngModel)]="config.dataConfig.columnFilter.fields[tf.key].value"
                                (change)="changeColumnFilter()"
                                [class.fw-table-field-filtered]="config.dataConfig.columnFilter.fields[tf.key].value != ''">
                            <option value="">{{ config.dataConfig.columnFilter.dropDownDefault}}</option>
                            <option *ngFor="let item of result.selects[tf.key]" [ngValue]="item">
                                {{item}}
                            </option>
                        </select>

                        <select *ngIf="result.selects && config.dataConfig.columnFilter.fields[tf.key]?.type === TableColumnFilterType.BOOLEAN"
                                class="form-control form-control-sm"
                                autocomplete="off"
                                [(ngModel)]="config.dataConfig.columnFilter.fields[tf.key].value"
                                (change)="changeColumnFilter()"
                                [class.fw-table-field-filtered]="config.dataConfig.columnFilter.fields[tf.key].value != ''">
                            <option value="">{{ config.dataConfig.columnFilter.dropDownDefault}}</option>
                            <option *ngFor="let item of result.selects[tf.key]" [ngValue]="item">
                                <ng-container *ngIf="item == '0'">{{ config.dataConfig.columnFilter.falseValue}}</ng-container>
                                <ng-container *ngIf="item == '1'">{{ config.dataConfig.columnFilter.trueValue}}</ng-container>
                            </option>
                        </select>

                        <select *ngIf="result.selects && (config.dataConfig.columnFilter.fields[tf.key]?.type === TableColumnFilterType.GROUP ||
                                config.dataConfig.columnFilter.fields[tf.key]?.type === TableColumnFilterType.SUBQUERY_GROUP)"
                                class="form-control form-control-sm"
                                autocomplete="off"
                                [(ngModel)]="config.dataConfig.columnFilter.fields[tf.key].value"
                                (change)="changeColumnFilter()"
                                [class.fw-table-field-filtered]="config.dataConfig.columnFilter.fields[tf.key].value != ''">
                            <option value="">{{ config.dataConfig.columnFilter.dropDownDefault}}</option>
                            <option *ngFor="let item of result.selects[tf.key]" [ngValue]="item">
                                <ng-container *ngIf="!tf.translate">
                                    {{item}}
                                </ng-container>
                                <ng-container *ngIf="tf.translate">
                                    {{(tf.translateValuePrefix ? (tf.translateValuePrefix + '.' + item) : item) | translate}}
                                </ng-container>
                            </option>
                        </select>

                    </th>
                </ng-container>
            </tr>
            <tr>
                <ng-container *ngFor="let tf of config.dataConfig.tableFields">
                    <th *ngIf="tf.sortable && tf.visible"
                        (click)="changeSort.emit(tf)"
                        style="cursor: pointer"
                        [ngClass]="{'asc' : config.dataConfig.order === tf.key && config.dataConfig.reverse === false, 'desc' : config.dataConfig.order === tf.key && config.dataConfig.reverse === true}"
                        [class.fw-table-column-sorted]="config.dataConfig.order === tf.key">
                        <div class="d-flex"
                             [class.fw-div-center]="tf.alignment === TableFieldAlignment.CENTER"
                             [class.fw-div-right]="tf.alignment === TableFieldAlignment.RIGHT">
                            <div [class.nowWrap]="tf.nowrapHeader">{{tf.translateTitle ? ((tf.translateTitlePrefix ? (tf.translateTitlePrefix + '.' + tf.title) : tf.title) | translate) : tf.title}}</div>
                            <div *ngIf="config.dataConfig.order === tf.key" class="pl-1">
                                <i class="fa" [ngClass]="{'fa-arrow-down': config.dataConfig.reverse === false, 'fa-arrow-up': config.dataConfig.reverse === true}"></i>
                            </div>
                        </div>
                    </th>
                    <th *ngIf="!tf.sortable && tf.visible" class="fw-table-filed-noSort"
                        [class.text-center]="tf.alignment === TableFieldAlignment.CENTER"
                        [class.text-right]="tf.alignment === TableFieldAlignment.RIGHT">{{tf.translateTitle ? ((tf.translateTitlePrefix ? (tf.translateTitlePrefix + '.' + tf.title) : tf.title) | translate) : tf.title}}</th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="result.table?.length == 0">
                <td [colSpan]="config.dataConfig.tableFields.length">
                    <span class="font-italic">Keine Daten gefunden</span>
                </td>
            </tr>
            <tr
                *ngFor="let row of result.table"
                [class.fw-table-row-focused]="rowFocus === row[config.dataConfig.tableIndexName]"
                [style.color]="config.dataConfig.rowColor ? getColor(row) : ''"
                (click)="openDataset(row)">

                <ng-container *ngFor="let tf of config.dataConfig.tableFields">
                    <td
                        *ngIf="tf.visible && (!tf.fileViewer && !tf.fileViewerFID)">
                        <span [class.nowWrap]="tf.nowrap">
                            <ng-container [ngSwitch]="tf._DB_type_name">
                                <ng-container *ngSwitchCase="'tinyint'">
                                    <app-table-table-field-image [dataConfig]="config.dataConfig" [tableField]="tf" [row]="row">
                                        {{row[tf.key] == '1' ? config.dataConfig.columnFilter.trueValue : config.dataConfig.columnFilter.falseValue}}
                                    </app-table-table-field-image>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <app-table-table-field-image [dataConfig]="config.dataConfig" [tableField]="tf" [row]="row">
                                        <ng-container *ngIf="!tf.translate">{{row[tf.key]}}</ng-container>
                                        <ng-container *ngIf="tf.translate && !tf.translateValuePrefix">{{row[tf.key] | translate}}</ng-container>
                                        <ng-container *ngIf="tf.translateValuePrefix">{{tf['translateValuePrefix'] + '.' + row[tf.key] | translate}}</ng-container>
                                    </app-table-table-field-image>
                                </ng-container>
                            </ng-container>
                        </span>
                    </td>

                    <td *ngIf="tf.fileViewer">

                    </td>

                    <td *ngIf="tf.fileViewerFID">
                        <app-file-viewer-single
                            [FID]="row[tf.fileViewerFID.FID]"
                            [imageWidth]="tf.fileViewerFID.width"
                            [imageHeight]="tf.fileViewerFID.height"
                            [viewThumbnail]="tf.fileViewerFID.viewThumbnail"
                        />
                    </td>
                </ng-container>
            </tr>
            </tbody>
            <tfoot *ngIf="checkFooterActive()">
            <tr>
                <td
                    *ngFor="let tf of config.dataConfig.tableFields"
                    [class.text-center]="tf.alignment === TableFieldAlignment.CENTER"
                    [class.text-right]="tf.alignment === TableFieldAlignment.RIGHT">
                    <span *ngIf="tf.footerSum" class="footer-value">{{tf._footerSumValue | number: '1.0-2'}}</span>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>
</div>

<app-file-modal
    *ngIf="config.fileViewerConfig"
    [viewFlipImage]="config.fileViewerConfig.viewFlipImage"
    [viewFileInfo]="config.fileViewerConfig.viewFileInfo"
/>
