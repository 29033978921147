<div class="jumbotron jumbotron-fluid mb-0">
    <div class="container">
        <h1 class="display-4">Vom Verwalten zum Gestalten</h1>
        <p class="lead subtext">Einfach und effizient Bescheide und Verträge<br/>managen und digitalisieren</p>
    </div>
</div>

<div class="container-fluid">
    <div class="row bg-white">
        <div class="offset-sm-1 col-sm-10 offset-md-2 col-md-8" style="padding-top: 40px; padding-bottom: 40px">

            <p>NoteRadar unterstützt Sie bei der Digitalisierung Ihrer <a [routerLink]="['/features']" fragment="Vertrag">Verträge</a> und <a [routerLink]="['/features']" fragment="Bescheid">Bescheide</a> und
                bietet Ihnen eine Online-Plattform für alle Ihre digitalisierten Unterlagen die Sie bequem verwalten können. Es
                steht Ihnen frei, einmalige oder wiederkehrende Aufgaben mit Fälligkeiten zu definieren über die Sie dann
                rechtzeitig per Erinnerung informiert werden. Ein übersichtliches Dashboard bietet einen raschen Überblick
                über aktuelle und anstehende Termine. Über eine eigene <a [routerLink]="['/features']" fragment="Share">FileShare</a> können Sie zusätzlich beliebige Dokumente mit Ihren externen Partnern austauschen.</p>

            <div class="row info">
                <div class="col-sm-6 col-md-6 col-lg-4">
                    <i class="fas fa-tachometer-alt" style="color: #f86c6b"></i>
                    <h3 class="">Schnelle Digitalisierung</h3>
                    <p>Innerhalb von 48h haben wir Ihre Bescheide oder Verträge digitalisiert und diese in einzelne Aufgaben geteilt. Diese werden dann in Ihrem Dashboard ersichtlich.</p>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4">
                    <i class="far fa-bell" style="color: #ffc107"></i>
                    <h3 class="">Flexible Erinnerungen</h3>
                    <p>Die Erinnerungsfunktionen sind bei uns flexibel gestaltet - ob Erinnerung, Fälligkeit oder Termine – Sie werden immer rechtzeitig benachrichtigt.</p>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                    <i class="far fa-check-circle" style="color: #4dbd74"></i>
                    <h3 class="">Einfache Bedienung</h3>
                    <p>Die Oberfläche der gesamten Applikation wurde sehr einfach, effizient und für die schnelle Bedienung entwickelt.</p>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="row" style="background: #f5f5f5">
        <div class="offset-sm-1 col-sm-10 offset-md-2 col-md-8" style="padding-top: 100px; padding-bottom: 100px">

            <h1 class="display-4 text-center mb-4"><i class="fas fa-cubes"></i> Demozugang anfordern</h1>

            <div class="row">
                <div class="col-md-12">
                    <ng-container *ngIf="!demoRequested">
                        <form class="form-inline form-demo" [formGroup]="demoForm">
                            <input type="email" class="form-control form-control-lg" placeholder="Geben Sie Ihre E-Mail ein" formControlName="email" email>
                            <button type="submit" class="btn btn-primary btn-lg ml-3" [disabled]="demoForm.invalid" (click)="requestDemo()">Anfordern</button>
                        </form>
                        <p class="mt-3">Fordern Sie einfach Ihren Demozugang an und Sie erhalten für 7 Tage Zugang zur Demo Applikation und der entsprechenden Erklärungsvideos.</p>
                    </ng-container>

                    <div class="text-center" *ngIf="demoRequested">
                        Vielen Dank! Sie erhalten in Kürze eine E-Mail mit Ihren Zugangsdaten!
                    </div>
                </div>
            </div>

        </div>
    </div>-->

    <div class="row" style="background: #f5f5f5">
        <div class="offset-sm-1 col-sm-10 offset-md-2 col-md-8 text-center" style="padding-top: 100px; padding-bottom: 100px">

            <h1 class="display-4 text-center mb-4"><i class="fas fa-cubes"></i> Angebot anfordern</h1>

            <p class="mt-3">Sie möchten NoteRadar einsetzen? Wir schicken Ihnen gerne ein individuelles Angebot!</p>

            <button type="button" class="btn btn-primary btn-lg mt-3" [routerLink]="['/offer']">Jetzt anfordern</button>

        </div>
    </div>

    <div class="row bg-white">
        <div class="offset-sm-1 col-sm-10 offset-md-2 col-md-8" style="padding-top: 40px; padding-bottom: 40px">

            <h2 class="display-4 text-center mb-4"><i class="far fa-comment-dots" style="color: #adb6bd"></i> Was unsere Kunden sagen</h2>

            <div class="row info">
                <div class="col-sm-12 col-md-6 col-lg-4 mb-2">
                    <img class="rounded-circle" alt="feedback" src="assets/img/person/person1.jpg" style="width: 150px">
                    <blockquote class="blockquote mt-1">
                        <p class="mb-0">Ich konnte durch die Einführung und der schnellen Digitalisierung meiner Verträge bereits im ersten Jahr Einsparungen umsetzen.</p>
                        <footer class="blockquote-footer">Tischlerei <cite title="Salzburg">Salzburg</cite></footer>
                    </blockquote>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mb-2">
                    <img class="rounded-circle" alt="feedback" src="assets/img/person/person2.jpg" style="width: 150px">
                    <blockquote class="blockquote mt-1">
                        <p class="mb-0">Danke an das Team von NoteRadar - bis dato keine Beanstandungen der Gewerbebehörde mehr.</p>
                        <footer class="blockquote-footer">Gastronom <cite title="Wien 1010">Wien 1010</cite></footer>
                    </blockquote>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4 mb-2">
                    <img class="rounded-circle" alt="feedback" src="assets/img/person/person3.jpg" style="width: 150px">
                    <blockquote class="blockquote mt-1">
                        <p class="mb-0">Durch die Nutzung der Software konnte ich meine Verträge rechtzeitig nachverhandeln.</p>
                        <footer class="blockquote-footer">UnternehmerIn <cite title="Graz">Graz</cite></footer>
                    </blockquote>
                </div>
            </div>

        </div>
    </div>

    <div class="row bg-white row-info">
        <div class="col mb-2">
            <p><i class="far fa-envelope-open" style="color: cornflowerblue"></i></p>Kompetenter Fachsupport
        </div>
        <div class="col mb-2">
            <p><img src="assets/img/Austria.png"></p>Qualitätsprodukt aus Österreich
        </div>
        <div class="col mb-2">
            <p><i class="fab fa-expeditedssl" style="color: forestgreen"></i></p>Sichere SSL Datenübertragung
        </div>
        <div class="col mb-2">
            <p><a href="https://www.world4you.com/" target="_blank"><i class="fas fa-globe-europe"></i></a></p>Alle Daten und Server liegen in Österreich
        </div>
        <div class="col mb-2">
            <p><i class="fas fa-user-secret" style="color: dimgray"></i></p>Datenschutz - Ihre Daten gehören Ihnen
        </div>
    </div>
</div>
