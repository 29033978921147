import {Component, OnInit, ViewChild, inject} from '@angular/core';
import {Router} from '@angular/router';

import {ChartConfiguration, ChartType} from 'chart.js';
import dayjs from 'dayjs';

import {DataService} from '../../global/services/data.service';
import {CryptoService} from '../../global/services/crypto.service';
import {MapComponent} from '../../global/components/map/map.component';
import {ViewMode, UrlData} from '../../interfaces';
import {NgIf, NgFor, DecimalPipe, DatePipe} from '@angular/common';
import {BaseChartDirective} from 'ng2-charts';
import {YesNoPipe} from '../../global/pipes/yes-no.pipe';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss'],
    imports: [NgIf, BaseChartDirective, MapComponent, NgFor, DecimalPipe, DatePipe, YesNoPipe]
})
export class AdminDashboardComponent implements OnInit {

    private dataService = inject(DataService);
    private cryptoService = inject(CryptoService);
    private router = inject(Router);

    @ViewChild('mapComponent') mapComponent!: MapComponent;

    stats: any = {};
    clients: any[] = [];
    locations: any[] = [];
    currentMonth!: number;

    lineChartData!: ChartConfiguration['data'];

    lineChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                tension: 0
            }
        },
    };

    lineChartType: ChartType = 'line';

    ngOnInit(): void {
        this.dataService.request('universe.Admin/getStats').subscribe(response => {
            this.stats = response.stats;
            this.clients = response.clients;
            this.clients.forEach((client: any) => {
                client.active = client.active === '1';
                client.demo = client.demo === '1';
            });

            this.lineChartData = {
                datasets: [
                    {
                        data: [],
                        label: 'Aktivitäten',
                        backgroundColor: 'rgba(0,34,255,0.3)',
                        borderColor: 'blue',
                        pointBackgroundColor: 'rgba(148,159,177,1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
                        fill: 'origin',
                    }
                ],
                labels: []
            };
            this.lineChartData.labels = response.stats.userlogs.map((log: any) => dayjs(log.date).format('DD.MM.YYYY'));
            this.lineChartData.datasets[0].data = response.stats.userlogs.map((log: any) => log.count);
        });

        this.currentMonth = (new Date()).getMonth();

        this.dataService.request('universe.Admin/listAllLocations').subscribe(response => {
            this.locations = response.locations;

            this.mapComponent.addMarkerData(this.locations.map(location => {
                return {
                    latLng: new google.maps.LatLng(parseFloat(location.lat), parseFloat(location.lng)),
                    infoWindow:
                        '<b>Mandant:</b> ' + location.client + '<br/>' +
                        '<b>Unternehmen:</b> ' + location.company + '<br/>' +
                        '<b>Objekt:</b> ' + location.location
                };
            }));
        });
    }

    openClient(CLID: any): void {
        const data: UrlData = {
            ID: CLID,
        };
        this.router.navigate(['/admin/detail', this.cryptoService.encrypt(data)]);
    }

    createClient(): void {
        const data: UrlData = {
            ID: '0',
            viewMode: ViewMode.INSERT
        };
        this.router.navigate(['/admin/detail', this.cryptoService.encrypt(data)]);
    }
}
