<app-file-viewer
    *ngIf="files && viewDisabled === false"
    [files]="files"
    [viewReadonly]="viewReadonly"
    [viewFullscreen]="viewFullscreen"
    [viewThumbnails]="viewThumbnails"
    [viewThumbnailsSize]="viewThumbnailsSize"
    [viewThumbnailsTilesSize]="viewThumbnailsTilesSize"
    [viewThumbnailsTitle]="viewThumbnailsTitle"
    [viewShowFileInfo]="viewShowFileInfo"
    [viewExternalData]="viewExternalData"
    [viewEditModeFlipImage]="viewEditModeFlipImage"
    [viewNoFilesText]="viewNoFilesText"
    [viewLayout]="viewLayout"
    [viewCheckbox]="viewCheckbox"
    [showViewLayoutSwitcher]="showViewLayoutSwitcher"
    [onlyCreatorCanDelete]="onlyCreatorCanDelete"
    [isAdmin]="isAdmin"
    [enableLinks]="enableLinks"
    (deleted)="deletedEvent.emit()"
    (selectedChange)="selectedChange.emit($event)"
/>
<app-file-upload class="fw-file-upload"
    *ngIf="files && viewReadonly === false"
    [doctype]="doctype"
    [fkId]="fkId"
    [fkName]="fkName"
    [fkTable]="fkTable"
    [uploadAccept]="uploadAccept"
    [uploadSelectMultiple]="uploadSelectMultiple"
    [uploadMaxFileCount]="uploadMaxFileCount"
    [uploadMaxFilesize]="uploadMaxFilesize"
    [uploadMaxSingleFilesize]="uploadMaxSingleFilesize"
    (uploaded)="uploadedEvent.emit($event); loadFiles()"
    [showDragAndDrop]="showDragAndDrop"
    [uploadDisabled]="uploadDisabled"
/>
