import {Component, ElementRef, inject, Inject, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT, NgFor, NgIf, NgClass, AsyncPipe} from '@angular/common';

import {AdminBreadcrumbsService} from './admin-breadcrumbs.service';
import {RouterLink} from '@angular/router';

function Replace(el: any): any {
    const nativeElement: HTMLElement = el.nativeElement;
    const parentElement: HTMLElement | null = nativeElement.parentElement;

    // move all children out of the element
    while (nativeElement.firstChild) {
        parentElement?.insertBefore(nativeElement.firstChild, nativeElement);
    }

    // remove the empty element(the host)
    parentElement?.removeChild(nativeElement);
}

@Component({
    selector: 'app-admin-breadcrumbs',
    templateUrl: './admin-breadcrumbs.component.html',
    styleUrls: ['./admin-breadcrumbs.component.scss'],
    imports: [NgFor, NgIf, NgClass, RouterLink, AsyncPipe]
})
export class AdminBreadcrumbsComponent implements OnInit, OnDestroy {

    service = inject(AdminBreadcrumbsService);
    el = inject(ElementRef);
    private document = inject(DOCUMENT);
    private renderer = inject(Renderer2);

    @Input() fixed = false;

    breadcrumbs!: any;
    private readonly fixedClass = 'breadcrumb-fixed';

    ngOnInit(): void {
        Replace(this.el);
        this.isFixed(this.fixed);
        this.breadcrumbs = this.service.breadcrumbs;
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, this.fixedClass);
    }

    isFixed(fixed: boolean = this.fixed): void {
        if (fixed) {
            this.renderer.addClass(this.document.body, this.fixedClass);
        }
    }
}
