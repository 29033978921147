import {Component} from '@angular/core';
import {environment} from "../../environments/environment";
import {RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {AdminBreadcrumbsComponent} from "../global/components/admin-breadcrumbs/admin-breadcrumbs.component";

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    imports: [RouterLink, RouterLinkActive, RouterOutlet, AdminBreadcrumbsComponent]
})
export class AdminComponent {

    currentApplicationVersion = environment.appVersion;

}
