import {Component, OnInit, inject} from '@angular/core';
import {UserService} from '../../../global/services/user.service';
import {
    TableColumnFilterType,
    TableConfig,
    TableFilterOperator,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {ActivatedRoute} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {TableComponent} from '../../../global/components/table/table.component';

@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrl: './document-list.component.scss',
    imports: [FormsModule, TableComponent]
})
export class DocumentListComponent implements OnInit {

    userService = inject(UserService);
    private activatedRoute = inject(ActivatedRoute);

    tableConfig!: TableConfig;
    search: any;
    private filetype!: any;
    private company!: any;
    private location!: any;

    ngOnInit(): void {
        this.filetype = this.activatedRoute.snapshot.queryParamMap.get('filetype') || null;
        this.company = this.activatedRoute.snapshot.queryParamMap.get('company') || null;
        this.location = this.activatedRoute.snapshot.queryParamMap.get('location') || null;
        this.search = this.activatedRoute.snapshot.queryParamMap.get('search') || null;

        this.createConfig();
    }

    createConfig(): void {
        this.tableConfig = {
            titleIcon: 'fas fa-file-invoice',
            title: 'Dokumente',
            openDatasetRouterLink: '/app/document/detail',
            enableCreate: false,
            enableExportExcel: true,
            description: 'Das sind Ihre hochgeladenen Dokumente zur Bearbeitung durch Aufgaben und Termine.',
            dataConfig: {
                tableName: 'document',
                tableIndexName: 'DID',
                tableFilter: [{
                    table: 'document', field: 'deleted', values: ['0'], operator: TableFilterOperator.EQUAL
                }],
                tableJoins: [
                    {left: 'filetype', right: 'document', key: 'FTID'},
                    {left: 'location', right: 'document', key: 'LID'},
                    {left: 'company', right: 'document', key: 'CID'},
                ],
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {
                        key: 'tasks',
                        subquery: '(SELECT COUNT(*) FROM task WHERE DID = document.DID AND type = "singular")',
                        title: 'Einmalige Aufgaben',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'tasks2',
                        subquery: '(SELECT COUNT(*) FROM task WHERE DID = document.DID AND type = "recurring")',
                        title: 'Wiederkehrende Aufgaben',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_wait',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "wait" AND TID IN (SELECT TID FROM task WHERE DID = document.DID))',
                        title: 'Termine warten',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_open',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "open" AND TID IN (SELECT TID FROM task WHERE DID = document.DID))',
                        title: 'Termine offen',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_progress',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "in_progress" AND TID IN (SELECT TID FROM task WHERE DID = document.DID))',
                        title: 'Termine in Bearbeitung',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_done',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID IN (SELECT TID FROM task WHERE DID = document.DID))',
                        title: 'Termine erledigt',
                        sortable: true,
                        searchable: true
                    },
                    {key: 'active', name: 'active', title: 'Aktiv', sortable: true, searchable: true},
                    {
                        key: 'filetype',
                        table: 'filetype',
                        name: 'name',
                        title: 'Dokumenttyp',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'company',
                        table: 'company',
                        name: 'name',
                        title: 'Unternehmen',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'location',
                        table: 'location',
                        name: 'name',
                        title: 'LOCATION',
                        translateTitle: true,
                        translateTitlePrefix: 'LOCATION',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'uploaded',
                        name: 'uploaded',
                        title: 'Hochgeladen am',
                        sortable: true,
                        searchable: true,
                        dateFormat: {en: '%Y.%m.%d', others: '%d.%m.%Y'}
                    },
                    {key: 'tasks_created', name: 'tasks_created', title: '', visible: false},
                ],
                rowImage: [
                    {
                        key: 'name',
                        icon: 'fa-exclamation-triangle',
                        color: '#ffc107',
                        conditions: [
                            {key: 'tasks_created', value: '0', operator: TableRowColorConditionOperator.EQUAL},
                        ],
                        tooltip: 'Die Aufgabenerstellung wurde noch nicht abgeschlossen'
                    },
                    {
                        key: 'name',
                        icon: 'fa-exclamation-triangle',
                        color: '#ff9800',
                        conditions: [
                            {key: 'tasks', value: '0', operator: TableRowColorConditionOperator.EQUAL},
                            {key: 'tasks2', value: '0', operator: TableRowColorConditionOperator.EQUAL},
                            {key: 'active', value: '1', operator: TableRowColorConditionOperator.EQUAL},
                        ],
                        tooltip: 'Es wurden noch keine Aufgaben angelegt'
                    },
                    {
                        key: 'status_wait',
                        icon: 'fa-circle',
                        color: '#cccccc',
                        conditions: [
                            {key: 'status_wait', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_open',
                        icon: 'fa-circle',
                        color: '#f86c6b',
                        conditions: [
                            {key: 'status_open', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_progress',
                        icon: 'fa-circle',
                        color: '#ffc107',
                        conditions: [
                            {
                                key: 'status_progress',
                                value: '0',
                                operator: TableRowColorConditionOperator.SMALLER_INT
                            },
                        ]
                    },
                    {
                        key: 'status_done',
                        icon: 'fa-circle',
                        color: '#4dbd74',
                        conditions: [
                            {key: 'status_done', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'active',
                        icon: 'fa-exclamation-circle text-warning',
                        color: '#4dbd74',
                        conditions: [
                            {key: 'active', value: '0', operator: TableRowColorConditionOperator.EQUAL},
                        ]
                    },
                ],
                reverse: false,
                order: 'uploaded',
                columnFilter: {
                    enabled: true,
                    active: this.filetype != null || this.company != null || this.location != null,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                        active: {value: '', type: TableColumnFilterType.BOOLEAN},
                        filetype: {value: this.filetype ?? '', type: TableColumnFilterType.GROUP},
                        company: {value: this.company ?? '', type: TableColumnFilterType.GROUP},
                        location: {value: this.location ?? '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 2,
                name: 'document-list',
                enabled: false
            },
            paginConfig: {
                id: 'documentList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };

        if (!this.userService.isAdmin()) {
            if (this.userService.hasRight('DOCUMENT_ONLY_ASSIGNED_VIEW')) {
                this.tableConfig.dataConfig.tableFilter.push({
                    subquery: "CONCAT(',', (SELECT GROUP_CONCAT(DISTINCT department.UID SEPARATOR ',') FROM task LEFT JOIN department ON department.DPID = task.DPID WHERE task.DID = document.DID), ',')",
                    values: [',' + this.userService.currentUser.UID + ','],
                    operator: TableFilterOperator.LIKE
                });
            }
        }

        if (this.search != null && this.search !== '') {
            /*this.tableConfig.dataConfig.tableFields.splice(1, 0,
                {key: 'search_count_ocr_text', subquery: '0', title: 'Treffer'},
            )*/
            this.tableConfig.dataConfig.tableFilter.push({
                table: 'document', field: 'ocr_text', values: [this.search], operator: TableFilterOperator.LIKE
            });
        }
    }
}
