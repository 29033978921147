import {Component, EventEmitter, Input, OnInit, Output, ViewChild, inject} from '@angular/core';
import {DataService} from '../../../global/services/data.service';
import {ModalDirective, ModalModule} from 'ngx-bootstrap/modal';
import {NgFor, NgIf, DecimalPipe, DatePipe} from '@angular/common';
import {DigitizationPipe} from '../../../global/pipes/digitization.pipe';
import {YesNoPipe} from '../../../global/pipes/yes-no.pipe';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-admin-client-documents',
    templateUrl: './admin-client-documents.component.html',
    styleUrls: ['./admin-client-documents.component.scss'],
    imports: [NgFor, NgIf, ModalModule, DecimalPipe, DatePipe, DigitizationPipe, YesNoPipe, RouterLink]
})
export class AdminClientDocumentsComponent implements OnInit {

    private dataService = inject(DataService);

    @Input({required: true}) CLID!: string;
    @Output() documentsLoaded = new EventEmitter();

    documents: any[] = [];
    waiting = null;
    @ViewChild('lgModal', {static: false}) lgModal?: ModalDirective;
    modalText = '';

    ngOnInit(): void {
        this.loadDocuments();
    }

    openModal(document: any): void {
        this.modalText = document.ocr_text;
        this.lgModal?.show();
    }

    loadDocuments(): void {
        this.dataService.request('universe.Admin/listClientDocuments', {
            CLID: this.CLID
        }).subscribe(response => {
            this.documents = response.documents;
            this.documentsLoaded.emit(response.documents.length);
        });
    }

    getFile(FID: any): void {
        this.dataService.request('universe.Admin/getClientFile', {
            CLID: this.CLID,
            FID
        }).subscribe(response => {
            if (response.file) {
                const byteCharacters = atob(response.file.base64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const file = new Blob([byteArray], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
        });
    }

    updatePagesAndOCR(document: any): void {
        if (this.waiting != null) return;

        this.waiting = document.DID
        document._hovered = false;
        this.dataService.request('universe.Admin/updateClientPagesAndOCR', {
            CLID: this.CLID,
            FID: document.FID,
        }).subscribe(() => {
            this.waiting = null;
            this.loadDocuments();
        });
    }
}
