import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';
import {myAppConfig} from '../../settings';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrl: './page-not-found.component.scss',
    imports: [TranslateModule]
})
export class PageNotFoundComponent {

    private router = inject(Router);

    home(): void {
        this.router.navigate([myAppConfig.auth.afterLoginDestination]);
    }

}
