import {Component, OnInit, inject} from '@angular/core';
import {
    TableColumnFilterType,
    TableConfig,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {UserService} from '../../../global/services/user.service';
import {TableComponent} from '../../../global/components/table/table.component';

@Component({
    selector: 'app-role-list',
    templateUrl: './role-list.component.html',
    styleUrl: './role-list.component.scss',
    imports: [TableComponent]
})
export class RoleListComponent implements OnInit {

    userService = inject(UserService);

    tableConfig!: TableConfig;
    count: any;

    ngOnInit(): void {
        // get all rights for administrator role, granted rights for other roles
        const subQuery = "CONCAT_WS('/',(SELECT CASE WHEN (role.id) = 1 THEN (SELECT COUNT(*) FROM universe.`right`) else (SELECT COUNT(*) FROM right_role WHERE id_role = role.id) END), (SELECT COUNT(*) FROM universe.`right`))";

        this.tableConfig = {
            titleIcon: 'fas fa-user-lock',
            title: 'Benutzerrollen',
            openDatasetRouterLink: '/app/role/detail',
            enableCreate: this.userService.hasRight('ADMIN_ACCESS'),
            enableExportExcel: true,
            description: 'Alle im System hinterlegten Benutzerrollen.',
            dataConfig: {
                tableName: 'role',
                tableIndexName: 'id',
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {key: 'rights', subquery: subQuery, title: '# Rechte'},
                    {
                        key: 'users',
                        subquery: '(SELECT COUNT(*) FROM user WHERE id_role = role.id)',
                        title: '# Benutzer'
                    },
                ],
                rowImage: [
                    {
                        key: 'users',
                        icon: 'fa-exclamation-triangle',
                        color: '#ffc107',
                        conditions: [
                            {key: 'users', value: '0', operator: TableRowColorConditionOperator.EQUAL},
                        ],
                        tooltip: 'Benutzerrolle wurde keinem Benutzer zugewiesen'
                    },
                ],
                reverse: false,
                order: 'name',
                columnFilter: {
                    enabled: false,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'role-list',
                enabled: true
            },
            paginConfig: {
                id: 'roleList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }

}
