import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {DataService} from '../../../global/services/data.service';
import {FwFileMimeType, FwFileViewLayout, FileComponent} from '../../../global/components/file/file.component';
import {FormService} from '../../../global/services/form.service';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {UserService} from '../../../global/services/user.service';
import {validateDate} from '../../../global/validators/validateDate';
import {Subscription} from 'rxjs';
import {MessageService} from "../../../global/services/message.service";
import {NgFor, NgIf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FileTypePipe} from '../../../global/pipes/file-type.pipe';

@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrl: './document-upload.component.scss',
    imports: [FormsModule, ReactiveFormsModule, NgFor, NgIf, FileComponent, TranslateModule, FileTypePipe]
})
export class DocumentUploadComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    formService = inject(FormService);
    private router = inject(Router);
    private fb = inject(UntypedFormBuilder);
    private dataService = inject(DataService);
    private routerHelperService = inject(RouterHelperService);
    private messageService = inject(MessageService);

    form!: UntypedFormGroup;
    locations: any = [];
    fileTypes: any = [];
    companies: any = [];
    FileMimeType = FwFileMimeType;
    showingUpload = false;
    DID = null;
    FID = null;

    private fileTypeChanges$!: Subscription;
    private companyChanges$!: Subscription;
    FileViewLayout = FwFileViewLayout;

    messageData: any;

    ngOnInit(): void {
        // filled by location detail
        this.messageData = this.messageService.getItem('uploadDocument', true);

        this.form = this.fb.group({
            name: ['', Validators.required],
            FTID: ['', Validators.required],
            CID: ['', Validators.required],
            LID: [{value: '', disabled: true}, Validators.required],
            digitization: ['text', Validators.required],
            consulting: [{value: false, disabled: this.userService.currentUser.client_consulting === '0'}],
            attributes: this.fb.array([])
        });

        this.dataService.request('Company/listAll').subscribe(response => {
            this.companies = response.companies;

            // automatically set company if there is only one
            if (this.companies.length === 1) {
                this.form.patchValue({
                    CID: this.companies[0].CID,
                });
            }

            // if document upload was routed from location set CID and LID
            if (this.messageData) {
                this.form.patchValue({
                    CID: this.messageData.CID
                });

                this.form.get('LID').enable();
                this.getLocations(this.messageData.CID);
            }

        });

        this.dataService.request('FileType/listAll').subscribe(response => {
            this.fileTypes = response.filetypes;
        });

        this.fileTypeChanges$ = this.form.get('FTID').valueChanges.subscribe(val => {
            this.getAttributes(val);
        });

        this.companyChanges$ = this.form.get('CID').valueChanges.subscribe(val => {
            this.form.get('LID').enable();
            this.getLocations(val);
        });
    }

    ngOnDestroy(): void {
        this.fileTypeChanges$.unsubscribe();
        this.companyChanges$.unsubscribe();
    }

    addAttribute(data: any = {}): void {
        this.formService.getFormArray(this.form, 'attributes').push(
            this.fb.group({
                ATTID: [data.ATTID],
                name: [data.name],
                type: [data.type],
                required: [data.required],
                position: [data.position],
                value_int: [null],
                value_dec: [null],
                value_date: [null, validateDate],
                value_text: [null],
                value_boolean: [false]
            })
        );
    }

    getAttributes(FTID: string): void {
        this.formService.getFormArray(this.form, 'attributes').clear();
        this.dataService.request('AttributeTemplate/listByFileType', {FTID}).subscribe(response => {
            response.attributes.forEach((attribute: any) => {
                this.addAttribute(attribute);
            });

            this.formService.getFormArray(this.form, 'attributes').controls.forEach((control: UntypedFormGroup, index) => {
                if (control.value.required === '1') {
                    if (control.value.type === 'int') {
                        control.controls['value_int'].setValidators([Validators.required]);
                    }
                    if (control.value.type === 'dec') {
                        control.controls['value_dec'].setValidators([Validators.required]);
                    }
                    if (control.value.type === 'date') {
                        control.controls['value_date'].setValidators([Validators.required]);
                    }
                    if (control.value.type === 'text') {
                        control.controls['value_text'].setValidators([Validators.required]);
                    }
                    if (control.value.type === 'boolean') {
                        control.controls['value_boolean'].setValidators([Validators.required]);
                    }
                }
            });
        });
    }

    getLocations(CID: string): void {
        this.form.get('LID')?.patchValue('');
        this.dataService.request('Location/listByCompany', {CID}).subscribe(response => {
            this.locations = response.locations;

            // automatically set location if there is only one
            if (this.locations.length === 1) {
                this.form.patchValue({
                    LID: this.locations[0].LID,
                });
            }

            if (this.messageData) {
                this.form.patchValue({
                    LID: this.messageData.LID,
                });
                this.messageData = null;
            }
        });
    }

    cancel(): void {
        // delete document entry if it already exists
        if (this.DID) {
            this.dataService.request('Document/delete', {DID: this.DID}).subscribe(() => {
                this.router.navigate(['/app/document/list']);
            });
        } else {
            this.router.navigate(['/app/document/list']);
        }
    }

    showUpload(): void {
        this.save();
        this.showingUpload = true;
    }

    /**
     * Sets the file ID after the file has been uploaded, required for saving the file metadata later
     * @param event Contains the file data
     */
    setFID(event: any): void {
        this.FID = event.results[0].fid;
    }

    /**
     * Saves the document - this is required before uploading a file because the file requires a FK to the document
     * @param close Whether to call the close function
     */
    save(close = false): void {
        this.dataService.request('Document/save', this.form.getRawValue()).subscribe(response => {
            this.DID = response.insert.lastID;

            if (close) {
                this.close();
            }
        });
    }

    /**
     * Called when clicking the "Save" button
     */
    finish(): void {
        if (!this.DID) {
            this.save(true);
        } else {
            this.close();
        }
    }

    /**
     * Saves attributes and handle file, then redirect to detail view
     */
    close(): void {
        this.dataService.request('Attribute/save', {
            attributes: this.formService.getFormArray(this.form, 'attributes').getRawValue(),
            DID: this.DID,
            LID: null
        }).subscribe(() => {
            this.routerHelperService.openDocument(this.DID);
        });

        // if a file was uploaded handle it
        if (this.FID) {
            this.dataService.request('Document/handleFileAfterUpload', {
                FID: this.FID,
                DID: this.DID
            }).subscribe();
        }
    }

}
