import {Component, OnInit, inject} from '@angular/core';
import {UserService} from '../../services/user.service';
import {FwFileViewLayout, FileComponent} from '../file/file.component';
import {NgClass, NgIf} from '@angular/common';
import {RightPipe} from '../../pipes/right.pipe';

enum TabsEnum {
    PUBLIC,
    PRIVATE,
}

/**
 * The public file share has no fkId, the private file share has a fkId
 */

@Component({
    selector: 'app-file-share',
    templateUrl: './file-share.component.html',
    styleUrl: './file-share.component.scss',
    imports: [NgClass, NgIf, FileComponent, RightPipe]
})
export class FileShareComponent implements OnInit {

    userService = inject(UserService);

    fileViewLayout = FwFileViewLayout;
    TABS = TabsEnum;
    tab!: TabsEnum;

    ngOnInit(): void {
        this.setTab(TabsEnum.PUBLIC);
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;
    }
}
