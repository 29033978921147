import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './global/services/user.service';
import {NgcCookieConsentService} from 'ngx-cookieconsent';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {RouterOutlet} from '@angular/router';
import {NgIf} from '@angular/common';
import {LocalstorageInfoComponent} from "./global/components/localstorage-info/localstorage-info.component";
import {AuthInfoComponent} from "./global/components/auth-info/auth-info.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [RouterOutlet, NgIf, LocalstorageInfoComponent, AuthInfoComponent]
})
export class AppComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    private translateService = inject(TranslateService);
    private ccService = inject(NgcCookieConsentService);

    private popupOpen$!: Subscription;
    private userStatus$!: Subscription;
    public environment = environment;

    ngOnInit(): void {
        //let browserLanguage = this.translateService.getBrowserLang();
        let browserLanguage = 'de'; //browserLanguage?.match(/en|de/) ? browserLanguage : 'en';
        this.translateService.use(browserLanguage);

        this.userStatus$ = this.userService.tokenChanged.subscribe(() => {
            const clientLabels = this.userService.currentUser?.client_labels;

            if (clientLabels) {
                this.translateService.getTranslation('de').pipe(take(1)).subscribe(response => {
                    this.translateService.setTranslation('de', JSON.parse(clientLabels), true);
                });
            }
        });

        this.popupOpen$ = this.ccService.popupOpen$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });
    }

    ngOnDestroy(): void {
        this.popupOpen$.unsubscribe();
        this.userStatus$.unsubscribe();
    }

}
