<div [attr.data-right]="'TASK_CREATE' | right" *ngIf="!importing && (userService.hasRight('TASK_CREATE'))">
    <div *ngIf="tasksCreated == false">
        <div class="bg-warning text-dark p-2 mb-3 d-inline-block">
            <i class="fa-solid fa-triangle-exclamation mr-2"></i>Die Aufgabenerstellung ist noch nicht abgeschlossen, bitte fehlende Aufgaben anlegen.
        </div>
    </div>
    <div *ngIf="tasksCreated == true">
        <div class="bg-success p-2 mb-3 d-inline-block"><i class="fa-regular fa-circle-check mr-2"></i>Die Aufgabenerstellung wurde abgeschlossen.</div>
    </div>
    <div *ngIf="!documentActive">
        <div class="bg-warning text-dark p-2 mb-3 d-inline-block"><i class="fa-solid fa-circle-exclamation mr-2"></i>Das Dokument ist inaktiv, es können keine Aufgaben angelegt werden.</div>
    </div>
    <button type="button" class="btn btn-primary mb-3 mr-2" (click)="createTask()" *ngIf="documentActive && !tasksCreated">Aufgabe anlegen</button>
    <button type="button" class="btn btn-primary mb-3 mr-2" (click)="importTasks()" *ngIf="documentActive && !tasksCreated">Aufgabe(n) aus Excel importieren</button>
    <button type="button" class="btn btn-primary mb-3" (click)="completeTasksCreation()" *ngIf="tasksCreated == false">Aufgabenerstellung abschließen</button>
</div>

<div class="mb-3" *ngIf="importing">
    <input type="file" (change)="handleInputChange($event)">
    <button type="button" class="btn btn-primary mr-2" (click)="uploadTasks()" [disabled]="!excelData || uploading">Importieren</button>
    <button type="button" class="btn btn-secondary" (click)="cancelImportTasks()" [disabled]="uploading">Abbrechen</button><a href="api/data/vorlage_aufgaben.xlsx" class="ml-3">Download Vorlage</a>
</div>

<div class="form-group form-check">
    <input type="checkbox" class="form-check-input" [(ngModel)]="hideCompleted" (ngModelChange)="setupTable()">
    <label class="form-check-label">Einmalige erledigte Aufgaben ausblenden</label>
</div>
<app-table #tableComponent [config]="tableConfig"/>
