interface SettingsAuth {
    // Route to the login view
    loginDestination: string;
    // Route to redirect after login
    afterLoginDestination: string;
    // Route to redirect after logout
    afterLogoutDestination: string;
    // URLs that should not be handled by the TokenInterceptor (eg. external links)
    interceptorWhitelist: string[];
}

interface FileField {
    name: string;
    key: string;
    suffix?: string;
    editable?: boolean;
    required?: boolean;
}

export interface MyAppConfig {
    appID: string;
    // Route to navigate to when urlData decryption fails
    urlDataFallbackLink: string;
    cryptoKey: string;
    auth: SettingsAuth;
    fileFields?: FileField[];
}

export enum ViewMode {
    VIEW,
    EDIT,
    INSERT,
    DELETE
}

export interface UrlData {
    ID: string;
    viewMode?: ViewMode;
    formName?: string;
    // any additional data that may be required
    data?: any;
    hash?: string;
}


