<div class="card mb-3">
    <div class="card-header">
        <i class="fas fa-user-friends mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.INSERT">Benutzer anlegen</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Benutzer bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Benutzer</ng-container>
    </div>
    <div class="card-body">
        <form [formGroup]="form" class="form">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Vorname</label>
                        <input type="text" class="form-control" formControlName="firstname" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.firstname }}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Nachname</label>
                        <input type="text" class="form-control" formControlName="lastname" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.lastname }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" formControlName="email" *ngIf="viewMode != ViewMode.VIEW">
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.email }}</p>
                    </div>
                    <div class="form-group" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">
                        <label>Aktiv</label>
                        <p>{{ form.value.active | yesNo }}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Rolle</label>
                        <ng-container *ngIf="viewMode != ViewMode.VIEW">
                            <select class="form-control" formControlName="id_role">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let role of roles" value="{{role.id}}">{{ role.name }}</option>
                            </select>
                        </ng-container>
                        <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.role }}</p>
                        <small class="form-text text-muted" *ngIf="roleText">{{ roleText}}</small>
                    </div>
                    <div class="form-group" *ngIf="viewMode != ViewMode.INSERT">
                        <label>Abteilung</label>
                        <p *ngIf="user.department"><a [routerLink]="[]" (click)="routerHelperService.openDepartment(user.DPID)">{{ user.department }}</a></p>
                        <div class="text-muted" *ngIf="!user.department">Nicht gesetzt</div>
                        <small class="form-text text-danger" *ngIf="!user.department && viewMode != ViewMode.VIEW">
                            Die Zuordnung erfolgt bei der Abteilung.
                        </small>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="viewMode != ViewMode.VIEW">
                <div class="col-6">
                    <div class="form-group">
                        <label>Passwort</label>
                        <input type="password" class="form-control" id="password" formControlName="password">
                        <small class="form-text text-danger" *ngIf="form.controls.password.errors?.minlength">
                            Das Passwort muss eine Mindestlänge von 6 Zeichen haben.
                        </small>
                    </div>
                    <div class="form-group">
                        <label>Passwort bestätigen</label>
                        <input type="password" class="form-control" formControlName="confirmPassword">
                        <small class="form-text text-danger" *ngIf="form.controls.confirmPassword.errors?.nomatch">
                            Das Passwort stimmt nicht überein.
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-2" *ngIf="viewMode == ViewMode.INSERT">
                <div class="col">
                    <div class="form-group">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" formControlName="sendMail"> Dem Benutzer die Zugangsdaten per Mail schicken
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <ng-container *ngIf="viewMode == ViewMode.VIEW">
            <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">Bearbeiten</button>
            <button type="button" class="btn btn-secondary" (click)="cancel()">Zurück</button>
            <button type="button" class="btn btn-danger mr-2 float-right" (click)="showDisable()" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="form.value.active == true && userService.hasRight('MASTER_DATA_EDIT') && !showDisableCard">Deaktivieren</button>
            <button type="button" class="btn btn-danger mr-2 float-right" (click)="enable()" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="form.value.active == false && userService.hasRight('MASTER_DATA_EDIT')">Aktivieren</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.INSERT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
        </ng-container>

    </div>
</div>

<ng-container *ngIf="viewMode != ViewMode.INSERT && showDisableCard">
    <div class="card mb-3">
        <div class="card-header bg-danger text-white">
            <i class="fas fa-exclamation-triangle"></i>Benutzer deaktivieren
        </div>
        <div class="card-body">
            <div>
                <ng-container *ngIf="!user.department"><i class="fas fa-check-circle text-success"></i> Keiner Abteilung zugewiesen</ng-container>
                <ng-container *ngIf="user.department"><i class="fas fa-times-circle text-danger"></i> Einer Abteilung mit {{ user.tasks_responsible }} Aufgabe(n) zugewiesen</ng-container>
            </div>
            <div>
                <ng-container *ngIf="user.tasks_external == 0"><i class="fas fa-check-circle text-success"></i> Nicht als externer Bearbeiter zugewiesen</ng-container>
                <ng-container *ngIf="user.tasks_external > 0"><i class="fas fa-times-circle text-danger"></i> Als externer Bearbeiter für {{ user.tasks_external }} Aufgabe(n) zugewiesen</ng-container>
            </div>
            <div>
                <ng-container *ngIf="fileShareCount == 0"><i class="fas fa-check-circle text-success"></i> Keine FileShare Dateien</ng-container>
                <ng-container *ngIf="fileShareCount > 0"><i class="fas fa-times-circle text-danger"></i> {{fileShareCount}} FileShare Dateien</ng-container>
            </div>
            <!--<div style="margin: 5px 0 15px 0">{{ user.comments }} Kommentare werden anonymisiert.</div>-->

            <label class="mt-3">Zugewiesene Aufgaben ({{ user.totalTasks }})</label>
            <app-table [config]="tableConfig"/>

            <label class="mt-3">Fileshare Dateien ({{fileShareCount}})</label>
            <app-file
                [doctype]="'fileshare'"
                [fkName]="'UID'"
                [fkTable]="'user'"
                [fkId]="UID"
                [viewThumbnails]="true"
                [viewThumbnailsSize]="'50px'"
                [viewThumbnailsTilesSize]="'100px'"
                [uploadSelectMultiple]="true"
                [uploadMaxFilesize]="300"
                [viewEditModeFlipImage]="true"
                [viewLayout]="FileViewLayout.TILES"
                [viewNoFilesText]="'Es wurden keine Dateien hochgeladen.'"
                [showViewLayoutSwitcher]="true"
                [onlyCreatorCanDelete]="true"
                [attr.data-right]="'MASTER_DATA_EDIT' | right"
                [isAdmin]="userService.hasRight('MASTER_DATA_EDIT')"
                [showDragAndDrop]="true"
                [enableLinks]="false"
                [viewReadonly]="true"
                (reloadEvent)="fileShareCount = $event.count"
            />

            <ng-container *ngIf="!user.department && user.totalTasks == 0">
                <button type="button" class="btn btn-secondary mr-2" (click)="cancelDisable()">Abbrechen</button>
                <button type="button" class="btn btn-danger" (click)="disable()">Jetzt deaktivieren</button>
            </ng-container>

            <div class="text-danger" *ngIf="user.department || user.totalTasks > 0">Bitte alle Zuweisungen entfernen um den Benutzer zu deaktivieren!</div>
        </div>
    </div>
</ng-container>

<!--<app-task-user [attr.data-right]="'TASK_USER_SHOW' | right" *ngIf="viewMode != ViewMode.INSERT && userService.hasRight('TASK_USER_SHOW')" [UID]="UID"/>-->
