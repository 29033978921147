import {Component, HostListener, Input, OnDestroy, OnInit, inject} from '@angular/core';
import {jwtDecode} from 'jwt-decode';
import dayjs from 'dayjs';
import {UserService} from '../../services/user.service';
import {JwtService, FwTokenType} from '../../services/jwt.service';
import {Subscription} from 'rxjs';
import {NgIf, JsonPipe} from '@angular/common';
import {myAppConfig} from "../../../settings";

@Component({
    selector: 'app-auth-info',
    templateUrl: './auth-info.component.html',
    styleUrl: './auth-info.component.scss',
    imports: [NgIf, JsonPipe]
})
export class AuthInfoComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    private jwtService = inject(JwtService);

    @Input() key = 'i';

    protected readonly myAppConfig = myAppConfig;

    jwtData: any;
    visible = false;
    private userServiceStatus$!: Subscription;

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent): void {
        event.preventDefault();
        if (event.ctrlKey && event.key === this.key) {
            this.visible = !this.visible;
        }
    }

    ngOnInit(): void {
        this.userServiceStatus$ = this.userService.tokenChanged.subscribe(() => {
            this.jwtData = jwtDecode(this.jwtService.getToken(FwTokenType.ACCESS_TOKEN));
            this.jwtData.expReadable = dayjs.unix(this.jwtData.exp).format('DD.MM.YYYY HH:mm');
        });
    }

    ngOnDestroy(): void {
        this.userServiceStatus$.unsubscribe();
    }
}
