import {Component, OnInit, ViewChild, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {UserService} from '../../../global/services/user.service';
import {Router, RouterLink} from '@angular/router';
import {CryptoService} from '../../../global/services/crypto.service';
import Swal from 'sweetalert2';
import {FwFileViewLayout, FileComponent} from '../../../global/components/file/file.component';
import {
    TableColumnFilterType,
    TableConfig,
    TableDesign,
    TableFilterOperator,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {MapService} from '../../../global/components/map/map.service';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {TagConfig} from '../../../global/components/tag/tag.interfaces';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {ViewMode} from '../../../interfaces';
import {MapComponent} from '../../../global/components/map/map.component';
import dayjs from 'dayjs'
import {MessageService} from "../../../global/services/message.service";
import {BreadcrumbService} from "../../../global/components/breadcrumb/breadcrumb.service";
import {NgIf, NgClass, NgFor} from '@angular/common';
import {TagComponent} from '../../../global/components/tag/tag.component';
import {TableComponent} from '../../../global/components/table/table.component';
import {AttributesComponent} from '../../../global/components/attributes/attributes.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {ToastrService} from "ngx-toastr";

enum TabsEnum {
    DETAILS = 'details',
    ATTRIBUTES = 'attributes',
    ATTACHMENTS = 'attachments',
    MAP = 'map',
    DOCUMENTS = 'documents',
}

@Component({
    selector: 'app-location-detail',
    templateUrl: './location-detail.component.html',
    styleUrl: './location-detail.component.scss',
    imports: [NgIf, NgClass, FormsModule, ReactiveFormsModule, NgFor, TagComponent, TableComponent, AttributesComponent, FileComponent, MapComponent, ModalModule, TranslateModule, RightPipe, RouterLink]
})
export class LocationDetailComponent implements OnInit {

    userService = inject(UserService);
    routerHelperService = inject(RouterHelperService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);
    private mapService = inject(MapService);
    private translateService = inject(TranslateService);
    private messageService = inject(MessageService);
    private breadcrumbService = inject(BreadcrumbService);
    private toastrService = inject(ToastrService);

    @ViewChild('mapComponent') mapComponent!: MapComponent;
    @ViewChild('checkListModal') checkListModal: any;
    @ViewChild('overviewRecurringModal') overviewRecurringModal: any;

    readonly ViewMode = ViewMode;
    readonly TABS = TabsEnum;

    form!: UntypedFormGroup;
    location: any = {};
    LID: any;
    CID: any;
    viewMode!: ViewMode;
    tab!: TabsEnum;
    companies!: any[];
    fileViewLayout = FwFileViewLayout;
    tableConfig!: TableConfig;
    coordinates: google.maps.LatLng[] = [];
    tagConfig!: TagConfig;
    tabCount: any = {};
    recurringDocuments!: any[];
    checkListYear = dayjs().year() + 3;
    checkListYearMin = dayjs().year();
    checkListYearMax = dayjs().year() + 5;
    waitingForPDF = false;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.LID = urlData.ID;
        this.CID = urlData.data?.CID ?? null;
        this.viewMode = urlData.viewMode;

        this.setTab(TabsEnum.DETAILS);

        this.tagConfig = {
            FK_ID: this.LID,
            FK_name: 'location',
            FK_table: 'location',
            editable: false,
        };

        this.form = this.fb.group({
            LID: [''],
            CID: [this.CID ?? '', Validators.required],
            company: [''],
            name: ['', Validators.required],
            country: ['', Validators.required],
            zip: ['', Validators.required],
            city: ['', Validators.required],
            street: ['', Validators.required],
        });

        this.dataService.request('Company/listAll').subscribe(response => {
            this.companies = response.companies;
        });

        if (this.LID !== '0') {
            this.getLocation();
            this.setupDocumentsTable();
        }

        if (this.CID) {
            this.dataService.request('Company/get', {
                CID: this.CID
            }).subscribe(response => {
                this.breadcrumbService.setManually([
                    {
                        label: {title: 'Unternehmen ' + response.company.name},
                        url: '/app/company/detail/' + this.cryptoService.encrypt({ID: this.CID})
                    },
                    {label: {title: this.translateService.instant('LOCATION.LOCATION') + ' anlegen'}},
                ]);
            });
        } else {
            this.breadcrumbService.setManually([
                {label: {title: this.translateService.instant('LOCATION.LOCATIONS')}, url: '/app/location/list'},
                {label: {title: this.translateService.instant('LOCATION.LOCATION') + ' anlegen'}},
            ]);
        }
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;

        if (this.tab === TabsEnum.MAP) {
            setTimeout(() => {
                this.setupMap();
            });
        }
    }

    getLocation(): void {
        this.dataService.request('Location/get', {
            LID: this.LID
        }).subscribe(response => {
            this.location = response.location;
            this.tabCount = response.tabCount;

            this.breadcrumbService.setManually([
                {
                    label: {title: 'Unternehmen ' + this.location.company},
                    url: '/app/company/detail/' + this.cryptoService.encrypt({ID: this.location.CID})
                },
                {label: {title: this.translateService.instant('LOCATION.LOCATION') + ' ' + this.location.name}},
            ]);

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            LID: this.location.LID,
            CID: this.location.CID,
            company: this.location.company,
            name: this.location.name,
            country: this.location.country,
            zip: this.location.zip,
            city: this.location.city,
            street: this.location.street
        });
    }

    save(): void {
        this.dataService.request('Location/save', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Standort wurde gespeichert');
            this.router.navigate(['/app/location/list']);
        });
    }

    update(): void {
        this.dataService.request('Location/update', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Standort wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
            this.getLocation();
        });
    }

    cancel(): void {
        if (this.CID) {
            this.router.navigate(['/app/company/detail/' + this.cryptoService.encrypt({ID: this.CID})]);
        } else {
            this.router.navigate(['/app/location/list']);
        }
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
        this.tagConfig.editable = this.viewMode === ViewMode.EDIT;
        this.tagConfig = Object.assign({}, this.tagConfig);
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Die ' + this.translateService.instant('LOCATION.LOCATION') + ' wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('Location/delete', {LID: this.LID}).subscribe(() => {
                    this.toastrService.success('Standort wurde gelöscht');
                    this.cancel();
                });
            }
        });
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    setupDocumentsTable(): void {
        this.tableConfig = {
            design: TableDesign.NONE,
            title: '',
            openDatasetRouterLink: '/app/document/detail',
            enableCreate: false,
            dataConfig: {
                tableName: 'document',
                tableIndexName: 'DID',
                tableFilter: [
                    {
                        table: 'document', field: 'deleted', values: ['0'], operator: TableFilterOperator.EQUAL
                    }, {
                        table: 'document', field: 'LID', values: [this.LID], operator: TableFilterOperator.EQUAL
                    }
                ],
                tableJoins: [
                    {left: 'filetype', right: 'document', key: 'FTID'},
                ],
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {
                        key: 'uploaded',
                        name: 'uploaded',
                        title: 'Hochgeladen am',
                        sortable: true,
                        searchable: true,
                        dateFormat: {en: '%Y.%m.%d', others: '%d.%m.%Y'}
                    },
                    {key: 'active', name: 'active', title: 'Aktiv', sortable: true, searchable: true},
                    {
                        key: 'filetype',
                        table: 'filetype',
                        name: 'name',
                        title: 'Dokumenttyp',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'tasks',
                        subquery: '(SELECT COUNT(*) FROM task WHERE DID = document.DID AND type = "singular")',
                        title: 'Einmalige Aufgaben',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'tasks2',
                        subquery: '(SELECT COUNT(*) FROM task WHERE DID = document.DID AND type = "recurring")',
                        title: 'Wiederkehrende Aufgaben',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_wait',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "wait" AND TID IN (SELECT TID FROM task WHERE DID = document.DID))',
                        title: 'Termine warten',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_open',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "open" AND TID IN (SELECT TID FROM task WHERE DID = document.DID))',
                        title: 'Termine offen',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_progress',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "in_progress" AND TID IN (SELECT TID FROM task WHERE DID = document.DID))',
                        title: 'Termine in Bearbeitung',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_done',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID IN (SELECT TID FROM task WHERE DID = document.DID))',
                        title: 'Termine erledigt',
                        sortable: true,
                        searchable: true
                    },
                ],
                rowImage: [
                    {
                        key: 'status_wait',
                        icon: 'fa-circle',
                        color: '#cccccc',
                        conditions: [
                            {key: 'status_wait', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_open',
                        icon: 'fa-circle',
                        color: '#f86c6b',
                        conditions: [
                            {key: 'status_open', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_progress',
                        icon: 'fa-circle',
                        color: '#ffc107',
                        conditions: [
                            {
                                key: 'status_progress',
                                value: '0',
                                operator: TableRowColorConditionOperator.SMALLER_INT
                            },
                        ]
                    },
                    {
                        key: 'status_done',
                        icon: 'fa-circle',
                        color: '#4dbd74',
                        conditions: [
                            {key: 'status_done', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                ],
                reverse: false,
                order: 'uploaded',
                columnFilter: {
                    enabled: true,
                    active: status != null,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                        active: {value: '', type: TableColumnFilterType.BOOLEAN},
                        filetype: {value: '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 2,
                name: 'location-document-list',
                enabled: true
            },
            paginConfig: {
                id: 'locationDocumentList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };

        if (!this.userService.isAdmin()) {
            if (this.userService.hasRight('DOCUMENT_ONLY_ASSIGNED_VIEW')) {
                this.tableConfig.dataConfig?.tableFilter?.push({
                    subquery: "CONCAT(',', (SELECT GROUP_CONCAT(DISTINCT department.UID SEPARATOR ',') FROM task LEFT JOIN department ON department.DPID = task.DPID WHERE task.DID = document.DID), ',')",
                    values: [',' + this.userService.currentUser.UID + ','],
                    operator: TableFilterOperator.LIKE
                });
            }
        }
    }

    private setupMap(): void {
        if (this.location.lat) {
            this.mapComponent.addMarkerData([
                {
                    latLng: new google.maps.LatLng(parseFloat(this.location.lat), parseFloat(this.location.lng))
                }
            ]);
        } else {
            this.location.notFound = true;
            /*this.mapService.codeAddress(this.location.zip + this.location.city + ', ' + this.location.street).subscribe(response => {
                this.mapService.addMarker.next({
                    latLng: response[0].geometry.location
                });
            });*/
        }
    }

    copyAddress(): void {
        const selectedCompany = this.companies.find(company => company.CID === this.form.value.CID);

        this.form.patchValue({
            country: selectedCompany.country,
            zip: selectedCompany.zip,
            city: selectedCompany.city,
            street: selectedCompany.street
        });
    }

    getTabCount(): void {
        this.dataService.request('Location/getTabCount', {LID: this.LID}).subscribe(response => {
            this.tabCount = response.tabCount;
        });
    }

    createChecklist(): void {
        this.waitingForPDF = true;
        this.dataService.request('Export/checklist', {
            LID: this.LID,
            checkListYear: this.checkListYear,
            documents: this.recurringDocuments
        }).subscribe(response => {
            this.waitingForPDF = false;
            this.checkListModal.hide();

            // thx to https://stackoverflow.com/a/52091804/757218
            const byteCharacters = atob(response.pdfData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    createOverviewRecurring(): void {
        this.waitingForPDF = true;
        this.dataService.request('Export/overviewRecurring', {
            LID: this.LID,
            documents: this.recurringDocuments
        }).subscribe(response => {
            this.waitingForPDF = false;
            this.overviewRecurringModal.hide();

            // thx to https://stackoverflow.com/a/52091804/757218
            const byteCharacters = atob(response.pdfData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new Blob([byteArray], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    getCheckListDocuments(): void {
        this.dataService.request('Document/listWithRecurringTasks', {
            LID: this.LID
        }).subscribe(response => {
            this.recurringDocuments = response.documents;
            this.recurringDocuments.forEach(document => document.selected = false);
        });
    }

    documentsSelected(): boolean {
        return this.recurringDocuments.some(document => document.selected);
    }

    uploadDocument(): void {
        this.messageService.setItem('uploadDocument', {
            LID: this.location.LID,
            CID: this.location.CID,
        });
        this.router.navigate(['/app/document/upload']);
    }

    selectAll() {
        this.recurringDocuments.forEach(document => document.selected = true);
    }

    deselectAll() {
        this.recurringDocuments.forEach(document => document.selected = false);
    }
}
