import {Component, OnInit, inject} from '@angular/core';
import {DataService} from '../../../global/services/data.service';
import {UserService} from '../../../global/services/user.service';
import {Router} from '@angular/router';
import {CryptoService} from '../../../global/services/crypto.service';
import dayjs from 'dayjs';
import {FormService} from '../../../global/services/form.service';
import {ViewMode, UrlData} from '../../../interfaces';
import {FormsModule} from '@angular/forms';
import {NgIf, NgFor, NgClass, DatePipe} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TaskStatusPipe} from '../../../global/pipes/task-status.pipe';
import {TaskTypePipe} from '../../../global/pipes/task-type.pipe';

@Component({
    selector: 'app-task-date-list',
    templateUrl: './task-date-list.component.html',
    styleUrl: './task-date-list.component.scss',
    imports: [FormsModule, NgIf, NgFor, NgClass, TooltipModule, DatePipe, TaskStatusPipe, TaskTypePipe]
})
export class TaskDateListComponent implements OnInit {

    formService = inject(FormService);
    userService = inject(UserService);
    private dataService = inject(DataService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);

    taskDates: any[] = [];
    task: any;
    taskDateFilter: any;

    inProgress = 0;
    open = 0;
    wait = 0;
    generated = 0;

    ngOnInit(): void {
        this.taskDateFilter = {
            number: 3,
            interval: 'month'
        };

        this.listTaskDates();
    }

    listTaskDates(): void {
        this.dataService.request('TaskDate/listByUser', {
            start: dayjs().format('YYYY-MM-DD'),
            end: dayjs().add(this.taskDateFilter.number, this.taskDateFilter.interval).format('YYYY-MM-DD')
        }).subscribe(response => {
            this.taskDates = response.taskDates;

            this.inProgress = this.taskDates.filter(taskDate => taskDate.status === 'in_progress').length;
            this.open = this.taskDates.filter(taskDate => taskDate.status === 'open').length;
            this.wait = this.taskDates.filter(taskDate => taskDate.status === 'wait').length;
            this.generated = this.taskDates.filter(taskDate => taskDate.status === 'generated').length;
        });
    }

    openTaskDate(taskDate: any): void {
        const urlData: UrlData = {
            ID: taskDate.TDID,
            data: {
                TID: taskDate.TID
            },
            viewMode: taskDate.TDID === '0' ? ViewMode.INSERT : ViewMode.VIEW,
        };

        // pass start_date and end_date of generated taskdates
        if (taskDate.TDID === '0') {
            // there is no start_date for singular tasks
            urlData.data.start_date = taskDate.start_date ? dayjs(taskDate.start_date).format('YYYY-MM-DD') : null;
            urlData.data.end_date = dayjs(taskDate.end_date).format('YYYY-MM-DD');
        }
        this.router.navigate(['/app/taskdate/detail/', this.cryptoService.encrypt(urlData)]);
    }
}
