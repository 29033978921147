import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Component, OnDestroy, OnInit, inject} from '@angular/core';
import {NgIf, NgClass, NgFor, DatePipe} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {Router, RouterLink} from '@angular/router';
import {Subscription} from 'rxjs';

import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TranslateService, TranslateModule} from "@ngx-translate/core";
import {QuillEditorComponent, QuillModules} from "ngx-quill";
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';

import {ViewMode} from '../../../interfaces';
import {DataService} from '../../../global/services/data.service';
import {CryptoService} from '../../../global/services/crypto.service';
import {UserService} from '../../../global/services/user.service';
import {TagConfig} from '../../../global/components/tag/tag.interfaces';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {BreadcrumbService} from "../../../global/components/breadcrumb/breadcrumb.service";
import {FwFileMimeType, FwFileViewLayout, FileComponent} from "../../../global/components/file/file.component";
import {environment} from "../../../../environments/environment";
import {TagComponent} from '../../../global/components/tag/tag.component';
import {AttributesComponent} from '../../../global/components/attributes/attributes.component';
import {DocumentTasksComponent} from '../document-tasks/document-tasks.component';
import {TextareaPipe} from '../../../global/pipes/textarea.pipe';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {YesNoPipe} from '../../../global/pipes/yes-no.pipe';

enum TabsEnum {
    DETAILS = 'details',
    ATTRIBUTES = 'attributes',
    PDF = 'pdf',
    OCR = 'ocr',
    TASKS = 'tasks',
    ATTACHMENTS = 'attachments',
}

@Component({
    selector: 'app-document-detail',
    templateUrl: './document-detail.component.html',
    styleUrl: './document-detail.component.scss',
    imports: [NgIf, NgClass, FormsModule, ReactiveFormsModule, NgFor, TooltipModule, RouterLink, FileComponent, TagComponent, AttributesComponent, DocumentTasksComponent, DatePipe, TranslateModule, TextareaPipe, RightPipe, YesNoPipe, QuillEditorComponent]
})
export class DocumentDetailComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    routerHelperService = inject(RouterHelperService);
    private dataService = inject(DataService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);
    private sanitizer = inject(DomSanitizer);
    private fb = inject(UntypedFormBuilder);
    private toastrService = inject(ToastrService);
    private translateService = inject(TranslateService);
    private breadcrumbService = inject(BreadcrumbService);

    readonly ViewMode = ViewMode;
    readonly TABS = TabsEnum;

    environment = environment;

    form!: UntypedFormGroup;

    DID!: any;
    document: any;
    file: any;
    viewMode!: ViewMode;
    tab!: TabsEnum;
    embedString!: any;
    fileViewLayout = FwFileViewLayout;
    locations!: any[];
    fileTypes!: any[]
    companies!: any[];
    private companyChanges$!: Subscription;
    FileMimeType = FwFileMimeType;
    tabCount: any = {};
    tagConfig!: TagConfig;
    checkOCRStatusInterval: any;
    groups: any[] = [];

    quillModules: QuillModules;

    ngOnInit(): void {
        let tab;

        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.viewMode = urlData.viewMode;
        this.DID = urlData.ID;

        if (urlData.hash) {
            tab = urlData.hash as TabsEnum;
        } else {
            tab = TabsEnum.DETAILS;
        }
        this.setTab(tab);

        this.tagConfig = {
            FK_ID: this.DID,
            FK_name: 'document',
            FK_table: 'document',
            editable: false,
        };

        this.form = this.fb.group({
            DID: [null],
            name: [null],
            FTID: ['', Validators.required],
            LID: ['', Validators.required],
            CID: ['', Validators.required],
            fileType: [''],
            location: [''],
            company: [''],
            create_date: [''],
            user: [''],
            pages: [''],
            active: [''],
            tasks_created: [false],
            digitization: [''],
            consulting: [false],
            consulting_complete: [false],
            comment: [null],
            groups: [],
            groupIDs: [],
        });

        this.dataService.request('Company/listAll').subscribe(response => {
            this.companies = response.companies;
        });

        this.dataService.request('FileType/listAll').subscribe(response => {
            this.fileTypes = response.filetypes;
        });

        this.dataService.request('VisibilityGroup/listAll').subscribe(response => {
            this.groups = response.groups;
        });

        this.companyChanges$ = this.form.get('CID').valueChanges.subscribe(val => {
            this.getLocations(val);
        });

        this.quillModules = {
            toolbar: [
                ['bold', 'italic', 'underline'],        // Text styles
                [{ list: 'ordered' }, { list: 'bullet' }], // Lists
                [{ script: 'sub' }, { script: 'super' }], // Sub/Superscript
                [{ indent: '-1' }, { indent: '+1' }],   // Indentation
                [{ direction: 'rtl' }],                 // Text direction
                [{ size: ['small', false, 'large', 'huge'] }], // Font size
                [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
                [{ color: [] }, { background: [] }],    // Colors
                [{ font: [] }],                         // Font type
                [{ align: [] }],                        // Text alignment
                ['link'],                               // Keep link but remove image and video
                ['clean']                               // Clear formatting
            ]
        };

        this.load();
    }

    ngOnDestroy(): void {
        this.companyChanges$.unsubscribe();

        if (this.checkOCRStatusInterval) clearInterval(this.checkOCRStatusInterval);
    }

    getLocations(CID: string): void {
        this.dataService.request('Location/listByCompany', {CID}).subscribe(response => {
            this.locations = response.locations;
        });
    }

    load(): void {
        this.dataService.request('Document/get', {DID: this.DID}).subscribe(response => {
            this.document = response.document;
            this.file = response.file;
            this.tabCount = response.tabCount;

            this.breadcrumbService.setManually([
                {
                    label: {title: 'Unternehmen ' + this.document.company},
                    url: '/app/company/detail/' + this.cryptoService.encrypt({ID: this.document.CID})
                },
                {
                    label: {title: this.translateService.instant('LOCATION.LOCATION') + ' ' + this.document.location},
                    url: '/app/location/detail/' + this.cryptoService.encrypt({ID: this.document.LID})
                },
                {label: {title: 'Dokument ' + this.document.name}},
            ]);

            this.patchForm();

            if (this.file) {
                const byteCharacters = atob(this.file.base64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const file = new Blob([byteArray], {type: 'application/pdf'});
                this.embedString = URL.createObjectURL(file);
                this.embedString = this.sanitizer.bypassSecurityTrustResourceUrl(this.embedString);
            }
        });
    }

    getFile(): void {
        this.dataService.request('Document/getFile', {DID: this.DID}).subscribe(response => {
            this.file = response.file;
            this.redoOcr(false);
        });
    }

    patchForm(): void {
        this.form.patchValue({
            DID: this.document.DID,
            name: this.document.name,
            FTID: this.document.FTID,
            LID: this.document.LID,
            CID: this.document.CID,
            fileType: this.document.fileType,
            location: this.document.location,
            company: this.document.company,
            create_date: this.document.uploaded,
            user: this.document.user,
            pages: this.document.pages,
            active: this.document.active === '1',
            tasks_created: this.document.tasks_created === '1',
            digitization: this.document.digitization,
            consulting: this.document.consulting === '1',
            consulting_complete: this.document.consulting_complete === '1',
            comment: this.document.comment,
            groupIDs: this.document.groups.map((group: any) => group.VISID),
            groups: this.document.groups,
        });

        this.getLocations(this.document.CID);
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;
        window.location.hash = tab;

        if (tab == TabsEnum.OCR) {
            this.setupOCRStatusInterval();
        } else {
            if (this.checkOCRStatusInterval) clearInterval(this.checkOCRStatusInterval);
        }
    }

    update(): void {
        this.dataService.request('Document/update', this.form.getRawValue()).subscribe(() => {
            this.toastrService.success('Dokument wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
            this.load();
        });
    }

    cancel(): void {
        this.router.navigate(['/app/document/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
        this.tagConfig.editable = this.viewMode === ViewMode.EDIT;
        this.tagConfig = Object.assign({}, this.tagConfig);
    }

    delete(DID: any): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Das Dokument wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('Document/delete', {DID}).subscribe(() => {
                    this.toastrService.success('Dokument wurde gelöscht');
                    this.cancel();
                });
            }
        });
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    updateOcrText(): void {
        this.dataService.request('Document/updateOcrText', this.document).subscribe(() => {
            this.toastrService.success('Der digitalisierte Text wurde aktualisiert');
        });
    }

    getTabCount(): void {
        this.dataService.request('Document/getTabCount', {DID: this.DID}).subscribe(response => {
            this.tabCount = response.tabCount;
        });
    }

    redoOcr(showConfirmation = false): void {
        if (showConfirmation) {
            Swal.fire({
                title: 'Sicher?',
                text: 'Der bestehende Text wird überschrieben.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Ja',
                cancelButtonText: 'Nein'
            }).then(result => {
                if (result.value) {
                    this.redoOCR();
                }
            });
        } else {
            this.redoOCR();
        }
    }

    redoOCR(): void {
        this.dataService.request('Document/redoOCR', {
            DID: this.DID,
            FID: this.file.FID
        }).subscribe(response => {
            this.document.ocr_status = 'waiting';
            this.toastrService.success('Das Dokument wird in Kürze digitalisiert');
            this.setupOCRStatusInterval();
        });
    }

    setupOCRStatusInterval(): void {
        if (this.document.ocr_status == 'waiting') {
            this.checkOCRStatusInterval = setInterval(() => {
                this.dataService.request('Document/getOCRStatus', {
                    DID: this.DID,
                }).subscribe(response => {
                    this.document.ocr_status = response.status.ocr_status;
                    if (response.status.ocr_status == 'done') {
                        this.document.ocr_text = response.status.ocr_text;
                        this.toastrService.success('Das Dokument wurde erfolgreich digitalisiert');
                        clearInterval(this.checkOCRStatusInterval);
                    } else if (response.status.ocr_status == 'error' || response.status.ocr_status == 'encrypted') {
                        this.toastrService.warning('Das Dokument konnte nicht digitalisiert werden');
                        clearInterval(this.checkOCRStatusInterval);
                    }
                });
            }, 5000);
        }
    }

    updateDocumentInfo(): void {
        this.dataService.request('Document/updateInfo', {
            DID: this.DID,
        }).subscribe(() => {
            this.document.ocr_status = 'none';
            this.document.ocr_text = null;
            this.document.ocr_checked = '0';
            this.document.pages = null;

            this.form.patchValue({
                pages: this.document.pages,
            });
        });
    }
}
