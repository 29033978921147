import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, inject} from '@angular/core';
import {FileService} from '../file.service';
import {DomSanitizer} from '@angular/platform-browser';
import dayjs from 'dayjs';
import {UserService} from '../../../../global/services/user.service';
import {DataService} from '../../../../global/services/data.service';
import {myAppConfig} from '../../../../settings';
import {FwFileExternalData, FwFileViewLayout} from '../file.component';
import {FileModalComponent} from '../file-modal/file-modal.component';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {NgIf, NgFor, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault, DatePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {SafeHtmlPipe} from '../../../../global/pipes/safe-html.pipe';
import {FileSizePipe} from '../../../../global/pipes/file-size.pipe';

dayjs.extend(customParseFormat);

@Component({
    selector: 'app-file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrl: './file-viewer.component.scss',
    imports: [
        NgIf,
        NgFor,
        NgStyle,
        FormsModule,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        FileModalComponent,
        TranslateModule,
        DatePipe,
        SafeHtmlPipe,
        FileSizePipe,
    ]
})
export class FileViewerComponent implements OnInit {

    private dataService = inject(DataService);
    private userService = inject(UserService);
    private fileService = inject(FileService);
    private sanitizer = inject(DomSanitizer);
    private modalService = inject(BsModalService);

    @Input() files: any;
    @Input() document = false;
    @Input() fileTypes = 'pics';
    @Input() viewReadonly = false;
    @Input() viewThumbnails = false;
    @Input() viewThumbnailsSize = null;
    @Input() viewThumbnailsTilesSize = null;
    @Input() viewThumbnailsTitle = true;
    @Input() viewFullscreen = false;
    @Input() viewExternalData: FwFileExternalData | null = null; // View external data
    @Input() viewShowFileInfo = true; // Show Fileinfo in Image Viewer Fullscreen Mode
    @Input() viewEditModeFlipImage = false; // allow Flip Image
    @Input() viewNoFilesText: string | null = null;
    @Input() viewLayout = FwFileViewLayout.TILES;
    @Input() showViewLayoutSwitcher = false;
    @Input() onlyCreatorCanDelete = false;
    @Input() enableLinks = false;
    @Input() viewCheckbox = false;

    // if set to true allows user to delete
    @Input() isAdmin = false;

    @Output() deleted = new EventEmitter();
    @Output() selectedChange = new EventEmitter();

    protected readonly myAppConfig = myAppConfig;

    fileViewLayout = FwFileViewLayout;

    @ViewChild('fileModalComponent') fileModalComponent!: FileModalComponent;

    @ViewChild('deleteTemplate') deleteTemplate!: TemplateRef<any>;
    modalDeleteRef?: BsModalRef;

    // DATA
    result: any = [];
    randomID!: string;

    ngOnInit(): void {
        this.randomID = (Math.random() * 1000).toFixed(0);
    }

    canBeDeleted(file: any): boolean {
        // admin can always delete
        if (this.isAdmin) {
            return true;
        }

        // if readonly don't allow
        if (this.viewReadonly) {
            return false;
        }

        return this.onlyCreatorCanDelete === false || (this.onlyCreatorCanDelete === true && file.create_UID === this.userService.currentUser.UID);
    }

    openDeleteFileModal(event: any, file: any): void {
        event.stopPropagation();
        this.dataService.request('framework.File/getFile', {
            FID: file.FID,
            thumbnail: false
        }).subscribe(response => {
            this.result.selectedFile = response.file;

            if (this.result.selectedFile.firstname !== null) {
                this.result.selectedFile.creator_name = this.result.selectedFile.firstname + ' ' + this.result.selectedFile.lastname;
            }

            if (this.userService.currentUser.language === 'de') {
                this.result.selectedFile.create_date = dayjs(this.result.selectedFile.create_date, 'YYYY-MM-DD HH:mm:ss').format(
                    'DD.MM.YYYY HH:mm'
                );
            }
            this.result.selectedFile.imgData = this.sanitizer.bypassSecurityTrustUrl(file.imgData);
            this.modalDeleteRef = this.modalService.show(this.deleteTemplate);

        });
    }

    deleteFile(file: any): void {
        this.modalDeleteRef?.hide();
        this.dataService.request('framework.File/deleteFile', {
            FID: file.FID,
        }).subscribe(() => {
            this.fileService.loadFiles.next(true);
            this.fileService.deselectFiles.next(true);
            this.fileService.countUploadedFiles.next(true);
            this.deleted.emit();
        });
    }

    openFileModal(FID: any): void {
        this.fileService.openModal.next(FID);

        this.fileModalComponent.openFile(FID);
    }

    setLayout(fileViewLayout: FwFileViewLayout): void {
        this.viewLayout = fileViewLayout;
    }

    handleSelectedChange() {
        this.selectedChange.emit(this.files.filter((file: any) => file.selected).map((file: any) => {
            return {
                FID: file.FID,
                name: file.name,
            }
        }));
    }
}
