import {Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../global/services/data.service';
import dayjs from 'dayjs';
import {NgIf, DatePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FileSizePipe} from '../../pipes/file-size.pipe';
import {YesNoPipe} from '../../pipes/yes-no.pipe';

@Component({
    selector: 'app-file-link',
    templateUrl: './file-link.component.html',
    styleUrl: './file-link.component.scss',
    imports: [NgIf, FormsModule, DatePipe, FileSizePipe, YesNoPipe]
})
export class FileLinkComponent implements OnInit {

    private route = inject(ActivatedRoute);
    private dataService = inject(DataService);

    hash!: any;
    fileLink: any;
    problem!: string;
    expired = false;
    password = '';
    invalidPassword = false;

    ngOnInit(): void {
        this.hash = this.route.snapshot.paramMap.get('hash');

        this.dataService.request('universe.Client/getFileLink', {
            hash: this.hash
        }).subscribe(response => {
            if (response.problem) {
                this.problem = 'Ungültiger Link';
            } else {
                this.fileLink = response.fileLink;
                this.fileLink.single_use = this.fileLink.single_use === '1';
                this.fileLink.password = this.fileLink.password === '1';
                this.fileLink.downloads = parseInt(this.fileLink.downloads, 10);

                if (this.fileLink.expiry) {
                    this.expired = dayjs().isAfter(dayjs(this.fileLink.expiry), 'day');
                }
            }
        });
    }

    download(): void {
        this.dataService.request('universe.Client/getFile', {
            hash: this.hash,
            password: this.password
        }).subscribe(response => {

            if (response.invalid_password) {
                this.invalidPassword = true;
            } else {
                this.invalidPassword = false;

                this.fileLink.downloads++;
                if (this.fileLink.single_use) {
                    this.fileLink.used = '1';
                }
                const downloadLink = document.createElement('a');

                downloadLink.href = response.file.data;
                downloadLink.download = response.file.name;
                downloadLink.click();
            }
        });
    }
}
