import {Component, OnInit, inject} from '@angular/core';
import {Router} from '@angular/router';

import {CryptoService} from '../../global/services/crypto.service';
import {DataService} from '../../global/services/data.service';
import {UrlData} from '../../interfaces';
import {NgFor, DatePipe} from '@angular/common';

@Component({
    selector: 'app-admin-document-list',
    templateUrl: './admin-document-list.component.html',
    styleUrls: ['./admin-document-list.component.scss'],
    imports: [NgFor, DatePipe]
})
export class AdminDocumentListComponent implements OnInit {

    private dataService = inject(DataService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);

    documents: any[] = [];

    ngOnInit(): void {
        this.dataService.request('universe.Admin/listDocumentsForDigitization').subscribe(response => {
            this.documents = response.documents;
        });
    }

    openDocument(document: any): void {
        const data: UrlData = {
            ID: document.DID,
            data: {
                dbName: document.dbName,
                CLID: document.CLID
            }
        };
        this.router.navigate(['/admin/document/detail', this.cryptoService.encrypt(data)]);
    }
}
