<div class="d-flex"
     [class.fw-div-center]="tableField.alignment === TableFieldAlignment.CENTER"
     [class.fw-div-right]="tableField.alignment === TableFieldAlignment.RIGHT">
    <div *ngFor="let imageConfig of imageConfigs" class="pr-1 cImage">
        <img
            *ngIf="imageConfig.image"
            [src]="'assets/img/framework/' + imageConfig.image"
            [style.height]="imageConfig.height"
            [style.width]="imageConfig.width" />
        <i class="fa"
           *ngIf="imageConfig.icon"
           [ngClass]="imageConfig.icon"
           [style.color]="imageConfig.color"
           [style.width]="imageConfig.width"
           [style.height]="imageConfig.height"
           [tooltip]="imageConfig.tooltip"></i>
    </div>
    <ng-content></ng-content>
</div>
