import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    private data: any = {};

    setItem(key: string, value: any): void {
        if (this.data[key]) {
            console.warn('Message with key "' + key + '" already exists');
        }
        this.data[key] = JSON.stringify(value);
    }

    getItem(key: string, deleteKey = false): any {
        if (this.data[key]) {
            const data = JSON.parse(this.data[key]);
            if (deleteKey) delete this.data[key];
            return data;
        } else {
            console.warn('Message with key "' + key + '" does not exist');
            return null;
        }
    }
}
