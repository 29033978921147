import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableConfig} from '../table.interfaces';
import {FormsModule} from '@angular/forms';
import {NgFor} from '@angular/common';

@Component({
    selector: 'app-table-rowswitch',
    templateUrl: './table-rowswitch.component.html',
    styleUrl: './table-rowswitch.component.scss',
    imports: [FormsModule, NgFor]
})
export class TableRowswitchComponent {

    @Input() config!: TableConfig;
    @Output() changeRows = new EventEmitter();

}
