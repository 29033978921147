import {Component, Input, OnInit, inject} from '@angular/core';
import {Router} from '@angular/router';

import {DataService} from '../../global/services/data.service';
import {CryptoService} from '../../global/services/crypto.service';
import {UrlData} from '../../interfaces';
import {NgFor, DatePipe} from '@angular/common';

@Component({
    selector: 'app-admin-task-list',
    templateUrl: './admin-task-list.component.html',
    styleUrls: ['./admin-task-list.component.scss'],
    imports: [NgFor, DatePipe]
})
export class AdminTaskListComponent implements OnInit {

    private dataService = inject(DataService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);

    @Input({required: true}) DID!: string;
    @Input({required: true}) dbName!: string;
    @Input({required: true}) CLID!: string;

    tasks!: any[];

    ngOnInit(): void {
        this.dataService.request('universe.Admin/listTasksByClientAndDocument', {
            DID: this.DID,
            dbName: this.dbName
        }).subscribe(response => {
            this.tasks = response.tasks;
        });
    }

    openTask(TID: any): void {
        const data: UrlData = {
            ID: TID,
            data: {
                DID: this.DID,
                dbName: this.dbName,
                CLID: this.CLID
            }
        };
        this.router.navigate(['/admin/task/detail/', this.cryptoService.encrypt(data)]);
    }
}
