import {Component, inject} from '@angular/core';
import {FileItem, FileUploader, FileUploadModule} from "ng2-file-upload";
import {DataService} from "../../../global/services/data.service";
import {MessageService} from "../../../global/services/message.service";
import {FwTokenType, JwtService} from "../../../global/services/jwt.service";
import {NgClass, NgIf, NgFor, NgStyle, DecimalPipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FileTypePipe} from '../../../global/pipes/file-type.pipe';

@Component({
    selector: 'app-document-uploadmultiple',
    templateUrl: './document-uploadmultiple.component.html',
    styleUrl: './document-uploadmultiple.component.scss',
    imports: [FileUploadModule, NgClass, NgIf, FormsModule, NgFor, NgStyle, DecimalPipe, TranslateModule, FileTypePipe]
})
export class DocumentUploadmultipleComponent {

    private dataService = inject(DataService);
    private messageService = inject(MessageService);
    private jwtService = inject(JwtService);

    uploader!: FileUploader;
    hasBaseDropZoneOver = false;
    responses: any[] = [];

    fileTypes: any[] = [];
    companies: any[] = [];

    filetype: any = null;
    company: any = null;
    LID: any = null;

    messageData: any;

    ngOnInit(): void {
        this.uploader = new FileUploader({
            url: "api/api.php",
            authToken: 'Bearer ' + this.jwtService.getToken(FwTokenType.ACCESS_TOKEN),
            itemAlias: 'files',
            additionalParameter: {
                apiController: 'framework.File/uploadDocument',
                doctype: 'document',
                fk_name: 'DID',
                fk_table: 'document',
            },
            filters: [
                {
                    name: 'sizeFilter',
                    fn: function (item) {
                        console.log(item);
                        return item.size < (1024 * 1024 * 300); // 300 MB
                    }
                },
                {
                    name: 'typeFilter',
                    fn: function (item) {
                        return item.type == 'application/pdf'
                    }
                }
            ],
        });

        this.uploader.onAfterAddingFile = ((fileItem: FileItem) => {
            fileItem.formData.name = fileItem.file.name.substring(0, fileItem.file.name.lastIndexOf('.')) || fileItem.file.name;
            fileItem.formData.name = fileItem.formData.name.replace(/_/g, ' ');
            fileItem.formData.filetype = '';
            fileItem.formData.company = '';
            fileItem.formData.LID = '';
            fileItem.formData.attributes = [];
            fileItem.formData.showAttributes = true;
        });

        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('name', fileItem.formData.name);
            form.append('FTID', fileItem.formData.filetype.FTID);
            form.append('CID', fileItem.formData.company.CID);
            form.append('LID', fileItem.formData.LID);
            form.append('attributes', JSON.stringify(fileItem.formData.attributes));
        };

        this.uploader.response.subscribe(res => this.responses.push(JSON.parse(res)));

        // filled by location detail
        this.messageData = this.messageService.getItem('uploadDocument', true);

        this.dataService.request('Company/listAllWithLocations').subscribe(response => {
            this.companies = response.companies;

            // automatically set company if there is only one
            if (this.companies.length === 1) {
                this.company = this.companies[0];
            }

            // if document upload was routed from location set CID and LID
            if (this.messageData) {
                this.company = this.companies.find(company => company.CID == this.messageData.CID);
                this.LID = this.company.locations.find((location: any) => location.LID == this.messageData.LID);
            }
        });

        this.dataService.request('FileType/listAllWithAttributes').subscribe(response => {
            this.fileTypes = response.filetypes;
        });
    }

    fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    setTypeAll() {
        this.uploader.queue.forEach((fileItem: FileItem) => {
            fileItem.formData.filetype = this.filetype;

            fileItem.formData.attributes = [];
            this.filetype.attributes.forEach((attribute: any) => {
                fileItem.formData.attributes.push(
                    {
                        ATTID: attribute.ATTID,
                        name: attribute.name,
                        type: attribute.type,
                        required: attribute.required == '1',
                        position: attribute.position,
                        value_int: null,
                        value_dec: null,
                        value_date: null,
                        value_text: null,
                        value_boolean: false
                    }
                );
            });
        });
    }

    setCompanyAll() {
        this.LID = '';
        this.uploader.queue.forEach((fileItem: FileItem) => {
            fileItem.formData.company = this.company;
            fileItem.formData.LID = '';
        });
    }

    setLocationAll() {
        this.uploader.queue.forEach((fileItem: FileItem) => {
            fileItem.formData.LID = this.LID;
        });
    }

    canUploadAll(): boolean {
        return this.uploader.queue.every((fileItem: FileItem) => {
            return this.canUpload(fileItem);
        });
    }

    setType(item: FileItem) {
        item.formData.attributes = [];
        item.formData.filetype.attributes.forEach((attribute: any) => {
            item.formData.attributes.push(
                {
                    ATTID: attribute.ATTID,
                    name: attribute.name,
                    type: attribute.type,
                    required: attribute.required == '1',
                    position: attribute.position,
                    value_int: null,
                    value_dec: null,
                    value_date: null,
                    value_text: null,
                    value_boolean: false
                }
            );
        });

        if (item.formData.filetype != this.filetype) {
            this.filetype = null;
        }
    }

    setCompany(item: FileItem) {
        item.formData.LID = '';

        if (item.formData.company != this.company) {
            this.company = null;
        }
    }

    setLocation(item: FileItem) {
        if (item.formData.LID != this.LID) {
            this.LID = null;
        }
    }

    canUpload(item: FileItem): boolean {
        const firstCheck = (item.formData.name && item.formData.filetype && item.formData.company && item.formData.LID);

        const secondCheck = item.formData.attributes.every((attribute: any) => {
            if (attribute.required) {
                switch (attribute.type) {
                    case 'int':
                        return attribute.value_int;
                    case 'dec':
                        return attribute.value_dec;
                    case 'date':
                        return attribute.value_date;
                    case 'text':
                        return attribute.value_text;
                    case 'boolean':
                        return attribute.value_boolean;
                }
            }
            return true;
        });

        return firstCheck && secondCheck;
    }

    clearResponses() {
        this.responses = [];
    }

    toggleAttributes(item: FileItem) {
        item.formData.showAttributes = !item.formData.showAttributes;
    }

    getMissingAttributesCount(item: FileItem): number {
        return item.formData.attributes.filter((attribute: any) => {
            if (attribute.required) {
                switch (attribute.type) {
                    case 'int':
                        return attribute.value_int == null || attribute.value_int == '';
                    case 'dec':
                        return attribute.value_dec == null || attribute.value_dec == '';
                    case 'date':
                        return attribute.value_date == null || attribute.value_date == '';
                    case 'text':
                        return attribute.value_text == null || attribute.value_text == '';
                    case 'boolean':
                        return attribute.value_boolean == null || attribute.value_boolean == '';
                }
            }
            return false;
        }).length;
    }
}
