import {MyAppConfig} from './interfaces';

export const myAppConfig: MyAppConfig = {
    appID: 'noteradar',
    urlDataFallbackLink: '/app/dashboard',
    cryptoKey: 'FbcCY2yCFBwVCUE9R+6kJ4fAL4BJxxjd',
    auth: {
        loginDestination: '/login',
        afterLoginDestination: '/app/dashboard',
        afterLogoutDestination: '',
        interceptorWhitelist: [],
    },
    fileFields: [
        {
            name: 'Dateiname',
            key: 'display_name',
            editable: true,
            required: true,
        },
        {
            name: 'Beschreibung',
            key: 'description',
            editable: true,
            required: false,
        },
        {
            name: 'Dateigröße',
            suffix: '(KB)',
            key: 'size'
        },
        {
            name: 'Erstellt von',
            key: 'creator_name'
        },
        {
            name: 'Erstellt am',
            key: 'create_date'
        }
    ]
};
