import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'month',
    standalone: true
})
export class MonthPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === '1') {
            return 'Jänner';
        } else if (value === '2') {
            return 'Februar';
        } else if (value === '3') {
            return 'März';
        } else if (value === '4') {
            return 'April';
        } else if (value === '5') {
            return 'Mai';
        } else if (value === '6') {
            return 'Juni';
        } else if (value === '7') {
            return 'Juli';
        } else if (value === '8') {
            return 'August';
        } else if (value === '9') {
            return 'September';
        } else if (value === '10') {
            return 'Oktober';
        } else if (value === '11') {
            return 'November';
        } else if (value === '12') {
            return 'Dezember';
        } else {
            return '';
        }
    }

}
