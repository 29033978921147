import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-file-type',
    templateUrl: './file-type.component.html',
    styleUrl: './file-type.component.scss',
    imports: [RouterOutlet]
})
export class FileTypeComponent {

}
