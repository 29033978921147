import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-task-topic',
    templateUrl: './task-topic.component.html',
    styleUrl: './task-topic.component.scss',
    imports: [RouterOutlet]
})
export class TaskTopicComponent {

}
