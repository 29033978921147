import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableConfig} from '../table.interfaces';
import {NgIf} from '@angular/common';
import {TranslateLabel} from "../../../../global/pipes/translateLabel";

@Component({
    selector: 'app-table-filterbutton',
    templateUrl: './table-filterbutton.component.html',
    styleUrl: './table-filterbutton.component.scss',
    imports: [NgIf, TranslateLabel]
})
export class TableFilterbuttonComponent {

    @Input() config!: TableConfig;
    @Output() changeColumnFilterActive = new EventEmitter();
    @Output() changeColumnFilterReset = new EventEmitter();

    toggleFilter(): void {
        this.changeColumnFilterActive.emit();
    }

    checkFiltered(): boolean {
        return Object.values(this.config.dataConfig.columnFilter.fields).some((field: any) => {
            return field.value !== '';
        });
    }

    resetFilter(): void {
        Object.values(this.config.dataConfig.columnFilter.fields).forEach(field => {
            field.value = '';
        });
        this.changeColumnFilterReset.emit();
    }
}
