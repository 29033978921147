import {Injectable, inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as StackTrace from 'stacktrace-js';
import {ToastrService} from 'ngx-toastr';
import {UserService} from "./user.service";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private http = inject(HttpClient);
    private toastrService = inject(ToastrService);
    private userService = inject(UserService);
    private router = inject(Router);

    request(action: string, dataObject: object | null = null): Observable<any> {
        const stackFrames = StackTrace.getSync();

        let componentName = null;
        let methodName = null;

        if (stackFrames[1].functionName) {
            const parts = stackFrames[1].functionName.split('.');
            componentName = parts[parts.length - 2] + '.ts';
            methodName = parts[parts.length - 1] + '()';
        }

        return this.http
            .post('api/api.php', {
                action,
                data: dataObject,
                adminToken: action.startsWith("universe.Admin") ? this.userService.adminToken : null,
                componentName,
                methodName
            })
            .pipe(
                tap((response: any) => {
                    if (response?.unauthorizedAdmin) {
                        this.userService.adminToken = '';
                        this.router.navigate(['/admin/login']);
                    }
                    if (response?.errors) {
                        response.errors.forEach((error: any) => {
                            this.toastrService.error(
                                error === 'ERRORS.DB' ? "Es ist ein Datenbank-Fehler aufgetreten" : error,
                                'Fehler'
                            );
                        });
                    }

                    if (response?.warnings) {
                        response.warnings.forEach((error: any) => {
                            this.toastrService.warning(error);
                        });
                    }
                })
            );
    }
}




