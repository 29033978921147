import {Injectable, inject} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {

    private router = inject(Router);
    private route = inject(ActivatedRoute);

    breadcrumbs: Observable<Array<any>>;

    private breadcrumbSubject: BehaviorSubject<Array<any>>;

    constructor() {
        this.breadcrumbSubject = new BehaviorSubject<any[]>(new Array<any>());

        this.breadcrumbs = this.breadcrumbSubject.asObservable();

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
            const breadcrumbs: any[] = [];
            let currentRoute = this.route.root;
            let url = '';

            let skipBreadcrumbs = false;
            do {
                const childrenRoutes = currentRoute.children;
                currentRoute = null;
                childrenRoutes.forEach(childRoute => {
                    if (childRoute.outlet === 'primary') {
                        const routeSnapshot = childRoute.snapshot;

                        if (routeSnapshot.data['skipBreadcrumbs']) {
                            skipBreadcrumbs = true;
                        }

                        url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
                        breadcrumbs.push({
                            label: routeSnapshot.data,
                            url
                        });

                        currentRoute = childRoute;
                    }
                });
            } while (currentRoute);

            if (skipBreadcrumbs) { // @ts-ignore
                return;
            }

            this.breadcrumbSubject.next(Object.assign([], breadcrumbs));

            return breadcrumbs;
        });
    }

    setManually(breadcrumbs: Array<any>) {
        this.breadcrumbSubject.next(Object.assign([], breadcrumbs));
    }
}
