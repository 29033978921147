import {Component, OnInit, inject} from '@angular/core';
import {
    TableColumnFilterType,
    TableConfig,
    TableFieldAlignment, TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {UserService} from '../../../global/services/user.service';
import {TableComponent} from '../../../global/components/table/table.component';

@Component({
    selector: 'app-department-list',
    templateUrl: './department-list.component.html',
    styleUrl: './department-list.component.scss',
    imports: [TableComponent]
})
export class DepartmentListComponent implements OnInit {

    private userService = inject(UserService);

    tableConfig!: TableConfig;

    ngOnInit(): void {
        this.tableConfig = {
            titleIcon: 'fas fa-user-tie',
            title: 'Abteilungen',
            openDatasetRouterLink: '/app/department/detail',
            enableCreate: this.userService.hasRight('MASTER_DATA_EDIT'),
            enableExportExcel: true,
            description: 'Abteilungen werden einer Aufgabe zugeordnet und definieren die verantwortliche Person.',
            dataConfig: {
                tableName: 'department',
                tableIndexName: 'DPID',
                tableJoins: [
                    {left: 'user', right: 'department', key: 'UID'},
                ],
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {
                        key: 'user',
                        table: 'user',
                        subquery: 'concat_ws(" ", firstname, lastname)',
                        title: 'Verantwortlicher',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'used',
                        subquery: '(SELECT COUNT(*) FROM task WHERE DPID = department.DPID)',
                        sortable: true,
                        title: '# Zugewiesen',
                        alignment: TableFieldAlignment.RIGHT
                    }
                ],
                rowImage: [
                    {
                        key: 'used',
                        icon: 'fa-exclamation-triangle',
                        color: '#ffc107',
                        conditions: [
                            {key: 'used', value: '0', operator: TableRowColorConditionOperator.EQUAL},
                        ],
                        tooltip: 'Aufgabentyp wurde nirgends zugewiesen'
                    },
                ],
                reverse: false,
                order: 'name',
                columnFilter: {
                    enabled: false,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                        user: {value: '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'department-list',
                enabled: true
            },
            paginConfig: {
                id: 'departmentList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }

}
