import {Component, inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {Router} from "@angular/router";

import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

import {environment} from '../../../environments/environment';
import {AutofocusDirective} from "../../global/directives/autofocus.directive";
import {UserService} from "../../global/services/user.service";
import {DataService} from "../../global/services/data.service";
import {FwTokenType, JwtService} from "../../global/services/jwt.service";
import {myAppConfig} from "../../settings";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    imports: [FormsModule, AutofocusDirective]
})
export class LoginComponent implements OnInit{

    userService = inject(UserService);
    toastrService = inject(ToastrService);
    private router = inject(Router);
    private dataService = inject(DataService);
    private translateService = inject(TranslateService);
    private jwtService = inject(JwtService);
    private modalService = inject(BsModalService);
    private http = inject(HttpClient);

    protected readonly myAppConfig = myAppConfig;

    @ViewChild('deniedTemplate') deniedTemplate!: TemplateRef<any>;
    modalDeniedRef?: BsModalRef;

    @ViewChild('resetTemplate') resetTemplate!: TemplateRef<any>;
    modalResetRef?: BsModalRef;

    deniedMessage: any;
    loginData: any;
    environment = environment;
    loginInProgress = false;
    resetPasswordMail: string = '';

    ngOnInit(): void {
        this.loginData = {
            username: "",
            password: "",
        };
    }

    login(): void {
        this.loginInProgress = true;

        this.http.post('api/api.php', {
            action: 'framework.Auth/login',
            data: {username: this.loginData.username, password: this.loginData.password},
            componentName: 'UserService.ts',
            methodName: 'login()'
        }).subscribe((response: any) => {
            if (response.auth.success) {
                this.jwtService.setToken(FwTokenType.ACCESS_TOKEN, response.jwt_token.accessToken);
                this.jwtService.setToken(FwTokenType.REFRESH_TOKEN, response.jwt_token.refreshToken);

                this.translateService.use(response.user.language);
                this.userService.currentUser = response.user;
                this.userService.currentUser.loggedIn = true;

                this.toastrService.info('Willkommen' + ' ' + this.userService.userDisplayName() + '!');
                this.router.navigate([myAppConfig.auth.afterLoginDestination]).catch();
            } else {
                if (response.auth.message === 'user-disabled') {
                    this.deniedMessage = 'Benutzer deaktiviert';
                }
                if (response.auth.message === 'invalid-credentials') {
                    this.deniedMessage = 'Ungültige Anmeldedaten';
                }
                if (response.auth.message === 'user-not-found') {
                    this.deniedMessage = 'Benutzer nicht gefunden';
                }
                this.loginInProgress = false;

                this.modalDeniedRef = this.modalService.show(this.deniedTemplate);
            }
        });
    }

    openForgotPasswordModal(): void {
        this.modalResetRef = this.modalService.show(this.resetTemplate);
    }

    resetPasswordNow(): void {
        this.modalResetRef?.hide();

        this.dataService.request('framework.Auth/resetPassword', {
            username: this.resetPasswordMail,
            language: this.userService.currentUser.language
        }).subscribe(response => {
            this.resetPasswordMail = '';
            if (!response.error) {
                this.toastrService.success("E-Mail wurde versendet");
            } else {
                this.toastrService.error("Zurücksetzen fehlgeschlagen! Wenden Sie sich bitte per E-Mail an uns");
            }
        });
    }
}
