import {Pipe, PipeTransform, inject} from '@angular/core';
import {UserService} from '../services/user.service';

@Pipe({
    name: 'right',
    standalone: true
})
export class RightPipe implements PipeTransform {

    private userService = inject(UserService);

    transform(value: string, ...args: unknown[]): unknown {
        return this.userService.rights[value];
    }

}
