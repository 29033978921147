import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {
    TableColumnFilterType,
    TableConfig,
    TableFieldAlignment,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {UserService} from '../../../global/services/user.service';
import {DataService} from '../../../global/services/data.service';
import {TableComponent} from '../../../global/components/table/table.component';
import {ToastrService} from "ngx-toastr";
import {NgFor, NgIf} from '@angular/common';
import {RightPipe} from '../../../global/pipes/right.pipe';

@Component({
    selector: 'app-task-topic-list',
    templateUrl: './task-topic-list.component.html',
    styleUrl: './task-topic-list.component.scss',
    imports: [TableComponent, NgIf, NgFor, RightPipe]
})
export class TaskTopicListComponent implements OnInit {

    userService = inject(UserService);
    private dataService = inject(DataService);
    private toastrService = inject(ToastrService);

    @ViewChild('tableComponent') tableComponent!: TableComponent;

    tableConfig!: TableConfig;
    count: any;
    standardSet!: any[] | null;

    ngOnInit(): void {
        this.getCount();

        this.tableConfig = {
            titleIcon: 'fas fa-tag',
            title: 'Aufgabenthemen',
            openDatasetRouterLink: '/app/tasktopic/detail',
            enableCreate: this.userService.hasRight('MASTER_DATA_EDIT'),
            enableExportExcel: true,
            description: 'Kategorisierung von Aufgaben in beliebige Themen.',
            dataConfig: {
                tableName: 'tasktopic',
                tableIndexName: 'TOID',
                tableFields: [
                    {key: 'name', name: 'name', title: 'Name', sortable: true, searchable: true},
                    {
                        key: 'used',
                        subquery: '(SELECT COUNT(*) FROM task WHERE TOID = tasktopic.TOID)',
                        title: '# Zugewiesen',
                        sortable: true,
                        alignment: TableFieldAlignment.RIGHT
                    }
                ],
                rowImage: [
                    {
                        key: 'used',
                        icon: 'fa-exclamation-triangle',
                        color: '#ffc107',
                        conditions: [
                            {key: 'used', value: '0', operator: TableRowColorConditionOperator.EQUAL},
                        ],
                        tooltip: 'Aufgabenthema wurde nirgends zugewiesen'
                    },
                ],
                reverse: false,
                order: 'name',
                columnFilter: {
                    enabled: false,
                    fields: {
                        name: {value: '', type: TableColumnFilterType.INPUT},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'topic-list',
                enabled: true
            },
            paginConfig: {
                id: 'topicList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };
    }

    getCount(): void {
        this.dataService.request('TaskTopic/count').subscribe(response => {
            this.count = response.count;
        });
    }

    loadSet(): void {
        this.dataService.request('TaskTopic/loadSet').subscribe(response => {
            this.standardSet = null;
            this.getCount();
            this.tableComponent.load();
            this.toastrService.success('Das Standardset wurde geladen');
        });
    }

    listStandardSet() {
        this.dataService.request('TaskTopic/listSet').subscribe(response => {
            this.standardSet = response.set;
        });
    }
}
