<div class="imageModal"
    *ngIf="viewImageModal"
    [ngStyle]="{'background-image': 'url(' + result.openFile.imageData + ')'}"
    [ngClass]="{'bgDefault': imageClass == 'bgDefault', 'bgVertical': imageClass == 'bgVertical', 'bgHorizontal': imageClass == 'bgHorizontal'}">
    <div class="content">
        <div *ngIf="result.openFile" class="w-100 h-100">
            <div class="d-flex justify-content-between h-100">
                <div class="nav">
                    <div *ngIf="files.length > 1" class="rounded-right nav-button nav-arrow-left" (click)="goPrevImage()" [ngClass]="navigation.first === navigation.focus ? 'nav-inactive' : 'nav-arrow'">
                        <i class="fa fa-angle-left fa-4x"></i>
                    </div>
                </div>
                <div *ngIf="viewFileInfo" class="p-2 align-self-end">
                    <div class="fileInfo rounded">
                        <div class="fileInfo-header" (click)="toggleFileInfo()">
                            <span *ngIf="!hideFileInfo"><i class="fa fa-angle-down fa-2x"></i></span>
                            <span *ngIf="hideFileInfo"><i class="fa fa-angle-up fa-2x"></i></span>
                        </div>
                        <div *ngIf="!hideFileInfo" class="fileInfo-content" [@slideInOut]="'in'">
                            <table>
                                <tr *ngFor="let field of myAppConfig.fileFields" [hidden]="result.openFile[field.key] == null">
                                    <td class="pr-2">{{field.name}}</td>
                                    <td>
                                        <strong>{{field.key == 'size' ? (result.openFile[field.key] | fileSize) : result.openFile[field.key]}}</strong>
                                        <span class="text-muted" *ngIf="!result.openFile[field.key]">Nicht gesetzt</span>
                                    </td>
                                </tr>
                            </table>
                            <button type="button" class="btn btn-secondary btn-block btn-sm mt-1" (click)="openInfoModal()">Dateiinformationen</button>
                        </div>
                    </div>
                </div>
                <div class="nav">
                    <div *ngIf="files.length > 1" class="rounded-left nav-button nav-arrow-right" (click)="goNextImage()" [ngClass]="navigation.last === navigation.focus ? 'nav-inactive' : 'nav-arrow'">
                        <i class="fa fa-angle-right fa-4x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="image-close" (click)="closeFileModal()">
        <i class="fa fa-times fa-3x"></i>
    </div>
    <div *ngIf="viewFlipImage && !loading" class="image-flip text-center">
        <i class="fa fa-undo fa-2x image-flip-link" (click)="rotateImage(90)"> </i> <i class="fa fa-picture-o fa-2x pl-3 pr-3" style="color:rgba(255, 255, 255, 0.5); cursor: default"></i> <i class="fa fa-undo fa-2x image-flip-link" style="transform: scaleX(-1)" (click)="rotateImage(-90)"> </i>
    </div>
    <div *ngIf="loading" class="image-flip text-center">
        <i class="fa fa-cog fa-spin fa-2x" style="color: rgba(255, 255, 255, 0.5);"></i>
    </div>
</div>

<div bsModal #fileModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal-info-label">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" *ngIf="viewMode == ViewMode.VIEW">
            <div class="modal-header state bg-info text-white">
                <h4 class="modal-title">Dateiinformationen</h4>
            </div>
            <div *ngIf="result.openFile" class="modal-body">
                <div class="row">
                    <div class="col-md-8">
                        <table>
                            <tbody>
                            <tr *ngFor="let field of myAppConfig.fileFields">
                                <td class="pr-2">{{field.name}}</td>
                                <td>
                                    <strong>{{field.key == 'size' ? (result.openFile[field.key] | fileSize) : result.openFile[field.key]}}</strong>
                                    <span class="text-muted" *ngIf="!result.openFile[field.key]">Nicht gesetzt</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-4 text-right">
                        <img [src]="result.openFile.imageData" class="img-fluid" style="height: 110px">
                    </div>
                </div>

                <ul class="nav nav-tabs mt-1" *ngIf="tab != null">
                    <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab == TABS.PREVIEW}" href="" data-toggle="tab" (click)="setTab(TABS.PREVIEW)" *ngIf="embedString">Vorschau</a></li>
                    <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab == TABS.FILELINKS}" href="" data-toggle="tab" (click)="setTab(TABS.FILELINKS)" *ngIf="enableLinks" >Fileshare Links</a></li>
                </ul>

                <!-- show PDF preview -->
                <div *ngIf="tab == TABS.PREVIEW" role="tabpanel" class="tab-pane active">
                    <div *ngIf="embedString" class="pdf-embed">
                        <embed [src]="embedString" type="application/pdf" width="100%" height="100%">
                    </div>
                </div>

                <div *ngIf="tab == TABS.FILELINKS" role="tabpanel" class="tab-pane active">

                    <ng-container *ngIf="!currentLink">
                        <h4 class="mt-1">Fileshare Links</h4>

                        <span class="text-muted" *ngIf="fileLinks.length == 0">Keine Links erstellt</span>

                        <div class="table-responsive">
                            <table class="table table-striped table-hover table-sm mb-0" *ngIf="fileLinks.length > 0">
                                <thead>
                                <tr>
                                    <th style="min-width: 70px"></th>
                                    <th>Erstellt</th>
                                    <th>Von</th>
                                    <th>Ablauf</th>
                                    <th>Einmalig</th>
                                    <th>Passwort</th>
                                    <th>Empfänger</th>
                                    <th># Downloads</th>
                                    <th>Link</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let fileLink of fileLinks">
                                    <td>
                                        <button type="button" class="btn btn-danger btn-xs"
                                                (click)="deleteFileLink(fileLink)"
                                                [attr.data-right]="'Filelink löschen'"
                                                *ngIf="userService.hasRight('FILESHARE_LINK_DELETE') && result.openFile?.create_UID == userService.currentUser.UID"><i class="fas fa-times"></i></button>
                                        <button type="button" class="btn btn-secondary btn-xs ml-2"
                                                (click)="editFileLink(fileLink)"
                                                [attr.data-right]="'Filelink bearbeiten'"
                                                *ngIf="userService.hasRight('FILESHARE_LINK_EDIT') && result.openFile?.create_UID == userService.currentUser.UID"><i class="fas fa-pencil-alt"></i></button>
                                    </td>
                                    <td>{{ fileLink.created | date: "dd.MM.yyyy HH:mm" }}</td>
                                    <td>{{ fileLink.firstname }} {{ fileLink.lastname }}</td>
                                    <td>
                                        <span *ngIf="fileLink.expiry">{{ fileLink.expiry | date: "dd.MM.yyyy" }}</span>
                                        <span *ngIf="!fileLink.expiry" class="text-muted">Keiner</span>
                                    </td>
                                    <td>{{ fileLink.single_use | yesNo }}<ng-container *ngIf="fileLink.used == '1'">, verwendet</ng-container></td>
                                    <td>
                                        {{ fileLink.hasPassword | yesNo }}
                                        <!--<ng-container *ngIf="fileLink.hasPassword && fileLink.password_transport == 'mail'"> (per Mail)</ng-container>
                                        <ng-container *ngIf="fileLink.hasPassword && fileLink.password_transport == 'phone'"> (an {{fileLink.phone}})</ng-container>-->
                                    </td>
                                    <td>
                                        <span *ngIf="fileLink.recipients">{{ fileLink.recipients }}</span>
                                        <span *ngIf="!fileLink.recipients" class="text-muted">Keine</span>
                                    </td>
                                    <td class="text-right">{{fileLink.downloads}}</td>
                                    <td><a [href]="'filelink/' + fileLink.CLID + '_' + fileLink.hash" target="_blank">Link</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentLink">
                        <h4 class="mt-1" style="border-bottom: 1px solid #c8ced3; padding-bottom: 7px">{{ linkForm.value.FLID == null ? 'Fileshare Link erstellen' : 'Fileshare Link bearbeiten' }}</h4>

                        <form [formGroup]="linkForm">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Ablaufdatum</label>
                                        <input type="date" class="form-control" formControlName="expiry">
                                        <small class="form-text text-muted">
                                            Optionales Ablaufdatum, wird es nicht gesetzt, bleibt der Link für immer gültig
                                        </small>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="form-group">
                                        <label>Passwort</label>
                                        <input type="text" class="form-control" formControlName="password">
                                        <small class="form-text text-muted">
                                            Optionales Passwort
                                        </small>
                                    </div>
                                    <!--<div class="form-group" *ngIf="linkForm.value.password != null && linkForm.value.password != ''">
                                        <label>Passwortversand</label>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" formControlName="password_transport" value="mail" style="position: absolute">
                                            <label class="form-check-label">
                                                E-Mail
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" formControlName="password_transport" value="phone" style="position: absolute">
                                            <label class="form-check-label">
                                                Smartphone
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="linkForm.value.password_transport == 'phone'">
                                        <label>Telefonnummer</label>
                                        <input type="text" class="form-control" formControlName="phone" placeholder="066012345">
                                    </div>-->
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Einmaliger Abruf</label>
                                        <div>
                                            <input type="checkbox" class="form-check-input ml-0" formControlName="single_use">
                                        </div>
                                        <div class="mt-4">
                                            <small class="form-text text-muted">
                                                Wenn aktiv, kann der Link nur einmalig abgerufen werden
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="form-group">
                                        <label>Empfänger</label>
                                        <input type="text" class="form-control" formControlName="recipients">
                                        <small class="form-text text-muted">
                                            Optionale Empfänger, die den Link per Mail erhalten sollen. Bitte mit Komma trennen zB. "max.mueller&#64;noteradar.at, max.muster&#64;noteradar.at".
                                        </small>
                                        <small class="form-text text-danger" *ngIf="linkForm.value.password != null && linkForm.value.recipients == null">
                                            Für Passwortversand Empfänger bitte ausfüllen!
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                </div>
            </div>
            <div class="modal-footer">
                <ng-container *ngIf="currentLink">
                    <button type="button" class="btn btn-primary" *ngIf="linkForm.value.FLID == null" [disabled]="linkForm.invalid || savingLink" (click)="saveFileLink()">Link erstellen</button>
                    <button type="button" class="btn btn-primary" *ngIf="linkForm.value.FLID != null" [disabled]="linkForm.invalid" (click)="updateFileLink()">Link aktualisieren</button>
                    <button type="button" class="btn btn-secondary ml-2" (click)="cancelCurrentLink()">Abbrechen</button>
                </ng-container>

                <ng-container *ngIf="!currentLink">
                    <button type="button" class="btn btn-primary"
                            (click)="createLink()"
                            [attr.data-right]="'Filelink erstellen'"
                            *ngIf="userService.hasRight('FILESHARE_LINK_CREATE') && enableLinks && tab == TABS.FILELINKS && result.openFile?.create_UID == userService.currentUser.UID">Link erstellen</button>
                    <button type="button" class="btn btn-primary"
                            (click)="editFile()"
                            [attr.data-right]="'PDF bearbeiten'"
                            *ngIf="userService.hasRight('DOCUMENT_PDF_EDIT') && result.openFile?.create_UID == userService.currentUser.UID">Bearbeiten</button>
                    <button type="button" class="btn btn-primary"
                            (click)="downloadFile()"
                            [attr.data-right]="'PDF herunterladen'"
                            *ngIf="userService.hasRight('DOCUMENT_PDF_DOWNLOAD')">Download</button>
                    <button type="button" class="btn btn-secondary" (click)="fileModal?.hide()">Schließen</button>
                </ng-container>
            </div>
        </div>

        <div class="modal-content" *ngIf="viewMode == ViewMode.EDIT">
            <div class="modal-header state bg-info text-white">
                <h4 class="modal-title">Dateiinformationen</h4>
            </div>
            <div class="modal-body" >
                <form class="form" #editForm="ngForm">
                    <ng-container *ngFor="let field of myAppConfig.fileFields">
                        <div class="form-group" *ngIf="field.editable == true">
                            <label>{{field.name}}</label>
                            <input type="text" class="form-control" name="{{field.key}}" autocomplete="off" [(ngModel)]="result.openFile[field.key]" [required]="field.required == true">
                        </div>
                    </ng-container>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" [disabled]="editForm.invalid" (click)="updateFile()">Aktualisieren</button>
                <button type="button" class="btn btn-secondary" (click)="cancelEditFile()">Abbrechen</button>
            </div>
        </div>
    </div>
</div>

