import {Component} from '@angular/core';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrl: './terms.component.scss',
    standalone: true
})
export class TermsComponent {

}
