<div class="row">
    <div class="col-md-4">
        <ul class="list-group mb-3">
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-building text-primary mr-2"></i>
                    {{ stats.clients }} <span class="fw-light text-muted">Mandanten</span>
                </div>
            </li>
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-store text-info mr-2"></i>
                    {{ stats.locations }} <span class="fw-light text-muted">Objekte</span>
                </div>
            </li>
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-users text-success mr-2"></i>
                    {{ stats.users }} <span class="fw-light text-muted">Benutzer</span>
                </div>
            </li>
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-file-invoice text-danger mr-2"></i>
                    {{ stats.documents }} <span class="fw-light text-muted">Dokumente</span>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-md-4">
        <ul class="list-group mb-3">
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-tasks text-warning mr-2"></i>
                    {{ stats.tasks }} <span class="fw-light text-muted">Aufgaben</span>
                </div>
            </li>
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-calendar text-secondary mr-2"></i>
                    {{ stats.taskdates }} <span class="fw-light text-muted">Termine</span>
                </div>
            </li>
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-spell-check text-primary mr-2"></i>
                    {{ stats.scanned_pages }} <span class="fw-light text-muted">Gescannte Seiten</span>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-md-4">
        <div class="card mb-3" *ngIf="stats.server">
            <div class="card-body p-3 clearfix">
                <h4>Server</h4>
                <dl class="row mt-2 mb-0">
                    <dt class="col-sm-6">CPU</dt>
                    <dd class="col-sm-6 mb-0">{{ stats.server.cpu.load }} %</dd>

                    <dt class="col-sm-6">Speicher</dt>
                    <dd class="col-sm-6 mb-0">{{ stats.server.mem.current }} / {{ stats.server.mem.total }} MB
                        ({{ stats.server.mem.load }}%)
                    </dd>

                    <dt class="col-sm-6">Festplatte</dt>
                    <dd class="col-sm-6 mb-0">{{ stats.server.disk.current }} / {{ stats.server.disk.total }} GB
                        ({{ stats.server.disk.used }}%)
                    </dd>

                    <dt class="col-sm-6">Uptime</dt>
                    <dd class="col-sm-6 mb-0">{{ stats.server.uptime.days }} Tage, {{ stats.server.uptime.hours }}
                        Stunden, {{ stats.server.uptime.minutes }} Minuten
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</div>

        <!--<h4>Zur Bearbeitung</h4>
        <ul class="list-group mb-3">
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-file-alt text-primary mr-2"></i>
                    {{ stats.incomplete_documents }} <span class="fw-light text-muted">Ungeprüfte OCR Texte</span>
                </div>
            </li>
            <li class="list-group-item">
                <div class="h5 mb-0">
                    <i class="fas fa-file-alt text-info mr-2"></i>
                    {{ stats.consulting_documents }} <span class="fw-light text-muted">Angefragtes Consulting</span>
                </div>
            </li>
        </ul>-->


<div class="row">
    <div class="col-md-6">
        <div class="card mb-3">
            <div class="card-header">
                <i class="fas fa-chart-line mr-2"></i>Aktivität der letzten 30 Tage
            </div>
            <div class="body p-3" style="height: 382px">
                <canvas baseChart
                        [data]="lineChartData"
                        [options]="lineChartOptions"
                        [type]="lineChartType"
                >
                </canvas>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card mb-3">
            <div class="card-header">
                <i class="fas fa-map mr-2"></i>Karte
            </div>
            <div class="card-body">
                <app-map #mapComponent [ID]="'locations'" [height]="'350px'"/>
            </div>
        </div>
    </div>
</div>

<div class="card mb-3">
    <div class="card-header">
        <i class="fas fa-building mr-2"></i>Mandanten ({{ clients.length }})
        <label class="badge bg-danger ml-2">Inaktiv</label>
        <label class="badge bg-warning ml-2">Demo</label>
    </div>
    <div class="card-body">
        <button type="button" class="btn btn-primary btn-sm mb-3" (click)="createClient()">Mandant anlegen</button>

        <div class="table-responsive">
            <table class="table table-sm table-hover table-clients mb-0">
                <thead>
                <tr>
                    <th class="border-right"></th>
                    <th colspan="2" class="text-center border-right">Vertrag</th>
                    <th colspan="2" class="text-center border-right">Objekte</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th class="border-right">Name</th>
                    <th>Beginn</th>
                    <th class="border-right">Ende</th>
                    <th>Freigegeben</th>
                    <th class="border-right">Verwendet</th>
                    <th>Benutzer</th>
                    <th>Dokumente</th>
                    <th>Speicher (MB)</th>
                    <th>Aufgaben</th>
                    <th>Aktiv</th>
                    <th>Demo</th>
                    <th>Erstellt am</th>
                    <th>Letzte Aktivität</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let client of clients"
                    (click)="openClient(client.CLID)"
                    [class.bg-danger]="!client.active"
                    [class.bg-warning]="client.demo">
                    <td class="border-right">{{ client.name }}</td>
                    <td>{{ client.contract_start | date: 'dd.MM.yyyy' }}</td>
                    <td class="border-right">
                        {{ client.contract_end | date: 'dd.MM.yyyy' }}
                        <span *ngIf="!client.contract_end" class="text-muted">Ohne</span>
                    </td>
                    <td>{{ client.number_locations }}</td>
                    <td class="border-right">{{ client.used_locations }}</td>
                    <td>{{ client.users }}</td>
                    <td>{{ client.documents }}</td>
                    <td>{{ client.storage | number: '1.0-0' }}</td>
                    <td>{{ client.tasks }}</td>
                    <td>{{ client.active | yesNo }}</td>
                    <td>{{ client.demo | yesNo }}</td>
                    <td>{{ client.created | date: 'dd.MM.yyyy' }}</td>
                    <td>{{ client.last_activity | date: 'dd.MM.yyyy, HH:mm' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
