<div class="card">
    <div class="card-header">
        <i class="fas fa-business-time mr-2"></i>Termine ({{ taskDates.length }})
    </div>
    <div class="card-body">
        <p class="text-muted">Hier sehen Sie unerledigte sowie zukünftige Termine zu den erstellten Aufgaben.</p>

        <div class="input-group" style="width: fit-content">
            <div class="input-group-prepend">
                <span class="input-group-text">Zeitraum Terminvorschau</span>
            </div>
            <input type="number" class="form-control" min="1" max="12" [(ngModel)]="taskDateFilter.number" (ngModelChange)="listTaskDates()">
            <select class="form-control" [(ngModel)]="taskDateFilter.interval" (ngModelChange)="listTaskDates()" style="width: 80px">
                <option value="week">Woche(n)</option>
                <option value="month">Monat(e)</option>
                <option value="year">Jahr(e)</option>
            </select>
        </div>

        <div class="row my-3">
            <div class="col"><div class="bg-warning text-dark text-center py-1">{{ inProgress }} In Bearbeitung</div></div>
            <div class="col"><div class="bg-danger text-center py-1">{{ open }} Offen</div></div>
            <div class="col"><div class="bg-secondary text-dark text-center py-1">{{ wait }} Warten</div></div>
            <div class="col"><div class="bg-dark text-center py-1">{{ generated }} Zukünftig</div></div>
        </div>

        <div class="text-muted mt-2" *ngIf="taskDates.length == 0">Es wurden keine Termin im gewählten Zeitraum gefunden!</div>

        <div class="table-responsive">
            <table class="table table-sm table-hover mb-0" *ngIf="taskDates.length > 0">
                <thead>
                <tr>
                    <th style="width: 150px">Status</th>
                    <th style="width: 100px">Start</th>
                    <th style="width: 100px">Fälligkeit</th>
                    <th>Info</th>
                    <th>Status Info</th>
                    <th>Aufgabe</th>
                    <th>Dokument</th>
                    <th>Abteilung</th>
                    <th>Art</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let taskDate of taskDates"
                    (click)="openTaskDate(taskDate)"
                    [ngClass]="{'bg-overdue-open': taskDate.overdue && taskDate.status == 'open', 'bg-overdue-in-progress': taskDate.overdue && taskDate.status == 'in_progress'}" style="cursor: pointer">
                    <td>
                        <i class="fas fa-circle" [ngClass]="{'text-success': taskDate.status == 'done', 'text-warning': taskDate.status == 'in_progress', 'text-danger': taskDate.status == 'open', 'text-secondary': taskDate.status == 'wait'}"></i>
                        {{ taskDate.status | taskStatus }}
                    </td>
                    <td>
                        {{ taskDate.start_date | date: 'dd.MM.yyyy' }}
                        <span class="text-muted" *ngIf="!taskDate.start_date">Nicht gesetzt</span>
                    </td>
                    <td>
                        <i class="fas fa-exclamation-triangle" style="color: #ff9800" *ngIf="taskDate.overdue" tooltip="Die Erledigung ist überfällig"></i>
                        {{ taskDate.end_date | date: 'dd.MM.yyyy' }}
                    </td>
                    <td>
                        {{ taskDate.description }}
                        <span class="text-muted" *ngIf="!taskDate.description">Nicht gesetzt</span>
                    </td>
                    <td>
                        {{ taskDate.status_info }}
                        <span class="text-muted" *ngIf="!taskDate.status_info">Nicht gesetzt</span>
                    </td>
                    <td>{{ taskDate.taskTitle }}</td>
                    <td>{{ taskDate.documentName }}</td>
                    <td>{{ taskDate.department }}</td>
                    <td>{{ taskDate.type | taskType }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
