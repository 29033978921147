<div class="fw-tags mb-3" [attr.data-right]="'Tags sehen'" *ngIf="userService.hasRight('TAGS_VIEW')">
    <label>Tags</label>
    <div class="text-muted" *ngIf="tags.length == 0">Keine Tags erstellt</div>

    <div>
        <span class="badge fw-tag" [ngClass]="{'fw-tag-viewer': !config.editable}" *ngFor="let tag of tags" (click)="searchTag(tag)">
            {{ tag.name }}<i class="fas fa-times fw-tag-delete-icon" (click)="deleteTag(tag)" *ngIf="config.editable"></i>
        </span>

        <input type="text" class="form-control form-control-sm fw-tags-input" placeholder="Neuer Tag" name="tag" #tagInput
               *ngIf="config.editable"
               [(ngModel)]="newTag.name"
               (keyup.enter)="addTag()"
               [disabled]="saving"
               [typeahead]="allTags"
               autocomplete="off"
        />
        <small class="text-muted ml-1" *ngIf="config.editable">(Vorschläge mit Pfeiltasten und Tab auswählen, Tag mit Enter bestätigen)</small>
    </div>
</div>
