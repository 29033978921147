<div class="table-responsive">
    <table class="table table-sm table-hover mb-0">
        <thead>
        <tr>
            <th>Aktion</th>
            <th>Name</th>
            <th>Pfad</th>
            <th>Größe (MB)</th>
            <th>Hochgeladen am</th>
            <th>OCR Status</th>
            <th>OCR Zeichen</th>
            <th>OCR überprüft</th>
            <th>Aufgaben fertig erstellt</th>
            <th>Seiten</th>
            <th>Gelöscht</th>
            <th>Aktiv</th>
            <th>Digitalisierung</th>
            <th>Consulting</th>
            <th>Consulting abgeschlossen</th>
            <th>Kommentar</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let document of documents">
            <td>
                <div class="d-grid gap-2">
                    <button type="button" class="btn btn-secondary btn-xs"
                            *ngIf="document.file_exists"
                            [disabled]="waiting !== null"
                            (click)="updatePagesAndOCR(document)"
                            [class.btn-warning]="document._hovered === true"
                            (mouseout)="document._hovered=false"
                            (mouseover)="document._hovered=true">
                        <ng-container *ngIf="waiting !== document.DID">Update</ng-container>
                        <i class="fa-solid fa-spinner fa-spin" *ngIf="waiting === document.DID"></i></button>
                    <button type="button" class="btn btn-primary btn-xs"
                            *ngIf="document.file_exists"
                            (click)="openModal(document)">Show OCR text</button>
                </div>

            </td>
            <td>
                <a [routerLink]="[]" (click)="getFile(document.FID)" *ngIf="document.file_exists">{{ document.name }}</a>
                <span *ngIf="!document.file_exists">
                    {{ document.name }}
                </span>
            </td>
            <td>{{ CLID }}/{{ document.path }}{{ document.fullname }}</td>
            <td>{{ document.size / 1024 / 1024 | number: '1.2-2' }}</td>
            <td>{{ document.uploaded | date: "dd.MM.yyyy HH:mm" }}</td>
            <td>{{ document.ocr_status }}</td>
            <td>{{ document.ocr_char_length }}</td>
            <td>{{ document.ocr_checked | yesNo }}</td>
            <td>{{ document.tasks_created | yesNo }}</td>
            <td>{{ document.pages }}</td>
            <td>{{ document.deleted | yesNo }}</td>
            <td>{{ document.active | yesNo }}</td>
            <td>{{ document.digitization | digitization }}</td>
            <td>{{ document.consulting | yesNo }}</td>
            <td>{{ document.consulting_complete | yesNo }}</td>
            <td>{{ document.comment }}</td>
        </tr>
        </tbody>
    </table>
</div>

<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-sizes-name1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">OCR Text</h4>
                <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="lgModal.hide()">
                    <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div [innerHTML]="modalText"></div>
            </div>
        </div>
    </div>
</div>
