<div class="card">
    <div class="card-header card-header-nr d-flex justify-content-between">
        <span>
            <i class="fas fa-tasks mr-2"></i>
            <ng-container *ngIf="viewMode == ViewMode.INSERT">Aufgabe anlegen</ng-container>
            <ng-container *ngIf="viewMode == ViewMode.EDIT">Aufgabe bearbeiten</ng-container>
            <ng-container *ngIf="viewMode == ViewMode.VIEW">Aufgabe</ng-container>
        </span>
    </div>
    <div class="card-header">
        <ng-container *ngIf="viewMode != ViewMode.INSERT">
            <h4 class="mb-3">Aufgabe "{{ form.value.title}}"</h4>
        </ng-container>

        <ul class="nav nav-tabs card-header-tabs mt-1">
            <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab == TABS.DETAILS}" href="" data-toggle="tab" (click)="setTab(TABS.DETAILS)">Details</a></li>
            <ng-container *ngIf="viewMode != ViewMode.INSERT">
                <li class="nav-item"><a class="nav-link" [ngClass]="{active: tab == TABS.DATES}" href="" data-toggle="tab" (click)="setTab(TABS.DATES)">
                    <i class="fas fa-exclamation-circle text-warning mr-2" *ngIf="document?.active == '0'"></i>Termine ({{tabCount.taskdates}})</a></li>
                <li class="nav-item" [attr.data-right]="'COMMENT_VIEW' | right" *ngIf="userService.hasRight('COMMENT_VIEW')">
                    <a class="nav-link" [ngClass]="{active: tab == TABS.COMMENTS}" href="" data-toggle="tab" (click)="setTab(TABS.COMMENTS)">Kommentare ({{tabCount.comments}})</a>
                </li>
                <li class="nav-item" [attr.data-right]="'TASK_ATTACHMENTS_VIEW' | right" *ngIf="userService.hasRight('TASK_ATTACHMENTS_VIEW')">
                    <a class="nav-link" [ngClass]="{active: tab == TABS.ATTACHMENTS}" href="" data-toggle="tab" (click)="setTab(TABS.ATTACHMENTS)">Anhänge ({{tabCount.attachments}})</a>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="card-body">
        <div *ngIf="tab == TABS.DETAILS" role="tabpanel" class="tab-pane active">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group" *ngIf="viewMode != ViewMode.INSERT">
                            <label>Erstellt</label>
                            <p>{{ form.value.created | date: "dd.MM.yyyy HH:mm" }}</p>
                        </div>
                        <div class="form-group">
                            <label>Aufgabenthema</label>
                            <ng-container *ngIf="viewMode != ViewMode.VIEW">
                                <select class="form-control" formControlName="TOID">
                                    <option value="null" disabled selected style="display: none;">Bitte auswählen</option>
                                    <option *ngFor="let topic of topics" [ngValue]="topic.TOID">
                                        {{ topic.name }}
                                    </option>
                                </select>
                            </ng-container>
                            <p *ngIf="task && viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="task.tasktopic">{{ task.tasktopic }}</ng-container>
                                <span class="text-muted" *ngIf="!task.tasktopic">Nicht gesetzt</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Aufgabentyp</label>
                            <ng-container *ngIf="viewMode != ViewMode.VIEW">
                                <select class="form-control" formControlName="TTID">
                                    <option value="null" disabled selected style="display: none;">Bitte auswählen</option>
                                    <option *ngFor="let taskType of taskTypes" [ngValue]="taskType.TTID">
                                        {{ taskType.name }}
                                    </option>
                                </select>
                            </ng-container>
                            <p *ngIf="task && viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="task.tasktype">{{ task.tasktype }}</ng-container>
                                <span class="text-muted" *ngIf="!task.tasktype">Nicht gesetzt</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Art</label>
                            <ng-container *ngIf="viewMode != ViewMode.VIEW">
                                <select class="form-control" formControlName="type">
                                    <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                    <option value="singular">Einmalig</option>
                                    <option value="recurring">Wiederkehrend</option>
                                </select>
                            </ng-container>
                            <ng-container *ngIf="viewMode == ViewMode.VIEW">
                                <p>
                                    <ng-container *ngIf="form.value.type">{{ form.value.type | taskType }}</ng-container>
                                    <span class="text-muted" *ngIf="!form.value.type">Nicht gesetzt</span>
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Abteilung</label>
                            <ng-container *ngIf="viewMode != ViewMode.VIEW">
                                <select class="form-control" formControlName="DPID">
                                    <option value="null" disabled selected style="display: none;">Bitte auswählen</option>
                                    <option *ngFor="let department of departments" [ngValue]="department.DPID">
                                        {{ department.name }} ({{ department.user }})
                                    </option>
                                </select>
                                <small class="form-text text-muted">
                                    Abteilung, die für die Erledigung der Aufgabe zuständig ist.
                                </small>
                            </ng-container>
                            <p *ngIf="task && viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="task.department">{{ task.department }}</ng-container>
                                <span class="text-muted" *ngIf="!task.department">Nicht gesetzt</span>
                            </p>
                        </div>
                        <div class="form-group" *ngIf="task">
                            <label>Verantwortlicher</label>
                            <p>
                                <ng-container *ngIf="task.responsible">{{ task.responsible }}</ng-container>
                                <span class="text-muted" *ngIf="!task.responsible">Nicht gesetzt</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Externe Bearbeitung</label>
                            <ng-container *ngIf="viewMode != ViewMode.VIEW">
                                <select class="form-control" formControlName="UID_external">
                                    <option value="null">Nein</option>
                                    <option *ngFor="let external of externals" [ngValue]="external.UID">
                                        {{ external.firstname }} {{ external.lastname }}
                                    </option>
                                </select>
                            </ng-container>
                            <p *ngIf="task && viewMode == ViewMode.VIEW">{{ task.external ? task.external : 'Nein' }}</p>
                        </div>
                        <div class="form-group">
                            <ng-container *ngIf="viewMode != ViewMode.VIEW">
                                <div [hidden]="form.value.type !== 'singular'">
                                    <label class="mt-2">Fälligkeit</label>
                                    <input type="date" class="form-control" formControlName="due_date">

                                    <ng-container *ngIf="attributes?.length > 0">
                                        <label class="mt-2">Von Attributen übernehmen</label>
                                        <table class="table table-sm table-hover table-attributes">
                                            <tbody>
                                            <tr *ngFor="let attribute of attributes" (click)="setDueDate(attribute)">
                                                <td>{{attribute.name}}</td>
                                                <td>{{attribute.value | date: "dd.MM.yyyy"}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <small class="form-text text-muted">
                                            Auf ein Attribut klicken, um dessen Datum zu übernehmen
                                        </small>
                                    </ng-container>
                                </div>
                                <div [hidden]="form.value.type !== 'recurring'">
                                    <label class="mt-2">Beginn</label>
                                    <input type="date" class="form-control" formControlName="recurring_start">

                                    <ng-container *ngIf="attributes?.length > 0">
                                        <label class="mt-2">Von Attributen übernehmen</label>
                                        <table class="table table-sm table-hover table-attributes">
                                            <tbody>
                                            <tr *ngFor="let attribute of attributes" (click)="setRecurringStart(attribute)">
                                                <td>{{attribute.name}}</td>
                                                <td>{{attribute.value | date: "dd.MM.yyyy"}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <small class="form-text text-muted">
                                            Auf ein Attribut klicken, um dessen Datum zu übernehmen
                                        </small>
                                    </ng-container>

                                    <label class="mt-2">Intervall</label>
                                    <select class="form-control mb-2" formControlName="interval">
                                        <option value="null" disabled selected style="display: none;">Bitte auswählen</option>
                                        <option value="daily">Täglich</option>
                                        <option value="weekly">Wöchentlich</option>
                                        <option value="monthly">Monatlich</option>
                                        <option value="yearly">Jährlich</option>
                                    </select>

                                    <div class="input-group" *ngIf="form.value.interval == 'weekly'">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Jeden</span>
                                        </div>
                                        <input type="number" min="1" max="6" class="form-control" formControlName="recurring_step">
                                        <select class="form-control" formControlName="weekly_day">
                                            <option value="null" disabled selected style="display: none;">Bitte auswählen</option>
                                            <option value="1">Montag</option>
                                            <option value="2">Dienstag</option>
                                            <option value="3">Mittwoch</option>
                                            <option value="4">Donnerstag</option>
                                            <option value="5">Freitag</option>
                                            <option value="6">Samstag</option>
                                            <option value="0">Sonntag</option>
                                        </select>
                                    </div>
                                    <div class="input-group" *ngIf="form.value.interval == 'monthly'">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Jedes</span>
                                        </div>
                                        <input type="number" min="1" max="6" class="form-control" formControlName="recurring_step">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Monat, am</span>
                                        </div>
                                        <input type="number" min="0" max="31" class="form-control" formControlName="monthly_yearly_date" placeholder="Tag">
                                    </div>
                                    <div class="input-group" *ngIf="form.value.interval == 'yearly'">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Jedes</span>
                                        </div>
                                        <input type="number" min="1" max="6" class="form-control" formControlName="recurring_step">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Jahr, am</span>
                                        </div>
                                        <input type="number" min="0" max="31" class="form-control" formControlName="monthly_yearly_date" placeholder="Tag">
                                        <input type="number" min="1" max="12" class="form-control" formControlName="yearly_month" placeholder="Monat">
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="viewMode == ViewMode.VIEW">
                                <ng-container *ngIf="form.value.type == 'singular'">
                                    <label>Fälligkeit</label>
                                    <p>
                                        <ng-container *ngIf="form.value.due_date">{{ form.value.due_date | date: 'dd.MM.yyyy' }}</ng-container>
                                        <span class="text-muted" *ngIf="!form.value.due_date"><i style="color: #ff9800" class="fa fa-exclamation-triangle"></i> Nicht gesetzt</span>
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="form.value.type == 'recurring'">
                                    <label>Beginn</label>
                                    <p>{{ form.value.recurring_start | date: 'dd.MM.yyyy' }}</p>

                                    <label>Intervall</label>

                                    <ng-container *ngIf="form.value.interval == 'daily'">
                                        <p>Jeden Tag</p>
                                    </ng-container>
                                    <ng-container *ngIf="form.value.interval == 'weekly'">
                                        <p>Jede <ng-container *ngIf="form.value.recurring_step > 1">{{ form.value.recurring_step }}.</ng-container> Woche am {{ form.value.weekly_day | weekday }}</p>
                                    </ng-container>
                                    <ng-container *ngIf="form.value.interval == 'monthly'">
                                        <p>Jedes <ng-container *ngIf="form.value.recurring_step > 1">{{ form.value.recurring_step }}.</ng-container> Monat am {{ form.value.monthly_yearly_date }}.</p>
                                    </ng-container>
                                    <ng-container *ngIf="form.value.interval == 'yearly'">
                                        <p>Jedes <ng-container *ngIf="form.value.recurring_step > 1">{{ form.value.recurring_step }}.</ng-container> Jahr am {{ form.value.monthly_yearly_date }}. {{ form.value.yearly_month | month }}</p>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="document">
                        <div class="form-group">
                            <label>Unternehmen</label>
                            <p>
                                <a [attr.data-right]="'COMPANY_VIEW' | right" *ngIf="userService.hasRight('COMPANY_VIEW')" [routerLink]="[]" (click)="routerHelperService.openCompany(document.CID)">{{ document.company }}</a>
                                <span *ngIf="!userService.hasRight('COMPANY_VIEW')">{{ document.company }}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>{{ 'LOCATION.LOCATION' | translate }}</label>
                            <p>
                                <a [attr.data-right]="'LOCATION_VIEW' | right" *ngIf="userService.hasRight('LOCATION_VIEW')" [routerLink]="[]" (click)="routerHelperService.openLocation(document.LID)">{{ document.location }}</a>
                                <span *ngIf="!userService.hasRight('LOCATION_VIEW')">{{ document.location }}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label>Dokument</label>
                            <p>
                                <i class="fas fa-exclamation-circle text-warning mr-1" *ngIf="document?.active == '0'" tooltip="Inaktiv"></i>
                                <a [attr.data-right]="'DOCUMENT_VIEW' | right" *ngIf="userService.hasRight('DOCUMENT_VIEW')" [routerLink]="[]" (click)="cancel()">{{ document.name }}</a>
                                <span *ngIf="!userService.hasRight('DOCUMENT_VIEW')">{{ document.name }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label>Nummer</label>
                            <input type="text" class="form-control" formControlName="number" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.number }}</p>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="form-group">
                            <label>Titel</label>
                            <input type="text" class="form-control" formControlName="title" *ngIf="viewMode != ViewMode.VIEW">
                            <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.title }}</p>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Beschreibung</label>
                    <textarea class="form-control" formControlName="text" *ngIf="viewMode != ViewMode.VIEW" rows="8"></textarea>
                    <p *ngIf="viewMode == ViewMode.VIEW" [innerHTML]="form.value.text | textarea"></p>
                </div>

                <div class="form-group mb-3" *ngIf="viewMode == ViewMode.INSERT">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" formControlName="sendMail"> Den Verantwortlichen per Mail informieren
                    </div>
                </div>

                <app-tag *ngIf="viewMode != ViewMode.INSERT" [config]="tagConfig"/>
            </form>

            <ng-container *ngIf="viewMode == ViewMode.VIEW">
                <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'TASK_EDIT' | right" *ngIf="userService.hasRight('TASK_EDIT')">Bearbeiten</button>
                <button type="button" class="btn btn-success" (click)="exportTask()" [attr.data-right]="'TASK_EXPORT' | right" *ngIf="userService.hasRight('TASK_EXPORT')">Export</button>
                <button type="button" class="btn btn-danger float-right" (click)="delete(form.value.TID)" [attr.data-right]="'TASK_DELETE' | right" *ngIf="userService.hasRight('TASK_DELETE')">Löschen</button>
            </ng-container>

            <ng-container *ngIf="viewMode == ViewMode.EDIT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
            </ng-container>

            <ng-container *ngIf="viewMode == ViewMode.INSERT">
                <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
            </ng-container>
        </div>

        <div *ngIf="tab == TABS.DATES" role="tabpanel" class="tab-pane active">
            <app-task-date-own [TID]="task.TID" [ownTask]="task.responsible_UID == userService.currentUID()"/>
        </div>

        <div *ngIf="tab == TABS.COMMENTS" role="tabpanel" class="tab-pane active">
            <app-comment [TID]="task.TID" (reloadEvent)="getTabCount()"/>
        </div>

        <div *ngIf="tab == TABS.ATTACHMENTS" role="tabpanel" class="tab-pane active">
            <app-file
                [viewReadonly]="!userService.hasRight('TASK_ATTACHMENTS_EDIT')"
                [doctype]="'task'"
                [fkId]="task.TID"
                [fkName]="'TID'"
                [fkTable]="'task'"
                [viewThumbnails]="true"
                [viewThumbnailsSize]="'50px'"
                [viewThumbnailsTilesSize]="'100px'"
                [uploadSelectMultiple]="true"
                [uploadMaxFilesize]="300"
                [viewEditModeFlipImage]="true"
                [viewLayout]="FileViewLayout.TABLE"
                [viewNoFilesText]="'Es wurden noch keine Anhänge hochgeladen.'"
                [showViewLayoutSwitcher]="true"
                (reloadEvent)="getTabCount()"
                [showDragAndDrop]="true"
            />
        </div>
    </div>
</div>
