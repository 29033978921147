<div class="card">
    <div class="card-header">
        <i class="fas fa-tag mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.INSERT">Aufgabentyp anlegen</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Aufgabentyp bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Aufgabentyp</ng-container>
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                <p *ngIf="viewMode == ViewMode.VIEW">{{ form.value.name }}</p>
            </div>
        </form>

        <ng-container *ngIf="viewMode == ViewMode.VIEW">
            <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">Bearbeiten</button>
            <button type="button" class="btn btn-secondary" (click)="cancel()">Zurück</button>
            <button type="button" class="btn btn-danger float-right"
                    [attr.data-right]="'MASTER_DATA_EDIT' | right"
                    *ngIf="userService.hasRight('MASTER_DATA_EDIT')"
                    (click)="delete()"
                    [disabled]="tasktype?.used > 0"
                    [tooltip]="tasktype?.used > 0 ? 'Dieser Aufgabentyp wird noch verwendet und kann daher nicht gelöscht werden.' : ''"
            >Löschen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.INSERT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
        </ng-container>

    </div>
</div>
