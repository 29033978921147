import {Component, OnInit, inject} from '@angular/core';
import {ViewMode, UrlData} from '../../../interfaces';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CryptoService} from '../../../global/services/crypto.service';
import {Router} from '@angular/router';
import {DataService} from '../../../global/services/data.service';
import {UserService} from '../../../global/services/user.service';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {NgIf, NgFor} from '@angular/common';
import {RightPipe} from '../../../global/pipes/right.pipe';
import {TooltipDirective} from "ngx-bootstrap/tooltip";

@Component({
    selector: 'app-role-detail',
    templateUrl: './role-detail.component.html',
    styleUrl: './role-detail.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, RightPipe, TooltipDirective]
})
export class RoleDetailComponent implements OnInit {

    userService = inject(UserService);
    private cryptoService = inject(CryptoService);
    private router = inject(Router);
    private fb = inject(UntypedFormBuilder);
    private dataService = inject(DataService);
    private toastrService = inject(ToastrService);

    readonly ViewMode = ViewMode;

    form!: UntypedFormGroup;
    id!: string;
    viewMode!: ViewMode;
    role: any;
    allRights!: any[];
    rights!: any[];
    rightGroups: any[] = [];
    currentGroup: any;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.id = urlData.ID;
        this.viewMode = urlData.viewMode;

        this.form = this.fb.group({
            id: [null],
            name: ['', Validators.required],
            description: [null],
        });

        if (this.id !== '0') {
            this.getRole();
        }
    }

    getRole(): void {
        this.dataService.request('Role/get', {
            id: this.id
        }).subscribe(response => {
            this.role = response.role;
            this.role.used = parseInt(this.role.used, 10);

            this.allRights = response.rights;
            this.allRights.forEach(right => {
                right.granted = right.granted === '1';

                const group = this.rightGroups.find(group => group.name === right.group);

                if (group) {
                    group.count++;
                    if (right.granted) group.granted++;
                } else {
                    this.rightGroups.push(
                        {
                            name: right.group,
                            count: 1,
                            granted: right.granted ? 1 : 0
                        }
                    )
                }
            })
            this.setCurrentGroup(this.rightGroups[0]);

            this.patchForm();
        });
    }

    patchForm(): void {
        this.form.patchValue({
            id: this.role.id,
            name: this.role.name,
            description: this.role.description,
        });
    }

    save(): void {
        this.dataService.request('Role/save', this.form.getRawValue()).subscribe(() => {
            this.router.navigate(['/app/role/list']);
        });
    }

    update(): void {
        this.dataService.request('Role/update', this.form.getRawValue()).subscribe(() => {
            this.setMode(ViewMode.VIEW);
        });
    }

    cancel(): void {
        this.router.navigate(['/app/role/list']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Die Benutzerrolle wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('Role/delete', {
                    id: this.id
                }).subscribe(() => {
                    this.cancel();
                });
            }
        });
    }

    // roles

    setCurrentGroup(group: any) {
        this.currentGroup = group;

        this.rights = this.allRights.filter(right => right.group === this.currentGroup.name);
    }

    saveRights() {
        // collect IDs of all rights that were granted
        const IDs = this.allRights.filter(right => right.granted).map(right => right.id);

        this.dataService.request('Role/saveRights', {
            IDs: IDs,
            id_role: this.role.id,
        }).subscribe(() => {
            this.toastrService.success('Die Rechte wurden erfolgreich aktualisiert');
        });
    }

    toggle(granted: any) {
        this.rights.forEach(right => right.granted = granted);
        this.updateGroupGrantedCount();
    }

    updateGroupGrantedCount() {
        this.currentGroup.granted = 0;
        this.rights.forEach(right => {
            if (right.granted) this.currentGroup.granted++
        });
    }

    copy() {
        Swal.fire({
            title: 'Sicher?',
            text: 'Die Benutzerrolle wird kopiert.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('Role/copy', {
                    id_role: this.role.id,
                }).subscribe((response) => {
                    this.toastrService.success('Die Rolle wurde erfolgreich kopiert');
                    const urlData: UrlData = {
                        ID: response.id,
                    };
                    this.router.navigateByUrl('/app/role/list', {skipLocationChange: true}).then(() => {
                        this.router.navigate(['/app/role/detail/', this.cryptoService.encrypt(urlData)]);
                    });
                    //this.router.navigate(['/app/role/detail/', this.cryptoService.encrypt(JSON.stringify(data))]);
                });
            }
        });
    }
}
