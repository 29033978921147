import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {DataService} from '../../../global/services/data.service';
import {FormService} from '../../../global/services/form.service';
import {UserService} from '../../../global/services/user.service';
import {validateDate} from '../../../global/validators/validateDate';
import dayjs from 'dayjs';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {NgIf, NgFor, DatePipe} from '@angular/common';
import {ReminderIntervalPipe} from '../../../global/pipes/reminder-interval.pipe';
import {WeekdayPipe} from '../../../global/pipes/weekday.pipe';
import {RightPipe} from '../../../global/pipes/right.pipe';

@Component({
    selector: 'app-task-date-reminder',
    templateUrl: './task-date-reminder.component.html',
    styleUrl: './task-date-reminder.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, DatePipe, ReminderIntervalPipe, WeekdayPipe, RightPipe]
})
export class TaskDateReminderComponent implements OnInit, OnDestroy {

    formService = inject(FormService);
    userService = inject(UserService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);

    @Input() TDID: any;

    @Output() reloadEvent = new EventEmitter();

    taskdate: any;
    reminders!: any[];
    remindersForm!: UntypedFormGroup;

    private reminderChangesList: Subscription[] = [];

    ngOnInit(): void {
        this.remindersForm = this.fb.group({
            reminders: this.fb.array([])
        });

        this.listAll();
    }

    ngOnDestroy(): void {
        this.reminderChangesList.forEach(element => {
            element.unsubscribe();
        });
    }

    listAll(): void {
        this.formService.getFormArray(this.remindersForm, 'reminders').clear();
        this.dataService.request('TaskDateReminder/listByTaskDate', {TDID: this.TDID}).subscribe(response => {
            this.taskdate = response.taskdate;
            this.reminders = response.reminders;

            this.reminders.forEach(reminder => {
                this.add(reminder);
            });
        });
    }

    add(data: any = {}): void {
        const newGroup = this.fb.group({
            TDRID: [data.TDRID || null],
            TDID: [data.TDID || null],
            interval: [data.interval || 'custom'],
            date: [data.date || null, [validateDate]],
            weekly_day: [data.weekly_day || null],
            monthly_yearly_date: [data.monthly_yearly_date || null],
            yearly_month: [data.yearly_month || null],
            _mode: [data._mode || 'view'],
        });

        this.reminderChangesList.push(newGroup.get('interval').valueChanges.subscribe(interval => {
            this.setupGroup(newGroup, interval);
        }));
        this.reminderChangesList.push(newGroup.get('weekly_day').valueChanges.subscribe(value => {
            this.updateDate(newGroup, 'weekly', value);
        }));
        this.reminderChangesList.push(newGroup.get('monthly_yearly_date').valueChanges.subscribe(value => {
            // INFO: we don't use updateDate() here because the logic depends on the set interval
            if (!value || (newGroup.value.interval === 'yearly' && !newGroup.value.yearly_month)) {
                newGroup.patchValue({date: null});
                return;
            }

            let newDate = null;
            if (newGroup.get('interval')?.value === 'monthly') {
                newDate = dayjs().date(value);
                if (newDate.isBefore(dayjs(), 'day')) {
                    newDate = newDate.add(1, 'month');
                }
            } else if (newGroup.get('interval')?.value === 'yearly') {
                newDate = dayjs().date(value).month(newGroup.value.yearly_month - 1);
                if (newDate.isBefore(dayjs(), 'day')) {
                    newDate = newDate.add(1, 'year');
                }
            }

            if (newDate) {
                newGroup.patchValue({date: newDate.format('YYYY-MM-DD')});
            }
        }));
        this.reminderChangesList.push(newGroup.get('yearly_month').valueChanges.subscribe(value => {
            this.updateDate(newGroup, 'yearly', value);
        }));
        this.setupGroup(newGroup, newGroup.value.interval);

        this.formService.getFormArray(this.remindersForm, 'reminders').push(newGroup);
    }

    setupGroup(group: UntypedFormGroup, interval: string): void {
        this.formService.clearValidators(group, ['date', 'weekly_day', 'monthly_yearly_date', 'yearly_month']);

        if (interval === 'custom') {
            this.formService.setValidators(group, ['date'], [Validators.required]);
        } else {
            if (this.taskdate.end_date) {
                if (interval === 'week_earlier') {
                    group.patchValue({date: dayjs(this.taskdate.end_date).subtract(1, 'week').format('YYYY-MM-DD')});
                }
                if (interval === 'month_earlier') {
                    group.patchValue({date: dayjs(this.taskdate.end_date).subtract(1, 'month').format('YYYY-MM-DD')});
                }
            }

            if (interval === 'daily') {
                this.formService.setValidators(group, ['weekly_day'], [Validators.required]);
                group.patchValue({date: dayjs().add(1, 'day').format('YYYY-MM-DD')});
            } else if (interval === 'weekly') {
                this.formService.setValidators(group, ['weekly_day'], [Validators.required]);
                this.updateDate(group, 'weekly', group.value.weekly_day);
            } else if (interval === 'monthly') {
                this.formService.setValidators(group, ['monthly_yearly_date'], [Validators.required]);
                this.updateDate(group, 'monthly', group.value.monthly_yearly_date);
            } else if (interval === 'yearly') {
                this.formService.setValidators(group, ['monthly_yearly_date'], [Validators.required]);
                this.formService.setValidators(group, ['yearly_month'], [Validators.required]);
                this.updateDate(group, 'yearly', group.value.yearly_month);
            }
        }
    }

    updateDate(group: any, interval: any, value: any): void {
        if (!value || (interval === 'yearly' && !group.value.monthly_yearly_date)) {
            group.patchValue({date: null});
            return;
        }

        let newDate = null;

        if (interval === 'weekly') {
            newDate = dayjs().day(parseInt(value));
            if (newDate.isBefore(dayjs(), 'day')) {
                newDate = newDate.add(1, 'week');
            }
        } else if (interval === 'monthly') {
            // set day of month - if in past add 1 month
            newDate = dayjs().date(value);
            if (newDate.isBefore(dayjs(), 'day')) {
                newDate = newDate.add(1, 'month');
            }
        } else if (interval === 'yearly') {
            // set day of month and year - if in past add 1 month
            newDate = dayjs().date(group.value.monthly_yearly_date).month(value - 1);
            if (newDate.isBefore(dayjs(), 'day')) {
                newDate = newDate.add(1, 'year');
            }
        }

        if (newDate) {
            group.patchValue({date: newDate.format('YYYY-MM-DD')});
        }
    }

    cancelCreate(index: number): void {
        this.formService.removeFormArrayItem(this.remindersForm, 'reminders', index);
    }

    edit(reminder: AbstractControl): void {
        reminder.patchValue({_mode: 'edit'});
    }

    cancelEdit(reminder: any): void {
        reminder.patchValue({_mode: 'view'});
    }

    save(reminder: AbstractControl): void {
        this.dataService.request('TaskDateReminder/save', {
            TDID: reminder.value.TDID,
            interval: reminder.value.interval,
            date: reminder.value.date,
            weekly_day: reminder.value.weekly_day,
            monthly_yearly_date: reminder.value.monthly_yearly_date,
            yearly_month: reminder.value.yearly_month,
        }).subscribe(() => {
            this.listAll();
            this.reloadEvent.emit();
        });
    }

    update(reminder: AbstractControl): void {
        this.dataService.request('TaskDateReminder/update', {
            TDRID: reminder.value.TDRID,
            TDID: reminder.value.TDID,
            interval: reminder.value.interval,
            date: reminder.value.date,
            weekly_day: reminder.value.weekly_day,
            monthly_yearly_date: reminder.value.monthly_yearly_date,
            yearly_month: reminder.value.yearly_month,
        }).subscribe(() => {
            this.listAll();
        });
    }

    delete(TDRID: string): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Die Erinnerung wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('TaskDateReminder/delete', {TDRID}).subscribe(() => {
                    this.listAll();
                    this.reloadEvent.emit();
                });
            }
        });
    }
}
