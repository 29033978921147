import {Component, Input, OnInit, inject} from '@angular/core';
import dayjs from 'dayjs';
import {DataService} from '../../services/data.service';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormService} from '../../services/form.service';
import {UserService} from '../../services/user.service';
import {validateDate} from '../../validators/validateDate';
import {NgIf, NgFor, DecimalPipe, DatePipe} from '@angular/common';
import {YesNoPipe} from '../../pipes/yes-no.pipe';
import {RightPipe} from '../../pipes/right.pipe';

@Component({
    selector: 'app-attributes',
    templateUrl: './attributes.component.html',
    styleUrl: './attributes.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, DecimalPipe, DatePipe, YesNoPipe, RightPipe]
})
export class AttributesComponent implements OnInit {

    userService = inject(UserService);
    formService = inject(FormService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);

    @Input() DID: any;
    @Input() LID: any;

    attributes: any[] = [];
    form!: UntypedFormGroup;

    ngOnInit(): void {
        this.form = this.fb.group({
            attributes: this.fb.array([])
        });

        this.listAttributes();
    }

    listAttributes(): void {
        if (this.DID) {
            this.dataService.request('Attribute/listByDocument', {DID: this.DID}).subscribe(response => {
                this.attributes = response.attributes;

                this.patchForm();
            });
        }

        if (this.LID) {
            this.dataService.request('Attribute/listByLocation', {LID: this.LID}).subscribe(response => {
                this.attributes = response.attributes;

                this.patchForm();
            });
        }
    }

    patchForm(): void {
        this.formService.getFormArray(this.form, 'attributes').clear();
        this.attributes.forEach(attribute => {
            this.formService.getFormArray(this.form, 'attributes').push(
                this.fb.group({
                    ATID: [attribute.ATID || null],
                    name: [attribute.name || null],
                    type: [attribute.type || null],
                    value_int: [attribute.value_int || null],
                    value_dec: [attribute.value_dec || null],
                    value_text: [attribute.value_text || null],
                    value_boolean: [attribute.value_boolean === '1' || null],
                    value_date: [attribute.type === 'date' && attribute.value_date ? dayjs(attribute.value_date).toDate() : null, validateDate],
                    _editing: [false]
                })
            );
        });
    }

    edit(attribute: AbstractControl): void {
        attribute.patchValue({
            _editing: true
        });
    }

    cancelEdit(attribute: AbstractControl): void {
        const originalAttribute = this.attributes.find(original => original.ATID === attribute.value.ATID);
        attribute.patchValue({
            value_int: originalAttribute.value_int,
            value_dec: originalAttribute.value_dec,
            value_text: originalAttribute.value_text,
            value_boolean: originalAttribute.value_boolean,
            value_date: originalAttribute.type === 'date' ? dayjs(originalAttribute.value_date).toDate() : null,
            _editing: false
        });
    }

    update(attribute: AbstractControl): void {
        this.dataService.request('Attribute/update', attribute.value).subscribe(() => {
            attribute.patchValue({
                _editing: false
            });
        });
    }

}
