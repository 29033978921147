<p class="bg-warning text-dark px-3 py-1" *ngIf="status != null">
    <span *ngIf="status == 'wait'">Es werden nur Aufgaben mit wartenden Terminen angezeigt!</span>
    <span *ngIf="status == 'open'">Es werden nur Aufgaben mit offenen Terminen angezeigt!</span>
    <span *ngIf="status == 'in_progress'">Es werden nur Aufgaben mit Terminen in Bearbeitung angezeigt!</span>
    <span *ngIf="status == 'done'">Es werden nur Aufgaben mit erledigten Terminen angezeigt!</span>
    <button type="button" class="btn btn-primary btn-sm ml-2" (click)="showAll()">Alle anzeigen</button>
</p>

<div class="form-group form-check">
    <input type="checkbox" class="form-check-input" [(ngModel)]="hideCompleted" (ngModelChange)="setupTable()">
    <label class="form-check-label">Einmalige erledigte Aufgaben ausblenden</label>
</div>
<app-table [config]="tableConfig"/>
