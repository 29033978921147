import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-errors',
    templateUrl: './errors.component.html',
    styleUrl: './errors.component.scss',
    imports: [RouterOutlet]
})
export class ErrorsComponent {

}
