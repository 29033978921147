<nav class="navbar navbar-expand-lg navbar-light sticky-top">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/brand/logo_small.png" width="20" height="20" class="d-inline-block mr-1" style="position: relative; top: -5px" alt="" loading="lazy">
            <span style="font-weight: 500; font-size: 1.5rem; color: black">Note<span style="color: #1f79d3">Radar</span></span>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/benefits"><i class="far fa-thumbs-up"></i>Vorteile</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/features"><i class="fas fa-cubes"></i>Funktionen</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="pricing" routerLink="/pricing"><i class="fas fa-euro-sign"></i>Preise</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="/contact"><i class="far fa-envelope"></i>Kontakt</a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto" *ngIf="router.url != '/login'">
                <div>
                    <button routerLink="/login" class="btn btn-outline-dark my-2 my-sm-0" type="submit">
                        <i class="fas fa-sign-in-alt"></i> Anmelden
                    </button>
                </div>
            </ul>
        </div>
    </div>
</nav>
