import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject} from '@angular/core';
import {TagConfig} from '../tag.interfaces';
import {TagService} from '../tag.service';
import {Subscription} from 'rxjs';
import {DataService} from '../../../services/data.service';
import {NgIf, NgFor, NgClass} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-tag-viewer',
    templateUrl: './tag-viewer.component.html',
    styleUrl: './tag-viewer.component.scss',
    imports: [NgIf, NgFor, NgClass, TranslateModule]
})
export class TagViewerComponent implements OnInit, OnDestroy {

    private dataService = inject(DataService);
    private tagService = inject(TagService);

    @Input() config!: TagConfig;
    @Output() tagClicked = new EventEmitter();
    @Output() found = new EventEmitter();

    tags: any[] = [];
    selected: any;

    private reload$!: Subscription;

    ngOnInit(): void {
        this.reload$ = this.tagService.reloadViewer.subscribe(() => this.listTags());
        this.listTags();
    }

    ngOnDestroy(): void {
        this.reload$.unsubscribe();
    }

    listTags(): void {
        this.dataService.request('Tag/listTags', {
            config: this.config
        }).subscribe(response => {
            this.tags = response.tags;
            this.found.emit(this.tags.length);

            if (this.config.selected) {
                const toSelect = this.tags.find((tag: any) => tag.name === this.config.selected);
                if (toSelect) {
                    this.select(toSelect);
                }
            }
        });
    }

    select(tag: any): void {
        this.selected = tag;
        this.tagClicked.emit(tag);
    }
}
