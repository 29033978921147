import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {UserService} from '../services/user.service';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

/**
 * Loads all rights for the data-right attributes
 */
export const RightsLoadedGuard: CanActivateFn = (route, state) => {

    const http = inject(HttpClient);
    const userService = inject(UserService);

    return http
        .post('api/api.php', {
            action: 'Role/listRightPairs',
            data: {},
            componentName: 'rights-loaded.guard.ts',
            methodName: 'canActivate()'
        })
        .pipe(
            map((response: any) => {
                userService.rights = response.rights;
                return true;
            })
        );

}
