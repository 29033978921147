<div class="card mb-3">
    <div class="card-header">
        <div style="margin-top: 4px; display: inline-block">
            <i class="fas fa-search mr-2"></i>
            Attributsuche
        </div>
        <button type="button" class="btn btn-sm btn-secondary float-right" (click)="export()" [disabled]="exporting || !form.value.FTID">Excel-Export</button>
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="form-group">
                <label>Dokumenttyp</label>
                <select class="form-control" formControlName="FTID">
                    <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                    <option *ngFor="let fileType of fileTypes" [ngValue]="fileType.FTID">
                        {{ fileType.name }}
                    </option>
                </select>
            </div>
        </form>

        <ng-container *ngIf="templates">
            <div class="form-check form-check-inline mb-1" *ngFor="let template of templates">
                <input class="form-check-input" type="checkbox" [(ngModel)]="columnFilter[template.name]">
                <span>{{ template.name }}</span>
            </div>
        </ng-container>

        <div class="table-responsive mt-1">
            <table class="table table-striped table-sm" *ngIf="documents">
                <thead>
                <tr>
                    <th></th>
                    <th>
                        <select class="form-control form-control-sm"
                                [(ngModel)]="CID"
                                (ngModelChange)="loadDocumentsWithAttributes()"
                                [class.fw-table-field-filtered]="CID != ''">
                            <option value="" selected>Alle</option>
                            <option *ngFor="let company of companies" value="{{company.CID}}">{{ company.name }}</option>
                        </select>
                    </th>
                    <th>
                        <select class="form-control form-control-sm"
                                [(ngModel)]="LID"
                                (ngModelChange)="loadDocumentsWithAttributes()"
                                [class.fw-table-field-filtered]="LID != ''">
                            <option value="" selected>Alle</option>
                            <option *ngFor="let location of locations" value="{{location.LID}}">{{ location.name }}</option>
                        </select>
                    </th>
                    <th *ngFor="let template of templates" [hidden]="!columnFilter[template.name]">
                        <input type="text" class="form-control form-control-sm"
                               *ngIf="template.type != 'boolean'"
                               [(ngModel)]="search[template.name]"
                               (keyup.enter)="loadDocumentsWithAttributes()"
                               [class.fw-table-field-filtered]="search[template.name] != ''">
                        <select class="form-control form-control-sm"
                                *ngIf="template.type == 'boolean'"
                                [(ngModel)]="search[template.name]"
                                (ngModelChange)="loadDocumentsWithAttributes()"
                                [class.fw-table-field-filtered]="search[template.name] != ''">
                            <option value="" selected>Alle</option>
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                        </select>
                    </th>
                </tr>
                <tr>
                    <th>Name</th>
                    <th>Unternehmen</th>
                    <th>{{'LOCATION.LOCATION' | translate}}</th>
                    <th *ngFor="let template of templates" [hidden]="!columnFilter[template.name]">
                        {{ template.name }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let document of documents">
                    <td>
                        <a [routerLink]="[]" (click)="routerHelperService.openDocument(document.DID)">{{ document.name }}</a>
                    </td>
                    <td>
                        <a [routerLink]="[]" (click)="routerHelperService.openCompany(document.CID)">{{ document.company }}</a>
                    </td>
                    <td>
                        <a [routerLink]="[]" (click)="routerHelperService.openLocation(document.LID)">{{ document.location }}</a>
                    </td>
                    <td *ngFor="let attribute of document.attributes" [hidden]="!columnFilter[attribute.name]">
                        <ng-container *ngIf="attribute.type == 'int'">
                            {{ attribute.value_int | number: '1.0' }}
                            <span class="text-muted" *ngIf="!attribute.value_int">Nicht gesetzt</span>
                        </ng-container>
                        <ng-container *ngIf="attribute.type == 'dec'">
                            {{ attribute.value_dec | number: '1.0-2' }}
                            <span class="text-muted" *ngIf="!attribute.value_dec">Nicht gesetzt</span>
                        </ng-container>
                        <ng-container *ngIf="attribute.type == 'date'">
                            {{ attribute.value_date | date: "dd.MM.yyyy" }}
                            <span class="text-muted" *ngIf="!attribute.value_date">Nicht gesetzt</span>
                        </ng-container>
                        <ng-container *ngIf="attribute.type == 'text'">
                            {{ attribute.value_text }}
                            <span class="text-muted" *ngIf="!attribute.value_text">Nicht gesetzt</span>
                        </ng-container>
                        <ng-container *ngIf="attribute.type == 'boolean'">
                            {{ attribute.value_boolean | yesNo }}
                            <span class="text-muted" *ngIf="!attribute.value_boolean">Nicht gesetzt</span>
                        </ng-container>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
