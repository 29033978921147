import {Component, Input, OnInit, ViewChild, inject} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../../global/services/data.service';
import {CryptoService} from '../../../global/services/crypto.service';
import {UserService} from '../../../global/services/user.service';
import {
    TableColumnFilterType,
    TableConfig,
    TableDesign,
    TableFilterOperator,
    TableRowColorConditionOperator
} from '../../../global/components/table/table.interfaces';
import {TableComponent} from '../../../global/components/table/table.component';
import {ViewMode, UrlData} from '../../../interfaces';
import {NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RightPipe} from '../../../global/pipes/right.pipe';

@Component({
    selector: 'app-document-tasks',
    templateUrl: './document-tasks.component.html',
    styleUrl: './document-tasks.component.scss',
    imports: [NgIf, FormsModule, TableComponent, RightPipe]
})
export class DocumentTasksComponent implements OnInit {

    userService = inject(UserService);
    private dataService = inject(DataService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);

    @Input() DID: any;
    @ViewChild('tableComponent') tableComponent!: TableComponent;

    tableConfig!: TableConfig;
    tasks = [];
    tasksCreated!: boolean;
    excelData: any;
    importing = false;
    uploading = false;
    hideCompleted = true;

    documentActive!: boolean;

    ngOnInit(): void {
        this.getTasksCreated();
        this.setupTable();
    }

    getTasksCreated(): void {
        this.dataService.request('Document/getTasksCreated', {DID: this.DID}).subscribe(response => {
            this.tasksCreated = response.tasksCreated === '1';
            this.documentActive = response.documentActive;
        });
    }

    createTask(): void {
        const urlData: UrlData = {
            viewMode: ViewMode.INSERT,
            ID: '0',
            data: {
                DID: this.DID
            }
        };
        this.router.navigate(['/app/task/detail/', this.cryptoService.encrypt(urlData)]);
    }

    handleInputChange(e: any): void {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (ev) => {
            this.excelData = ev.target?.result;
        };
        reader.readAsDataURL(file);
    }

    uploadTasks(): void {
        this.uploading = true;
        this.dataService.request('Task/import', {
            excelData: this.excelData,
            DID: this.DID,
        }).subscribe(() => {
            this.uploading = false;
            this.importing = false;
            this.tableComponent.load();
        });
    }

    importTasks(): void {
        this.importing = true;
    }

    cancelImportTasks(): void {
        this.importing = false;
    }

    setupTable(): void {
        this.tableConfig = {
            design: TableDesign.NONE,
            title: '',
            openDatasetRouterLink: '/app/task/detail',
            enableCreate: false,
            enableExportExcel: true,
            dataConfig: {
                tableName: 'task',
                tableIndexName: 'TID',
                tableJoins: [
                    {left: 'document', right: 'task', key: 'DID'},
                    {left: 'location', right: 'document', key: 'LID'},
                    {left: 'department', right: 'task', key: 'DPID'},
                    {left: 'tasktopic', right: 'task', key: 'TOID'},
                    {left: 'tasktype', right: 'task', key: 'TTID'},
                ],
                tableFilter: [
                    {
                        table: 'task', field: 'DID', values: [this.DID], operator: TableFilterOperator.EQUAL
                    }
                ],
                tableFields: [
                    {key: 'number', name: 'number', title: 'Nummer', sortable: true, searchable: true},
                    {key: 'title', name: 'title', title: 'Titel', sortable: true, searchable: true},
                    {
                        key: 'type',
                        name: 'type',
                        title: 'Art',
                        sortable: true,
                        translate: true,
                        translateValuePrefix: 'DUETYPE'
                    },
                    {
                        key: 'location',
                        table: 'location',
                        name: 'name',
                        title: 'LOCATION',
                        translateTitle: true,
                        translateTitlePrefix: 'LOCATION',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_wait',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "wait" AND TID = task.TID)',
                        title: 'Termine warten',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_open',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "open" AND TID = task.TID)',
                        title: 'Termine offen',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_progress',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "in_progress" AND TID = task.TID)',
                        title: 'Termine in Bearbeitung',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'status_done',
                        subquery: '(SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID = task.TID)',
                        title: 'Termine erledigt',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'created',
                        name: 'created',
                        title: 'Erstellt am',
                        sortable: true,
                        searchable: true,
                        dateFormat: {en: '%Y.%m.%d', others: '%d.%m.%Y'}
                    },
                    {
                        key: 'document',
                        table: 'document',
                        name: 'name',
                        title: 'Dokument',
                        sortable: true,
                        searchable: true
                    },
                    {
                        key: 'department',
                        table: 'department',
                        name: 'name',
                        title: 'Abteilung',
                        sortable: true,
                        searchable: true
                    },
                    {key: 'topic', table: 'tasktopic', name: 'name', title: 'Thema', sortable: true, searchable: true},
                    {key: 'tasktype', table: 'tasktype', name: 'name', title: 'Typ', sortable: true, searchable: true},
                ],
                rowImage: [
                    {
                        key: 'status_wait',
                        icon: 'fa-circle',
                        color: '#cccccc',
                        conditions: [
                            {key: 'status_wait', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_open',
                        icon: 'fa-circle',
                        color: '#f86c6b',
                        conditions: [
                            {key: 'status_open', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                    {
                        key: 'status_progress',
                        icon: 'fa-circle',
                        color: '#ffc107',
                        conditions: [
                            {
                                key: 'status_progress',
                                value: '0',
                                operator: TableRowColorConditionOperator.SMALLER_INT
                            },
                        ]
                    },
                    {
                        key: 'status_done',
                        icon: 'fa-circle',
                        color: '#4dbd74',
                        conditions: [
                            {key: 'status_done', value: '0', operator: TableRowColorConditionOperator.SMALLER_INT},
                        ]
                    },
                ],
                reverse: false,
                order: 'created',
                columnFilter: {
                    enabled: true,
                    fields: {
                        title: {value: '', type: TableColumnFilterType.INPUT},
                        type: {value: '', type: TableColumnFilterType.GROUP},
                        location: {value: '', type: TableColumnFilterType.GROUP},
                        status_wait: {value: '', type: TableColumnFilterType.INPUT},
                        status_open: {value: '', type: TableColumnFilterType.INPUT},
                        status_progress: {value: '', type: TableColumnFilterType.INPUT},
                        status_done: {value: '', type: TableColumnFilterType.INPUT},
                        document: {value: '', type: TableColumnFilterType.INPUT},
                        department: {value: '', type: TableColumnFilterType.GROUP},
                        topic: {value: '', type: TableColumnFilterType.GROUP},
                        tasktype: {value: '', type: TableColumnFilterType.GROUP},
                    }
                }
            },
            localStorage: {
                version: 1,
                name: 'document-task-list',
                enabled: false
            },
            paginConfig: {
                id: 'documentTaskList',
                rowFields: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
                itemsPerPageDb: true,
                itemsPerPage: 10,
                currentPage: 1,
            }
        };

        // hide completed singular tasks
        if (this.hideCompleted) {
            this.tableConfig.dataConfig.tableFilter.push({
                field: '((task.type = "singular" AND (SELECT COUNT(*) FROM taskdate WHERE status = "done" AND TID = task.TID) = 0) OR task.type = "recurring")',
                values: ['1'],
                operator: TableFilterOperator.EQUAL
            });
        }
    }

    completeTasksCreation() {
        this.dataService.request('Document/completeTasksCreation', {
            DID: this.DID,
        }).subscribe(() => {
            this.getTasksCreated();
        });
    }
}
