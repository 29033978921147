import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {UserService} from '../services/user.service';

export const AdminGuard: CanActivateFn = (route, state) => {

    const router = inject(Router);
    const userService = inject(UserService);

    if (userService.adminToken != '') {
        return true;
    } else {
        router.navigate(['/admin/login']);
        return false;
    }

}
