import {AfterViewInit, Component, Input, OnInit, inject} from '@angular/core';
import {MapService, MarkerData} from './map.service';
import {MarkerClusterer} from '@googlemaps/markerclusterer';
import {NgStyle} from '@angular/common';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrl: './map.component.scss',
    imports: [NgStyle]
})
export class MapComponent implements OnInit, AfterViewInit {

    private mapService = inject(MapService);

    @Input({required: true}) ID!: string;
    @Input() width = '100%';
    @Input() height = '500px';
    @Input() coordinates!: google.maps.LatLng[];
    @Input() zoom = 12;
    @Input() draggable = true;
    @Input() center: google.maps.LatLng | null = null;

    map!: google.maps.Map;
    bounds!: google.maps.LatLngBounds;
    markers: google.maps.marker.AdvancedMarkerElement[] = [];
    infoWindow!: google.maps.InfoWindow;
    markerClusterer!: MarkerClusterer;
    mapLoaded = false;
    markerData: MarkerData[] = [];

    ngOnInit(): void {
        this.bounds = new google.maps.LatLngBounds();
        this.infoWindow = new google.maps.InfoWindow({content: 'empty'});
    }

    ngAfterViewInit(): void {
        if (!this.center) {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                this.setupMap();
            }, () => {
                this.center = new google.maps.LatLng({lat: 47.7497231, lng: 13.5299573});
                this.setupMap();
            });
        } else {
            this.setupMap();
        }
    }

    setupMap(): void {
        const element = document.getElementById(this.ID);
        if (!element) return;

        this.map = new google.maps.Map(element, {
            mapId: this.ID,
            center: this.center,
            zoom: this.zoom,
            streetViewControl: false,
            gestureHandling: this.draggable ? 'auto' : 'none',
            maxZoom: 15
        });
        if (this.coordinates) {
            this.coordinates.forEach(coordinate => this.addMarker(coordinate));
        }

        setTimeout(() => {
            this.mapLoaded = true;
            if (this.markerData.length > 0) {
                this.addMarkers(this.markerData);
            }
        });
    }

    /**
     * Adds marker data.
     * Markers are created after ensuring the map has loaded.
     */
    addMarkerData(markerData: MarkerData[]): void {
        this.markerData = markerData;
        if (this.mapLoaded) {
            this.addMarkers(this.markerData);
        }
    }

    private addMarkers(markerData: MarkerData[]): void {
        markerData.forEach(data => {
            this.addMarker(data.latLng, data.infoWindow);
        });

        this.map.fitBounds(this.bounds);
        this.markerClusterer = new MarkerClusterer({map: this.map, markers: this.markers});

        this.markerData = [];
    }

    private addMarker(latLng: google.maps.LatLng, infoWindow: string | null = null): void {
        const marker = new google.maps.marker.AdvancedMarkerElement({
            map: this.map,
            position: latLng
        });

        if (infoWindow) {
            marker.addListener('click', () => {
                this.infoWindow.setContent(infoWindow);
                this.infoWindow.open(this.map, marker);
            });
        }

        if (marker.position) this.bounds.extend(marker.position);
        this.markers.push(marker);
    }

}
