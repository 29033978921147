import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'taskStatus',
    standalone: true
})
export class TaskStatusPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === 'generated') {
            return 'Zukünftig';
        } else if (value === 'wait') {
            return 'Warten';
        } else if (value === 'open') {
            return 'Offen';
        } else if (value === 'in_progress') {
            return 'In Bearbeitung';
        } else if (value === 'done') {
            return 'Erledigt';
        } else {
            return 'Generiert';
        }
    }

}
