import {Injectable, inject} from '@angular/core';
import CryptoES from 'crypto-es';
import {Router} from '@angular/router';
import {myAppConfig} from '../../settings';
import {ViewMode, UrlData} from '../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class CryptoService {

    private router = inject(Router);

    // Encrypt data to pass as route parameter
    encrypt(urlData: UrlData): string {
        if (urlData.viewMode == ViewMode.VIEW) {
            delete urlData.viewMode;
        }
        const toEncrypt = JSON.stringify(urlData);
        const $iv = this.randomHex(16);
        const encrypted = $iv + CryptoES.AES.encrypt(toEncrypt, CryptoES.enc.Utf8.parse(myAppConfig.cryptoKey), {iv: CryptoES.enc.Utf8.parse($iv)}).toString();
        return CryptoES.enc.Base64.parse(encrypted).toString(CryptoES.enc.Hex);
    }

    decrypt(toDecrypt: string): string {
        toDecrypt = CryptoES.enc.Hex.parse(toDecrypt).toString(CryptoES.enc.Base64);
        const $iv = toDecrypt.substring(0, 16);
        toDecrypt = toDecrypt.substring(16);
        return CryptoES.AES.decrypt(toDecrypt, CryptoES.enc.Utf8.parse(myAppConfig.cryptoKey), {iv: CryptoES.enc.Utf8.parse($iv)}).toString(CryptoES.enc.Utf8);
    }

    private randomHex(size: number): string {
        return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    }

    getUrlData(url: string): UrlData {
        const noHash = url.split('#')[0];
        const toDecrypt = noHash.split('/').reverse()[0];

        try {
            const urlData: UrlData = JSON.parse(this.decrypt(toDecrypt));
            if (!urlData.viewMode) {
                urlData.viewMode = ViewMode.VIEW;
            }
            return urlData;
        } catch (e) {
            this.router.navigate([myAppConfig.urlDataFallbackLink]);
            return null;
        }
    }

}
