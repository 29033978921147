import {Component, OnDestroy, OnInit, ViewChild, inject} from '@angular/core';
import {FileComponent, FwFileViewLayout} from "../../../global/components/file/file.component";
import {UserService} from "../../../global/services/user.service";
import {FormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FormService} from "../../../global/services/form.service";
import {DataService} from "../../../global/services/data.service";
import {Subscription} from "rxjs";
import {validateDate} from "../../../global/validators/validateDate";
import {NgIf, NgFor} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FileTypePipe} from '../../../global/pipes/file-type.pipe';

@Component({
    selector: 'app-document-inbox',
    templateUrl: './document-inbox.component.html',
    styleUrl: './document-inbox.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FileComponent, TranslateModule, FileTypePipe]
})
export class DocumentInboxComponent implements OnInit, OnDestroy {

    userService = inject(UserService);
    formService = inject(FormService);
    private fb = inject(FormBuilder);
    private dataService = inject(DataService);

    @ViewChild('fileComponent') fileComponent!: FileComponent;

    fileViewLayout = FwFileViewLayout;
    form!: UntypedFormGroup;
    locations: any[] = [];
    fileTypes: any[] = [];
    companies: any[] = [];

    private fileTypeChanges$!: Subscription;
    private companyChanges$!: Subscription;

    selectedFiles = [];
    email!: string;

    ngOnInit(): void {
        this.form = this.fb.group({
            FTID: ['', Validators.required],
            CID: ['', Validators.required],
            LID: [{value: '', disabled: true}, Validators.required],
            attributes: this.fb.array([])
        });

        this.dataService.request('universe.Client/getEmail', {
            CLID: this.userService.currentUser.CLID
        }).subscribe(response => {
            this.email = response.email;
        });

        this.dataService.request('Company/listAll').subscribe(response => {
            this.companies = response.companies;

            // automatically set company if there is only one
            if (this.companies.length === 1) {
                this.form.patchValue({
                    CID: this.companies[0].CID,
                });
            }
        });

        this.dataService.request('FileType/listAll').subscribe(response => {
            this.fileTypes = response.filetypes;
        });

        this.fileTypeChanges$ = this.form.get('FTID').valueChanges.subscribe(val => {
            this.getAttributes(val);
        });

        this.companyChanges$ = this.form.get('CID').valueChanges.subscribe(val => {
            this.form.get('LID').enable();
            this.getLocations(val);
        });
    }

    ngOnDestroy(): void {
        this.fileTypeChanges$.unsubscribe();
        this.companyChanges$.unsubscribe();
    }

    handleSelectedChange($event: any) {
        this.selectedFiles = $event;
    }

    addAttribute(data: any = {}): void {
        this.formService.getFormArray(this.form, 'attributes').push(
            this.fb.group({
                ATTID: [data.ATTID],
                name: [data.name],
                type: [data.type],
                required: [data.required],
                position: [data.position],
                value_int: [null],
                value_dec: [null],
                value_date: [null, validateDate],
                value_text: [null],
                value_boolean: [false]
            })
        );
    }

    getAttributes(FTID: string): void {
        this.formService.getFormArray(this.form, 'attributes').clear();
        this.dataService.request('AttributeTemplate/listByFileType', {FTID}).subscribe(response => {
            response.attributes.forEach((attribute: any) => {
                this.addAttribute(attribute);
            });

            this.formService.getFormArray(this.form, 'attributes').controls.forEach((control: UntypedFormGroup, index) => {
                if (control.value.required === '1') {
                    if (control.value.type === 'int') {
                        control.controls['value_int'].setValidators([Validators.required]);
                    }
                    if (control.value.type === 'dec') {
                        control.controls['value_dec'].setValidators([Validators.required]);
                    }
                    if (control.value.type === 'date') {
                        control.controls['value_date'].setValidators([Validators.required]);
                    }
                    if (control.value.type === 'text') {
                        control.controls['value_text'].setValidators([Validators.required]);
                    }
                    if (control.value.type === 'boolean') {
                        control.controls['value_boolean'].setValidators([Validators.required]);
                    }
                }
            });
        });
    }

    getLocations(CID: string): void {
        this.form.get('LID')?.patchValue('');
        this.dataService.request('Location/listByCompany', {CID}).subscribe(response => {
            this.locations = response.locations;

            // automatically set location if there is only one
            if (this.locations.length === 1) {
                this.form.patchValue({
                    LID: this.locations[0].LID,
                });
            }
        });
    }

    finish() {
        this.dataService.request('Document/saveFromInbox', {
            selectedFiles: this.selectedFiles,
            form: this.form.getRawValue(),
        }).subscribe(response => {
            this.reload();
        });
    }

    reload(): void {
        this.selectedFiles = [];
        this.fileComponent.loadFiles();
    }
}
