<div class="card">
    <div class="card-header">
        <i class="fas fa-file-upload mr-2"></i>Dokument hochladen
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" formControlName="name">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Dokumenttyp</label>
                        <select class="form-control" formControlName="FTID">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let fileType of fileTypes" [ngValue]="fileType.FTID">
                                {{ fileType.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Unternehmen</label>
                        <select class="form-control" formControlName="CID">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let company of companies" [ngValue]="company.CID">
                                {{ company.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>{{ 'LOCATION.LOCATION' | translate }}</label>
                        <select class="form-control" formControlName="LID">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option *ngFor="let location of locations" [ngValue]="location.LID">
                                {{ location.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <!--<div class="col">
                    <div class="form-group">
                        <label>Digitalisierung</label>
                        <select class="form-control" formControlName="digitization">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option value="none">Ohne</option>
                            <option value="text">Umwandlung auf Textdatei (OCR)</option>
                            <option value="full">Vollständig</option>
                        </select>
                        <small class="form-text text-muted" *ngIf="form.value.digitization == 'none'">
                            Es wird keine Digitalisierung vorgenommen
                        </small>
                        <small class="form-text text-muted" *ngIf="form.value.digitization == 'text'">
                            Der Dokumenttext wird digitalisiert
                        </small>
                        <small class="form-text text-muted" *ngIf="form.value.digitization == 'full'">
                            Der Dokumenttext wird digitalisiert, Aufgaben und Attribute erstellt
                        </small>
                    </div>
                </div>-->
                <div class="col">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" formControlName="consulting">
                        <label class="form-check-label">Support anfordern</label>
                        <small class="form-text text-muted">
                            Ein Mitarbeiter von NoteRadar meldet sich innerhalb von 24h um Sie zu unterstützen.
                            <span class="text-danger" *ngIf="userService.currentUser.client_consulting == '0'">Dieses Service ist für Ihren Mandanten deaktiviert!</span>
                        </small>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.value.FTID">
                <h4 class="mt-3">Attribute setzen</h4>

                <div class="text-muted" *ngIf="formService.getFormArray(form, 'attributes').length == 0">Dem gewählten Dokumenttyp sind keine Attribute zugeordnet!</div>

                <table class="table table-striped table-sm mb-0" *ngIf="formService.getFormArray(form, 'attributes').length > 0">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Typ</th>
                        <th>Wert</th>
                    </tr>
                    </thead>
                    <tbody formArrayName="attributes">
                    <tr [formGroupName]="i" *ngFor="let attribute of formService.getFormArray(form, 'attributes').controls; let i = index">
                        <td>
                            {{ attribute.value.name }}
                        </td>
                        <td>
                            {{ attribute.value.type | fileType }}
                        </td>
                        <td>
                            <input type="number" class="form-control form-control-sm" formControlName="value_int" *ngIf="attribute.value.type == 'int'">
                            <input type="number" step="0.01" class="form-control form-control-sm" formControlName="value_dec" *ngIf="attribute.value.type == 'dec'">
                            <input type="text" class="form-control form-control-sm" formControlName="value_text" *ngIf="attribute.value.type == 'text'">
                            <input type="checkbox" class="form-check-input" formControlName="value_boolean" *ngIf="attribute.value.type == 'boolean'">
                            <input type="date" class="form-control form-control-sm" formControlName="value_date" *ngIf="attribute.value.type == 'date'">
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-container>
        </form>

        <button type="button" class="btn btn-primary mt-3" [disabled]="form.invalid" (click)="showUpload()" *ngIf="!showingUpload">PDF auswählen</button>

        <app-file
            *ngIf="DID"
            [viewShowFileInfo]="false"
            [viewThumbnailsTitle]="false"
            [doctype]="'document'"
            [fkId]="DID"
            [fkName]="'DID'"
            [fkTable]="'document'"
            [viewThumbnails]="true"
            [viewThumbnailsSize]="'100px'"
            [uploadMaxFileCount]="1"
            [viewNoFilesText]="'Es wurde noch kein PDF hochgeladen.'"
            [showDragAndDrop]="true"
            [uploadAccept]="[[FileMimeType.DOCUMENT_PDF]]"
            (uploadedEvent)="setFID($event)"
            [viewLayout]="FileViewLayout.TABLE"
        />

        <div class="text-muted mt-1" *ngIf="!DID && form.invalid">Bitte alle Pflichtfelder ausfüllen um ein PDF auszuwählen. Der Upload kann auch erst später erfolgen.</div>

        <div class="mt-3">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="finish()">Speichern</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
        </div>
    </div>
</div>
