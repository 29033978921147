<div class="card">
    <div class="card-header">
        <i class="fas fa-cubes mr-2"></i>
        <ng-container *ngIf="viewMode == ViewMode.EDIT">Attribute bearbeiten</ng-container>
        <ng-container *ngIf="viewMode == ViewMode.VIEW">Attribute</ng-container>
    </div>
    <div class="card-body">
        <form [formGroup]="form">

            <p class="text-muted">Die hier angelegten Attribute können bei jeder {{ 'LOCATION.LOCATIONS' | translate }} optional gesetzt werden.</p>

            <button type="button" class="btn btn-primary mb-3" *ngIf="viewMode != ViewMode.VIEW" (click)="addAttribute()">Attribut hinzufügen</button>

            <table class="table table-striped table-sm">
                <thead>
                <tr>
                    <th style="width: 140px"></th>
                    <th>Name</th>
                    <th style="width: 110px">Typ</th>
                    <th style="width: 85px">Pflichtfeld</th>
                </tr>
                </thead>
                <tbody formArrayName="attributes">
                <tr [formGroupName]="i" *ngFor="let attribute of formService.getFormArray(form, 'attributes').controls; let i = index; let first = first; let last = last">
                    <td>
                        <span class="mr-2">{{ i + 1 }}</span>
                        <ng-container *ngIf="viewMode != ViewMode.VIEW">
                            <button type="button" class="btn btn-primary btn-sm" *ngIf="attribute.value.ATTID == null" (click)="formService.removeFormArrayItem(form, 'attributes', i)"><i class="fas fa-trash"></i></button>
                            <button type="button" class="btn btn-primary btn-sm" *ngIf="attribute.value.ATTID != null" (click)="deleteAttribute(i, attribute.value.ATTID)"><i class="fas fa-trash"></i></button>
                            <button type="button" class="btn btn-primary btn-sm ml-2" *ngIf="!first" (click)="formService.moveFormArrayEntry(form, 'attributes', i, -1)"><i class="fas fa-arrow-up"></i></button>
                            <button type="button" class="btn btn-primary btn-sm ml-2" *ngIf="!last" (click)="formService.moveFormArrayEntry(form, 'attributes', i, 1)"><i class="fas fa-arrow-down"></i></button>
                        </ng-container>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" formControlName="name" *ngIf="viewMode != ViewMode.VIEW">
                        <ng-container *ngIf="viewMode == ViewMode.VIEW">{{ attribute.value.name }}</ng-container>
                    </td>
                    <td>
                        <select class="form-control form-control-sm" formControlName="type" *ngIf="viewMode != ViewMode.VIEW">
                            <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                            <option value="int">Ganze Zahl</option>
                            <option value="dec">Kommazahl</option>
                            <option value="date">Datum</option>
                            <option value="text">Text</option>
                            <option value="boolean">Ja/Nein</option>
                        </select>
                        <ng-container *ngIf="viewMode == ViewMode.VIEW">{{ attribute.value.type | fileType}}</ng-container>
                    </td>
                    <td>
                        <div class="form-check" *ngIf="viewMode != ViewMode.VIEW">
                            <input type="checkbox" class="form-check-input" formControlName="required">
                        </div>
                        <ng-container *ngIf="viewMode == ViewMode.VIEW">{{ attribute.value.required | yesNo }}</ng-container>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>

        <ng-container *ngIf="viewMode == ViewMode.VIEW">
            <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)" [attr.data-right]="'MASTER_DATA_EDIT' | right" *ngIf="userService.hasRight('MASTER_DATA_EDIT')">Bearbeiten</button>
            <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">Zurück</button>
        </ng-container>

        <ng-container *ngIf="viewMode == ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>
    </div>
</div>
