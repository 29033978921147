import {Component, inject, OnInit} from '@angular/core';
import {RouterLink} from '@angular/router';
//import {SettingsService} from '../../framework/services/settings.service';
//import {DataService} from '../../framework/services/data.service';
//import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrl: './start.component.scss',
    imports: [RouterLink]
})
export class StartComponent implements OnInit {

    // demoForm: FormGroup;
    // demoRequested = false;

    //settingsService = inject(SettingsService);
    //private dataService = inject(DataService);
    //private fb = inject(FormBuilder);

    ngOnInit(): void {
        /*this.demoForm = this.fb.group({
            email: ['', Validators.required],
        });*/
    }

    /*requestDemo(): void {
        if (this.demoForm.value !== '') {
            this.dataService.request('universe.Client/requestDemo', this.demoForm.value).subscribe(() => {
                this.demoRequested = true;
            });
        }
    }*/
}
