import {Component, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrl: './features.component.scss',
    standalone: true
})
export class FeaturesComponent {

    private route = inject(ActivatedRoute);

    ngAfterViewInit() {
        this.route.fragment.subscribe(f => {
            const element = document.getElementById(f)
            if (element) {
                element.scrollIntoView()
            }
        })
    }

}
