import {Component, OnInit, inject, OnDestroy} from '@angular/core';
import {DataService} from '../../../global/services/data.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterHelperService} from '../../../global/services/router-helper.service';
import {NgFor, NgIf, DecimalPipe, DatePipe} from '@angular/common';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {YesNoPipe} from '../../../global/pipes/yes-no.pipe';
import {Subscription} from "rxjs";

@Component({
    selector: 'app-document-attributes',
    templateUrl: './document-attributes.component.html',
    styleUrl: './document-attributes.component.scss',
    imports: [FormsModule, ReactiveFormsModule, NgFor, NgIf, RouterLink, DecimalPipe, DatePipe, TranslateModule, YesNoPipe]
})
export class DocumentAttributesComponent implements OnInit, OnDestroy {

    routerHelperService = inject(RouterHelperService);
    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);

    form!: UntypedFormGroup;
    fileTypes: any[] = [];
    documents: any[] | null = null;
    templates!: any[];
    search: any = {};

    // used in filter
    locations!: any[];
    companies!: any[];
    CID: any = '';
    LID: any = '';

    exporting = false;
    columnFilter: any = {};

    private typeChanges$!: Subscription;

    ngOnInit(): void {
        this.dataService.request('FileType/listAll').subscribe(response => {
            this.fileTypes = response.filetypes;
        });

        this.form = this.fb.group({
            FTID: ['', Validators.required],
        });

        this.typeChanges$ = this.form.get('FTID').valueChanges.subscribe(_ => {
            this.loadDocumentsWithAttributes(true);
        });
    }

    ngOnDestroy(): void {
        this.typeChanges$.unsubscribe();
    }

    loadDocumentsWithAttributes(reset = false): void {
        this.documents = null;

        // reset search when changing document type
        if (reset) {
            this.search = {};
        }

        this.dataService.request('Document/listWithAttributes', {
            FTID: this.form.get('FTID')?.value,
            search: this.search,
            LID: this.LID,
            CID: this.CID,
        }).subscribe(response => {
            this.documents = response.documents;
            this.templates = response.templates;

            // if the search object is empty populate it
            if (Object.keys(this.search).length === 0) {
                this.templates.forEach(template => {
                    this.search[template.name] = '';
                    this.columnFilter[template.name] = true;
                });
            }

            // create list of companies
            this.companies = [];
            this.documents?.forEach(document => {
                const companyFound = this.companies.find(company => company.CID === document.CID);
                if (!companyFound) {
                    this.companies.push({
                        CID: document.CID,
                        name: document.company,
                    });
                }
            });

            // create list of locations
            this.locations = [];
            this.documents?.forEach(document => {
                const locationFound = this.locations.find(location => location.LID === document.LID);
                if (!locationFound) {
                    this.locations.push({
                        LID: document.LID,
                        name: document.location,
                    });
                }
            });
        });
    }

    export(): void {
        this.exporting = true;

        this.dataService.request('Document/exportAttributes', {
            FTID: this.form.get('FTID')?.value,
            search: this.search,
            LID: this.LID,
            CID: this.CID,
        }).subscribe(response => {
            this.exporting = false;

            const link = document.createElement('a');

            link.setAttribute('href', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data);
            link.setAttribute('download', response.path);
            link.click();
        });
    }
}
