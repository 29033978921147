import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';

import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';

import {MapComponent} from '../../global/components/map/map.component';
import {DataService} from '../../global/services/data.service';
import {CryptoService} from '../../global/services/crypto.service';
import {FormService} from '../../global/services/form.service';
import {ViewMode} from '../../interfaces';
import {ModalDirective, ModalModule} from "ngx-bootstrap/modal";
import {NgClass, NgIf, NgFor, JsonPipe, DatePipe} from '@angular/common';
import {AdminClientActivityComponent} from './admin-client-activity/admin-client-activity.component';
import {AutofocusDirective} from '../../global/directives/autofocus.directive';
import {AdminClientDocumentsComponent} from './admin-client-documents/admin-client-documents.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PaymentTypePipe} from '../../global/pipes/payment-type.pipe';
import {YesNoPipe} from '../../global/pipes/yes-no.pipe';
import {ToastrService} from "ngx-toastr";

enum TabsEnum {
    DETAILS = 'details',
    USERS = 'users',
    ONLINE = 'online',
    ACTIVITY = 'activity',
    COMPANIES = 'companies',
    MAIL_LOG = 'mail_log',
    OTHER = 'other',
    MAP = 'map',
    DOCUMENTS = 'documents',
}

@Component({
    selector: 'app-admin-client-detail',
    templateUrl: './admin-client-detail.component.html',
    styleUrls: ['./admin-client-detail.component.scss'],
    imports: [NgClass, NgIf, FormsModule, ReactiveFormsModule, NgFor, AdminClientActivityComponent, ModalModule, AutofocusDirective, MapComponent, AdminClientDocumentsComponent, TooltipModule, JsonPipe, DatePipe, PaymentTypePipe, YesNoPipe]
})
export class AdminClientDetailComponent implements OnInit, OnDestroy {

    private dataService = inject(DataService);
    private fb = inject(UntypedFormBuilder);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);
    private formService = inject(FormService);
    private toastrService = inject(ToastrService);

    readonly ViewMode = ViewMode;
    readonly TABS = TabsEnum;

    tab: TabsEnum = TabsEnum.DETAILS;
    form!: UntypedFormGroup;
    client: any = {};
    users: any[] = [];
    CLID!: string;
    viewMode!: ViewMode;
    logs: any[] = [];
    taskTypes: any[] = [];
    taskTopics: any[] = [];
    documentTypes: any[] = [];
    companies: any = [];
    labelTemplate: any;
    locationsCount = 0;
    stats: any;
    mapLocations: any[] = [];
    userlogs!: any[];
    autoReloadActivities = false;
    autoReloadInterval: any;
    autoReloadValue = 15;
    documents!: number;
    message!: string;

    saving: boolean = false;

    private formSubscriptions: Subscription[] = [];

    @ViewChild('mapComponent') mapComponent!: MapComponent;
    @ViewChild('mailTemplate') mailTemplate!: TemplateRef<any>;
    @ViewChild('mailModal') mailModal!: ModalDirective;

    ngOnInit(): void {
        const urlData = this.cryptoService.getUrlData(this.router.url);
        this.CLID = urlData.ID;
        this.viewMode = urlData.viewMode;

        this.form = this.fb.group({
            CLID: [''],
            name: ['', Validators.required],
            zip: ['', Validators.required],
            city: ['', Validators.required],
            street: ['', Validators.required],
            register_number: [null],
            invoice_zip: [null, Validators.required],
            invoice_city: [null, Validators.required],
            invoice_street: [null, Validators.required],
            invoice_text: [null, Validators.required],
            invoice_email: [null, [Validators.required, Validators.email]],
            invoice_UID: [null, Validators.required],
            contract_start: [null, Validators.required],
            contract_end: [null],
            email: [null, Validators.email],
            phone: [null],
            cost_unit: [null],
            number_locations: ['', Validators.required],
            paid_locations: ['', Validators.required],
            price: [null, Validators.required],
            payment_type: ['', Validators.required],
            db_host: ['localhost'], // [{value: 'localhost', disabled: true}, Validators.required],
            db_user: ['', Validators.required],
            db_pass: [this.generatePassword(12), Validators.required],
            user_email: [''],
            user_pass: [''],
            active: [true],
            demo: [false],
            created: [''],
            labels: [null],
            consulting: [false],
            consulting_limit: [null],
            comment: [null],
            email_import: [null],
            email_import_pass: [null],
        });

        if (this.viewMode === ViewMode.INSERT) {
            this.formService.setValidators(this.form, ['user_email'], [Validators.required, Validators.email]);
            this.formService.setValidators(this.form, ['user_pass'], [Validators.required]);
        }

        // @ts-ignore
        this.formSubscriptions.push(this.form.get('demo').valueChanges.subscribe((val) => {
            if (val) { // is demo
                this.formService.removeValidators(this.form, ['invoice_zip', 'invoice_city', 'invoice_street', 'invoice_text', 'invoice_email', 'invoice_UID', 'price', 'payment_type'], [Validators.required]);
                this.form.patchValue({
                    consulting: false,
                    consulting_limit: null
                });
                this.formService.disableControls(this.form, ['consulting', 'consulting_limit']);
            } else { // is not demo
                this.formService.setValidators(this.form, ['invoice_zip', 'invoice_city', 'invoice_street', 'invoice_text', 'invoice_email', 'invoice_UID', 'price', 'payment_type'], [Validators.required]);
                this.formService.enableControls(this.form, ['consulting', 'consulting_limit']);
            }
        }));

        if (this.CLID !== '0') {
            this.getClient();
        }

        this.labelTemplate = {
            LOCATION: {
                LOCATION: 'Betriebsstätte',
                LOCATIONS: 'Betriebsstätten'
            }
        };

        this.autoReloadInterval = setInterval(() => {
            if (this.autoReloadActivities) {
                this.autoReloadValue--;
                if (this.autoReloadValue === 0) {
                    this.loadUserLogs();
                    this.autoReloadValue = 15;
                }
            }
        }, 1000);
    }

    ngOnDestroy(): void {
        this.formSubscriptions.forEach(subscription => subscription.unsubscribe());

        if (this.autoReloadInterval) {
            clearInterval(this.autoReloadInterval);
        }
    }

    getClient(): void {
        this.dataService.request('universe.Admin/getClient', {
            CLID: this.CLID
        }).subscribe(response => {
            this.client = response.client;

            this.logs = response.logs;
            this.logs.forEach((log: any) => log.result = log.result === '1');

            this.userlogs = response.userlogs;

            this.users = response.users;
            this.users.forEach((user: any) => {
                user.active = user.active === '1';
                user.s_reminders = user.s_reminders === '1';
                user.s_monthly_report = user.s_monthly_report === '1';
            });

            this.taskTypes = response.tasktypes;
            this.taskTopics = response.tasktopics;
            this.documentTypes = response.documenttypes;
            this.companies = response.companies;
            this.locationsCount = this.companies.reduce((current: any, next: any) => {
                return current + parseInt(next.locations, 10);
            }, 0);

            this.stats = {
                tasks: response.tasks,
                taskdates: response.taskdates,
            };

            this.patchForm();
        });

        this.dataService.request('universe.Admin/listAllLocationsClient', {
            CLID: this.CLID
        }).subscribe(response => {
            this.mapLocations = response.locations;
        });
    }

    patchForm(): void {
        let labels = null;
        if (this.client.labels) {
            const data = JSON.parse(this.client.labels);
            labels = JSON.stringify(data, undefined, 4);
        }

        this.form.patchValue({
            CLID: this.client.CLID,
            name: this.client.name,
            zip: this.client.zip,
            city: this.client.city,
            street: this.client.street,
            register_number: this.client.register_number,
            invoice_zip: this.client.invoice_zip,
            invoice_city: this.client.invoice_city,
            invoice_street: this.client.invoice_street,
            invoice_text: this.client.invoice_text,
            invoice_email: this.client.invoice_email,
            invoice_UID: this.client.invoice_UID,
            contract_start: this.client.contract_start,
            contract_end: this.client.contract_end,
            email: this.client.email,
            phone: this.client.phone,
            cost_unit: this.client.cost_unit,
            number_locations: this.client.number_locations,
            paid_locations: this.client.paid_locations,
            price: this.client.price,
            payment_type: this.client.payment_type,
            db_host: this.client.db_host,
            db_user: this.client.db_user,
            db_pass: this.client.db_pass,
            active: this.client.active === '1',
            demo: this.client.demo === '1',
            created: this.client.created,
            labels,
            consulting: this.client.consulting === '1',
            consulting_limit: this.client.consulting_limit,
            comment: this.client.comment,
            email_import: this.client.email_import,
            email_import_pass: this.client.email_import_pass,
        });
    }

    save(): void {
        // @ts-ignore
        const json = JSON.parse(this.form.get('labels').value);
        const labels = json === null ? null : JSON.stringify(json);

        this.saving = true;
        this.dataService.request('universe.Admin/saveClient', {
            form: this.form.getRawValue(),
            labels
        }).subscribe(() => {
            this.saving = false;
            this.toastrService.success('Mandant wurde angelegt');
            this.router.navigate(['/admin']);
        });
    }

    update(): void {
        // @ts-ignore
        const json = JSON.parse(this.form.get('labels').value);
        const labels = json === null ? null : JSON.stringify(json);

        this.dataService.request('universe.Admin/updateClient', {
            form: this.form.getRawValue(),
            labels
        }).subscribe(() => {
            this.toastrService.success('Mandant wurde aktualisiert');
            this.setMode(ViewMode.VIEW);
        });
    }

    cancel(): void {
        this.router.navigate(['/admin']);
    }

    setMode(viewMode: ViewMode): void {
        this.viewMode = viewMode;
    }

    cancelEdit(): void {
        this.patchForm();
        this.setMode(ViewMode.VIEW);
    }

    delete(): void {
        Swal.fire({
            title: 'Sicher?',
            text: 'Der Mandant wird gelöscht.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein'
        }).then(result => {
            if (result.value) {
                this.dataService.request('universe.Admin/deleteClient', {
                    CLID: this.CLID
                }).subscribe(() => {
                    this.toastrService.success('Mandant wurde gelöscht');
                    this.router.navigate(['/admin']);
                });
            }
        });
    }

    private generatePassword(length = 8): string {
        // leave out l,O,0 and 1
        const chars = 'abcdefghijkmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ23456789';

        let result = '';
        for (let i = 0; i < length; i++) {
            const index = Math.floor(Math.random() * chars.length);
            result += chars.substring(index, index + 1);
        }

        return result;
    }

    setTab(tab: TabsEnum): void {
        this.tab = tab;

        if (this.tab === TabsEnum.MAP) {

            // wait to give the map component time to render
            setTimeout(() => {
                this.mapComponent.addMarkerData(this.mapLocations.map(location => {
                    return {
                        latLng: new google.maps.LatLng(parseFloat(location.lat), parseFloat(location.lng)),
                        infoWindow:
                            '<b>Unternehmen:</b> ' + location.company + '<br/>' +
                            '<b>Objekt:</b> ' + location.location
                    };
                }));
            }, 500);
        }
    }

    toggleAutoReloadActivities(): void {
        this.autoReloadActivities = !this.autoReloadActivities;

        if (!this.autoReloadActivities) {
            this.autoReloadValue = 15;
        }
    }

    loadUserLogs(): void {
        this.dataService.request('universe.Admin/loadClientUserLogs', {
            CLID: this.CLID,
        }).subscribe(response => {
            this.userlogs = response.userlogs;
        });
    }

    openMail(log: any) {
        this.message = log.message;
        this.message = this.message.replace("cid:logo'", "assets/img/brand/logo.png' width='100'");
        this.mailModal.show();
    }
}
