import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrl: './location.component.scss',
    imports: [RouterOutlet]
})
export class LocationComponent {

}
