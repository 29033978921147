import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'textarea',
    standalone: true
})
export class TextareaPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {
        if (!value) {
            return '';
        }
        return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }

}
