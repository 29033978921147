import {Component, OnInit, inject} from '@angular/core';
import {DataService} from "../../services/data.service";
import dayjs from 'dayjs';
import {RouterHelperService} from "../../services/router-helper.service";
import {NgFor, NgIf, NgTemplateOutlet, NgClass, DatePipe} from '@angular/common';
import {RouterLink} from '@angular/router';
import {TaskStatusPipe} from '../../pipes/task-status.pipe';

enum ActivityType {
    COMMENT_TASK = 'comment_task',
    COMMENT_TASKDATE = 'comment_taskdate',
    CREATE_DOCUMENT = 'create_document',
    CREATE_TASK = 'create_task',
    CREATE_TASKDATE = 'create_taskdate',
    COMPLETE_TASK_CREATION = 'complete_task_creation',
    STATUS_CHANGE = 'status_change',
    ATTACHMENT_DOCUMENT = 'attachment_document',
    ATTACHMENT_TASK = 'attachment_task',
    ATTACHMENT_TASKDATE = 'attachment_taskdate',
}

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrl: './activity.component.scss',
    imports: [NgFor, NgIf, RouterLink, NgTemplateOutlet, NgClass, DatePipe, TaskStatusPipe]
})
export class ActivityComponent implements OnInit {

    routerHelperService = inject(RouterHelperService);
    private dataService = inject(DataService);

    readonly ActivityType = ActivityType;

    activities: any[] = [];
    loading = false;

    ngOnInit(): void {
        this.loadActivities();
    }

    loadActivities() {
        this.activities = [];
        this.loading = true;
        this.dataService.request("Activity/listAll").subscribe(response => {
            this.loading = false;
            this.activities = response.activities;

            this.activities.forEach(activity => {
                activity.diff = dayjs().diff(dayjs(activity.created, "YYYY-MM-DD HH:mm:ss"), "minutes");
                activity.created = dayjs(activity.created, "YYYY-MM-DD HH:mm:ss").toDate();
            });
        });
    };
}
