import {Component, Input, OnInit} from '@angular/core';
import {
    TableDataConfig,
    TableField,
    TableRowColorConditionOperator,
    TableRowImage,
    TableFieldAlignment
} from '../../table.interfaces';
import dayjs from 'dayjs';
import {NgFor, NgIf, NgClass} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'app-table-table-field-image',
    templateUrl: './table-table-field-image.component.html',
    styleUrl: './table-table-field-image.component.scss',
    imports: [NgFor, NgIf, NgClass, TooltipModule]
})
export class TableTableFieldImageComponent implements OnInit {

    @Input() dataConfig!: TableDataConfig;
    @Input() tableField!: TableField;
    @Input() row: any;

    readonly TableFieldAlignment = TableFieldAlignment;

    imageConfigs!: TableRowImage[];

    ngOnInit(): void {
        if (!this.dataConfig.rowImage) {
            return;
        }

        this.imageConfigs = [];

        this.dataConfig.rowImage.forEach(icon => {
            if (icon.key === this.tableField.key) {
                const valid = icon.conditions.every(condition => {
                    if (condition.operator === TableRowColorConditionOperator.SMALLER_DATE) {
                        if (dayjs(condition.value) < dayjs(this.row[condition.key])) {
                            return true;
                        }
                    }
                    if (condition.operator === TableRowColorConditionOperator.BIGGER_DATE) {
                        if (dayjs(condition.value) > dayjs(this.row[condition.key])) {
                            return true;
                        }
                    }
                    if (condition.operator === TableRowColorConditionOperator.SMALLER_INT) {
                        if (condition.value < this.row[condition.key]) {
                            return true;
                        }
                    }
                    if (condition.operator === TableRowColorConditionOperator.BIGGER_INT) {
                        if (condition.value > this.row[condition.key]) {
                            return true;
                        }
                    }
                    if (condition.operator === TableRowColorConditionOperator.EQUAL) {
                        if (condition.value === this.row[condition.key]) {
                            return true;
                        }
                    }

                    return false;
                });

                if (valid) {
                    this.imageConfigs.push(icon);
                }
            }
        });
    }
}
