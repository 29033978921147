import {Component} from '@angular/core';

@Component({
    selector: 'app-benefits',
    templateUrl: './benefits.component.html',
    styleUrl: './benefits.component.scss',
    standalone: true
})
export class BenefitsComponent {

}
