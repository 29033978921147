<div class="card mb-3">
    <div class="card-header">
        <i class="fas fa-tasks mr-2"></i>
        <ng-container *ngIf="viewMode === ViewMode.INSERT">Aufgabe anlegen</ng-container>
        <ng-container *ngIf="viewMode === ViewMode.EDIT">Aufgabe bearbeiten</ng-container>
        <ng-container *ngIf="viewMode === ViewMode.VIEW">Aufgabe</ng-container>
    </div>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                    <div class="form-group" *ngIf="document">
                        <label>Dokument</label>
                        <p><a [routerLink]="[]" (click)="cancel()">{{ document.name }}</a></p>
                    </div>
                    <div class="form-group" *ngIf="viewMode !== ViewMode.INSERT">
                        <label>Erstellt</label>
                        <p>{{ form.value.created | date: "dd.MM.yyyy HH:mm" }}</p>
                    </div>
                    <div class="form-group">
                        <label>Aufgabenthema</label>
                        <ng-container *ngIf="viewMode !== ViewMode.VIEW">
                            <select class="form-control" formControlName="TOID">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let topic of topics" [ngValue]="topic.TOID">
                                    {{ topic.name }}
                                </option>
                            </select>
                        </ng-container>
                        <p *ngIf="task && viewMode === ViewMode.VIEW">{{ task.topic }}</p>
                    </div>
                    <div class="form-group">
                        <label>Nummer</label>
                        <input type="text" class="form-control" formControlName="number" *ngIf="viewMode !== ViewMode.VIEW">
                        <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.number }}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Abteilung</label>
                        <ng-container *ngIf="viewMode !== ViewMode.VIEW">
                            <select class="form-control" formControlName="DPID">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let department of departments" [ngValue]="department.DPID">
                                    {{ department.name }}
                                </option>
                            </select>
                            <small class="form-text text-muted">
                                Abteilung, die für die Erledigung der Aufgabe zuständig ist.
                            </small>
                        </ng-container>
                        <p *ngIf="task && viewMode === ViewMode.VIEW">{{ task.department }}</p>
                    </div>
                    <div class="form-group" *ngIf="task">
                        <label>Verantwortlicher</label>
                        <p>{{ task.responsible }}</p>
                    </div>
                    <div class="form-group">
                        <label>Aufgabentyp</label>
                        <ng-container *ngIf="viewMode !== ViewMode.VIEW">
                            <select class="form-control" formControlName="TTID">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option *ngFor="let taskType of taskTypes" [ngValue]="taskType.TTID">
                                    {{ taskType.name }}
                                </option>
                            </select>
                        </ng-container>
                        <p *ngIf="task && viewMode === ViewMode.VIEW">{{ task.tasktype }}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Fälligkeit</label>
                        <ng-container *ngIf="viewMode !== ViewMode.VIEW">
                            <select class="form-control" formControlName="type">
                                <option value="" disabled selected style="display: none;">Bitte auswählen</option>
                                <option value="singular">Einmalig</option>
                                <option value="recurring">Wiederkehrend</option>
                            </select>
                            <ng-container *ngIf="form.value.type === 'singular'">
                                <label class="mt-2">Datum</label>
                                <input type="date" class="form-control" formControlName="due_date">

                                <label class="mt-2">Von Attributen übernehmen</label>
                                <table class="table table-sm table-hover table-attributes">
                                    <tbody>
                                    <tr *ngFor="let attribute of attributes" (click)="setDueDate(attribute)">
                                        <td>{{ attribute.name }}</td>
                                        <td>{{ attribute.value | date: "dd.MM.yyyy" }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-container *ngIf="form.value.type === 'recurring'">
                                <label class="mt-2">Intervall</label>
                                <select class="form-control" formControlName="interval">
                                    <option value="daily">Täglich</option>
                                    <option value="weekly">Wöchentlich</option>
                                    <option value="monthly">Monatlich</option>
                                    <option value="yearly">Jährlich</option>
                                </select>

                                <ng-container *ngIf="form.value.interval === 'weekly'">
                                    <label class="mt-2">Wochentag</label>
                                    <select class="form-control" formControlName="weekly_day">
                                        <option value="1">Montag</option>
                                        <option value="2">Dienstag</option>
                                        <option value="3">Mittwoch</option>
                                        <option value="4">Donnerstag</option>
                                        <option value="5">Freitag</option>
                                        <option value="6">Samstag</option>
                                        <option value="0">Sonntag</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="form.value.interval === 'monthly' || form.value.interval === 'yearly'">
                                    <label class="mt-2">Tag des Monats</label>
                                    <input type="number" min="0" max="31" class="form-control" formControlName="monthly_yearly_date">
                                </ng-container>
                                <ng-container *ngIf="form.value.interval === 'yearly'">
                                    <label class="mt-2">Monat</label>
                                    <input type="number" min="1" max="12" class="form-control" formControlName="yearly_month">
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="viewMode === ViewMode.VIEW">
                            <p>{{ form.value.type | taskType }}</p>
                            <ng-container *ngIf="form.value.type === 'singular'">
                                <label class="mt-2">Datum</label>
                                <p>{{ form.value.due_date | date: 'dd.MM.yyyy' }}</p>
                            </ng-container>
                            <ng-container *ngIf="form.value.type === 'recurring'">
                                <label>Intervall</label>
                                <p>{{ form.value.interval | reminderInterval }}</p>

                                <ng-container *ngIf="form.value.interval === 'weekly'">
                                    <label>Wochentag</label>
                                    <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.weekly_day | weekday }}</p>
                                </ng-container>
                                <ng-container *ngIf="form.value.interval === 'monthly' || form.value.interval === 'yearly'">
                                    <label>Tag des Monats</label>
                                    <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.monthly_yearly_date }}</p>
                                </ng-container>
                                <ng-container *ngIf="form.value.interval === 'yearly'">
                                    <label>Monat</label>
                                    <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.yearly_month }}</p>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label>Titel</label>
                <input type="text" class="form-control" formControlName="title" *ngIf="viewMode !== ViewMode.VIEW">
                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.title }}</p>
            </div>
            <div class="form-group">
                <label>Text</label>
                <textarea class="form-control" formControlName="text" *ngIf="viewMode !== ViewMode.VIEW" rows="8"></textarea>
                <p *ngIf="viewMode === ViewMode.VIEW">{{ form.value.text }}</p>
            </div>
        </form>

        <ng-container *ngIf="viewMode === ViewMode.VIEW">
            <button type="button" class="btn btn-primary mr-2" (click)="setMode(ViewMode.EDIT)">Bearbeiten</button>
            <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">Zurück</button>
            <button type="button" class="btn btn-danger" (click)="delete(form.value.TID)">Löschen</button>
        </ng-container>

        <ng-container *ngIf="viewMode === ViewMode.EDIT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="update()">Aktualisieren</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancelEdit()">Abbrechen</button>
        </ng-container>

        <ng-container *ngIf="viewMode === ViewMode.INSERT">
            <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="save()">Speichern</button>
            <button type="button" class="btn btn-secondary ml-2" (click)="cancel()">Abbrechen</button>
        </ng-container>
    </div>
</div>
