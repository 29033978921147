import {Pipe, PipeTransform, inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'yesNo',
    standalone: true
})
export class YesNoPipe implements PipeTransform {

    private translateService = inject(TranslateService);

    transform(value: boolean, ...args: unknown[]): string {
        return value === true ? 'Ja' : 'Nein';
    }

}
