import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'fileType',
    standalone: true
})
export class FileTypePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === 'text') {
            return 'Text';
        } else if (value === 'int') {
            return 'Ganze Zahl';
        } else if (value === 'dec') {
            return 'Kommazahl';
        } else if (value === 'date') {
            return 'Datum';
        } else if (value === 'boolean') {
            return 'Ja/Nein';
        } else {
            return '';
        }
    }

}
