import {Component} from '@angular/core';
import {RouterLink} from '@angular/router';

@Component({
    selector: 'app-public-footer',
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    imports: [RouterLink]
})
export class PublicFooterComponent {

}
