<button type="button" class="btn btn-primary mb-3" [attr.data-right]="'COMMENT_CREATE' | right" *ngIf="userService.hasRight('COMMENT_CREATE') && !showAddComment" (click)="add()">
    <i class="fas fa-plus"></i> Kommentar schreiben
</button>

<div *ngIf="showAddComment">
    <textarea name="comment" class="form-control" style="margin-bottom: 10px" rows="5" [(ngModel)]="newComment"></textarea>
    <button type="button" class="btn btn-primary" style="margin-right: 10px" (click)="save()" [disabled]="newComment == ''"><i class="fas fa-check"></i> Speichern</button>
    <button type="button" class="btn btn-secondary" (click)="cancelAdd()"><i class="fas fa-times"></i> Abbrechen</button>
</div>

<div class="text-muted" *ngIf="formService.getFormArray(form, 'comments').length == 0">Es wurden noch keine Kommentare geschrieben!</div>

<form [formGroup]="form">
    <div formArrayName="comments">
        <div class="media mt-3" [formGroupName]="i" *ngFor="let comment of formService.getFormArray(form, 'comments').controls; let i = index">
            <div class="media-body">
                <h5 class="mt-0">
                    {{ comment.value.user }}
                    <small>am {{ comment.value.created | date: 'dd.MM.yyyy, HH:mm' }}</small>
                    <button type="button" class="btn btn-primary btn-sm" style="margin-left: 10px; margin-right: 10px" *ngIf="isOwn(comment) && !comment.value._editing" (click)="edit(comment)"><i class="fas fa-pencil-alt"></i></button>
                    <button type="button" class="btn btn-secondary btn-sm" *ngIf="isOwn(comment) && !comment.value._editing" (click)="delete(comment)"><i class="fas fa-trash"></i></button>
                </h5>
                <div *ngIf="!comment.value._editing">{{ comment.value.content }}</div>
                <ng-container *ngIf="comment.value._editing">
                    <textarea formControlName="content" class="form-control" style="margin-bottom: 10px" rows="5"></textarea>
                    <button type="button" class="btn btn-primary" style="margin-right: 10px" (click)="update(comment)" [disabled]="comment.invalid"><i class="fas fa-check"></i> Aktualisieren</button>
                    <button type="button" class="btn btn-secondary" (click)="cancelEdit(comment)"><i class="fas fa-times"></i> Abbrechen</button>
                </ng-container>
            </div>
        </div>
    </div>
</form>
