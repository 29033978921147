import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';
import {myAppConfig} from '../../settings';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-account-disabled',
    templateUrl: './account-disabled.component.html',
    styleUrl: './account-disabled.component.scss',
    imports: [TranslateModule]
})
export class AccountDisabledComponent {

    private router = inject(Router);

    home(): void {
        this.router.navigate([myAppConfig.auth.afterLoginDestination]);
    }
}
