<p class="text-muted">Hier sehen Sie die Anzahl der Dokumente und Aufgaben, gruppiert nach den entsprechenden Kategorien.</p>

<div class="display-5 mb-3">{{ documents }} Dokumente</div>

<p class="text-muted" *ngIf="documents == 0">Es wurden noch keine Dokumente hochgeladen.</p>

<div class="card-deck mb-3" *ngIf="documents > 0 && stats">
    <ng-container *ngFor="let chart of charts">
        <div class="card" *ngIf="chart.category == 'documents'">
            <div class="card-header">
                {{ chart.name }}
            </div>
            <div class="card-body">
                <canvas baseChart
                        [data]="chart.data"
                        [options]="chart.options"
                        [type]="chart.type">
                </canvas>
            </div>
        </div>
    </ng-container>
</div>

<div class="display-5 mb-3">{{ tasks }} Aufgaben</div>

<p class="text-muted" *ngIf="tasks == 0">Es wurden noch keine Aufgaben hochgeladen.</p>

<div class="card-deck mb-3" *ngIf="tasks > 0 && stats">
    <ng-container *ngFor="let chart of charts">
        <div class="card" *ngIf="chart.category == 'tasks'">
            <div class="card-header">
                {{ chart.name }}
            </div>
            <div class="card-body">
                <canvas baseChart
                        [data]="chart.data"
                        [options]="chart.options"
                        [type]="chart.type">
                </canvas>
            </div>
        </div>
    </ng-container>
</div>
