import {Pipe, PipeTransform, inject} from '@angular/core';
import {FwTranslatable} from '../components/table/table.interfaces';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'translateLabel',
    pure: false,
    standalone: true
})
export class TranslateLabel implements PipeTransform {

    private translateService = inject(TranslateService);

    transform(value: FwTranslatable | string, ...args: unknown[]): string {
        if (typeof value === 'string') {
            return value;
        } else {
            return this.translateService.instant(value.label);
        }
    }

}
