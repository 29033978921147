import {Component, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {CryptoService} from '../../global/services/crypto.service';
import {DataService} from '../../global/services/data.service';
import {UrlData} from '../../interfaces';
import {NgIf, NgFor, DatePipe} from '@angular/common';

@Component({
    selector: 'app-admin-consulting-list',
    templateUrl: './admin-consulting-list.component.html',
    styleUrls: ['./admin-consulting-list.component.scss'],
    imports: [NgIf, NgFor, DatePipe]
})
export class AdminConsultingListComponent implements OnInit {

    private dataService = inject(DataService);
    private router = inject(Router);
    private cryptoService = inject(CryptoService);

    documents: any[] = [];

    ngOnInit(): void {
        this.dataService.request('universe.Admin/listDocumentsForConsulting').subscribe(response => {
            this.documents = response.documents;
        });
    }

    openDocument(document: any): void {
        const data: UrlData = {
            ID: document.DID,
            data: {
                dbName: document.dbName,
                CLID: document.CLID
            }
        };
        this.router.navigate(['/admin/document/detail', this.cryptoService.encrypt(data)]);
    }
}
